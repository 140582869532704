import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var MD5:any;//this way u can include other MD5 file/JQuery library
declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  userListData='';
  delAuthObj='';
  userStatus='';
  loginDataObj:any;
  configCustList:any;
  configCustListID:any;
  configCustListVersion:any;
  configNavList:any;
  userConfig=false;
  
  constructor(private router:Router, private route:ActivatedRoute, private http:HttpClient, private Services:CommonService){
    /*
    var status = AuthService.header({'event':'show', 'activeLink':'none', 'pageCheck':'authenticationPage'});
    if(status == false){ $location.path('/list'); };
    */
  }    
  ngOnInit() {
    this.Services.checkValidURL('authentication');
    this.loadAuthList();
  }

  loadAuthList = function(){
    $('body').addClass('loading');
    this.Services.listAuth({}).subscribe(res =>{
      this.userListData = res;
      $('body').removeClass('loading');
    },
    err => {
      $('body').removeClass('loading');
      console.log(err);
      return err;
    });
  }

  actionAuth = function(ths,act){
      if(act == 'del'){
        this.delAuthObj = ths;
      }
      else if(act == 'cancel'){
        this.delAuthObj = '';  
      }
      else if(act == 'confirmed'){
        var deleteData = { 'delArray':this.delAuthObj.user}
        this.Services.deleteAuth(deleteData).subscribe(res =>{
          $('#deleteAuthConfirmation .close').click();
          this.loadAuthList();
        },
        err => {
          console.log(err);
          return err;
        });
      }
      else if(act == 'toggle'){
        var auth = ths.auth;
        if(auth == true){ auth = false }
        else if(auth == false){ auth = true }
  
        var toggleData = { 'user':ths.user, 'auth':auth }
        this.Services.updateAuth(toggleData).subscribe(res =>{
          this.loadAuthList();
        },
        err => {
          console.log(err);
          return err;
        });
      }
      else if(act == 'backConfigure'){
        this.userConfig=false;
        this.loadAuthList();
      }
      else if(act == 'saveConfigure'){

        let serverObj1 = [];
        for(let m=0; m<this.configCustList.length; m++){
          if( this.configCustList[m].status == 'active' ){
            let serverObj2 = {
              'bookingName': this.configCustList[m].bookingName,
              'status': this.configCustList[m].status,
              'booking_checked': this.configCustList[m].booking_checked,
              'custValue': []
            };
            for(let n=0; n<(this.configCustList[m].custValue).length; n++){
              if( this.configCustList[m].custValue[n].server_checked == true ){
                serverObj2.custValue.push(
                  {
                    "ref":this.configCustList[m].custValue[n].ref,
                    "title":this.configCustList[m].custValue[n].title,
                    "source":this.configCustList[m].custValue[n].source,
                    "srNo":parseInt(this.configCustList[m].custValue[n].srNo),
                    "client_checked":this.configCustList[m].custValue[n].client_checked,
                    "server_checked":this.configCustList[m].custValue[n].server_checked,
                  }
                );
              }
            }
            serverObj1.push(serverObj2);
          }
        }

        let serverNavObj1=[];
        for(let m=0; m<this.configNavList.length; m++){
          if( this.configNavList[m].server_checked == true ){
            serverNavObj1.push(
              {
                "title": this.configNavList[m].title,
                "activeLink": this.configNavList[m].activeLink,
                "usr1": this.configNavList[m].usr1,
                "usr2": this.configNavList[m].usr2,
                "server_checked": this.configNavList[m].server_checked,
                "srNo": this.configNavList[m].srNo,
                "evnt": this.configNavList[m].evnt,
              }
            )
          }
        }
        
        let data = {
          "Version":parseInt(this.configCustListVersion)+1,
          "navigationLinks":serverNavObj1,
          "CustomizeSettings":serverObj1
        }
        let finalData = {
          "queryID":this.configCustListID,
          "data":data
        }
        
        this.Services.saveCustomizeList(finalData).subscribe(res =>{
          this.userConfig=false;
          this.loadAuthList();
        },
        err => {
          console.log(err);
          return err;
        });
      }
      else if(act == 'customizeListConfig'){
        this.userConfig = true;
        this.Services.viewOtherDetails({ 'Settings':'CustomizeList' }).subscribe(res =>{
          this.configCustListID = ths.myId;
          this.configCustListVersion = ths.configCustomizeList.Version;
          this.configCustList = res[0].CustomizeSettings;
          this.configNavList = res[0].navigationLinks;

          let thsObj = ths.configCustomizeList.CustomizeSettings;
          for(let t=0; t<thsObj.length; t++){
            for(let u=0; u<this.configCustList.length; u++){
              if( this.configCustList[u].bookingName == thsObj[t].bookingName && this.configCustList[u].status == 'active'){
                this.configCustList[u].booking_checked = thsObj[t].booking_checked;

                for(let v=0; v<thsObj[u].custValue.length; v++){
                  for(let w=0; w<(this.configCustList[u].custValue).length; w++){

                    if( this.configCustList[u].custValue[w].ref != undefined && thsObj[t].custValue[v].ref != undefined ){
                      if( this.configCustList[u].custValue[w].ref == thsObj[t].custValue[v].ref ){
                        this.configCustList[u].custValue[w].server_checked = true;
                        this.configCustList[u].custValue[w].client_checked = thsObj[t].custValue[v].client_checked;
                        this.configCustList[u].custValue[w].srNo = thsObj[t].custValue[v].srNo;
                        this.configCustList[u].custValue[w].source = thsObj[t].custValue[v].source;
                        this.configCustList[u].custValue[w].title = thsObj[t].custValue[v].title;
                        this.configCustList[u].custValue[w].ref = thsObj[t].custValue[v].ref;
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
          this.actionAuth('custList','srNo');

          let thsNavObj = ths.configCustomizeList.navigationLinks;
          for(let x=0; x<thsNavObj.length; x++){
            for(let y=0; y<this.configNavList.length; y++){ 
              if( this.configNavList[y].evnt == thsNavObj[x].evnt){
                this.configNavList[y].title = thsNavObj[x].title;
                this.configNavList[y].activeLink = thsNavObj[x].activeLink;
                this.configNavList[y].usr1 = thsNavObj[x].usr1;
                this.configNavList[y].usr2 = thsNavObj[x].usr2;
                this.configNavList[y].server_checked = thsNavObj[x].server_checked;
                this.configNavList[y].srNo = thsNavObj[x].srNo;
                this.configNavList[y].evnt = thsNavObj[x].evnt;
                break;
              }
            }
          }

          this.actionAuth('navList','srNo');
          setTimeout(function(){
            $('.authCustList h5').parent().find('ul').slideUp(20);
            setTimeout(function(){
              $('.authCustList h5').parent().find('ul').slideUp(10);
            },300);
          },200);
        },
        err => {
          console.log(err);
          return err;
        });
      }
      
      if(ths == 'custList'){
        for(let r=0; r<this.configCustList.length; r++){
          if( this.configCustList[r].status == 'active' ){
            for(let s=0; s<(this.configCustList[r].custValue).length; s++){
              if( this.configCustList[r].custValue[s].server_checked == false ){
                this.configCustList[r].custValue[s].srNo = 100;
              }
              else if( this.configCustList[r].custValue[s].server_checked == true && this.configCustList[r].custValue[s].srNo == 100 ){
                let serverCheckedLength = (JSON.stringify(this.configCustList[r].custValue).split('"server_checked":true')).length-2;
                this.configCustList[r].custValue[s].srNo = serverCheckedLength;
              }
            }
            this.configCustList[r].custValue = this.Services._ObjNumOrdering( true, 'srNo', this.configCustList[r].custValue );        
          }
        }
      }

      if(ths == 'navList'){
        for(let r=0; r<this.configNavList.length; r++){
          if( this.configNavList[r].server_checked == false ){
            this.configNavList[r].srNo = 20;
          }
          else if( this.configNavList[r].server_checked == true && this.configNavList[r].srNo == 20 ){
            let serverCheckedLength = (JSON.stringify(this.configNavList).split('"server_checked":true')).length-2;
            this.configNavList[r].srNo = serverCheckedLength;
          }
        }
        this.configNavList = this.Services._ObjNumOrdering( true, 'srNo', this.configNavList );        
      }

      
    }
}
