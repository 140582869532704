import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notificationFormData;
  notiList=[];

  constructor(private http:HttpClient, private Services:CommonService) {
    //var status = AuthService.header({'event':'show', 'activeLink':'none', 'pageCheck':'notificationPage'});
    //if(status == false){ $location.path('/list') };

    //let obj = {companyId:localStorage._bookingCID,companyUNM:localStorage._bookingUNM};
    //this.http.post('/user/viewOtherDetails',obj).subscribe(res =>{
  }

  ngOnInit() {
    this.Services.checkValidURL('notification');
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      this.notiList = res[0].notification;
    },
    err => {
      console.log(err);
    })
  }

}
