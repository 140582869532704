import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';


import { LoginComponent } from './login/login.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { LogComponent } from './log/log.component';
import { MastersComponent } from './masters/masters.component';
import { RegisterComponent } from './register/register.component';
import { ListComponent } from './list/list.component';
import { ReportsComponent } from './reports/reports.component';
import { RequestComponent } from './request/request.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { AddComponent } from './add/add.component';



import { AirService } from './add/air.service';
import { BusService } from './add/bus.service';
import { TrainService } from './add/train.service';
import { CommonService } from './common.service';
import { AuthGuard } from './auth.guard';


import { FileSelectDirective } from 'ng2-file-upload';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AuthenticationComponent,
    DashboardComponent,
    HeaderComponent,
    InfoComponent,
    SettingsComponent,
    NotificationComponent,
    LogComponent,
    MastersComponent,
    RegisterComponent,
    ListComponent,
    ReportsComponent,
    RequestComponent,
    ThankyouComponent,
    AddComponent,
    
    
    FileSelectDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [CommonService, AirService, BusService, TrainService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
