import { Injectable } from '@angular/core';

@Injectable()
export class TrainService {

  constructor() { }
  //private monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public monthArrSmallTrain = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  
  public getTrainSrcData = function(src, frm, to, typ, myCase) {
    var tempSrc = src;
    if (tempSrc == undefined) { this.autoFillContentFlag = true; return ''; }
    if (myCase == 'convertToSmallCase') { tempSrc = tempSrc.toLowerCase(); }

    var a = tempSrc.split(frm)[1];
    if (a == undefined) { this.autoFillContentFlag = true; return ''; }
    if (to != '') {
        a = a.split(to)[0];
        if (a == undefined) { this.autoFillContentFlag = true; return ''; }
    }
    if (typ == 'num') { return parseInt(a); } else if (typ == 'str') {
        return a.trim();
    } else if (typ == 'numDecimal') { return a.replace(/ /g, '') * 1 }
  }

  public capFirstLetter = function(param) {
    try {
        var arr = (param + ' ').split(' ');
        var finalStr = '';
        for (var i = 0; i < arr.length; i++) {
            finalStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            if (i < (arr.length - 2)) { finalStr += ' ' }
        }
        return finalStr.replace(/  /g, ' ');
      } catch (err) {
        console.log('capFirstLetter');
        console.log('err-----><----',err);
    }
  }

  public getOnlyNumbersAndDots(str){
    return str.replace(/[^0-9.]/g, "")*1;
  }

  public autoFillPdf = function(src, myObj, trainClassList, agentList, trainQuotaList, clientList, all_flagShowTravelAgentCharge){
    
      var compressedSrc = src.replace(/\t|\x0A| /g, '');

      //var get_trainNo = function() {
        try {
            let t1 = this.getTrainSrcData(src, 'train no. & name', 'quota:', 'str', 'convertToSmallCase').replace(/:| /g, '').replace(/\/|-/g, '##').split('##')[0];
            myObj.trainNo = t1;
        } 
        catch (err) {
            console.log('err----->get_trainNo<----',err);
        }
      //}
      
      //var get_dateOfJourney = function() {
        try {
            var dt = this.getTrainSrcData(src, 'date of journey', 'to', 'str', 'convertToSmallCase').replace(/:| |-|\//g, '');
            var dd = parseInt(dt.charAt(0) + '' + dt.charAt(1)) + '';
            var mm = function() {
                let m1:any = '';
                let m2:any = '';
                if (dt.length == 8 || dt.length == 9 || dt.length == 11) {
                    //m2 = this.monthArrSmallTrain;
                    m2 = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

                    if (dt.length == 8) {
                        m1 = parseInt(dt.charAt(2) + '' + dt.charAt(3)) + 1;
                    } else if (dt.length == 9) {
                        m1 = (parseInt(m2.indexOf(dt.charAt(2) + '' + dt.charAt(3) + '' + dt.charAt(4) + '')) + 1) + '';
                    } else if (dt.length == 11) {
                        m1 = (parseInt(m2.indexOf(dt.charAt(3) + '' + dt.charAt(4) + '' + dt.charAt(5) + '')) + 1) + '';
                    }
                    else{
                        m1=0; 
                    }
                } else {
                    alert('Please select Journey Month');
                }
                return m1 + '';
            }
            var yy = function() {
                var l = dt.length;
                var y1 = parseInt(dt.charAt(l - 4) + '' + dt.charAt(l - 3) + '' + dt.charAt(l - 2) + '' + dt.charAt(l - 1)) + '';
                return y1;
            }
            myObj.journeyDateDD = dd;
            myObj.journeyDateMM = mm();
            myObj.journeyDateYY = yy();
        } 
        catch (err) {
            console.log('err----->get_dateOfJourney<----',err);
        }
      //}
      //var get_transactionNo = function() {
        try {
            var t1 = this.getTrainSrcData(src, 'transaction id', 'date', 'str', 'convertToSmallCase');
            if (t1.length < 5) {
                t1 = this.getTrainSrcData(src, 'reference id', 'date', 'str', 'convertToSmallCase');
            }
            myObj.transactionNo = t1.replace(/:| /g, '');
        } 
        catch (err) {
            console.log('err----->get_transactionNo<----',err);
        }
      //}
      
      //var get_fromTo = function() {
        try {
            var data = this.getTrainSrcData(src, 'date & time', 'boarding', 'str', 'convertToSmallCase');
            var f1 = data.split('from')[1].split('date')[0].replace(':', '').replace(/ *\([^)]*\) */g, "");
            var t1 = data.split(' to')[1].replace(':', '').replace(/ *\([^)]*\) */g, "");
            var b1 = this.getTrainSrcData(src, 'boarding', 'date', 'str', 'convertToSmallCase').replace(':', '').split('(')[0];
            
            myObj.from = this.capFirstLetter(f1);
            myObj.to = this.capFirstLetter(t1);
            myObj.boarding = this.capFirstLetter(b1);
        } 
        catch (err) {
            console.log('err----->get_fromTo<----',err);
        }
      //}
      
      //var get_class = function() {
        try {
            var a = this.getTrainSrcData(src, 'date & time', 'from', 'str', 'convertToSmallCase').replace(/:/g, '(').replace(')', '').replace(/ /g, '').split('(');
            var a1 = a[a.length - 1].toLowerCase().toString();
            var b = trainClassList;
            var c = '';
            for (var i = 0; i < b.length; i++) {
                if (((b[i].className).toLowerCase().toString()) == a1) {
                    c = b[i].className;
                    break;
                }
            }
            myObj.class = c;
        } 
        catch (err) {
            console.log('err----->get_class<----',err);
        }
      //}

      //var get_pnr = function() {
        try {
            var p1 = this.getTrainSrcData(src, 'pnr no', 'train', 'str', 'convertToSmallCase').replace(/ /g, '').replace('.', '').replace(':', '');
            myObj.pnrNo = p1;
        } 
        catch (err) {
            console.log('err----->get_pnr<----',err);
        }
      //}

      //var get_MobileNo = function() {
        try {
            let m1 = this.getTrainSrcData(src, 'Mobile', 'Distanc', 'str', 'convertToSmallCase');
            let m2 = this.getOnlyNumbersAndDots(m1);
            console.log('m1>>>>>>>>> ??? ',m1,m2);
            myObj.contactNo1 = m1;
        } 
        catch (err) {
            console.log('err----->get_MobileNo<----',err);
        }
      //}

      //var get_quota = function() {
        try {
            var a = this.getTrainSrcData(src, 'quota', 'id', 'str', 'convertToSmallCase').replace(/transaction|reference|:/g, '').replace(/ /g, '').replace(/\x0A/gi, '').replace(/ *\([^)]*\) */g, "");
            var b = trainQuotaList;
            var c = '';
            if (a == 'gn') { c = 'general' } else if (a == 'lb') { c = 'lower' } else if (a == 'pt') { c = 'primier' } else if (a == 'vp') { c = 'vip' } else if (a == 'tq') { c = 'tatkal' } else if (a == 'ld') { c = 'ladies' }
            for (var i = 0; i < b.length; i++) {
                if ( ((b[i].quotaName).toLowerCase().toString() ) == a) {
                    c = b[i].quotaName;
                    break;
                }
                else if ( (b[i].quotaName).toLowerCase().indexOf(a) != -1 ) {
                    c = b[i].quotaName;
                    break;
                }
            }
            
            myObj.quota = c;
            if (c == '') {
                alert('Please select the Quota');
            }
        } 
        catch (err) {
            console.log('err----->get_quota<----',err);
        }
      //}
      //var get_chargerPg = function() {
        try {
            if (myObj.chargesDetails.pg == 0) {
                var p1;
                if (src.indexOf('Payment Gateway Charges') > 0) {
                    ///only for IFTSPL Agent
                    p1 = (this.getTrainSrcData(src, 'payment gateway charges', 'rupees', 'str', 'convertToSmallCase').replace('# rs.', '')) * 1;
                } else {
                    p1 = (this.getTrainSrcData(src, 'pg charges', 'rupees', 'str', 'convertToSmallCase').replace(/ |[#]*/g, '').replace('rs.', '')) * 1;
                    if( isNaN(p1) ){
                        p1 = this.getOnlyNumbersAndDots(this.getTrainSrcData(src, 'pg charges', 'rupees', 'str', 'convertToSmallCase'));
                    }
                }
                myObj.chargesDetails.pg = p1;
            }
        } 
        catch (err) {
            console.log('err----->get_chargerPg<----',err);
        }
      //}
      //var get_catering = function() {
        try {
            var ct1 = 0;
            if (src.indexOf('Catering') != -1) {
                ct1 = (this.getTrainSrcData(src, 'catering charges', 'rupees', 'str', 'convertToSmallCase').replace(/ /g, '').split(')')[1]) * 1;
                if( isNaN(ct1) ){
                    ct1 = this.getOnlyNumbersAndDots(this.getTrainSrcData(src, 'catering charges', 'rupees', 'str', 'convertToSmallCase'));
                }
                console.log('ct1---0', ct1);
            } else {
                ct1 = 0;
                console.log('ct1---1', ct1);
            }
            console.log('ct1---2', ct1);
            myObj.chargesDetails.catering = ct1;
        } 
        catch (err) {
            console.log('err----->get_catering<----',err);
        }
      //}
      //var get_chargerTF = function() {
        try {
            var tf1 = (this.getTrainSrcData(src, 'ticket fare', 'rupees', 'str', 'convertToSmallCase').replace(/ |[*]*/g, '').replace('rs.', '')) * 1;
            if( isNaN(tf1) ){
                tf1 = this.getOnlyNumbersAndDots(this.getTrainSrcData(src, 'ticket fare', 'rupees', 'str', 'convertToSmallCase'));
            }
            myObj.chargesDetails.ticketFare = tf1;
        } 
        catch (err) {
            console.log('err----->get_chargerTF<----',err);
        }
        //}
        //var get_chargerET = function() {
        try {
            var et = (this.getTrainSrcData(src, 'irctc service charge', 'rupees', 'str', 'convertToSmallCase').replace(/ |#|[*]*/g, '').replace('rs.', ''));
            if(et.indexOf(')') != 0){ et = et.split(')')[1] }
            myObj.chargesDetails.eTicket = et*1;
        } 
        catch (err) {
            console.log('err----->get_chargerET<----',err);
        }
      //}
      //var get_chargerSC = function() {
        try {
            //this.addAction('updateCharger', 'clientSelectChange');
            var cl1 = myObj.client;
            var cl2 = clientList;
            for (var i = 0; i < cl2.length; i++) {
                if (cl2[i].clientName == cl1) {
                    myObj.chargesDetails.service = cl2[i].service;
                    break;
                }
            }
        } 
        catch (err) {
            console.log('err----->get_chargerSC<----',err);
        }
      //}
      //var get_travelAgentCharge = function() {
        try {
          if (all_flagShowTravelAgentCharge == true) {
              var ta1 = (this.getTrainSrcData(src, 'agent service charge', 'rupees', 'str', 'convertToSmallCase').replace(/ |[s#]*/, '').replace('rs.', '')) * 1;
              if( isNaN(ta1) ){
                ta1 = this.getOnlyNumbersAndDots(this.getTrainSrcData(src, 'agent service charge', 'rupees', 'str', 'convertToSmallCase'));
              }
              myObj.chargesDetails.travelAgentCharge = ta1;
          }
        } catch (err) {
            console.log('err----->get_travelAgentCharge<----',err);
        }
        
      //}
      //var get_passengerDetails = function() {
        try {
            var a1 = this.getTrainSrcData(src, 'adult', 'passenger', 'str', 'convertToSmallCase').replace(/ |[:]*/g, '').split('child');
            var a2 = (a1[0]) * 1,
                a3 = (a1[1]) * 1;
            myObj.adult = a2;
            myObj.child = a3;

            var p1 = this.getTrainSrcData(src, 'current status', 'agent', 'str', 'convertToSmallCase').replace(/ \/ /g, '/');
            var p2 = p1.replace(/veg/gi, '').replace(/srctzn/gi, '').replace(/srctnw/gi, '').replace(/null/gi, '').replace(/female/gi, 'f').replace(/male/gi, 'm');
            var p3 = p2.split('\x0A');
            var p4 = [];

            if (p1.charAt(0) != '1') {
                p3.splice(0, 1);
            }
            if (p3[0].charAt(0) != '1') {
                p3.splice(0, 1);
            }
            for (var x = 0; x < a2; x++) {
                p4.push(p3[x]);
            }

            for (var y = 0; y < a2; y++) {
                while (p4[y].charAt(0) === ' ') {
                    p4[y] = p4[y].substr(1);
                }
                while (p4[y].charAt(0) === '1' || p4[y].charAt(0) === '2' || p4[y].charAt(0) === '3' || p4[y].charAt(0) === '4' || p4[y].charAt(0) === '5' || p4[y].charAt(0) === '6') {
                    p4[y] = p4[y].substr(1);
                }
                while (p4[y].charAt(0) === ' ') {
                    p4[y] = p4[y].substr(1);
                }
            }

            for (var i = 0; i < a2; i++) {
                var p5 = p4[i].split(' ');
                var obj = { empId: '', empLevel: '', name: '', age: 0, sex: '', coachNoSeat: '' }

                for (var j = 0; j < p5.length; j++) {
                    var p8 = p5[j];

                    if (obj.age == 0) {
                        if (isNaN(p8)) { obj.name += this.capFirstLetter(p8) + ' ' } else { obj.age = p8; }
                    } else if (p8 == 'm' || p8 == 'f') {
                        obj.sex = this.capFirstLetter(p8);
                    } else if (obj.coachNoSeat == '') {
                        //console.log(p8, p8.toUpperCase(), p5[j+1], p5[j+2]);
                        //obj.coachNoSeat = p8.toUpperCase();
                        obj.coachNoSeat = p5[j].toUpperCase();
                        if(p5[j+2] != undefined ){
                            obj.coachNoSeat = p5[j+2].toUpperCase();
                        }
                        else if(p5[j+1] != undefined ){
                            obj.coachNoSeat = p5[j+1].toUpperCase();
                        }
                    } else {
                        break;
                    }
                }
                eval('myObj.pd' + i + ' = obj');
                //myObj.passengerDetails[i] = obj;
            }
        } catch (err) {
            console.log('err----->get_passengerDetails<----',err);
        }
      //}
            
      //var get_quota = function() {
        try {
            myObj.chargesDetails.quota = 0;
        } catch (err) {
            console.log('err----->get_quota<----',err);
        }
      //}
                
      //var get_quota = function() {
        try {
            var cnt1 = this.getTrainSrcData(src, 'obile', 'istance', 'str', '');
            var cnt2 = cnt1.replace(':', '').replace(/[A-Z ]/gi, '');
            myObj.contactNo2 = cnt2;
        } catch (err) {
            console.log('err----->get_quota<----',err);
        }
      //}

      //var get_agentDetails = function() {
          try {
              var at1 = this.getTrainSrcData(src, 'agent detail', 'corporate name', 'str', 'convertToSmallCase').replace(/\x0A/g, ' ').split(':')[2].replace(/ /g, '5');
              var at2 = at1 + ' ';
              if (at1.charAt(0) == '5') { at2 = at1.replace('5', ''); }
              var at3 = at2.replace(/5/g, ' ').split(' ')[0];
              var at5 = agentList;
              //console.log(at5);
              for (var i = 0; i < at5.length; i++) {
                  if (((at5[i].agentType).toLowerCase().indexOf(at3) >= 0 || (at5[i].agentPaymentMode).toLowerCase().indexOf(at3) >= 0)) {
                      myObj.agentDetails.agentType = at5[i].agentType;
                      myObj.agentDetails.payment = at5[i].payment;
                      myObj.agentDetails.userId = at5[i].userId;
                      myObj.agentDetails.agentGSTIN = at5[i].agentGSTIN;
                      break;
                  }
              };
          } catch (err) {
            console.log('err----->get_agentDetails<----',err);
        }
      //}
      console.log(myObj);
      return myObj;

    /*
        get_trainNo();
        get_dateOfJourney();
        get_transactionNo();
        get_fromTo();
        get_class();
        get_pnr();
        get_quota();

        get_catering();
        get_chargerPg();
        get_chargerTF();
        get_chargerET();
        get_chargerSC();
        get_travelAgentCharge();
        get_passengerDetails();
        get_agentDetails();

        this.autoFillTrainArea = '';
        //this.addAction('getTrainName');
        this.addAction('updateChargerOnBlur');
        this.addAction('paymentStatusSorting')

        setTimeout(()=> {
            this.addAction('updateChargerOnBlur')
            this.addAction('updateCharger');
            this.addAction('paymentStatusSorting');
        }, 500);
    */
  }
}
