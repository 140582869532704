import { Injectable } from '@angular/core';

@Injectable()
export class AirService {

  constructor() { }
  private monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  public getAirSrcData = function(src, frm, to, typ) {
    if (src == undefined) { this.autoFillContentFlag = true; return ''; }
    var a = src.split(frm)[1];
    if (a == undefined) { this.autoFillContentFlag = true; return ''; }
    if (to != '') {
        a = a.split(to)[0];
        if (a == undefined) { this.autoFillContentFlag = true; return ''; }
    }
    if (typ == 'num') { return parseInt(a); } else if (typ == 'str') {
        return a.trim();
    } else if (typ == 'numDecimal') { return a.replace(/ /g, '') * 1 }
  }

  public autoFillHtml = function(src, e, myObj, agentList){
      if (e == 'akbarTravels_goAir') {
          myObj.airName = 'Go Air';

          var infoData1 = this.getAirSrcData(src, 'TOUR CODE', 'Passenger', 'str');
          var infoData2 = infoData1.split('\x0A')[0].split(')/'); /////    BOM (Mumbai 1)/LKO (Lucknow ) G8 - 397 02 Jul'17
          var infoData3 = infoData1.replace(/\x0A/g, ' ').split(' hrs');
          var infoData4 = infoData3[0].split(' ');
          var infoData5 = infoData3[1].split(' ');

          myObj.from = infoData2[0].split('(')[1];
          myObj.to = infoData2[1].split('(')[1].split(')')[0];
          var date = infoData2[1].split(' - ')[1].replace("'", " 20").split(' ');
          myObj.journeyDateDD = parseInt(date[1]) + '';
          myObj.journeyDateMM = (parseInt(this.monthArr.indexOf(date[2])) + 1) + '';
          myObj.journeyDateYY = parseInt(date[3]) + '';
          myObj.airFlight = infoData2[1].split(')')[1].split(' - ')[0].replace(' ', '') + ' ' + date[0];
          myObj.pnrNo = this.getAirSrcData(src, 'PNR', 'Effective', 'str').replace(/:| /g, '');

          myObj.airReference_AO = '';
          myObj.airReference_Airline = '';
          myObj.airReference_CRS = '';

          myObj.airDepTime = infoData4[infoData4.length - 1];
          myObj.airDepTerminal = '';
          myObj.airArrTime = infoData5[infoData5.length - 1];
          myObj.airArrTerminal = '';

          myObj.airBaggage_purchased = this.getAirSrcData(src, 'Purchased Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_cabin = this.getAirSrcData(src, 'Cabin Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_free = this.getAirSrcData(src, 'Free Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';


          var str0 = this.getAirSrcData(src, 'TOUR CODE', '(', 'str').replace(/ /g, '');
          var str1 = this.getAirSrcData(src, 'Seats Baggage', 'Price Summary', 'str').replace(/Mrs|MRS|MR|MS/gi, '##').replace(/  /g, ' ');
          var str2 = str1.split('\x0A');
          var str3 = str1.split('Purchased Baggage').length - 1;
          var str4 = str1.split('##');

          for (var i = 0; i < str3; i++) {
              var reg = new RegExp(str0, "g")
              var nm1 = str4[(i + 1)].split(reg)[0];
              eval('myObj.pd' + i + '={}');
              eval('myObj.pd' + i + '.name = nm1');
          }
          myObj.adult = str3;

          var cst1 = this.getAirSrcData(src, 'Price Summary', 'Amount to pay', 'str').split('\x0A');
          var obj = [];

          for (var i = 0; i < cst1.length; i++) {
              var s1 = cst1[i].split(' ');
              var l = s1.length;
              if (l >= 2) {
                  var n = parseInt(s1[l - 1]);
                  if (!isNaN(n) && n > 0) {
                      var s2 = cst1[i].split(n)[0];

                      if (s2.indexOf('fuelsurchage') != -1) {
                          myObj.chargesDetails.ticketFare = n;
                          continue;
                      } else if (s2.indexOf('Total') != -1) {
                          myObj.chargesDetails.totalOtherChargesData = n;
                          continue;
                      }

                      if (s2.indexOf('Discount') > 1) {
                          n = n * -1;
                      }
                      obj.push({ "title": s2, "amount": n });
                  }
              }
          };

          myObj.chargesDetails.taxes = 0;
          myObj.chargesDetails.discountFrmAirlines = 0;
          myObj.chargesDetails.totalOtherChargesData = obj;

      } else if (e == 'akbarTravels_spiceJet') {
          myObj.airName = 'Spice Jet';

          var infoData1 = this.getAirSrcData(src, 'AIRLINE TOURCODE', 'Base Fare', 'str').split('\x0A')[0].split('/');
          var infoData2 = infoData1[0].split(' ');
          var infoData3 = infoData1[1].split(' ');
          var infoData4 = (infoData1[2].split(' SPICEJET')[0].split(' ')).reverse();
          infoData2[2] = infoData2[2][0] + infoData2[2][1].toLowerCase() + infoData2[2][2].toLowerCase();

          myObj.journeyDateDD = parseInt(infoData2[1]) + '';
          myObj.journeyDateMM = (parseInt(this.monthArr.indexOf(infoData2[2])) + 1) + '';
          myObj.journeyDateYY = parseInt(infoData2[3]) + '';
          myObj.airFlight = infoData2[4] + ' ' + infoData2[5];
          myObj.from = infoData2[6];
          myObj.to = infoData3[1];

          myObj.airDepTime = infoData4[1];
          myObj.airDepTerminal = infoData3[0];
          myObj.airArrTime = infoData4[0];
          myObj.airArrTerminal = infoData4[2];

          myObj.pnrNo = this.getAirSrcData(src, 'PNR)', 'Booking', 'str').replace(/:| /g, '');
          myObj.airBaggage_purchased = this.getAirSrcData(src, 'Purchased Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_cabin = this.getAirSrcData(src, 'Cabin Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_free = this.getAirSrcData(src, 'Free Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';

          myObj.airReference_AO = '';
          myObj.airReference_Airline = '';
          myObj.airReference_CRS = '';

          let str0:any = this.getAirSrcData(src, 'Bagg Out First', 'TRAVEL DATE', 'str').replace(/Mrs|MRS|MR|MS/gi, '').replace(/  /g, ' ');
          let str1:any = str0.split('\x0A');
          let str2:any = str0.split('Purchased Baggage').length - 1;
          let str3:any = str1[0].replace(/-/g, '').replace(/  /g, ' ').split(' ');
          let str4:any = ' ' + str3[str3.length - 2].replace(/ /g, '');

          for (var i = 0; i < str2; i++) {
              var s = (i + 1) + '. ';
              var nm1 = str0.split(s)[1].split(str4)[0].split(' ');
              var nm2 = '';
              for (var j = 0; j < nm1.length - 1; j++) { nm2 += nm1[j] + ' '; }
              eval('myObj.pd' + i + '={}');
              eval('myObj.pd' + i + '.name = nm2');
          }
          myObj.adult = str2;

          var cst1 = this.getAirSrcData(src, 'SPICEJET', 'Information', 'str').split('\x0A');
          var obj = [];

          for (var i = 0; i < cst1.length; i++) {
              var s1 = cst1[i].split(' ');
              var l = s1.length;
              if (l >= 2) {
                  var n = parseInt(s1[l - 1]);
                  if (!isNaN(n) && n > 0) {
                      var s2 = cst1[i].split(n)[0];
                      if (s2.indexOf('Base Fare') != -1) {
                          myObj.chargesDetails.ticketFare = n;
                          continue;
                      } else if (s2.indexOf('Total Price') != -1) {
                          myObj.chargesDetails.totalOtherChargesData = n;
                          continue;
                      }

                      if (s2.indexOf('Discount') > 1) {
                          n = n * -1;
                      }
                      obj.push({ "title": s2, "amount": n });
                  }
              }
          };

          myObj.chargesDetails.taxes = 0;
          myObj.chargesDetails.discountFrmAirlines = 0;
          myObj.chargesDetails.totalOtherChargesData = obj;

      } else if (e == 'akbarTravels_jetAirways' || e == 'akbarTravels_airIndia') {
          myObj.airName = 'Jet Airways';
          if (e == 'akbarTravels_airIndia') {
              myObj.airName = 'Air India';
          }

          var infoData1 = this.getAirSrcData(src, 'Code Airline', 'Numbers', 'str').split('\x0A')[0].split(' ');
          var infoData2 = this.getAirSrcData(src, 'Itinerary', 'Flight', 'str').split(' ');

          myObj.from = infoData2[0];
          myObj.to = infoData2[infoData2.length - 1];
          myObj.airSector = myObj.from +' - '+ myObj.to;

          var flightNo = src.split('Flight No')[2].split('|')[0].replace(':', '').replace('.', '').replace(/ /g, '');
          myObj.airFlight = flightNo;
          myObj.pnrNo = this.getAirSrcData(src, 'Numbers', 'Booking', 'str').split('\x0A')[0];
          var date = this.getAirSrcData(src, 'Travel Date', 'Airline', 'str').replace(':', '').replace(/ /, '').replace("'", " 20").split(' '); // : 25 Aug'17
          myObj.journeyDateDD = parseInt(date[0]) + '';
          myObj.journeyDateMM = parseInt(this.monthArr.indexOf(date[1])) + '';
          myObj.journeyDateYY = parseInt(date[2]) + '';

          myObj.airBaggage_purchased = this.getAirSrcData(src, 'Purchased Baggage', 'Kg', 'str').replace('=', '').replace(/ /g, '') + ' kg';
          myObj.airBaggage_cabin = this.getAirSrcData(src, 'Cabin Baggage', 'Kg', 'str').replace('=', '').replace(/ /g, '') + ' kg';
          myObj.airBaggage_free = this.getAirSrcData(src, 'Free Baggage', 'Kg', 'str').replace('=', '').replace(/ /g, '') + ' kg';
          myObj.airReference_AO = this.getAirSrcData(src, 'AO Ref', '\x0A', 'str').replace(/[ :.\t]/g, '').replace('/',' / ');
          myObj.airReference_Airline = this.getAirSrcData(src, 'Airline Ref', '\x0A', 'str').replace(/[ :.\t]/g, '').replace('/',' / ');
          myObj.airReference_CRS = this.getAirSrcData(src, 'CRS Ref', '\x0A', 'str').replace(/[ :.\t]/g, '').replace('/',' / ');

          var str1 = this.getAirSrcData(src, 'Code Airline', 'Numbers', 'str').split(' ');
          for (var i = 0; i < str1.length; i++) {
              myObj.airDepTime = str1[2];
              if (str1[i] == flightNo) { myObj.airDepTerminal = str1[i + 1]; break; }
          };

          var str2 = this.getAirSrcData(src, 'Arrival Duration', 'Flight Services', 'str').split('\x0A')[0].replace(/  /g, ' ').split(' ').reverse();
          if (parseInt(str2[3]) == 1 || parseInt(str2[3]) == 2) { myObj.airArrTerminal = str2[3]; } else { myObj.airArrTerminal = ''; }
          myObj.airArrTime = str2[1];

          let str3:any = this.getAirSrcData(src, 'Information', 'Date Dep', 'str').replace(/[0-9]|,|MRS|MR|MS/g, '').split(')');
          let str4:any = str3.length - 1;
          console.log(str3);
          console.log(str4);
          //
          for (var i = 0; i < str4; i++) {
              //myObj.pd0 = {"name":str3[i+1]};
              var val1 = str3[i+1];
              console.log(val1);
              //>>>>>>>>eval('myObj.pd'+i+' = {"name":'+val1+'}');
              //>>>>>>>>eval('myObj.pd' + i + '={}');
              //>>>>>>>>eval('myObj.pd' + i + '.name = '+val1);
          }

          var objAmt = this.getAirSrcData(src, 'Fees Total', 'Total SSR', 'str').split('\x0A');
          if (objAmt.length >= 1) {
              objAmt = objAmt[objAmt.length - 1].split(' '); //1209 117 1483 0 0 2809
          } else {
              objAmt = objAmt[0].split(' '); //1209 117 1483 0 0 2809
          }
          var objAmtRev = objAmt.reverse(); //Reversing will bring the values First and Name at the end. //Dont change this flow
          var obj = [];

          obj.push({ "title": "Airline Serv Tax", "amount": objAmtRev[4] * str4 });
          if (!(objAmtRev[3] == 0 || objAmtRev[3] == '')) {
              obj.push({ "title": "Tax & Charges", "amount": objAmtRev[3] * str4 });
          }
          if (!(objAmtRev[2] == 0 || objAmtRev[2] == '')) {
              obj.push({ "title": "Discounts", "amount": objAmtRev[2] * str4 });
          }

          myObj.adult = str4;
          myObj.chargesDetails.ticketFare = objAmtRev[5] * str4;
          myObj.chargesDetails.taxes = 0;
          myObj.chargesDetails.discountFrmAirlines = 0;
          myObj.chargesDetails.totalOtherChargesData = obj;

      } else if (e == 'akbarTravels_indigo') {
          myObj.airName = 'Indigo';

          var infoData1 = this.getAirSrcData(src, 'Tour Code', 'Base Fare', 'str').replace("'", " 20").split(' ');
          infoData1[1] = (parseInt(this.monthArr.indexOf(infoData1[1])) + 1) + '';

          myObj.journeyDateDD = parseInt(infoData1[0]) + '';
          myObj.journeyDateMM = parseInt(infoData1[1]) + '';
          myObj.journeyDateYY = parseInt(infoData1[2]) + '';
          myObj.airDepTime = infoData1[3];
          myObj.from = infoData1[4];
          myObj.to = infoData1[5];

          myObj.airArrTime = infoData1[infoData1.length - 2];
          let airDepTerminal:any = parseInt(infoData1[infoData1.length - 3]);
          if (airDepTerminal > 5) { airDepTerminal = '-'; }
          myObj.airDepTerminal = airDepTerminal;
          if (airDepTerminal == '-') {
              myObj.airFlight = infoData1[infoData1.length - 4] + ' ' + infoData1[infoData1.length - 3];
          } else {
              myObj.airFlight = infoData1[infoData1.length - 5] + ' ' + infoData1[infoData1.length - 4];
          }

          myObj.airBaggage_purchased = this.getAirSrcData(src, 'Purchased Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_cabin = this.getAirSrcData(src, 'Cabin Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airBaggage_free = this.getAirSrcData(src, 'Free Baggage', 'Kg', 'str').replace(/=| /g, '') + ' kg';
          myObj.airReference_AO = '';
          myObj.airReference_Airline = '';
          myObj.airReference_CRS = '';

          myObj.referenceNo = myObj.pnrNo = this.getAirSrcData(src, 'Date of Booking', 'Passenger', 'str').replace(/:/g, '').split(' ')[0];
          myObj.airArrTerminal = '-';

          let str0:any = this.getAirSrcData(src, 'Seats Meals', 'IndiGo Flight', 'str').replace(/Mrs|MRS|MR|MS/gi, '').replace(/  /g, ' ');
          let str1:any = str0.split('\x0A');
          let str2:any = str0.split('Purchased Baggage').length - 1;
          let str3:any = str1[0].replace(/-/g, '').replace(/  /g, ' ').split(' ');
          let str4:any = ' ' + str3[str3.length - 2].replace(/ /g, '');

          for (var i = 0; i < str2; i++) {
              var s = (i + 1) + '. ';
              var nm1 = str0.split(s)[1].split(str4)[0].split(' ');
              var nm2 = '';
              for (var j = 0; j < nm1.length - 1; j++) { nm2 += nm1[j] + ' '; }
              eval('myObj.pd' + i + '={}');
              eval('myObj.pd' + i + '.name = nm2');
          }
          myObj.adult = str2;


          var cst1 = this.getAirSrcData(src, 'mobile Phone', 'Amount To Pay', 'str').split('\x0A');
          var obj = [];

          for (var i = 0; i < cst1.length; i++) {
              var s1 = cst1[i].split(' ');
              var l = s1.length;
              if (l >= 2) {
                  var n = parseInt(s1[l - 1]);
                  if (!isNaN(n) && n > 0) {
                      var s2 = cst1[i].split(n)[0];
                      if (s2.indexOf('Total Price') != -1) {
                          myObj.chargesDetails.totalOtherChargesData = n;
                          continue;
                      }
                      if (s2.indexOf('Discount') > 1) {
                          n = n * -1;
                      }
                      obj.push({ "title": s2, "amount": n });
                  }
              }
          };

          myObj.chargesDetails.ticketFare = parseInt(this.getAirSrcData(src, 'Contact Information', 'Home Phone', 'str').split(' ')[2]);
          myObj.chargesDetails.grandTotal = parseInt(this.getAirSrcData(src, 'Amount To Pay', 'Note', 'str'));

          myObj.chargesDetails.taxes = 0;
          myObj.chargesDetails.discountFrmAirlines = 0;
          myObj.chargesDetails.totalOtherChargesData = obj;

      }
      if (e.indexOf('akbar') != -1) {
          for (var i = 0; i < agentList.length; i++) {
              if ((agentList[i].agentType).indexOf('Akbar') != -1) {
                  myObj.agentDetails.agentType = agentList[i].agentType;
                  break;
              }
          }
      }
  }
  
  /********************************************************************/

  public autoFillPdf = function(src, e, myObj, agentList){
    if (e == 'multiLink_goAir') {
        console.log('this is multiLink_goAir');

        myObj.from = this.getSrcFromToData(src)[0];
        myObj.to = this.getSrcFromToData(src)[1];
        myObj.airSector = this.getSrcFromToData(src)[2];

        myObj.airName = 'Go Air';
        myObj.airFlight = this.getAirSrcData(src, 'Go Air', 'GSP', 'str');
        myObj.pnrNo = this.getAirSrcData(src, 'AIRLINE PNR', 'GDS', 'str');

        myObj.chargesDetails.ticketFare = parseInt(this.getAirSrcData(src, 'Air Fare Charges', 'Fuel', 'str').split(') ')[1]);
        myObj.chargesDetails.taxes = this.getAirSrcData(src, 'Taxes and Other Charges:', '.0', 'num'); ///SULAMANAIR

        var dataAirVal1 = parseInt(this.getAirSrcData(src, 'Fuel Surcharge', 'Airline', 'str').split(':')[1].replace(/ /g, ''));
        var dataAirVal2 = parseInt(this.getAirSrcData(src, 'Airline Service Tax', 'Fees', 'str').split(':')[1].replace(/ /g, ''));
        var dataAirVal3 = parseInt(this.getAirSrcData(src, 'Fees and Taxes', 'Total', 'str').split(':')[1].replace(/ /g, ''));

        var dataAir1 = [
            { "title": "Fuel Surcharge", "amount": dataAirVal1 },
            { "title": "Airline Tax", "amount": dataAirVal2 },
            { "title": "Fees and Taxes", "amount": dataAirVal3 }
        ];

        myObj.chargesDetails.totalOtherChargesData = dataAir1;
        //>>>>>this.addAction('otherChargesShow');
        //>>>>>this.addAction('otherChargesBlur')

        myObj.journeyDateDD = this.autoFilldd(src);
        myObj.journeyDateMM = this.autoFillmm(src);
        myObj.journeyDateYY = this.autoFillyy(src);
        myObj.chargesDetails.quota = 0;

        let a = src.split('Passenger Name Frequent Flyer Type')[1].split('Important')[0];
        let b = a.split("\x0A");
        for (let i = 0; i < b.length - 2; i++) {
            let d = b[i + 1].replace(/(\s+)/gi, ' ');
            let e = d.split(' ');
            let f = e[2] + ' ' + e[3];

            let scop = eval('myObj.pd' + i + ' = {}');
            scop.name = f;
            scop.ticketNo = '';
            scop.age = '';
            scop.sex = '';
            scop.coachNoSeat = '';
        }
        myObj.adult = b.length - 1;
        myObj.total = b.length - 1;
    

    }
    else if (e == 'multiLink_spiceJet') {}
  }
    
  private airTravels = '';
  private airName = '';

  private getTravelsName = function(src){
      if (src.indexOf('iatatravelcentre') > 0) {
          this.airTravels = 'AKBAR';
          this.airName = '';
      } else if (src.indexOf('yatriadda') > 0) {
          this.airTravels = 'Yatriadda';
          this.airName = '';
      } else {
          this.airTravels = 'common';
          this.airName = '';
      }
  }
  private getSrcFromToData = function(src) {
      var a = src.split('Departing Arriving')[1].split('Terminal')[0];
      var b = a.split('(')[1].split(')')[0];
      var c = a.split('(')[2].split(')')[0];
      return [b,c,b + '/' + c];
      /*
      myObj.from = b;
      myObj.to = c;
      myObj.airSector = b + '/' + c;
      */
  }
  private autoFilldd = function(src) {
      var a0 = this.getAirSrcData(src, 'Departing Arriving', 'Terminal', 'str');
      var a1 = a0.split(',')[1];
      var a2 = a1.split(' ')[0];
      for (var i = 0; i < this.monthArr.length; i++) {
          if (a2 == this.monthArr[i]) { a2 = (i + 1); break; }
      }
      a2 = parseInt(a2);
      return a2 + '';
  }
  private autoFillmm = function(src) {
      var a0 = this.getAirSrcData(src, 'Departing Arriving', 'Terminal', 'str');
      var a1 = a0.split(',')[1];
      var a2 = a1.split(' ')[1];
      for (var i = 0; i < this.monthArr.length; i++) {
          if (a2 == this.monthArr[i]) { a2 = (i + 1); break; }
      }
      return a2 + '';
  }
  private autoFillyy = function(src) {
      var a0 = this.getAirSrcData(src, 'Departing Arriving', 'Terminal', 'str');
      var a1 = a0.split(',')[1];
      var a2 = a1.split(' ')[2];
      return a2;
  }


  public autoFillPdf__ = function(src, e, myObj, agentList){
      this.getTravelsName(src);
      
      if (this.airTravels == 'AKBAR') {
        alert('Need to confirm all the fields.');
      }
      
      if (e == 'multiLink') {

          myObj.from = this.getSrcFromToData(src)[0];
          myObj.to = this.getSrcFromToData(src)[1];
          myObj.airSector = this.getSrcFromToData(src)[2];
          
          myObj.airName = this.getAirSrcData(src, 'Corporate ID:', 'Booking', 'str'); //working
          myObj.pnrNo = this.getAirSrcData(src, 'AIRLINE PNR', 'GDS', 'str'); //working

          myObj.chargesDetails.ticketFare = this.getAirSrcData(src, 'Basic Fare:', '.0', 'num');
          myObj.chargesDetails.taxes = this.getAirSrcData(src, 'Taxes and Other Charges:', '.0', 'num');

          myObj.journeyDateDD = this.autoFilldd(src);
          myObj.journeyDateMM = this.autoFillmm(src);
          myObj.journeyDateYY = this.autoFillyy(src);
          myObj.chargesDetails.quota = 0;

          let a = src.split('Passenger Name Frequent Flyer Type')[1].split('Important Information')[0];
          let b = a.split("\x0A");
          for (let i = 0; i < b.length - 2; i++) {
              let d = b[i + 1].replace(/(\s+)/gi, ' ');
              let e = d.split(' ');
              let f = e[2] + ' ' + e[3];
    
              let scop = eval('myObj.pd' + i + ' = {}');
              scop.name = f;
              scop.ticketNo = '';
              scop.age = '';
              scop.sex = '';
              scop.coachNoSeat = '';
          }
          myObj.adult = b.length - 1;
          myObj.total = b.length - 1;

      }
      if (e == 'Go Air') {

          myObj.from = this.getSrcFromToData(src)[0];
          myObj.to = this.getSrcFromToData(src)[1];
          myObj.airSector = this.getSrcFromToData(src)[2];

          myObj.airName = 'Go Air';
          myObj.airFlight = this.getAirSrcData(src, 'Go Air', 'GSP', 'str');
          myObj.pnrNo = this.getAirSrcData(src, 'AIRLINE PNR', 'GDS', 'str');

          myObj.chargesDetails.ticketFare = parseInt(this.getAirSrcData(src, 'Air Fare Charges', 'Fuel', 'str').split(') ')[1]);
          myObj.chargesDetails.taxes = this.getAirSrcData(src, 'Taxes and Other Charges:', '.0', 'num'); ///SULAMANAIR

          var dataAirVal1 = parseInt(this.getAirSrcData(src, 'Fuel Surcharge', 'Airline', 'str').split(':')[1].replace(/ /g, ''));
          var dataAirVal2 = parseInt(this.getAirSrcData(src, 'Airline Service Tax', 'Fees', 'str').split(':')[1].replace(/ /g, ''));
          var dataAirVal3 = parseInt(this.getAirSrcData(src, 'Fees and Taxes', 'Total', 'str').split(':')[1].replace(/ /g, ''));

          var dataAir1 = [
              { "title": "Fuel Surcharge", "amount": dataAirVal1 },
              { "title": "Airline Tax", "amount": dataAirVal2 },
              { "title": "Fees and Taxes", "amount": dataAirVal3 }
          ];

          myObj.chargesDetails.totalOtherChargesData = dataAir1;
          //>>>>>this.addAction('otherChargesShow');
          //>>>>>this.addAction('otherChargesBlur')

          myObj.journeyDateDD = this.autoFilldd(src);
          myObj.journeyDateMM = this.autoFillmm(src);
          myObj.journeyDateYY = this.autoFillyy(src);
          myObj.chargesDetails.quota = 0;

          let a = src.split('Passenger Name Frequent Flyer Type')[1].split('Important')[0];
          let b = a.split("\x0A");
          for (let i = 0; i < b.length - 2; i++) {
              let d = b[i + 1].replace(/(\s+)/gi, ' ');
              let e = d.split(' ');
              let f = e[2] + ' ' + e[3];

              let scop = eval('myObj.pd' + i + ' = {}');
              scop.name = f;
              scop.ticketNo = '';
              scop.age = '';
              scop.sex = '';
              scop.coachNoSeat = '';
          }
          myObj.adult = b.length - 1;
          myObj.total = b.length - 1;
      
      }

      return myObj;
  }


  
}
