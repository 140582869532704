import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library
declare var FroalaEditor:any;//this way u can include other FroalaEditor


@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.scss']
})
export class MastersComponent implements OnInit {
  
  //var status = AuthService.header({'event':'show', 'activeLink':'none', 'pageCheck':'mastersPage'});
  //if(status == false){ $location.path('/list') };
  errorMessage:any;
  error=false;
  addMastersFormData:any = {};
  editMastersFormData:any = {};
  delMastersObj:any = '';
  bookingTypeList:any=[];
  dropdownList:any=[];
  clientTypeList:any=[];
  mastersListData:any={};
  mastersListDataAll:any;
  pagination:any = [];
  pageNo=0;
  MFD:any={};
  commonFlags:any={};
  commonReqs:any={};


  selectListFlag1 = false;
  selectListFlag2 = false;
  agentListFlag = false;
  airClassListFlag = false;
  airFlightListFlag = false;
  airFromToListFlag = false;
  busTypeListFlag = false;
  clientListFlag = false;
  tourPackageListFlag = false;
  hotelRoomTypeListFlag = false;
  visaTypeListFlag = false;
  trainClassListFlag = false;
  trainNumberListFlag = false;
  trainQuotaListFlag = false;
  trainFromToListFlag = false;
  expenseListFlag = false;
  //expenseList = false;

  agentListReq = '';
  airClassListReq = '';
  airFlightListReq = '';
  busTypeListReq = '';
  clientListReq = '';
  tourPackageListReq = '';
  hotelRoomTypeListReq = '';
  visaTypeListReq = '';
  trainClassListReq = '';
  trainNumberListReq = '';
  trainQuotaListReq = '';
  expenseListReq = '';

  specialClient:any={};
  tourPackageViewObj:any={};

  constructor(private http:HttpClient, private router:Router, private Services:CommonService) { }

  ngOnInit() {
    this.addMastersFormData['pg'] = 0;
    this.addMastersFormData['serviceTax'] = 0;
    this.editMastersFormData['pg'] = 0;
    this.editMastersFormData['serviceTax'] = 0;
    this.specialClient = this.Services.specialClientObj();
    
    this.Services.checkValidURL('masters');
    
    $('body').addClass('loading');
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      let data = res[0];
      this.bookingTypeList = data.bookingType;
      this.addMastersFormData.bookingType = this.bookingTypeList[0].bookingName;
      this.editMastersFormData.bookingType = this.bookingTypeList[0].bookingName;

      this.specialClient = this.Services.specialClientFunc(data.specialClient, this.Services.specialClientObj());//Special Settings for the client
      
      let dd_ls = (localStorage._dynamicBTypeList);
      
      for(let d=0; d<data.dropdownTypeList.length; d++){
        let v = data.dropdownTypeList[d].value;
        if(v == 'selectList' || v == 'clientList'){
          this.dropdownList.push(data.dropdownTypeList[d]);
        }
        
        if(v == 'trainClassList' || v == 'trainNumberList' || v == 'trainQuotaList' || v == 'trainFromToList'){
          if( dd_ls.indexOf('Train') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
        if(v == 'agentList'){
          this.dropdownList.push(data.dropdownTypeList[d]);
        }
        if(v == 'airClassList' || v == 'airFromToList'){
          if( dd_ls.indexOf('Air') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
        if(v == 'hotelRoomTypeList'){
          if( dd_ls.indexOf('Hotel') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
        if(v == 'visaTypeList'){
          if( dd_ls.indexOf('Visa') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
        if(v == 'expenseList'){
          if( dd_ls.indexOf('Expense') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
        if(v == 'tourPackageList'){
          if( dd_ls.indexOf('Package') != -1){
            this.dropdownList.push(data.dropdownTypeList[d]);
          }
        }
      }

      this.addMastersFormData.type = data.dropdownTypeList[0].value;
      $('body').removeClass('loading');
    },
    err => {
      $('body').removeClass('loading');
      console.log(err);
    });
    
    if(localStorage._jsonDropdown == undefined){
      this.Services.jsonDropdown().subscribe(res =>{
        localStorage._jsonDropdown = JSON.stringify(res);
        this.updateDropdownMaster(localStorage._jsonDropdown);
      },
      err => {
        console.log(err);
      });
    }
    else{
      this.updateDropdownMaster(localStorage._jsonDropdown);
    }
    this.masterReset();
    //this.loadMastersList('');
  }
    
  updateDropdownMaster = function(data0){
    let data = JSON.parse(data0);
    if(data.length != 0){
      this.clientTypeList = data.clientTypeList;
      this.addMastersFormData.clientType = data.clientTypeList[0].value;
      //this.addMastersFormData.clientStatus = data.clientStatus.value;
    }
  }
  
  masterReset = function(){
    this.selectListFlag1 = false;
    this.selectListFlag2 = false;
    this.agentListFlag = false;
    this.airClassListFlag = false;
    this.airFlightListFlag = false;
    this.airFromToListFlag = false;
    this.busTypeListFlag = false;
    this.clientListFlag = false;
    this.tourPackageListFlag = false;
    this.hotelRoomTypeListFlag = false;
    this.visaTypeListFlag = false;
    this.trainClassListFlag = false;
    this.trainNumberListFlag = false;
    this.trainQuotaListFlag = false;
    this.trainFromToListFlag = false;
    this.expenseListFlag = false;

    this.agentListReq = '';
    this.airClassListReq = '';
    this.airFlightListReq = '';
    this.airFromToListReq = '';
    this.busTypeListReq = '';
    this.clientListReq = '';
    this.tourPackageListReq = '';
    this.hotelRoomTypeListReq = '';
    this.visaTypeListReq = '';
    this.trainClassListReq = '';
    this.trainNumberListReq = '';
    this.trainQuotaListReq = '';
    this.trainFromToListReq = '';
    this.expenseListReq = '';

    this.addMastersFormData.agentType = '--';
    this.addMastersFormData.agentPaymentMode = '--';
    this.addMastersFormData.agentGSTIN = '--';
    this.addMastersFormData.airClassName = '--';
    this.addMastersFormData.airFlightName = '--';
    this.addMastersFormData.busName = '--';
    this.addMastersFormData.className = '--';
    this.addMastersFormData.eTicket = '--';
    this.addMastersFormData.quotaName = '--';
    this.addMastersFormData.trainNumber = '--';
    this.addMastersFormData.trainName = '--';
    this.addMastersFormData.clientName = '--';
    this.addMastersFormData.clientAddress = '--';
    this.addMastersFormData.clientNumber = '--';
    this.addMastersFormData.clientContactPerson = '--';
    this.addMastersFormData.clientType = 'Company';
    this.addMastersFormData.clientStatus = 'Active';
    this.addMastersFormData.pg = '--';
    this.addMastersFormData.service = '--';
    this.addMastersFormData.serviceTax = '--';
    this.addMastersFormData.cgstPer = '--';
    this.addMastersFormData.sgstPer = '--';
    this.addMastersFormData.igstPer = '--';
    this.addMastersFormData.clientGSTIN = '--';
    this.addMastersFormData.clientSAC = '--';
    this.addMastersFormData.cancelRail = '--';
    this.addMastersFormData.cancelService = '--';
  }

  masterAction = function(ths){
    this.masterReset();

    let type = this.addMastersFormData.type;
    if(type != 'selectList' ){this.selectListFlag1 = true; this.selectListFlag2 = true;}

    if(type == 'agentList' ){this.agentListReq = 'required'; this.agentListFlag = true; }
    else if(type == 'airClassList' ){this.airClassListReq = 'required'; this.airClassListFlag = true; }
    else if(type == 'airFlightList' ){this.airFlightListReq = 'required'; this.airFlightListFlag = true; }
    else if(type == 'airFromToList' ){this.airFromToListReq = 'required'; this.airFromToListFlag = true; }
    else if(type == 'busTypeList' ){this.busTypeListReq = 'required'; this.busTypeListFlag = true; }
    else if(type == 'clientList' ){this.clientListReq = 'required'; this.clientListFlag = true; }
    else if(type == 'tourPackageList' ){this.tourPackageListReq = 'required'; this.tourPackageListFlag = true; }
    else if(type == 'hotelRoomTypeList' ){this.hotelRoomTypeListReq = 'required'; this.hotelRoomTypeListFlag = true; }
    else if(type == 'visaTypeList' ){this.visaTypeListReq = 'required'; this.visaTypeListFlag = true; }
    else if(type == 'trainClassList' ){this.trainClassListReq = 'required'; this.trainClassListFlag = true; }
    else if(type == 'trainNumberList' ){this.trainNumberListReq = 'required'; this.trainNumberListFlag = true; }
    else if(type == 'trainQuotaList' ){this.trainQuotaListReq = 'required'; this.trainQuotaListFlag = true; }
    else if(type == 'trainFromToList' ){this.trainFromToListReq = 'required'; this.trainFromToListFlag = true; }
    else if(type == 'expenseList' ){this.expenseListReq = 'required'; this.expenseListFlag = true; }

    this.addMastersFormData.selectedDD = '';

    if(type == 'agentList' ){
      this.addMastersFormData.agentType = '';
      this.addMastersFormData.agentPaymentMode = '';
      this.addMastersFormData.agentGSTIN = '';
      this.addMastersFormData.bookingType = '';
    }
    else if(type == 'airClassList' ){
      this.addMastersFormData.airClassName = '';
    }
    else if(type == 'airFlightList' ){
      this.addMastersFormData.airFlightName = '';
    }
    else if(type == 'busTypeList' ){
      this.addMastersFormData.busName = '';
    }
    else if(type == 'clientList' ){
      this.addMastersFormData.clientName = '';
      this.addMastersFormData.clientAddress = '';
      this.addMastersFormData.clientNumber = '';
      this.addMastersFormData.clientContactPerson = '';
      this.addMastersFormData.clientType = '';
      this.addMastersFormData.clientStatus = '';
      this.addMastersFormData.pg = '';
      this.addMastersFormData.service = '';
      this.addMastersFormData.serviceTax = '';
      this.addMastersFormData.cgstPer = '';
      this.addMastersFormData.sgstPer = '';
      this.addMastersFormData.igstPer = '';
      this.addMastersFormData.clientGSTIN = '';
      this.addMastersFormData.clientSAC = '';
      this.addMastersFormData.cancelRail = '';
      this.addMastersFormData.cancelService = '';
    }
    else if(type == 'hotelRoomTypeList' ){
      this.addMastersFormData.roomTypeName = '';
    }
    else if(type == 'visaTypeList' ){
      this.addMastersFormData.visaTypeName = '';
    }
    else if(type == 'trainClassList' ){
      this.addMastersFormData.className = '';
      this.addMastersFormData.eTicket = '';
    }
    else if(type == 'trainNumberList' ){
      this.addMastersFormData.trainName = '';  
      this.addMastersFormData.trainNumber = '';
    }
    else if(type == 'trainQuotaList' ){
      this.addMastersFormData.quotaName = '';
    }
    else if(type == 'expenseList' ){
      this.addMastersFormData.expenseName = '';
    }
    else if(type == 'tourPackageList' ){
      
      this.addMastersFormData.packageName='';
      this.addMastersFormData.packageInfo={};
      this.addMastersFormData.packageInfo.id = '';
      this.addMastersFormData.packageInfo.name = '';
      this.addMastersFormData.packageInfo.category = '';
      this.addMastersFormData.packageInfo.discription = '';
      this.addMastersFormData.packageInfo.createdDate = '';
      this.addMastersFormData.packageInfo.costOriginalAdult = '';
      this.addMastersFormData.packageInfo.costOriginalChild = '';
      this.addMastersFormData.packageInfo.costAdult = '';
      this.addMastersFormData.packageInfo.costChild = '';
      
      this.addMastersFormData.travelDetailObj = {hotel:'', country:'', cityF:'', cityT:'', travelBy:'', stayDay:'', stayNight:''}
      this.addMastersFormData.travelDetailArr = [];
      //this.addMastersFormData.travelDetailArr.push( (this.addMastersFormData.travelDetailObj) );
      this.addMastersFormData.travelDetailObj2 = this.addMastersFormData.travelDetailObj;
      //this.addMastersFormData.travelDetailArr.push( JSON.parse(angular.toJson(this.addMastersFormData.travelDetailObj) ));
    
      this.addMastersFormData.groupDetailObj = {sectionOrdering:'', heading:'', rte:''}
      this.addMastersFormData.groupDetailArr = [];
      this.addMastersFormData.groupDetailArr.push( (this.addMastersFormData.groupDetailObj) );
      //this.addMastersFormData.groupDetailArr.push( JSON.parse(angular.toJson(this.addMastersFormData.groupDetailObj) ));

      setTimeout(()=>{
        //var id = $('.packageGroupDetails').eq(0).find('textarea').attr('id');
        //nicEditors.allTextAreas(id);//Sulaman
        //$('.rteAddWrap .nicEdit-main').addClass('nicEdit-main-w100').parent().addClass('nicEdit-main-w100').prev().addClass('nicEdit-main-w100');

        new FroalaEditor('div#add-froala-editor', {});
        setTimeout(()=>{
          $('#logo').remove();
        },250);

      },250);

    }

    this.mastersListData = [this.addMastersFormData];//Have added in Angular-4 only
    this.loadMastersList(type);
  }

  addMastersForm = function (e) {
    e.preventDefault();

    // initial values
    this.error = false;
    this.disabled = true;

    var bookingDropdownObj='';
    var t = "train_"+this.addMastersFormData.trainNumber;
    var typ = this.addMastersFormData.type;

    bookingDropdownObj += '{';
    bookingDropdownObj += '"type":"'+typ+'",';

    if(typ == 'agentList' ){
      bookingDropdownObj += '"agentType":"'+this.addMastersFormData.agentType+'",';
      bookingDropdownObj += '"agentPaymentMode":"'+this.addMastersFormData.agentPaymentMode+'",';
      bookingDropdownObj += '"agentGSTIN":"'+this.addMastersFormData.agentGSTIN+'",';
      bookingDropdownObj += '"bookingType":"'+this.addMastersFormData.bookingType+'"';
    }
    else if(typ == 'airClassList' ){
      bookingDropdownObj += '"airClassName":"'+this.addMastersFormData.airClassName+'"';
    }
    else if(typ == 'airFlightList' ){
      bookingDropdownObj += '"airFlightName":"'+this.addMastersFormData.airFlightName+'"';
    }
    else if(typ == 'busTypeList' ){
      bookingDropdownObj += '"busName":"'+this.addMastersFormData.busName+'"';
    }
    else if(typ == 'clientList' ){
      bookingDropdownObj += '"clientName":"'+this.addMastersFormData.clientName+'",';
      bookingDropdownObj += '"clientAddress":"'+this.addMastersFormData.clientAddress+'",';
      bookingDropdownObj += '"clientNumber":"'+this.addMastersFormData.clientNumber+'",';
      bookingDropdownObj += '"clientContactPerson":"'+this.addMastersFormData.clientContactPerson+'",';
      bookingDropdownObj += '"clientType":"'+this.addMastersFormData.clientType+'",';
      bookingDropdownObj += '"clientStatus":"'+this.addMastersFormData.clientStatus+'",';
      bookingDropdownObj += '"pg":"'+this.addMastersFormData.pg+'",';
      bookingDropdownObj += '"service":"'+this.addMastersFormData.service+'",';
      bookingDropdownObj += '"serviceTax":"'+this.addMastersFormData.serviceTax+'",';
      bookingDropdownObj += '"cgstPer":"'+this.addMastersFormData.cgstPer+'",';
      bookingDropdownObj += '"sgstPer":"'+this.addMastersFormData.sgstPer+'",';
      bookingDropdownObj += '"igstPer":"'+this.addMastersFormData.igstPer+'",';
      bookingDropdownObj += '"clientGSTIN":"'+this.addMastersFormData.clientGSTIN+'",';
      bookingDropdownObj += '"clientSAC":"'+this.addMastersFormData.clientSAC+'",';
      bookingDropdownObj += '"cancelRail":"'+this.addMastersFormData.cancelRail+'",';
      bookingDropdownObj += '"cancelService":"'+this.addMastersFormData.cancelService+'"';
    }
    else if(typ == 'tourPackageList' ){
      var cnt=0, dataNum = 0;
      for(var i=1; i<=this.addMastersFormData.groupDetailArr.length; i++){
        cnt += i;
        dataNum += parseInt(this.addMastersFormData.groupDetailArr[i-1].sectionOrdering);
      }
      /*
      if(cnt != dataNum){
        alert('Invalid Inputs'); return false
      }
      */
      //this.addMastersFormData.groupDetailArr
      /*
      for(var i=0; i<$('.packageGroupDetails').length; i++){
        var rteCont1 = $('.packageGroupDetails').eq(i).find('.nicEdit-main').html();
        rteCont1 = rteCont1.replace(/<b>/g,'#B#').replace(/<\/b>/g,'#BB#');
        rteCont1 = rteCont1.replace(/<ol>/g,'#OL#').replace(/<\/ol>/g,'#OLOL#');
        rteCont1 = rteCont1.replace(/<ul>/g,'#UL#').replace(/<\/ul>/g,'#ULUL#');
        rteCont1 = rteCont1.replace(/<dl>/g,'#DL#').replace(/<\/dl>/g,'#DLDL#');
        rteCont1 = rteCont1.replace(/<li>/g,'#LI#').replace(/<\/li>/g,'#LILI#');
        rteCont1 = rteCont1.replace(/<i>/g,'#I#').replace(/<\/i>/g,'#II#');
        rteCont1 = rteCont1.replace(/<u>/g,'#UU#').replace(/<\/u>/g,'#UU#');
        rteCont1 = rteCont1.replace(/<br>/g,'#BR#');
        this.addMastersFormData.groupDetailArr[i].rte = rteCont1;
      }

      var groupDetailArrNew = [];
      for(var a=0; a<this.addMastersFormData.groupDetailArr.length; a++){
        var v = parseInt(this.addMastersFormData.groupDetailArr[a].sectionOrdering)-1;
        groupDetailArrNew[v] = this.addMastersFormData.groupDetailArr[a];
      }
      this.addMastersFormData.groupDetailArr = groupDetailArrNew;
      */
       
      let html = ($('.fr-element.fr-view').html());
      this.addMastersFormData.groupDetailArr = [html];
      
      bookingDropdownObj += '"packageName":"'+this.addMastersFormData.packageInfo.name+'('+this.addMastersFormData.packageInfo.category+')",';
      bookingDropdownObj += '"packageInfo":{';
      bookingDropdownObj += '"id":"'+this.addMastersFormData.packageInfo.id+'",';
      bookingDropdownObj += '"name":"'+this.addMastersFormData.packageInfo.name+'",';
      bookingDropdownObj += '"category":"'+this.addMastersFormData.packageInfo.category+'",';
      bookingDropdownObj += '"discription":"'+this.addMastersFormData.packageInfo.discription+'",';
      bookingDropdownObj += '"createdDate":"'+this.addMastersFormData.packageInfo.createdDate+'",';
      bookingDropdownObj += '"costOriginalAdult":"'+this.addMastersFormData.packageInfo.costOriginalAdult+'",';
      bookingDropdownObj += '"costOriginalChild":"'+this.addMastersFormData.packageInfo.costOriginalChild+'",';
      bookingDropdownObj += '"costAdult":"'+this.addMastersFormData.packageInfo.costAdult+'",';
      bookingDropdownObj += '"costChild":"'+this.addMastersFormData.packageInfo.costChild+'"';
      bookingDropdownObj += '},';
      bookingDropdownObj += '"travelDetail":'+ JSON.stringify(this.addMastersFormData.travelDetailArr)+',';
      bookingDropdownObj += '"groupDetail":""';

      //bookingDropdownObj += '"travelDetail":'+angular.toJson(this.addMastersFormData.travelDetailArr)+',';
      //bookingDropdownObj += '"groupDetail":'+angular.toJson(this.addMastersFormData.groupDetailArr);

      //localStorage._groupDetail = angular.toJson(this.addMastersFormData.groupDetailArr);
      
      console.log('>>>>> bookingDropdownObj >>> ', bookingDropdownObj);

    }
    else if(typ == 'hotelRoomTypeList' ){
      bookingDropdownObj += '"roomTypeName":"'+this.addMastersFormData.roomTypeName+'"';
    }
    else if(typ == 'visaTypeList' ){
      bookingDropdownObj += '"visaTypeName":"'+this.addMastersFormData.visaTypeName+'"';
    }
    else if(typ == 'trainClassList' ){
      bookingDropdownObj += '"className":"'+this.addMastersFormData.className+'",';
      bookingDropdownObj += '"eTicket":"'+this.addMastersFormData.eTicket+'"';
    }
    else if(typ == 'trainNumberList' ){
      bookingDropdownObj += '"'+t+'":"'+this.addMastersFormData.trainName+'",';    
      bookingDropdownObj += '"trainNumber":"'+this.addMastersFormData.trainNumber+'",';
      bookingDropdownObj += '"trainName":"'+this.addMastersFormData.trainName+'"';
    }
    else if(typ == 'trainQuotaList' ){
      bookingDropdownObj += '"quotaName":"'+this.addMastersFormData.quotaName+'"';
    }
    else if(typ == 'trainFromToList' ){
      bookingDropdownObj += '"trainFromToName":"'+this.addMastersFormData.trainFromToName+'"';
    }
    else if(typ == 'expenseList' ){
      bookingDropdownObj += '"expenseName":"'+this.addMastersFormData.expenseName+'"';
    }
    else if(typ == 'airFromToList' ){
      bookingDropdownObj += '"airFromToName":"'+this.addMastersFormData.airFromToName+'"';
    }
    bookingDropdownObj += '}';

    let bookingDetailsObj = JSON.parse(bookingDropdownObj);
    let addMastersFormJsonData1 = bookingDetailsObj;
    let addMastersFormJsonDataTypeVal = 'add';
    let otherDetails_TypObj:any;

    //console.log('bookingDetailsObj >>>> ', bookingDetailsObj);

    if( typ == 'tourPackageList' ){
      bookingDetailsObj['groupDetail'] = JSON.stringify( ($('#add-froala-editor .fr-wrapper .fr-element.fr-view').html()).replace(/"/gi,"'") );
      //bookingDetailsObj['groupDetail'] = ( $('#add-froala-editor .fr-wrapper .fr-element.fr-view').html() ).replace(/"/gi,'\"').replace(/<\//gi,'<\/').replace(/\/>/gi,'\/>');
      //console.log("bookingDetailsObj['groupDetail'] 222 >>>>>>>>> ", bookingDetailsObj['groupDetail']);
    }
   
    if(typ == 'airFromToList'){
      otherDetails_TypObj = {"obj":this.addMastersFormData.airFromToName, "queryOld":"AirCities"};
    }
    else if(typ == 'trainFromToList'){
      otherDetails_TypObj = {"obj":this.addMastersFormData.trainFromToName, "queryOld":"TrainCities"};
    }

    if(typ == 'airFromToList' || typ == 'trainFromToList'){
      this.Services.saveOtherDetails(otherDetails_TypObj).subscribe(res =>{
        alert('City Added Successfully');

        if(typ == 'airFromToList'){
          localStorage.AirCities = JSON.stringify(((JSON.parse(localStorage.AirCities).join('||') + "||" + this.addMastersFormData.airFromToName).replace(/"/gi, '')).split("||"));
          this.addMastersFormData.airFromToName = "";
          this.generatePagination( JSON.parse(localStorage.AirCities) , 0);
        }
        else if(typ == 'trainFromToList'){
          localStorage.TrainCities = JSON.stringify(((JSON.parse(localStorage.TrainCities).join('||') + "||" + this.addMastersFormData.trainFromToName).replace(/"/gi, '')).split("||"));
          this.addMastersFormData.trainFromToName = "";
          this.generatePagination( JSON.parse(localStorage.TrainCities) , 0);
        }
        
      },
      err => {
        console.log(err);
      });
    }
    else{
      var data = {addMastersFormJsonDataType:addMastersFormJsonDataTypeVal, addMastersFormJsonData2:addMastersFormJsonData1}    
      this.Services.addMasters(data).subscribe(res =>{
        var data = res;
        this.disabled = false;
        this.addMastersFormData = {};
        this.router.navigate(['masters'])
        this.masterReset();
        this.addMastersFormData.type = data.selectedType;
        this.masterAction();
      },
      err => {
        console.log(err);
      });
    }
    
  };

  updateMastersForm = function (e) {
    e.preventDefault();

    // initial values
    this.error = false;
    this.disabled = true;

    var bookingDropdownObj='';
    var t = "train_"+this.editMastersFormData.trainNumber;
    var typ = this.addMastersFormData.type;

    bookingDropdownObj += '{';
    bookingDropdownObj += '"type":"'+typ+'",';

    if(typ == 'agentList' ){
      bookingDropdownObj += '"agentType":"'+this.editMastersFormData.agentType+'",';
      bookingDropdownObj += '"agentPaymentMode":"'+this.editMastersFormData.agentPaymentMode+'",';
      bookingDropdownObj += '"agentGSTIN":"'+this.editMastersFormData.agentGSTIN+'",';
      bookingDropdownObj += '"bookingType":"'+this.editMastersFormData.bookingType+'"';
    }
    else if(typ == 'airClassList' ){
      bookingDropdownObj += '"airClassName":"'+this.editMastersFormData.airClassName+'"';
    }
    else if(typ == 'airFlightList' ){
      bookingDropdownObj += '"airFlightName":"'+this.editMastersFormData.airFlightName+'"';
    }
    else if(typ == 'busTypeList' ){
      bookingDropdownObj += '"busName":"'+this.editMastersFormData.busName+'"';
    }
    else if(typ == 'clientList' ){
      bookingDropdownObj += '"clientName":"'+this.editMastersFormData.clientName+'",';
      bookingDropdownObj += '"clientAddress":"'+this.editMastersFormData.clientAddress+'",';
      bookingDropdownObj += '"clientNumber":"'+this.editMastersFormData.clientNumber+'",';
      bookingDropdownObj += '"clientContactPerson":"'+this.editMastersFormData.clientContactPerson+'",';
      bookingDropdownObj += '"clientType":"'+this.editMastersFormData.clientType+'",';
      bookingDropdownObj += '"clientStatus":"'+this.editMastersFormData.clientStatus+'",';
      bookingDropdownObj += '"pg":"'+this.editMastersFormData.pg+'",';
      bookingDropdownObj += '"service":"'+this.editMastersFormData.service+'",';
      bookingDropdownObj += '"serviceTax":"'+this.editMastersFormData.serviceTax+'",';
      bookingDropdownObj += '"cgstPer":"'+this.editMastersFormData.cgstPer+'",';
      bookingDropdownObj += '"sgstPer":"'+this.editMastersFormData.sgstPer+'",';
      bookingDropdownObj += '"igstPer":"'+this.editMastersFormData.igstPer+'",';
      bookingDropdownObj += '"clientGSTIN":"'+this.editMastersFormData.clientGSTIN+'",';
      bookingDropdownObj += '"clientSAC":"'+this.editMastersFormData.clientSAC+'",';
      bookingDropdownObj += '"cancelRail":"'+this.editMastersFormData.cancelRail+'",';
      bookingDropdownObj += '"cancelService":"'+this.editMastersFormData.cancelService+'"';
    }
    else if(typ == 'tourPackageList' ){
      var cnt=0, dataNum = 0;
      for(var i=1; i<=this.editMastersFormData.groupDetailArr.length; i++){
        cnt += i;
        dataNum += parseInt(this.editMastersFormData.groupDetailArr[i-1].sectionOrdering);
      }
      /*
      if(cnt != dataNum){
        alert('Invalid Inputs'); return false
      }
      
      /*
      for(var i=0; i<$('#editMasterList .packageGroupDetails').length; i++){
        var rteCont1 = $('#editMasterList .packageGroupDetails').eq(i).find('.nicEdit-main').html();
        rteCont1 = rteCont1.replace(/<b>/g,'#B#').replace(/<\/b>/g,'#BB#');
        rteCont1 = rteCont1.replace(/<ol>/g,'#OL#').replace(/<\/ol>/g,'#OLOL#');
        rteCont1 = rteCont1.replace(/<ul>/g,'#UL#').replace(/<\/ul>/g,'#ULUL#');
        rteCont1 = rteCont1.replace(/<dl>/g,'#DL#').replace(/<\/dl>/g,'#DLDL#');
        rteCont1 = rteCont1.replace(/<li>/g,'#LI#').replace(/<\/li>/g,'#LILI#');
        rteCont1 = rteCont1.replace(/<i>/g,'#I#').replace(/<\/i>/g,'#II#');
        rteCont1 = rteCont1.replace(/<u>/g,'#UU#').replace(/<\/u>/g,'#UU#');
        rteCont1 = rteCont1.replace(/<br>/g,'#BR#');
        this.editMastersFormData.groupDetailArr[i].rte = rteCont1;
      }
      */

      var groupDetailArrNew = [];
      for(var a=0; a<this.editMastersFormData.groupDetailArr.length; a++){
        var v = parseInt(this.editMastersFormData.groupDetailArr[a].sectionOrdering)-1;
        groupDetailArrNew[v] = this.editMastersFormData.groupDetailArr[a];
      }
      this.editMastersFormData.groupDetailArr = groupDetailArrNew;

      bookingDropdownObj += '"packageName":"'+this.editMastersFormData.packageInfo.name+'('+this.editMastersFormData.packageInfo.category+')",';
      bookingDropdownObj += '"packageInfo":{';
      bookingDropdownObj += '"id":"'+this.editMastersFormData.packageInfo.id+'",';
      bookingDropdownObj += '"name":"'+this.editMastersFormData.packageInfo.name+'",';
      bookingDropdownObj += '"category":"'+this.editMastersFormData.packageInfo.category+'",';
      bookingDropdownObj += '"discription":"'+this.editMastersFormData.packageInfo.discription+'",';
      bookingDropdownObj += '"createdDate":"'+this.editMastersFormData.packageInfo.createdDate+'",';
      bookingDropdownObj += '"costOriginalAdult":"'+this.editMastersFormData.packageInfo.costOriginalAdult+'",';
      bookingDropdownObj += '"costOriginalChild":"'+this.editMastersFormData.packageInfo.costOriginalChild+'",';
      bookingDropdownObj += '"costAdult":"'+this.editMastersFormData.packageInfo.costAdult+'",';
      bookingDropdownObj += '"costChild":"'+this.editMastersFormData.packageInfo.costChild+'"';
      bookingDropdownObj += '},';
      bookingDropdownObj += '"travelDetail":'+JSON.stringify(this.editMastersFormData.travelDetailArr)+',';
      //bookingDropdownObj += '"groupDetail":'+JSON.stringify(this.editMastersFormData.groupDetailArr);
      bookingDropdownObj += '"groupDetail":""';
      /** */
    }
    else if(typ == 'hotelRoomTypeList' ){
      bookingDropdownObj += '"roomTypeName":"'+this.editMastersFormData.roomTypeName+'"';
    }
    else if(typ == 'visaTypeList' ){
      bookingDropdownObj += '"visaTypeName":"'+this.editMastersFormData.visaTypeName+'"';
    }
    else if(typ == 'trainClassList' ){
      bookingDropdownObj += '"className":"'+this.editMastersFormData.className+'",';
      bookingDropdownObj += '"eTicket":"'+this.editMastersFormData.eTicket+'"';
    }
    else if(typ == 'trainNumberList' ){
      bookingDropdownObj += '"'+t+'":"'+this.editMastersFormData.trainName+'",';    
      bookingDropdownObj += '"trainNumber":"'+this.editMastersFormData.trainNumber+'",';
      bookingDropdownObj += '"trainName":"'+this.editMastersFormData.trainName+'"';
    }
    else if(typ == 'trainQuotaList' ){
      bookingDropdownObj += '"quotaName":"'+this.editMastersFormData.quotaName+'"';
    }
    else if(typ == 'expenseList' ){
      bookingDropdownObj += '"expenseName":"'+this.editMastersFormData.expenseName+'"';
    }
    bookingDropdownObj += '}';


    let bookingDetailsObj= JSON.parse(bookingDropdownObj);
    let editMastersFormJsonDataIdVal = this.editMastersFormData.objID;

    //SSSSSSSSSSSSSSSS
    if(typ == 'tourPackageList' ){
      bookingDetailsObj['groupDetail'] = JSON.stringify($('#edit-froala-editor .fr-wrapper .fr-element.fr-view').html());
    }

    let data = {addMastersFormJsonDataType:'edit', addMastersFormJsonData2:bookingDetailsObj, addMastersFormJsonDataID:editMastersFormJsonDataIdVal}
    
    //>>>>pageLoader('show');
    this.Services.addMasters(data).subscribe(res =>{
    //this.http.post('/user/addMasters',this.Services.convertToJson(data)).subscribe(res => {
      var data = res;
      this.disabled = false;
      this.editMastersFormData = {};
      this.router.navigate(['masters'])
      this.masterReset();
      this.editMastersFormData.type = data.selectedType;
      this.masterAction();
      $('#editMasterList .modal-header .close').click();
      //>>>>pageLoader('hide');
    },
    err => {
      //>>>>pageLoader('hide');
      console.log(err);
    });

  };

  loadMastersList = function(typ){
    //>>>>>pageLoader('show');
    if(typ == 'trainNumberList'){
      let data = {listFormJsonData2:{searchType:'tn'}}
      this.Services.globalListMasters(data).subscribe(res =>{
      //this.http.post('/user/globalListMasters',this.Services.convertToJson(data)).subscribe(res =>{
        //this.mastersListData = res;
        this.pagination = new Array(0);
        this.mastersListDataAll = res;
        this.generatePagination( res , 0);
        //>>>>>pageLoader('hide');
      },err => {
        console.log(err);
        //>>>>>pageLoader('hide');
      });
    }
    else if(typ == 'airFromToList'){
      //this.mastersListData = JSON.parse(localStorage.AirCities);
      this.pagination = new Array(0);
      this.mastersListDataAll = JSON.parse(localStorage.AirCities);
      this.generatePagination( JSON.parse(localStorage.AirCities) , 0);
    }
    else if(typ == 'trainFromToList'){
      //this.mastersListData = JSON.parse(localStorage.TrainCities);
      this.pagination = new Array(0);
      this.mastersListDataAll = JSON.parse(localStorage.TrainCities);
      this.generatePagination( JSON.parse(localStorage.TrainCities) , 0);
    }
    else{
        let data = {listFormJsonData2:{searchType:{"type":this.addMastersFormData.type}}}
        this.Services.listMasters(data).subscribe(res =>{
        //this.http.post('/user/listMasters',this.Services.convertToJson(data)).subscribe(res =>{
          //this.mastersListData = res;
          this.pagination = new Array(0);
          this.mastersListDataAll = res;
          this.generatePagination( res , 0);
          //>>>>>pageLoader('hide');
        },err => {
          console.log(err);
          //>>>>>pageLoader('hide');
        });
    }

    //console.log( this.mastersListData );
  }

  generatePagination( data, pag=0 ){

    if( data.length <= 500 ){
      this.mastersListData = data;
      this.pagination[pag] = data;
    }
    else{
      if(pag == 0){
        this.pagination = new Array( Math.ceil(data.length/500) );
        for(let a=0; a<this.pagination.length; a++){
          let min = a*500;
          let max = (a+1)*500;
          let b = data.slice( min, max );
          this.pagination[a] = [b];
        }
        this.mastersListData = (this.pagination[pag][0]);
      }
    }

    this.pageNo = pag;

  }
  
  editMaster = function(obj,type){
    
    if(type == 'edit'){
      var ths = obj;
      var type = ths.type;
      this.editMastersFormData = {}
      this.editMastersFormData.objID = ths._id;
      
      if(type == 'agentList' ){
        this.editMastersFormData.agentType = ths.agentType;
        this.editMastersFormData.agentPaymentMode = ths.agentPaymentMode;
        this.editMastersFormData.agentGSTIN = ths.agentGSTIN;
        this.editMastersFormData.bookingType = ths.bookingType;
      }
      else if(type == 'airClassList' ){
        this.editMastersFormData.airClassName = ths.airClassName;
      }
      else if(type == 'airFlightList' ){
        this.editMastersFormData.airFlightName = ths.airFlightName;
      }
      else if(type == 'airFromToList' ){
        this.editMastersFormData.airFromToName = ths;
      }
      else if(type == 'busTypeList' ){
        this.editMastersFormData.busName = ths.busName;
      }
      else if(type == 'clientList' ){
        this.editMastersFormData.clientName = ths.clientName;
        this.editMastersFormData.clientAddress = ths.clientAddress;
        this.editMastersFormData.clientNumber = ths.clientNumber;
        this.editMastersFormData.clientContactPerson = ths.clientContactPerson;
        this.editMastersFormData.clientType = ths.clientType;
        this.editMastersFormData.clientStatus = ths.clientStatus;
        this.editMastersFormData.pg = ths.pg;
        this.editMastersFormData.service = ths.service;
        this.editMastersFormData.serviceTax = ths.serviceTax;
        this.editMastersFormData.cgstPer = ths.cgstPer;
        this.editMastersFormData.sgstPer = ths.sgstPer;
        this.editMastersFormData.igstPer = ths.igstPer;
        this.editMastersFormData.clientGSTIN = ths.clientGSTIN;
        this.editMastersFormData.clientSAC = ths.clientSAC;
        this.editMastersFormData.cancelRail = ths.cancelRail;
        this.editMastersFormData.cancelService = ths.cancelService;
      }
      else if(type == 'hotelRoomTypeList' ){
        this.editMastersFormData.roomTypeName = ths.roomTypeName;
      }
      else if(type == 'visaTypeList' ){
        this.editMastersFormData.visaTypeName = ths.visaTypeName;
      }
      else if(type == 'trainClassList' ){
        this.editMastersFormData.className = ths.className;
        this.editMastersFormData.eTicket = ths.eTicket;
      }
      else if(type == 'trainNumberList' ){
        this.editMastersFormData.trainName = ths.trainName;
        this.editMastersFormData.trainNumber = ths.trainNumber;
      }
      else if(type == 'trainQuotaList' ){
        this.editMastersFormData.quotaName = ths.quotaName;
      }
      else if(type == 'trainFromToList' ){
        this.editMastersFormData.trainFromToName = ths;
      }
      else if(type == 'expenseList' ){
        this.editMastersFormData.expenseName = ths.expenseName;
      }
      else if(type == 'tourPackageList' ){
        this.editMastersFormData.packageName=ths.packageName;
        this.editMastersFormData.packageInfo={};
        this.editMastersFormData.packageInfo['id'] = ths.packageInfo.id;
        this.editMastersFormData.packageInfo['name'] = ths.packageInfo.name;
        this.editMastersFormData.packageInfo['category'] = ths.packageInfo.category;
        this.editMastersFormData.packageInfo['discription'] = ths.packageInfo.discription;
        this.editMastersFormData.packageInfo['createdDate'] = ths.packageInfo.createdDate;
        this.editMastersFormData.packageInfo['costOriginalAdult'] = ths.packageInfo.costOriginalAdult;
        this.editMastersFormData.packageInfo['costOriginalChild'] = ths.packageInfo.costOriginalChild;
        this.editMastersFormData.packageInfo['costAdult'] = ths.packageInfo.costAdult;
        this.editMastersFormData.packageInfo['costChild'] = ths.packageInfo.costChild;
        
        this.editMastersFormData.travelDetailArr = ths.travelDetail;
        
        //this.editMastersFormData.groupDetailObj = { sectionOrdering:'', heading:'', rte:''}
        this.editMastersFormData.groupDetailArr = ths.groupDetail;
        //this.editMastersFormData.groupDetailArr.push( JSON.parse(angular.toJson(this.editMastersFormData.groupDetailObj) ));
        
        console.log('ths.groupDetail >>> ', this.editMastersFormData, JSON.parse(ths.groupDetail) );
 
        let tempHTML = `
          <div class="col-xs-12 col-sm-7 col-sm-push-5 col-md-9 col-lg-8 col-lg-offset-1 col-md-push-3">
            <h2 class="text-light text-xlarge">Ultra high <span class="text-normal">performance</span></h2>
            <p class="text-tint text-large">Froala Editor is one of the most powerful Javascript rich text editors in every single aspect. It is designed with performance in mind and will withstand the most rigorous tests.</p>
          </div>
        `;


        setTimeout(()=>{
          $('div#edit-froala-editor').html(JSON.parse(ths.groupDetail));
          new FroalaEditor('div#edit-froala-editor', {});
          setTimeout(()=>{
            $('#logo').remove();
          },250);
        },250);

        
      }
    }
  }

  deleteMaster = function(ths,param){
    if(param == 'del'){
      this.delMastersObj = ths;
      console.log(' this.delMastersObj >>> ', this.delMastersObj);
    }
    if(param == 'confirmed' && this.addMastersFormData.type == 'trainFromToList' || param == 'confirmed' && this.addMastersFormData.type == 'airFromToList'){
        let otherDetails_TypObj;
        if(this.addMastersFormData.type == 'airFromToList'){
          otherDetails_TypObj = {"obj":this.delMastersObj, "queryOld":"AirCities", "action":"delete"};
        }
        else if(this.addMastersFormData.type == 'trainFromToList'){
          otherDetails_TypObj = {"obj":this.delMastersObj, "queryOld":"TrainCities", "action":"delete"};
        }

        this.Services.saveOtherDetails(otherDetails_TypObj).subscribe(res =>{
          alert('City Deleted Successfully');
          $('#deleteConfirmation .close').click();

          let delStr = this.delMastersObj;
          let regExp = new RegExp(delStr);  // regex pattern string

          if(this.addMastersFormData.type == 'airFromToList'){
            localStorage.AirCities = JSON.stringify(JSON.parse(localStorage.AirCities).join('||').replace(regExp, '').replace(',""', '').replace('"",', '').replace('""', '').replace('||||', '||').split('||'));
            this.generatePagination( JSON.parse(localStorage.AirCities) , 0);
          }
          else if(this.addMastersFormData.type == 'trainFromToList'){
            localStorage.TrainCities = JSON.stringify(JSON.parse(localStorage.TrainCities).join('||').replace(regExp, '').replace(',""', '').replace('"",', '').replace('""', '').replace('||||', '||').split('||'));
            this.generatePagination( JSON.parse(localStorage.TrainCities) , 0);
          }

        },
        err => {
          console.log(err);
        });
      
    }
    else if(param == 'confirmed'){
      let data = { 'delArray': this.delMastersObj }
      this.Services.deleteMast(data).subscribe(res =>{
        setTimeout(()=>{
          $('#deleteConfirmation .close').click();
          this.loadMastersList('');
        },100);
      },err => {
        console.log(err);
      });
    }
  }

  
  tourPackageAction = function (ths,param,eventType) {
    this.MFD = this.addMastersFormData;
    if(eventType == 'edit'){
      this.MFD = this.editMastersFormData;
    }
    if(param == 'addTravelDetails' ){
      
      //var lastEntryLen = this.MFD.travelDetailArr.length - 1;
      //var lastEntryData = this.MFD.travelDetailArr[lastEntryLen];        
      var lastEntryData = this.addMastersFormData.travelDetailObj;        
      /*if( lastEntryData.hotel == '' || lastEntryData.country=='' || lastEntryData.cityF=='' || lastEntryData.cityT=='' || lastEntryData.travelBy=='' || lastEntryData.cost=='' || lastEntryData.stayDay=='' || lastEntryData.stayNight=='' ){
        alert('Kindly fill above Travel details');
        return false;
      }*/
      //this.MFD.travelDetailArr.push( JSON.parse(angular.toJson(lastEntryData) ));
      var lastEntryData2 = JSON.parse(JSON.stringify(lastEntryData));
      this.MFD.travelDetailArr.push( lastEntryData2 );
    }
    else if(param == 'removeTravelDetails' ){
      var lastEntryLen = this.MFD.travelDetailArr.length - 1;
      this.MFD.travelDetailArr.splice(lastEntryLen, 1);
    }


    /*
    if(param == 'addGroupDetails' ){
      var lastEntryLen = this.MFD.groupDetailArr.length - 1;
      var lastEntryData = this.MFD.groupDetailArr[lastEntryLen];
      if( this.MFD.groupDetailArr[lastEntryLen].sectionOrdering == '' || this.MFD.groupDetailArr[lastEntryLen].heading == '' ){
        alert('Kindly fill above Group details');
        return false;
      }
      //this.MFD.groupDetailArr.push( JSON.parse(angular.toJson(lastEntryData) ));
      this.MFD.groupDetailArr.push( lastEntryData );
      this.MFD.groupDetailArr[lastEntryLen+1].sectionOrdering = '';
      this.MFD.groupDetailArr[lastEntryLen+1].heading = '';
      this.MFD.groupDetailArr[lastEntryLen+1].rte = '';
      setTimeout(()=>{
        var id = $('.packageGroupDetails:last').find('textarea').attr('id');
        nicEditors.allTextAreas(id);
        $('.rteAddWrap .nicEdit-main').addClass('nicEdit-main-w100').parent().addClass('nicEdit-main-w100').prev().addClass('nicEdit-main-w100');
      },50);
    }
    else if(param == 'removeGroupDetails' ){
      var lastEntryLen = this.MFD.groupDetailArr.length - 1;
      this.MFD.groupDetailArr.splice(lastEntryLen, 1);
    }
    */
    
    if(param == 'tourPackageView' ){
      var obj1 = ths;
      var obj2 = obj1.groupDetail;
      this.tourPackageViewObj = obj1;
      setTimeout(()=>{
        $('#tourPackageViewObj_groupDetail').html(this.tourPackageViewObj.groupDetail);
        $('#txtHtml_0').html(obj2[0]);
      },100);
    }
    if(param == 'loadData' ){
      /*
      setTimeout(()=>{
        var rte = ths.i.rte;
        var ind = ths.$index;
        console.log('#txtHtml_0', rte);
        $('#txtHtml_0').html(rte);
      },100);
      */
    }
    if(param == 'print' ){
      alert('Print');
      window.print();
    }
  }
  

}


