import { Component, OnInit } from '@angular/core';
//import { HttpModule, Http, Response } from '@angular/http';
//import { HttpClient } from '@angular/common/http';

import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public headerSection = true;
    public menuLinks=[];
    public allpagetitle='';
    public myusername='';
    public myusernameFlag = false;
 
    constructor(private Services:CommonService) { }

    ngOnInit() {
        if(localStorage._bookingNav != undefined){
            this.menuLinks = JSON.parse(localStorage._bookingNav);
            this.allpagetitle = localStorage._AllPageTitle;
            this.myusername = localStorage._bookingUNM;
            
            if( this.myusername != undefined ){
                if( this.myusername != '' ){
                    this.myusernameFlag = true;
                }
            }
        }
        else{
            this.menuLinks = [ 
                {
                    "title" : "",
                    "usr1" : false,
                    "usr2" : false,
                    "evnt" : "#"
                }
            ];
            this.allpagetitle = 'Tours & Travels';
    
            localStorage._bookingNav = JSON.stringify(this.menuLinks);
            localStorage._AllPageTitle = this.allpagetitle;
        }
        
        this.Services.newHeaderSubject.subscribe(res=>{
            //https://www.youtube.com/watch?v=o5V_eGdE7-A
            //Communicating Between Components.
            this.menuLinks = res._menuLinks;
            this.allpagetitle = res._allpagetitle;
            this.myusernameFlag = res._myusernameFlag;
            this.myusername = '';
            this.myusernameFlag = false;
            if( (localStorage._bookingUNM != undefined) ){
                this.myusernameFlag = true;
                this.myusername = localStorage._bookingUNM;
            }
        });
    }

    public navAction = function (param){
        /*
        if(param == 'add'){
            this.router.navigate(['add']);
            //$location.path('/add');
        }
        else if(param == 'masters'){
            this.router.navigate(['masters']);
            //$location.path('/masters');
        }
        else if(param == 'register'){
            AuthService.logout().then(function () {
              localStorage._booking = JSON.stringify({'addUpdate':'', 'id':''});
              AuthService.header({'event':'hide', 'activeLink':'none'});
              //$location.path('/register');
              this.router.navigate(['register']);
            });
        }
        else if(param == 'logout'){
    
              localStorage._bookingCID = '';
              $rootScope.bookingCID2 = '';
              localStorage._bookingUNM = '';
              $rootScope.bookingUNM2 = '';
              $rootScope.allpagetitle = '';
              localStorage._AllPageTitle = '';
              user = false;
    
              AuthService.header({'event':'hide', 'activeLink':'none'});
              var ckList = localStorage._ckeckBoxList;
              localStorage._clear();
              localStorage._ckeckBoxList = ckList;
    
              localStorage.removeItem('JWT_Token');
              localStorage.removeItem('JWT_Username');
              $scope.userLogin = false;
    
              $location.path('/login');
        }
        else{
            localStorage._booking = JSON.stringify({'addUpdate':'', 'id':''});
            //$location.path('/'+param);
            this.router.navigate([param]);
        }
        */

        localStorage._booking = JSON.stringify({'addUpdate':'', 'id':''});
        this.router.navigate([param])

    }

}
