const _filterList:any = {
    "bookingType" : "Select",
    "typeOfDate":"billDate",
    "paymentDetails" : {
        "creditNoteNo" : 0
    },
    "groupInvoiceNo" : "",
    "invoiceNo" : "",
    "planId" : "",
    "ticketNo" : "",
    "client" : "Select",
    "agentType" : "Select",
    "paymentStatus" : "Select",
    "creditNotePaymentStatus" : "Select",
    "busTravelPaymentStatus" : "Select",
    "contactNo1" : "",
    "approvedBy" : "",
    "advanceBooking" : "",
}


const _addFormData:any = {
    "groupInvoiceNo" : "",
    "invoiceNo" : "",
    "client" : "",
    "invoiceFullDate" : "",
    "invoiceFullDateTP" : 0,
    "packageInfo1" : {
        "ticketFlag" : false,
        "invoiceNo" : "",
        "tourId" : ""
    },
    "address" : "",
    "contactNo1" : "",
    "contactNo2" : "",
    "clientGSTIN" : "",
    "approvedBy" : "",
    "bookingType" : "",
    "travelPlanId" : "",
    "bookingDetails" : {
        "journeyDate" : "",
        "trainNo" : "",
        "trainName" : "",
        "from" : "",
        "to" : "",
        "class" : "",
        "pnrNo" : "",
        "transactionNo" : "",
        "quota" : ""
    },
    "passengerDetails" : [ 
        {
            "checkbox" : false,
            "empId" : "",
            "empLevel" : "",
            "name" : "",
            "sex" : "",
            "age" : 0,
            "coachNoSeat" : "",
            "planId" : "0",
            "ticketNo" : ""
        }
    ],
    "passengerCount" : {
        "adult" : 1,
        "child" : 0,
        "total" : 1
    },
    "chargesDetails" : {
        "quota" : 0,
        "eTicket" : 0,
        "pg" : 0,
        "ticketFare" : 0,
        "service" : 0,
        "total" : 0,
        "taxes" : 0,
        "discountFrmAirlines" : 0,
        "serviceTax" : 0,
        "cgstPer" : 0,
        "sgstPer" : 0,
        "gstFlag" : false,
        "grandTotal" : 0,
        "creditAmount" : 0,
        "cancellationRailway" : 0,
        "cancellationService" : 0,
        "cancellationRailwayTotal" : 0,
        "cancellationServiceTotal" : 0,
        "hiddenServiceCharges" : 0
    },
    "paymentDetails" : {
        "paymentStatus" : "Pending",
        "paymentFrom" : "",
        "paymentBy" : "",
        "paymentByChequeNo" : "",
        "paymentByBankName" : "",
        "paymentDate" : "",
        "creditNoteFlag" : true,
        "creditNotePaymentStatus" : "Given",
        "creditNotePaymentDate" : "",
        "creditNoteNo" : 0,
        "creditNoteDate" : "",
        "discount" : "",
        "pendingAmount" : 0,
        "paymentReceived" : 0,
        "busTravelPaymentStatus" : "",
        "cancelled" : "",
        "remark" : ""
    },
    "agentDetails" : {
        "agentType" : "",
        "payment" : "",
        "userId" : ""
    },
    "otherDetails":{
        "createdBy":"",
        "createdDate":"",
        "createdTime":"",
        "modifiedBy":"",
        "modifiedDate":"",
        "modifiedTime":""
    }
}

const _othersDetails = {
    "contactDetails":"",
    "companyName":"",
    "companyFullName":"",
    "fullAddress":"",
    "logoPath":"",
    "GSTIN":"",
    "sac_shn":"",
    "hsn_shn":"",
    "terms":"",
    "allTerms":""
}

const _ls = {
    "obj":{
        "cl1":false,
        "cl1_1":false,
        "cl1_2":false,
        "cl1_3":false,
        "cl1_4":false,
        "cl1_5":false,
        "cl1_6":false,
        "cl1_7":false,
        "cl1_8":false,
        "cl1_9":false,
        "cl1_10":false,
        "cl1_11":false,
        "cl1_12":false,
        "cl1_13":false,
        "cl1_14":false,
        "cl1_15":false,
        "cl1_16":false,
        "cl1_17":false,
        "cl1_18":false,
        "cl1_19":false,
        "cl1_20":false,
        "cl1_21":false,
        "cl1_22":false,
        "cl1_23":false,
        "cl1_24":false,
        "cl1_25":false,
        "cl1_26":false,
        "cl1_27":false,
        "cl1_28":false,
        "cl1_29":false,
        "cl1_30":false,
        "cl1_31":false,
        "cl1_32":false,
        "cl1_33":false,
        "cl1_34":false,
        "cl1_35":false,
        "cl1_36":false,
        "cl1_37":false,
        "cl1_38":false,
        "cl1_39":false,

        "cl2":false,
        "cl2_1":false,
        "cl2_2":false,
        "cl2_3":false,
        "cl2_4":false,
        "cl2_5":false,
        "cl2_6":false,
        "cl2_7":false,
        "cl2_8":false,
        "cl2_9":false,
        "cl2_10":false,
        "cl2_11":false,
        "cl2_12":false,
        "cl2_13":false,
        "cl2_14":false,
        "cl2_15":false,
        "cl2_16":false,
        "cl2_17":false,
        "cl2_18":false,
        "cl2_19":false,
        "cl2_20":false,
        "cl2_21":false,
        "cl2_22":false,
        "cl2_23":false,
        "cl2_24":false,
        "cl2_25":false,
        "cl2_26":false,
        "cl2_27":false,
        "cl2_28":false,
        "cl2_29":false,
        "cl2_30":false,
        "cl2_31":false,
        "cl2_32":false,
        "cl2_33":false,
        "cl2_34":false,
        "cl2_35":false,
        "cl2_36":false,
        "cl2_37":false,
        "cl2_38":false,
        "cl2_39":false,
        
        "cl3":false,
        "cl3_1":false,
        "cl3_2":false,
        "cl3_3":false,
        "cl3_4":false,
        "cl3_5":false,
        "cl3_6":false,
        "cl3_7":false,
        "cl3_8":false,
        "cl3_9":false,
        "cl3_10":false,
        "cl3_11":false,
        "cl3_12":false,
        "cl3_13":false,
        "cl3_14":false,
        "cl3_15":false,
        "cl3_16":false,
        "cl3_17":false,
        "cl3_18":false,
        "cl3_19":false,
        "cl3_20":false,
        "cl3_21":false,
        "cl3_22":false,
        "cl3_23":false,
        "cl3_24":false,
        "cl3_25":false,
        "cl3_26":false,
        "cl3_27":false,
        "cl3_28":false,
        "cl3_29":false,
        "cl3_30":false,
        "cl3_31":false,
        "cl3_32":false,
        "cl3_33":false,
        "cl3_34":false,
        "cl3_35":false,
        "cl3_36":false,
        "cl3_37":false,
        "cl3_38":false,
        "cl3_39":false,
    }
}

const _totalListAmountObj = {
    advancePayment:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.advancePayment'
    },
    creditAmount:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.creditAmount'
    },
    serviceCharges:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.service'
    },
    grandTotal:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.grandTotal'
    },
    serviceTaxPercent:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.serviceTax'
    },
    serviceTaxAmount:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.serviceTax'
    },
    paymentReceived:{
        total:0, checked:0, unchecked:0, refObj:'paymentDetails.paymentReceived'
    },
    pendingAmount:{
        total:0, checked:0, unchecked:0, refObj:'paymentDetails.pendingAmount'
    },
    cgstval:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.cgstVal'
    },
    sgstval:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.sgstVal'
    },
    igstval:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.igstVal'
    },
    discount:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.discount'
    },
    ticketFare:{
        total:0, checked:0, unchecked:0, refObj:'chargesDetails.ticketFare'
    },
    hiddenAmount1:{
        total:0, checked:0, unchecked:0, refObj:'paymentDetails.hiddenAmount1'
    },
    hiddenAmount2:{
        total:0, checked:0, unchecked:0, refObj:'paymentDetails.hiddenAmount2'
    },
}


export {_filterList, _addFormData, _othersDetails, _ls, _totalListAmountObj};
