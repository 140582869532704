import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
//import 'rxjs/add/operator/map';
import 'rxjs';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Injectable()
export class CommonService {
  public newHeaderSubject = new Subject<any>();
  public _setGlobalObjectFunc = new Subject<any>();
  
  //Variables of Header function starts here>
  //public header;
  public headerSection;
  public allpagetitle='';
  public bookingUNM2;
  public bookingCID2;
  public bookingUT2;  
  public myusername='';
  public adminLogin;
  public menuLinks={};
  
  private isUserLoggedIn;
  private username;
  
  
  // private myUrl = 'http://api-tnt.apphubs.in/user';//LIVE A2Hosting
  // private myUrl = 'http://45.120.149.53:4444/user';//LIVE A2Hosting
  // private myUrl = 'http://api-railway.apphubs.in/user';//LIVE AWS
  // public myUrl = 'http://localhost:4444/user';//LOCAL
  public myUrl = 'http://185.151.51.179:4001/user';//VPS-SERVER-API

  //Variables of Header function starts here>
  //public viewOtherDetails;

  constructor(private http:HttpClient, private route: ActivatedRoute, private router:Router, private _httpclient:HttpClient) {
    this.isUserLoggedIn = false;
    //localStorage.isUserLoggedIn=null;
  }

  public setUserLoggedIn(){
    this.isUserLoggedIn = true;
    localStorage.isUserLoggedIn = true;
  }

  public getUserLoggedIn(){
    return localStorage.isUserLoggedIn;
    //return this.isUserLoggedIn;
  }

  public checkValidURL = function(param){
    //This function will not allow user to login without permission from DB
    //Note: this function should be call on all pages onInit();
    let nav = JSON.parse(localStorage._bookingNav);
    let ut = JSON.parse(localStorage._bookingAL);
    let flag = false;
    
    /*for(var n=0; n<nav.length; n++){
      if( ut==true && nav[n].usr1==true && nav[n].evnt==param ){
        flag = true;
        break;
      }
      else if( ut==false && nav[n].usr2==true && nav[n].evnt==param ){
        flag = true;
        break;
      }
    }*/

    for(var m=0; m<nav.length; m++){
      if( nav[m].evnt==param ){
        flag = true;
        break;
      }
    }

    if(flag==false){
      this.router.navigate(['/login']);
    }
  }

  public header = function(param){
    /*
    if(param.event == 'hide'){
      this.headerSection=false;
      this.menuLinks = {};
      this.myusername='';
      return true;
    }
    else if(param.event == 'show'){
      this.headerSection=true;
      this.allpagetitle = localStorage._AllPageTitle;
      if(localStorage._bookingNav == undefined){
        alert('your booking Navigation is not set, cannot proceed further');
        return false;
      }
      var navi = JSON.parse(localStorage._bookingNav);
      for (var i = 0; i < navi.length; i++) {
        var n = navi[i];
        n.activeLink = '';
        if(n.evnt+'Page' == param.pageCheck){
          n.activeLink = "active";
        }
      };

      localStorage._bookingNav = JSON.stringify(navi);

      if(this.bookingUNM2 == undefined){
        this.bookingCID2 = localStorage._bookingCID;
        this.bookingUNM2 = localStorage._bookingUNM;
        this.bookingUT2 = localStorage._bookingUT;
      }
      this.myusername = this.bookingUNM2;
      this.adminLogin = false;
      this.menuLinks = JSON.parse(localStorage._bookingNav);
      
      if(this.bookingUT2 == 'administrator'){
        this.adminLogin = true;
        if(param.pageCheck == 'loginPage'){
          return 'dashboardPage';
        }
      }
      else{
        if(this.bookingUNM2 == undefined){
          alert('kindly relogin');
          //>>>>>$location.path('/login');
        }
        
        if(param.pageCheck == 'loginPage'){
          return 'listPage';
        }
        else{
          return true;
        }
      }
    }
    */
  }
  
  public convertToJson = function (jsonData,flag=''){
    let check1 = (JSON.stringify(jsonData)).replace(/(<([^>]+)>)/ig,"");
    let check2 = check1.replace(/ *\<?[^?>]*\?> */g, "");
    let check3 = check2.replace(/\+/g, "").replace(/\--/g, "").replace(/=/ig, "").replace(/></ig, "");
    let check4 = check3.replace(/\</g, "").replace(/\>/g, "").replace(/\<!--/g, "").replace(/\-->/g, "").replace(/!/ig, "");
    let check5 = check4.replace(/;/g, "");
    let finalJsonData = JSON.parse(check5);
    if(flag==''){
      finalJsonData.companyId = localStorage._bookingCID;
      finalJsonData.companyUNM = localStorage._bookingUNM;
    }
    return finalJsonData;
  }

  public convertToJson__ = function (jsonData){
    let check1 = (JSON.stringify(jsonData)).replace(/(<([^>]+)>)/ig,"");
    //var check1 = check1.replace(/ *\([^)]*\) */g, "");
    //var check1 = check1.replace(/ *\{{[^}}]*\}} */g, "");
    let check2 = check1.replace(/ *\<?[^?>]*\?> */g, "");
    //var check1 = check1.replace(/\(|\)/g, "");
    let check3 = check2.replace(/\+/g, "").replace(/\--/g, "").replace(/=/ig, "").replace(/></ig, "");
    let check4 = check3.replace(/\</g, "").replace(/\>/g, "").replace(/\<!--/g, "").replace(/\-->/g, "").replace(/!/ig, "");
    let check5 = check4.replace(/;/g, "");
    //.replace(/(\||,)/g, "");>> error in this replace
    
    let finalJsonData = JSON.parse(check5);
    finalJsonData.companyId = localStorage._bookingCID;
    finalJsonData.companyUNM = localStorage._bookingUNM;
    return finalJsonData;
  }

  public login(param):Observable<any>{
    return this.http.post(this.myUrl+'/login',this.convertToJson(param,'withoutUID'));
  }
  public register(param):Observable<any>{
    return this.http.post(this.myUrl+'/register',this.convertToJson(param,'withoutUID'));
  }
  public dashboardBK(param):Observable<any>{
    return this.http.post(this.myUrl+'/dashboardBK',this.convertToJson(param));
  }
  public reportsData(param):Observable<any>{
    return this.http.post(this.myUrl+'/reportsData',this.convertToJson(param));
  }
  public viewOtherDetails(param):Observable<any>{
    return this.http.post(this.myUrl+'/viewOtherDetails',this.convertToJson(param));
  }
  public listMastersData(param):Observable<any>{
    return this.http.post(this.myUrl+'/listMasters',this.convertToJson(param));
  }
  public globalListMastersData(param):Observable<any>{
    return this.http.post(this.myUrl+'/globalListMasters',this.convertToJson(param));
  }
  public listData(param):Observable<any>{
    return this.http.post(this.myUrl+'/list',this.convertToJson(param));
  }
  public addData(param):Observable<any>{
    return this.http.post(this.myUrl+'/add',this.convertToJson(param));
  }
  public deleteAllData(param):Observable<any>{
    return this.http.post(this.myUrl+'/delete',this.convertToJson(param));
  }
  public paymentReceivedForm(param):Observable<any>{
    return this.http.post(this.myUrl+'/paymentReceived',this.convertToJson(param));
  }
  public groupInvoiceNoForm(param):Observable<any>{
    return this.http.post(this.myUrl+'/groupInvoiceNo',this.convertToJson(param));
  }
  public groupPrintBill(param):Observable<any>{
    return this.http.post(this.myUrl+'/groupPrint',this.convertToJson(param));
  }
  public postponeBookingForm(param):Observable<any>{
    return this.http.post(this.myUrl+'/postponeBooking',this.convertToJson(param));
  }
  public deleteUploadedFile(param):Observable<any>{
    return this.http.post(this.myUrl+'/deleteUploadedFile',this.convertToJson(param));
  }
  public creditNoteForm(param):Observable<any>{
    return this.http.post(this.myUrl+'/creditNote',this.convertToJson(param));
  }
  public saveOtherDetails(param):Observable<any>{
    return this.http.post(this.myUrl+'/saveOtherDetails',this.convertToJson(param));
  }
  public saveCustomizeList(param):Observable<any>{
    return this.http.post(this.myUrl+'/saveCustomizeList',this.convertToJson(param));
  }
  public requestList(param):Observable<any>{
    return this.http.post(this.myUrl+'/requestList',this.convertToJson(param));
  }
  public bookingStatus(param):Observable<any>{
    return this.http.post(this.myUrl+'/bookingStatus',this.convertToJson(param));
  }
  public deleteAuth(param):Observable<any>{
    return this.http.post(this.myUrl+'/deleteAuth',this.convertToJson(param));
  }
  public updateAuth(param):Observable<any>{
    return this.http.post(this.myUrl+'/updateAuth',this.convertToJson(param));
  }
  public listAuth(param):Observable<any>{
    return this.http.post(this.myUrl+'/listAuth',this.convertToJson(param));
  }
  public globalListMasters(param):Observable<any>{
    return this.http.post(this.myUrl+'/globalListMasters',this.convertToJson(param));
  }
  public addMasters(param):Observable<any>{
    if( param.addMastersFormJsonData2.type == 'tourPackageList' ){
      let tempData1 = param.addMastersFormJsonData2.groupDetail;
      let tempData2 = this.convertToJson(param);
      tempData2.addMastersFormJsonData2['groupDetail'] = tempData1;
      return this.http.post(this.myUrl+'/addMasters', tempData2);
    }
    return this.http.post(this.myUrl+'/addMasters',this.convertToJson(param));
    //return this.http.post(this.myUrl+'/addMasters',param);
  }
  public listMasters(param):Observable<any>{
    return this.http.post(this.myUrl+'/listMasters',this.convertToJson(param));
  }
  public deleteMast(param):Observable<any>{
    return this.http.post(this.myUrl+'/deleteMast',this.convertToJson(param));
  }
  public jsonDropdown():Observable<any>{
    return this.http.get('../assets/json/dropdown.json');
  }
  



  

  public updateMainHeader(data,act){
    let umh = data._menuLinks;
    let pageTitle = data._allpagetitle;
    let logoPath = data._LogoPath;
    let myusername = data._myusername;
    let myusernameFlag = data._myusernameFlag;
    
    if(act == 'forcefully'){
      umh = [{"title":"Login","usr1":true,"usr2":true,"evnt":"login" },{"title":"Register","usr1":true,"usr2":true,"evnt":"register"}];
      pageTitle = 'Tours & Travels';
      logoPath = 'Empty';
      myusername='';
      myusernameFlag = false;
      localStorage.removeItem('JWT_Token');
      localStorage.removeItem('JWT_Username');
      localStorage.removeItem('isUserLoggedIn');

      localStorage.removeItem('_Navigation');
      localStorage.removeItem('_LogoPath');

      //localStorage.removeItem('mainDetails');
      localStorage.removeItem('_booking');
      //localStorage.removeItem('_AllPageTitle');
      localStorage.removeItem('_bookingCID');
      localStorage.removeItem('_bookingNav');
      localStorage.removeItem('_bookingUNM');
      localStorage.removeItem('_bookingUT');
      //localStorage.removeItem('_ckeckBoxList');
      localStorage.removeItem('_bookingAL');
      localStorage.removeItem('_jsonDropdown');
    }
    localStorage._bookingNav = JSON.stringify(umh);
    localStorage._AllPageTitle = pageTitle;
    localStorage._LogoPath = logoPath;

    var mydata = {"_allpagetitle":pageTitle,"_menuLinks":umh,"_LogoPath":logoPath,"_myusername":myusername,"_myusernameFlag":myusernameFlag};
    this.newHeaderSubject.next( mydata );
    
  }
  public _updateGlobalObjectFunc(data,flag){
    if(flag == 'loginData'){
      let d = {
        bookingCID2:data.compId,
        bookingUNM2:data.usern,
        bookingUT2:data.usert
      }
      localStorage._bookingCID = data.compId;
      localStorage._bookingUNM = data.usern;
      localStorage._bookingUT = data.usert;
      this._setGlobalObjectFunc.next( {dataType:flag,data:d} );
    }
    if(flag == 'viewOtherDetails'){
      let d = { AllPageTitle:data.AllPageTitle }
      this._setGlobalObjectFunc.next( {dataType:flag,data:d} );
    }
    /*if(flag == 'getGlobalObjectToRouter'){
      console.log('>>>-----'+flag);
      this._setGlobalObjectFunc.next( {dataType:flag,data:{}} );
    }*/
    
    /*
      var ut = false;
      if(data.usert == 'administrator'){ut = true}
      var dataG = {
        bookingCID2:data.compId,
        bookingUNM2:data.usern,
        bookingUT2:data.usert,
        adminLogin:ut
      }
      this.globalObjFunc.next( dataG );
    */
  }


  public addZero(val){
    if( parseInt(val) < 10 ){
      return '0'+val;
    }
    return val;
  }


  public fullDateTPFunc(dd,mm,yy){
    var d = new Date('' + mm + '/' + dd + '/' + yy + '');
    var n = d.getTime();
    return n;
  }


  public specialClientObj(){
    //Default Settings which is based on the 1st version of this software
    let obj = {
      'list_btnShow': false, ////DEFAULT = false
      'add_airLinesChargesDiscount': false, ////DEFAULT = false
      'list_showInvoiceText': true, ////DEFAULT = true
      'all_flagHiddenServiceCharges': true, ////DEFAULT = true
      'all_flagShowTravelAgentCharge': false, ////DEFAULT = false
      'list_showText_serviceTax': true, ////DEFAULT = true

      'list_show_uploadPDF': false, ////DEFAULT = false
      'list_show_postponeBooking': false, ////DEFAULT = false
      'global_show_charges_quota':true, ////DEFAULT = true
      'global_show_charges_eTicket':true, ////DEFAULT = true
      'global_show_charges_pg':true, ////DEFAULT = true
      'global_show_charges_catering':true, ////DEFAULT = true
      'global_show_charges_convenience':true, ////DEFAULT = true
      'global_show_charges_insurance':true, ////DEFAULT = true
      'global_show_charges_misc':true, ////DEFAULT = true
      'global_show_charges_serviceTax':true, ////DEFAULT = true
      'global_show_agentDetails_module':true, ////DEFAULT = true
      'global_show_journeyDetails_pnrNo':true, ////DEFAULT = true
      'global_show_journeyDetails_transactionId':true, ////DEFAULT = true
      'global_show_generalDetails_advanceBooking':false, ////DEFAULT = false
      'global_show_generalDetails_clientRequestDate':false, ////DEFAULT = false
      'global_show_groupInvoiceNo':false, ////DEFAULT = false
      'add_show_autoFill':true, ////DEFAULT = true
      'global_show_air_baggage':true, ////DEFAULT = true
      'global_show_air_bookingReference':true, ////DEFAULT = true
      'global_show_air_flightDetils':true, ////DEFAULT = true
      'global_show_payments_hiddenAmount':false, ////DEFAULT = false
    }
    return obj; 
  }
  public specialClientFunc(serverObj, localObj){
    var serverObjArr = serverObj;
    var localObjArr = localObj;
    var a = JSON.stringify(serverObjArr);
    var b:any = (a+' ').split(' ');
    if(a.indexOf(',') != undefined){
      b = a.split(',');
      console.log('Showing Issue n showing error sometime, if there is no "object[specialClientFunc] & its child"  on server');
    }
    
    for(var i=0; i<b.length; i++ ){
      var c = b[i].replace(/"|{|}/g,'').split(':');
      eval("localObjArr."+c[0]+" = "+c[1]);
    }
    return localObjArr;
  }

  public inwords(num) {
    var a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    var b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];

    if(num > 1){
      if ((num = num.toString()).length > 9) return 'overflow';
      var n:any = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return; var str = '';
      str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
      str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
      str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
      str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
      str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'rupees only ' : '';
      return str;
    }
    else{
      return 0;
    }
  }


  public _ObjNumOrdering(order=true, orderBy:string, objArray){
    let dummyArray = objArray.slice(0);
    dummyArray.sort(function(a,b) {
        return eval('a.'+orderBy) - eval('b.'+orderBy);
    });
    return dummyArray;
  }
  public _ObjOrdering(order=true, orderBy:string, objArray){
    function GetSortOrder(prop) {  
      return function(a, b) {  
        if (a[prop] > b[prop]) {  
            return 1;  
        } else if (a[prop] < b[prop]) {  
            return -1;  
        }  
        return 0;  
      }  
    };

    let objct1 = (objArray).sort(GetSortOrder(orderBy));
    if(order == false){ objct1.reverse(); }
    return objct1;
  }

  public _getActiveClients(objArray){
    let clLst = objArray;
    let clLstArr = []; 
    for(let cl=0; cl<clLst.length; cl++){
        if( clLst[cl].clientStatus != "false" ){
            clLstArr.push(clLst[cl]);
        }
    }
    return clLstArr;
  }

  /*
    public commonJsonObjectFunc = function(){
        var tempObject = {
            "invoiceNo" : "",
            "client" : "",
            "invoiceFullDate" : "",
            "invoiceFullDateTP" : 0,
            "packageInfo1" : {
                "ticketFlag" : false,
                "invoiceNo" : "",
                "tourId" : ""
            },
            "address" : "",
            "contactNo1" : "",
            "contactNo2" : "",
            "clientGSTIN" : "",
            "approvedBy" : "",
            "bookingType" : "",
            "travelPlanId" : "",
            "bookingDetails" : {
                "journeyDate" : "",
                "trainNo" : "",
                "trainName" : "",
                "from" : "",
                "to" : "",
                "class" : "",
                "pnrNo" : "",
                "transactionNo" : "",
                "quota" : ""
            },
            "passengerDetails" : [ 
                {
                    "checkbox" : false,
                    "empId" : "",
                    "empLevel" : "",
                    "name" : "",
                    "sex" : "",
                    "age" : 0,
                    "coachNoSeat" : "",
                    "planId" : "0",
                    "ticketNo" : ""
                }
            ],
            "passengerCount" : {
                "adult" : 3,
                "child" : 0,
                "total" : 3
            },
            "chargesDetails" : {
                "quota" : 0,
                "eTicket" : 0,
                "pg" : 0,
                "ticketFare" : 0,
                "service" : 0,
                "total" : 0,
                "taxes" : 0,
                "discountFrmAirlines" : 0,
                "serviceTax" : 0,
                "cgstPer" : 0,
                "sgstPer" : 0,
                "gstFlag" : false,
                "grandTotal" : 0,
                "creditAmount" : 0,
                "cancellationRailway" : 0,
                "cancellationService" : 0,
                "cancellationRailwayTotal" : 0,
                "cancellationServiceTotal" : 0,
                "hiddenServiceCharges" : 0
            },
            "paymentDetails" : {
                "paymentStatus" : "Pending",
                "paymentFrom" : "",
                "paymentBy" : "",
                "paymentByChequeNo" : "",
                "paymentByBankName" : "",
                "paymentDate" : "",
                "creditNoteFlag" : true,
                "creditNotePaymentStatus" : "Given",
                "creditNotePaymentDate" : "",
                "creditNoteNo" : 0,
                "creditNoteDate" : "",
                "discount" : "",
                "pendingAmount" : 0,
                "paymentReceived" : 0,
                "busTravelPaymentStatus" : "",
                "cancelled" : "",
                "remark" : ""
            },
            "agentDetails" : {
                "agentType" : "",
                "payment" : "",
                "userId" : ""
            }
        }
        return tempObject;
    }
  */

///////////////////////COMPONENTS//LIBRARY//STARTED///////////////
///////////////////////COMPONENTS//LIBRARY//STARTED///////////////
///////////////////////COMPONENTS//LIBRARY//STARTED///////////////
///////////////////////COMPONENTS//LIBRARY//STARTED///////////////


  //public _services(url,param,urlType=''):Observable<any>{
  public _services(url,param,urlType=''){
    // DO NOT DELETE BELOW COMMENTS
    //  this.Services._services('_add/dropdown', {response:{"thisIsWhichPage":"DL-AddPage 12Feb2018-"}} ).subscribe(res =>{
    //    console.log('------success delete',res.json());
    //  },
    //  err => {
    //    console.log('------error delete');
    //  });
    //console.log('>>>>>>>>>>>>>>',url,param,urlType);
    ////////////param['CID'] = localStorage.JWT_Username;

    /* 
    var u ='';
    if(urlType=='LIVE_V1'){
      u = 'https://www.lalpathlabs.com/partner/api/v1/'+url;
      //u = this.urlObj.partner+''+url;

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('x-auth-token',localStorage.JWT_Token);
      if( localStorage.JWT_V1_Token != undefined ){
      }

      const options = new RequestOptions({headers: headers});
      return this.http.post(u, param, options);
    }
    else{
      //console.log('!! API URL not found !!');
    }*/
    return this.http.post(url, param);
  }


  public _GetCheckedObjFromList(objList){
    //Sort all the input attr Checked from list of Objects
    return objList.filter(dt => dt.__checked === true);
  }

  public _FromElementValidation(validationObj){
    let reg = false;
    let value = validationObj.value;

    if(validationObj.type == 'input' ){
      
      if( validationObj.validation.type == 'email' ){
        reg = (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(value);
        alert('Email Validation Functionality is Pending :(...');
      }
      
    }

    let validFlag = false;
    if( validationObj.validation.required == 'required' ){
      if( validationObj.type == "checkbox" ){
        validFlag = true;
        for(let c=0; c<validationObj.checkboxOptions.length; c++){
          if( validationObj.checkboxOptions[c].checked == "checked" ){
            validFlag = false;
          }
        }
      }
      else if( validationObj.type == "radio" ){
        validFlag = true;
        for(let r=0; r<validationObj.radioOptions.length; r++){
          if( validationObj.radioOptions[r].checked == "checked" ){
            validFlag = false;
          }
        }
      }
      else if( validationObj.type == "select" ){
        validFlag = true;
        for(let s=0; s<validationObj.selectOptions.length; s++){
          if( validationObj.selectOptions[s].checked == "checked" ){
            validFlag = false;
          }
        }
      }
      else if( validationObj.type == "textarea" || validationObj.type == "input" || validationObj.type == "uiDatepicker" ){
        if( validationObj.value == '' ){
          validFlag = true;
        }
      }
    }


    if( validationObj.type == "input" && validationObj.class == "email" ){
      if( validationObj.value != '' ){
        reg = (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/).test(validationObj.value);
        if(reg == false){
          validFlag = true;
        }
      }
    }


    if( validFlag == true){
      ////This is ERROR
      validationObj.validation.error = true;
      
      if(validationObj.validation.fixedErrorMessage != undefined){
        validationObj.validation.errorMessage = validationObj.validation.fixedErrorMessage;
      }
      else{
        if( validationObj.type == 'input' || validationObj.type == 'textarea' ){
          validationObj.validation.errorMessage = "Kindly enter your "+ validationObj.title;
        }
        else if( validationObj.type == 'select' || validationObj.type == 'radio' || validationObj.type == 'checkbox' || validationObj.type == 'uiDatepicker' ){
          validationObj.validation.errorMessage = "Kindly select your "+ validationObj.title;
        }
      }

      return validationObj;
    }
    else{
      ////This is NOT ERROR
      validationObj.validation.error = false;
      validationObj.validation.errorMessage = "";
      return validationObj;
    }

  }

  public _FormToObjL0(paramObj, objList){
    //converting client objects to server objects (targeting Level 1)
    let validationFlag=false;
    let a={};
    for(let i=0; i<objList.length; i++){
      if(this._FromElementValidation( paramObj[objList[i]] ).validation.error == true || this._FromElementValidation( paramObj[objList[i]] ).validation.error == "true" ){
        validationFlag = true;
        paramObj[objList[i]].validation.error = true;
        //console.log('////////validationFlag>>> ', validationFlag );
      }
      else{
        paramObj[objList[i]].validation.error = false;
        if( paramObj[objList[i]].type == "radio" ){
          //console.log( 'paramObj[objList[i]]>>', paramObj[objList[i]] );
          let v1 = "";
          for(let r=0; r<paramObj[objList[i]].radioOptions.length; r++ ){
            //console.log(">>111111>>",paramObj[objList[i]].radioOptions[r].checked)
            if( paramObj[objList[i]].radioOptions[r].checked == "checked" ){
              v1 = paramObj[objList[i]].radioOptions[r].value;
              paramObj[objList[i]].value = paramObj[objList[i]].radioOptions[r].value;
              //console.log('paramObj[objList[i]]-----------', paramObj[objList[i]]);
              //break;
            }
          }
          //paramObj[objList[i]].value = paramObj[objList[i]].value.value;
          paramObj[objList[i]].value = v1;
        }
        else if( paramObj[objList[i]].type == "checkbox" ){

          
          let newChkVal = [];
          //console.log('>>>>>>>>------', paramObj[objList[i]].checkboxOptions, paramObj[objList[i]].checkboxOptions.length );
          for(let c=0; c<paramObj[objList[i]].checkboxOptions.length; c++ ){
            //console.log('>>------', paramObj[objList[i]].checkboxOptions[c].checked, 'checked' );

            if( paramObj[objList[i]].checkboxOptions[c].checked == "checked" ){
              let v1 = paramObj[objList[i]].checkboxOptions[c].value;
              newChkVal.push( {"checkboxValue":v1} );
            }
          }
          paramObj[objList[i]].value = newChkVal;

        }
        else if( paramObj[objList[i]].type == "uiDatepicker" ){
          //let v1 = paramObj[objList[i]].checkboxOptions[c].value;
          //paramObj[objList[i]].value = newChkVal;
          //console.log('uiDatepicker>>>>>>', paramObj[objList[i]] );
        }
        a[objList[i]] = paramObj[objList[i]].value;
        //console.log('////////', paramObj[objList[i]], a[objList[i]]  )
      }
    }

    //console.log('////if(validationFlag)>>> ', validationFlag );
    if(validationFlag == true){return 'error';}
    return a;
  }

  public _FormToObjL1(paramObj){
    //converting client objects to server objects (targeting Level 2)
    let objList = Object.keys(paramObj);
    let validationFlag = this._FormToObjL0(paramObj, objList);
    //console.log('////_FormToObjL1//////if(validationFlag)>>> ', validationFlag );
    if(validationFlag == 'error'){return 'error';}

    let a={};
    Object.entries(paramObj).forEach(([key,val]) => {
      a[key] = eval('val.value');///////Have added EVAL() as it was throwing error in Angular-6 ng build
    });
    return a;
  }

  public _formatDate(dateString, before, after){
    //Date formatting ;)
    let getSplitValue = before.replace(/[dmy]/gi,'').charAt(0);
    let splitBefore = before.split(getSplitValue);
    let ddIndexBefore = splitBefore.indexOf('dd');
    let mmIndexBefore = splitBefore.indexOf('mm');
    let yyIndexBefore = splitBefore.indexOf('yyyy');
    let valueOfBefore = ddIndexBefore+''+mmIndexBefore+''+yyIndexBefore;

    let setSplitValue = after.replace(/[dmy]/gi,'').charAt(0);
    let splitAfter = after.split(setSplitValue);
    let ddIndexAfter = splitAfter.indexOf('dd');
    let mmIndexAfter = splitAfter.indexOf('mm');
    let yyIndexAfter = splitAfter.indexOf('yyyy');
    let valueOfAfter = ddIndexAfter+''+mmIndexAfter+''+yyIndexAfter;

    let d = dateString.split(getSplitValue);
    let finalVal=['d1','m2','y3'];

    finalVal[ddIndexAfter] = d[ddIndexBefore];
    finalVal[mmIndexAfter] = d[mmIndexBefore];
    finalVal[yyIndexAfter] = d[yyIndexBefore];

    return finalVal.join(setSplitValue);
  }

  public _getTime(dateString, currentFormat){
    let newDateString = this._formatDate(dateString, currentFormat, 'dd/mm/yyyy');
    let cf1 = newDateString.split('/');
    let dd:any=parseInt(cf1[0]), mm:any=parseInt(cf1[1]);
    let str = ''+mm+'/'+dd+'/'+parseInt(cf1[2])+'';
    let cf2 = new Date(str);////mm/dd/yyyy
    return cf2.getTime();
  }

  public _textToHtml(className){
    setTimeout(()=>{
      let ele = document.querySelectorAll(className);
      for (let i = 0; i < ele.length; i++) {
        let e = ele[i].textContent;
        ele[i].innerHTML = e;
        document.querySelectorAll(className)[0].removeAttribute('class');
      }
    },500);
  }

  public _RemoveExtraObjectsFromArrayList(arr,heading ){
    //This function is very important > this will remove OBJECT is its not having any KEY mentioned in HEADING Object
    let heading1 = Object.keys(heading);
    let dataBody = arr.reduce((objB, line, ind) => {
      let lineKeys = Object.keys(line);
      let lineFlag = false;      
      
      heading1.map(prop => {
        if(lineKeys.indexOf(prop) != -1 && lineFlag == false){
          lineFlag = true;
        }
      });

      if(lineFlag){
        objB.push(line);
      }      
      return objB;
    },[]);
    return dataBody;
  }

  public _ObjToForm(clientObj, serverObj){
    //converting server objects to client objects
    //console.log('_ObjToForm', clientObj, serverObj);
    var ck = Object.keys(clientObj);
    var sk = Object.keys(serverObj);
    Object.entries(clientObj).forEach(([cKey,cVal]) => {
      Object.entries(serverObj).forEach(([sKey,sVal]) => {
        if(sKey == cKey){
          if(clientObj[cKey].type == 'radio'){
            for(let rd=0; rd<clientObj[cKey].radioOptions.length; rd++){
                if( clientObj[cKey].radioOptions[rd].value == sVal ){
                  clientObj[cKey].radioOptions[rd].checked = "checked" 
                }
            }
          }
          else if(clientObj[cKey].type == 'select'){
            for(let ss=0; ss<clientObj[cKey].selectOptions.length; ss++){
                if( clientObj[cKey].selectOptions[ss].value == sVal ){
                  clientObj[cKey].selectOptions[ss].checked = "checked" 
                }
            }
            clientObj[cKey].value = sVal;
          }
          else if(clientObj[cKey].type == 'checkbox'){
            let svLength = eval('sVal.length');
            for(let cb=0; cb<clientObj[cKey].checkboxOptions.length; cb++){
              for(let sv=0; sv<svLength; sv++){
                if( clientObj[cKey].checkboxOptions[cb].value == sVal[sv].checkboxValue ){
                  clientObj[cKey].checkboxOptions[cb].checked = "checked";
                }
              }
            }
          }
          else{
            clientObj[cKey].value = sVal;
          }
        }
      });
    });
    return clientObj;
  }

  public _loadFromToDatePicker(){
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let dt = new Date();
    let dt1 = dt.getDate();
    let dt2 = months[dt.getMonth()];
    let dt3 = dt1+'-'+dt2+'-'+dt.getFullYear();

    var dateFormat = "d-MM-yy",
    from = $( ".from" )
    .datepicker({
      defaultDate: dt3,
      dateFormat: 'd-MM-yy',
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1,
    })
    .on( "change", function() {
      to.datepicker( "option", "minDate", getDate( this ) );
    }),
    to = $( ".to" ).datepicker({
      defaultDate: dt3,
      dateFormat: 'd-MM-yy',
      changeMonth: true,
      changeYear: true,
      numberOfMonths: 1,
    })
    .on( "change", function() {
      from.datepicker( "option", "maxDate", getDate( this ) );
    });

    $('.from').datepicker().datepicker('setDate', 'today');
    $('.to').datepicker().datepicker('setDate', 'today');

    function getDate( element ) {
      var date;
      try {
        date = $.datepicker.parseDate( dateFormat, element.value );
      } catch( error ) {
        date = null;
      }
      return date;
    }

    return '';

  }

  public getSeach_info(){
    return {"Name":"", "FromRegDate":"", "ToRegDate":""}
  }

}
