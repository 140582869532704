import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  infoFormData;
  contactList;

  constructor(private http:HttpClient, private Services:CommonService) { 
    

  }

  ngOnInit() {
    this.Services.checkValidURL('info');
    //var status = AuthService.header({'event':'show', 'activeLink':'none', 'pageCheck':'infoPage'});
    //if(status == false){ $location.path('/list') };
    this.infoFormData={
      contactList:[],
      AllTermsConditions:[]
    }
    
    //let obj = {companyId:localStorage._bookingCID,companyUNM:localStorage._bookingUNM};
    //this.http.post('/user/viewOtherDetails',obj).subscribe(res =>{
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      this.infoFormData = res[0];
      this.contactList = this.infoFormData.ContactDetails;
      this.allTermsConditionsListFunc();
      this.generateInfoGraph();
    },
    err => {
      console.log(err);
      //return err;
    })
  }

  
  allTermsConditionsListFunc = function(){
    for(var h=0; h<this.infoFormData.AllTermsConditions.length; h++){
      var tnc = this.infoFormData.AllTermsConditions[h].TermsConditions;
      var a = '';
      for(var i=0; i<tnc.length; i++){
        a += tnc[i].line;
        if(i<tnc.length-1){ a += ' ## ' }
      }
      this.infoFormData.AllTermsConditions[h].TermsConditions = a;
    }
  };

  
  generateInfoGraph = function(){
    var ls = JSON.parse(localStorage.mainDetails);
    var dd1 = parseInt(ls.dd);
    var dynText = '<span style="color:#333"> Subscription <br> Days remaining <br> '+(365-dd1)+'/365 </span>';
    if(dd1 >= 365){
      dd1 = 365;
      dynText = '<span style="color:#e92012"> Subscription </span><br> <b style="color:#e92012">Expired!</b>';
    }
    //var dd1 = parseInt(localStorage.mainDetails.dd);
    
    $('#infoChart').highcharts({
          //Highcharts.chart('infoChart', {
          chart: {
              type: 'gauge',
              plotBorderWidth: 0,
              plotBackgroundImage: null,
              height: 140
          },
          exporting: { enabled: false },
          credits: {enabled: false },
          title: {text: ''},
          pane: [{
              startAngle: -55,
              endAngle: 55,
              background: null,
              center: ['50%', '150%'],
              size: 300
          }],
          tooltip: {
              enabled: false
          },
          yAxis: [{
              min: 0,
              max: 365,
              minorTickPosition: 'inside',
              tickPosition: 'inside',
              labels: {
                  rotation: 'auto',
                  distance: 13
              },
              plotBands: [{
                  from: 0,
                  to: 215,
                  color: '#29bda4',
                  innerRadius: '100%',
                  outerRadius: '103%'
              },{
                  from: 215,
                  to: 305,
                  color: '#ffa060',
                  innerRadius: '100%',
                  outerRadius: '105%'
              },{
                  from: 305,
                  to: 365,
                  color: '#e92012',
                  innerRadius: '100%',
                  outerRadius: '107%'
              }],
              pane: 0,
              title: {
                  text: dynText,
                  y: -30
              }
          }],

          plotOptions: {
              gauge: {
                  dataLabels: {
                      enabled: false
                  },
                  dial: {
                      radius: '100%'
                  }
              }
          },
          series: [{
              name: ' ',
              data: [dd1],
              yAxis: 0
          }]
    });
  }

}
