import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { OrderModule } from 'ngx-order-pipe';
import { _filterList, _addFormData, _othersDetails, _ls, _totalListAmountObj } from './list.objects';
import {  FileUploader, FileSelectDirective } from 'ng2-file-upload';


declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library
declare var saveAs:any;//this way u can include other saveAs file

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    constructor(private http:HttpClient, private router:Router, private Services:CommonService) { }

    clientListOrder: string = 'clientName';
    agentTypeOrder: string = 'agentType';

    public globalVar={};
    public filterFormData:any = _filterList;
    public viewOtherDetails = '';
    public showClientTo = true;
    public showListFlag = false;
    public showListActionFlag = false;
    public adminLogin = false;
    public viewCheckboxObjList = [];
    public jsonListDataExcel = [];
    public jsonListDataExcelNew = [];
    public jsonListData:any = [];
    public jsonListDataAdvanced:any = [];

    public viewOtherDetailsData:any = [];
    public bookingTypeList:any = [];
    public onClearClickSeverHit = false;
    public clientList:any;
    public agentList:any;
    public agentListFinal:any;
    public trainClassListFinal:any;
    public passengerDetailsList:any;
    public searchResultInfoBox='';
    public searchResultInfoBoxValue='';
    public sac_shnValClass = 'col-xs-9';
    public hsn_shnValClass = 'col-xs-9';
    
    public HtmlServices:any;
    public errorClass:any;
    public typeOfClient:any;
    public typeOfClientForAirFlag:any = null;
    public AirWrap = false;
    public BusWrap = false;
    public TrainWrap = false;
    public PackageWrap = false;
    public VehicleWrap = false;
    public HotelWrap = false;
    public VisaWrap = false;
    public ExpenseWrap = false;
    public AirFilterWrap = false;
    public BusFilterWrap = false;
    public TrainFilterWrap = false;
    public PackageFilterWrap = false;
    public VehicleFilterWrap = false;
    public HotelFilterWrap = false;
    public VisaFilterWrap = false;
    public ExpenseFilterWrap = false;
    public ls = _ls;
    public paymentStatusList:any = [];
    public creditNotePaymentStatusList:any = [];
    public busTravelPaymentStatusList:any = [];
    public paymentByList:any = [];

    public readonlyPaymentByChequeNo = false;
    public readonlyPaymentByBankName = false;
    public updatePaymentRecNum = 0;
    public readonlyCreditNotePaymentDate = false;
    public passengerList = [];
    public othersDetails = _othersDetails;
    public grandTotalInWords = "";
    public creditAmountInWords = "";
    public otherDetails = _othersDetails;
    
    public monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    public jsonListDataAdvancedCheck=[];
    public jsonListDataAdvancedCheckAll;
    public invoiceNoArray=[];
    
    public groupBillFlag_TicketBill = true;
    public groupBillFlag_CreditNote = true;
    public groupBillFlag_InvoiceNos = true;
    public generateGroupInvoiceNoFT = {
        fromDate:'',
        toDate:'',
        date:'',
    };
    public totalListAmount:any={};
    public totalListAmountObj:any={};
    public totalListAmountObjFlag:any=false;

    public groupPrintObject={
        clientInfo:{
            clientName:'',
            clientAddress:'',
            clientNumber:'',
            clientGSTIN:'',
        },
        groupInvoiceNo:0,
        groupInvoiceDate:0,
        groupInvoiceNoFromDate:0,
        groupInvoiceNoToDate:0,
        ticketInvoiceNo:[],
        ticketBill:{
            ticketFare:0,
            serviceCharges:0,
            cgst:0,
            sgst:0,
            igst:0,
            grandTotal:0,
        },
        creditNote:{
            ticketFare:0,
            railwayCancellation:0,
            serviceCancellation:0,
            creditAmount:0,
        },
    };

    public dyCustomizeListFinalHeading = [];
    public dyCustomizeListFinal = [];
    public dyCustomizeList = [];
    public hidden = false;

    public dynamicJsonListDataAdvanced = [];

    public specialClient:any={};
    public all_flagHiddenServiceCharges=false;
    public addFormData:any = _addFormData;
    public uploadFileArray = [];
    public uploaderComment = '';
    public browseFileName = '';

    public uploader:FileUploader;
   
    ngOnInit() {

        this.HtmlServices = this.Services;
 
        this.uploaderInit();

        this.Services.checkValidURL('list');
        this.otherDetails = _othersDetails;
        this.filterFormData = _filterList;
        
        this.totalListAmountObj = _totalListAmountObj;
    
        this.specialClient = this.Services.specialClientObj();
        this.adminLogin = localStorage._bookingAL;
        //this.customizeListFunc();
        
        //-------------------------
        var d = new Date();
        var yrRange = "2016:"+(d.getFullYear()*1+1);
        $(".datepicker").datepicker({
          dateFormat: 'dd M, yy',
          changeMonth: true,
          changeYear: true,
          yearRange: yrRange
        }); 
        
        //-------------------------
        var dateFormat = "dd M, yy",
        from = $( "input[name='journeyDateFrom']" )
          .datepicker({
            dateFormat: 'dd M, yy',
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            yearRange: yrRange
          })
          .on( "change", function() {
            to.datepicker( "option", "minDate", getDate( this ) );
          }),
        to = $( "input[name='journeyDateTo']" ).datepicker({
          dateFormat: 'dd M, yy',
          defaultDate: "+1w",
          changeMonth: true,
          changeYear: true,
          yearRange: yrRange
        })
        .on( "change", function() {
          from.datepicker( "option", "maxDate", getDate( this ) );
        });
   
        function getDate( element ) {
            var date;
            try {
            date = $.datepicker.parseDate( dateFormat, element.value );
            } catch( error ) {
            date = null;
            }
    
            return date;
        }
        //-------------------------
        
        var data = { listFormJsonData2: { searchType: {} } }
        this.Services.listMastersData(data).subscribe(res =>{
            //console.log('check 2', this.filterFormData.bookingType);
            let data = res;
            this.clientList = this.getDropdownData(data, 'clientList', 'clientName', true);

            this.clientList = this.Services._ObjOrdering( true, 'clientName', this.clientList);
            this.clientList = this.Services._getActiveClients(this.clientList);
            
            this.agentList = this.getDropdownData(data, 'agentList', 'agentType', true);

            this.trainClassListFinal = this.getDropdownData(data, 'trainClassList', 'className', true);
        },
        err => {
          console.log(err);
        });

        this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
            //console.log('check 3', this.filterFormData.bookingType);
            var d = res[0];
            //console.log('D', d);
            var contactDetails = '';
            this.viewOtherDetailsData = d;
    
            for (var i = 0; i < d.ContactDetails.length; i++) {
                contactDetails += d.ContactDetails[i].contactType + ':' + d.ContactDetails[i].contactNumber;
                if (i != d.ContactDetails.length - 1) { contactDetails += ', ' }
            }
            this.otherDetails = {
            contactDetails : contactDetails,
            companyName : d.companyName,
            companyFullName : d.companyFullName,
            fullAddress : d.Address,
            logoPath : 'assets/uploads/'+d.LogoPath,
            GSTIN : d.GSTIN,
            sac_shn : d.sac_shn,
            hsn_shn : d.hsn_shn,
            terms : d.TermsConditions,
            allTerms : d.AllTermsConditions
            }
            
            //this.bookingTypeList = this.viewOtherDetailsData.bookingType;
            this.bookingTypeList = JSON.parse(localStorage._dynamicBTypeList);          
            this.filterFormData = JSON.parse(JSON.stringify(_filterList));
            
            //console.log( 'this.otherDetails.sac_shn >>>',this.otherDetails['sac_shn']);
            if(this.otherDetails['sac_shn'] != undefined){
                this.sac_shnValClass = 'col-xs-6';
            }
            if(this.otherDetails['hsn_shn'] != undefined){
                this.hsn_shnValClass = 'col-xs-6';
            }

            //this.bookingTypeList = this.viewOtherDetailsData.bookingType;
            //this.filterFormData.bookingType = this.bookingTypeList[0].bookingName;
            this.specialClient = this.Services.specialClientFunc(d.specialClient, this.Services.specialClientObj());//Special Settings for the client
            //console.log('this.specialClient >>>',this.specialClient);
    
            setTimeout(()=> {
                this.onClearClickSeverHit = true;
                this.actions('', 'filterClearDates');
                this.AirWrap = this.BusWrap = this.TrainWrap = this.PackageWrap = this.VehicleWrap = this.HotelWrap = this.VisaWrap = this.ExpenseWrap = false;
                this.AirFilterWrap = this.BusFilterWrap = this.TrainFilterWrap = this.PackageFilterWrap = this.VehicleFilterWrap = this.HotelFilterWrap = this.VisaFilterWrap = this.ExpenseFilterWrap = false;
                
                this.filterFormData.bookingType = this.bookingTypeList[0].bookingName;
                var selectionType = this.bookingTypeList[0].bookingName;

                if(selectionType == 'Air'){ this.AirWrap = this.AirFilterWrap = true; }
                else if(selectionType == 'Bus'){ this.BusWrap = this.BusFilterWrap = true; }
                else if(selectionType == 'Train'){ this.TrainWrap = this.TrainFilterWrap = true; }
                else if(selectionType == 'Package'){ this.PackageWrap = this.PackageFilterWrap = true; }
                else if(selectionType == 'Vehicle'){ this.VehicleWrap = this.VehicleFilterWrap = true; }
                else if(selectionType == 'Hotel'){ this.HotelWrap = this.HotelFilterWrap = true; }
                else if(selectionType == 'Visa'){ this.VisaWrap = this.VisaFilterWrap = true; }
                else if(selectionType == 'Expense'){ this.ExpenseWrap = this.ExpenseFilterWrap = true; }

                setTimeout(()=> {
                    this.actions('onload','filterUpdateAgentSelectBox');
                }, 500);
                /*
                var tnc = this.otherDetails.allTerms;
                if (tnc != undefined) {
                    for (var i = 0; i < tnc.length; i++) {
                        if (tnc[i].bookingName == selectionType) {
                            this.otherDetails.terms = tnc[i].TermsConditions;
                            break;
                        }
                    };
                }
                */


            }, 100);
        },
        err => {
            console.log(err);
        });

        this.onClearClickSeverHit = false;
    
        if (localStorage._jsonDropdown == undefined) {
          this.Services.jsonDropdown().subscribe(res =>{
            localStorage._jsonDropdown = JSON.stringify(res);
            this.updateDropdownList(localStorage._jsonDropdown);
          },
          err => {
            console.log(err);
          });
        } else {
          this.updateDropdownList(localStorage._jsonDropdown);
        }
    
        $(".dropdown").on("show.bs.dropdown", function(event) {
            var x = $(event.relatedTarget).text(); // Get the button text
        });
        
        $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
        $(document).on('change', '#listGrid input[type="checkbox"]', function() {
            $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
            var l = $('#listGrid .table tbody input:checked').length;
            if (l >= 1) {
                $('.multiEventButtons a').removeClass('disabled');
            }
            if (l == 1) {
                $('.singleEventButtons a').removeClass('disabled');
            }
        });
    
        $('#creditNoteForm button[type="submit"]').addClass('disabled');
    
        $(document).on('change', '#creditNoteForm input:checkbox', function() {
            //console.log('line - 345');
            var l = $('#creditNoteForm input:checked').length;
            $('#creditNoteForm button[type="submit"]').addClass('disabled');
            if (l != 0) {
                $('#creditNoteForm button[type="submit"]').removeClass('disabled');
                //console.log('Code commented here');
                /*>>>>>>>>this.actions('', 'creditNoteFormCheckbox');*/
            }
        });
    
        $(document).on('click', '.showHideTable', function() {
            var ths = $(this).parent().parent();
            if (ths.hasClass('notPrintable')) {
                ths.removeClass('hidePage').removeClass('notPrintable');
            } else {
                ths.addClass('hidePage').addClass('notPrintable');
            }
        });
    
        $(document).on('click', '.listWrapper th ', function() {
            if( $(this).find('input').length != 1 ){
                if ($(this).hasClass('clicked')) {
                    $('.listWrapper .clicked').removeClass('clicked');
                    return false;
                }
                $('.listWrapper .clicked').removeClass('clicked');
                var ind = $(this).index();
                var len = $('.listWrapper tr').length;
                $('.listWrapper tr').eq(0).find('th').eq(ind).addClass('clicked');
                for (var i = 0; i < len; i++) {
                    $('.listWrapper tr').eq(i).find('td').eq(ind).addClass('clicked');
                }
            }
        });
    
        this.actions('', 'checkBox_onload');
        //this.actions(this.defaultObj, 'loadInputData');
    }

    
    uploaderInit(){
        let path = eval('this.Services.myUrl');
        this.uploader = new FileUploader({
            url: path+'/upload/'+localStorage._bookingCID, 
            itemAlias: 'photo',
            additionalParameter: {
                comments: 'testing comments...',
                browseFileName: '',
                showFlag: 'true'
            }
        });

        this.uploader.onAfterAddingFile = (file) => { 
            file.withCredentials = false;
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            $('body').addClass('topLoading');
            if( response == '' || response == undefined){
                alert('Not able to upload the file');
                $('body').removeClass('topLoading');
            }
            else if(status == 500){
                alert('In-correct upload location');
            }
            else{
                this.uploaderComment = '';
                this.browseFileName = '';
                var data = { listFormJsonData2: { searchBy: { "invoiceNo": JSON.parse(response).invoiceNo }, searchType:"filter" } };
                this.Services.listData(data).subscribe(res =>{
                    let data22 = res[0];
                    alert('File uploaded successfully');
                    this.actions(data22,'uploadFileWrapJump');
                },
                err => {
                    alert('Not able to upload the file');
                    $('body').removeClass('topLoading');
                    console.log(err);
                });
            }
        };
    }
    
    fileBrowsed(){ 
        $('body').addClass('loading');
        this.uploader.options.additionalParameter['companyId'] = localStorage._bookingCID;
        this.uploader.options.additionalParameter['username'] = localStorage._bookingUNM;
        this.uploader.options.additionalParameter['invoiceNo'] = this.addFormData.invoiceNo;
        this.uploader.options.additionalParameter['comments'] = this.uploaderComment;
        this.uploader.options.additionalParameter['browseFileName'] = this.browseFileName;
        this.uploader.options.additionalParameter['id'] = this.addFormData._id;

        let uf = this.addFormData.uploadFiles;
        if(uf == undefined || uf == ''){
            uf = [
                {
                    "fileName":"",
                    "fileComment":""
                }
            ];
            uf = JSON.stringify(uf);
        }
        this.uploader.options.additionalParameter['uploadArr'] = uf;
        $('body').removeClass('loading');
    } 





    private getDropdownData = function(data, type, typeName, selectFlag) {
        var dataFinal = [];
        if (type == 'agentList') {
            data.push({ "type": "agentList", "agentType": "Select", "agentPaymentMode": "", "bookingType": "" });
        }
    
        for (var i = 0; i < data.length; i++) {
            if (data[i].type == type) {
                dataFinal.push(data[i]);
            }
        }
    
        if (typeName == 'trainNumber') {
            var dataFinal2 = '';
            dataFinal2 += '{';
            for (var i = 0; i < dataFinal.length; i++) {
                var tn1 = dataFinal[i].trainNumber;
                var tn2 = dataFinal[i].trainName;
                dataFinal2 += '"train_' + tn1 + '":"' + tn2 + '"';
                if (i != dataFinal.length - 1) { dataFinal2 += ','; }
            }
            dataFinal2 += '}';
    
            return JSON.parse(dataFinal2);
        }
    
        return dataFinal;
    }
    
    private updateDropdownList = function(data) {
        var data = JSON.parse(data);
        ///this.clientList = data.clientList;
        this.agentList = data.agentList;
        this.paymentStatusList = data.paymentStatusList;
        this.busTravelPaymentStatusList = data.busTravelPaymentStatusList;
        this.creditNotePaymentStatusList = data.creditNotePaymentStatusList;
        this.paymentByList = data.paymentByList;
        //this.bookingTypeList = data.bookingTypeList;
    
        //this.filterFormData.bookingType = data.bookingTypeList[0].bookingName;
        //this.filterFormData.client = data.clientList[0].clientName; //trainClassList
        this.filterFormData.typeOfDate = "billDate";
        this.filterFormData.agentType = data.agentList[0].agentName;
        this.filterFormData.paymentStatus = data.paymentStatusList[0].statusName;
        this.filterFormData.busTravelPaymentStatus = data.busTravelPaymentStatusList[0].statusName;
        this.filterFormData.creditNotePaymentStatus = data.creditNotePaymentStatusList[0].statusName;
        this.filterFormData.advanceBooking = 'Select';

        //this.addFormData = {}
        //this.addFormData.payment = {}
        this.addFormData.paymentDetails.paymentBy = data.paymentByList[0].paymentByName;
    
        localStorage._booking = JSON.stringify({ 'addUpdate': '', 'id': '' });
    }
    
    public fullAddress = function(addrss) {
      return addrss;
      /*var a = addrss.split('##');
      var val = '';
      for(var i=0; i<a.length; i++){
        val += a[i]+'<br>';
      }
      return val;*/
    }
    
    public airBaggage_flag = function() {
        if (this.addFormData.airBaggage_purchased == undefined){
            return false;
        }
        else if (this.addFormData.airBaggage_purchased.length <= 1 && this.addFormData.airBaggage_cabin.length <= 1 && this.addFormData.airBaggage_free.length <= 1) {
            return false;
        }
        return true;
    };
    public airReference_flag = function() {
        if (this.addFormData.airReference_AO == undefined){
            return false;
        }
        else if (this.addFormData.airReference_AO.length <= 1 && this.addFormData.airReference_Airline.length <= 1 && this.addFormData.airReference_CRS.length <= 1) {
            return false;
        }
        return true;
    };

    public loadInputData = function(data) {
        this.AirWrap = this.BusWrap = this.TrainWrap = this.PackageWrap = this.VehicleWrap = this.HotelWrap = this.VisaWrap = this.ExpenseWrap = false;
        this.AirWrapRequired = this.BusWrapRequired = this.TrainWrapRequired = this.PackageWrapRequired = this.VehicleWrapRequired = this.HotelWrapRequired = this.VisaWrapRequired = this.ExpenseWrapRequired = '';

        //this.addFormData = {};
        //console.log('loadInputData >> data >> ', data);
        if( data.groupInvoiceNo != undefined ){
            this.addFormData.groupInvoiceNo = data.groupInvoiceNo;
            this.addFormData.groupInvoiceDate = data.groupInvoiceDate;
            this.addFormData.groupInvoiceNoFromDate = data.groupInvoiceNoFromDate;
            this.addFormData.groupInvoiceNoToDate = data.groupInvoiceNoToDate;
        }
        this.addFormData.invoiceNo = data.invoiceNo;
        this.addFormData.client = data.client;
        this.addFormData.clientGSTINFlag = false;
        this.addFormData.typeOfClient = true;
        this.addFormData.clientAddress = '';

        for (var i = 0; i < this.clientList.length; i++) {
            var cl = data.client;
            if (cl == this.clientList[i].clientName) {
                var cl2 = this.clientList[i].clientGSTIN;
                this.addFormData.clientAddress = this.clientList[i].clientAddress;
                if (cl2 == undefined || cl2 == null || cl2 == '' || cl2 == '0') {
                    this.addFormData.clientGSTIN = '-';
                    this.addFormData.clientGSTINFlag = false;
                } else {
                    this.addFormData.clientGSTIN = cl2;
                    this.addFormData.clientGSTINFlag = true;
                }

                var cl4 = this.clientList[i].clientType;
                if (cl4 == 'Company') {
                    this.addFormData.typeOfClient = true;
                } else if (cl4 == 'Individual') {
                    this.addFormData.typeOfClient = false;
                }
                break;
            }
        }

        //this.addFormData.clientGSTIN = '-WIP-';
        //this.addFormData.typeOfClient = data.typeOfClient;
        this.addFormData.invoiceFullDate = data.invoiceFullDate;
        this.addFormData.address = data.address;
        this.addFormData.contactNo1 = data.contactNo1;
        this.addFormData.contactNo2 = data.contactNo2;
        this.addFormData.approvedBy = data.approvedBy;
        //console.log('data.bookingType >>>>>>-----', data.bookingType);
        this.addFormData.bookingType = data.bookingType;
        this.addFormData.typeOfDate = data.typeOfDate;
        this.addFormData.travelPlanId = data.travelPlanId;

        var d1 = (JSON.stringify(data));
        if (d1.indexOf('packageInfo1Details') > 0) {
            this.addFormData.packageInfo1 = {}
            this.addFormData.packageInfo1.ticketFlag = data.packageInfo1Details.ticketFlag;
            this.addFormData.packageInfo1.invoiceNo = data.packageInfo1Details.invoiceNo;
            this.addFormData.packageInfo1.tourId = data.packageInfo1Details.tourId;
        } else if (d1.indexOf('packageInfo1') > 0) {
            this.addFormData.packageInfo1 = {}
            this.addFormData.packageInfo1.ticketFlag = data.packageInfo1.ticketFlag;
            this.addFormData.packageInfo1.invoiceNo = data.packageInfo1.invoiceNo;
            this.addFormData.packageInfo1.tourId = data.packageInfo1.tourId;
        } else {
            this.addFormData.packageInfo1 = {}
            this.addFormData.packageInfo1.ticketFlag = false;
            this.addFormData.packageInfo1.invoiceNo = '';
            this.addFormData.packageInfo1.tourId = '';
        }

        this.addFormData.journeyDate = data.bookingDetails.journeyDate;
        if (data.bookingType != 'Package') {
            this.addFormData.from = data.bookingDetails.from;
            this.addFormData.to = data.bookingDetails.to;
            this.addFormData.boarding = data.bookingDetails.boarding;
            this.addFormData.airSector = data.bookingDetails.airSector;
            this.addFormData.pnrNo = data.bookingDetails.pnrNo;
            this.addFormData.transactionNo = data.bookingDetails.transactionNo;
            this.addFormData.referenceNo = data.bookingDetails.referenceNo;
        }

        if (data.bookingType == 'Train') {
            this.addFormData.trainNo = data.bookingDetails.trainNo;
            this.addFormData.trainName = data.bookingDetails.trainName;
            this.addFormData.class = data.bookingDetails.class;
            this.addFormData.quota = data.bookingDetails.quota;

            this.TrainWrap = true;
            this.TrainWrapRequired = 'required';
        } else if (data.bookingType == 'Bus') {
            this.addFormData.busName = data.bookingDetails.busName;
            this.addFormData.busType = data.bookingDetails.busType;

            this.addFormData.busName = data.bookingDetails.busName;
            this.addFormData.busTicketNo = data.bookingDetails.busTicketNo;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.busCoachType = data.bookingDetails.busCoachType;
            this.addFormData.from = data.bookingDetails.from;
            this.addFormData.to = data.bookingDetails.to;
            this.addFormData.boarding = data.bookingDetails.boarding;
            this.addFormData.busDepTime = data.bookingDetails.busDepTime;
            this.addFormData.busBoardingAddress = data.bookingDetails.busBoardingAddress;

            this.BusWrap = true;
            this.BusWrapRequired = 'required';
        } else if (data.bookingType == 'Air') {
            this.addFormData.airName = data.bookingDetails.airName;
            this.addFormData.airPreference = data.bookingDetails.airPreference;
            this.addFormData.airClass = data.bookingDetails.airClass;
            this.addFormData.airFlight = data.bookingDetails.airFlight;
            this.addFormData.airDetails = data.bookingDetails.airDetails;

            this.addFormData.airBaggage_purchased = data.bookingDetails.airBaggage_purchased;
            this.addFormData.airBaggage_cabin = data.bookingDetails.airBaggage_cabin;
            this.addFormData.airBaggage_free = data.bookingDetails.airBaggage_free;

            this.addFormData.airReference_AO = data.bookingDetails.airReference_AO;
            this.addFormData.airReference_Airline = data.bookingDetails.airReference_Airline;
            this.addFormData.airReference_CRS = data.bookingDetails.airReference_CRS;
            this.addFormData.airDepTerminal = data.bookingDetails.airDepTerminal;
            this.addFormData.airArrTerminal = data.bookingDetails.airArrTerminal;
            this.addFormData.airDepTime = data.bookingDetails.airDepTime;
            this.addFormData.airArrTime = data.bookingDetails.airArrTime;

            this.AirWrap = true;
            this.AirWrapRequired = 'required';
        } else if (data.bookingType == 'Package') {
            this.addFormData.packageName = data.bookingDetails.packageName;
            this.addFormData.packageCostAdult = data.bookingDetails.packageCostAdult;
            this.addFormData.packageCostChild = data.bookingDetails.packageCostChild;
            this.addFormData.packageOriginalCostAdult = data.bookingDetails.packageOriginalCostAdult;
            this.addFormData.packageOriginalCostChild = data.bookingDetails.packageOriginalCostChild;

            this.PackageWrap = true;
            this.PackageWrapRequired = 'required';
        } else if (data.bookingType == 'Vehicle') {
            this.addFormData.vehicleSeater = data.bookingDetails.vehicleSeater;
            this.addFormData.vehicleName = data.bookingDetails.vehicleName;
            this.addFormData.vehicleNo = data.bookingDetails.vehicleNo;
            this.addFormData.vehicleJourneyEndDate = data.bookingDetails.vehicleJourneyEndDate;
            this.addFormData.vehicleStartingKms = data.bookingDetails.vehicleStartingKms;
            this.addFormData.vehicleEndingKms = data.bookingDetails.vehicleEndingKms;
            this.addFormData.vehicleGarageStartingTime = data.bookingDetails.vehicleGarageStartingTime;
            this.addFormData.vehicleGarageEndingTime = data.bookingDetails.vehicleGarageEndingTime;
            this.addFormData.vehicleDriverName = data.bookingDetails.vehicleDriverName;
            this.addFormData.vehicleDriverMob = data.bookingDetails.vehicleDriverMob;

            this.VehicleWrap = true;
            this.VehicleWrapRequired = 'required';
        } else if (data.bookingType == 'Hotel') {
            this.addFormData.hotelName = data.bookingDetails.hotelName;
            this.addFormData.hotelNumber = data.bookingDetails.hotelNumber;
            this.addFormData.hotelAddress = data.bookingDetails.hotelAddress;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.checkOutDate = data.bookingDetails.checkOutDate;
            this.addFormData.roomType = data.bookingDetails.roomType;
            this.addFormData.noOfNights = data.bookingDetails.noOfNights;
            this.addFormData.noOfRooms = data.bookingDetails.noOfRooms;
        
            this.HotelWrap = true;
            this.HotelWrapRequired = 'required';
        } else if (data.bookingType == 'Visa') {
            this.addFormData.visaType = data.bookingDetails.visaType;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.journeyDateTP = data.bookingDetails.journeyDateTP;
            this.addFormData.validTill = data.bookingDetails.validTill;
            this.addFormData.validTillTP = data.bookingDetails.validTillTP;
            this.addFormData.travelingTo = data.bookingDetails.travelingTo;
            this.addFormData.visaName = data.bookingDetails.visaName;
            this.addFormData.visaIssuesFrom = data.bookingDetails.visaIssuesFrom;
            this.addFormData.contactNo1 = data.bookingDetails.contactNo1;
            this.addFormData.passportNo = data.bookingDetails.passportNo;

            this.VisaWrap = true;
            this.VisaWrapRequired = 'required';
        } else if (data.bookingType == 'Expense') {
            this.addFormData.expenseType = data.bookingDetails.expenseType;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.journeyDateTP = data.bookingDetails.journeyDateTP;
            this.addFormData.contactNo1 = data.bookingDetails.contactNo1;
            this.addFormData.vendorInvoiceNo = data.bookingDetails.vendorInvoiceNo;
            this.addFormData.description = data.bookingDetails.description;

            this.ExpenseWrap = true;
            this.ExpenseWrapRequired = 'required';
        }

        this.addFormData.passengerDetails = data.passengerDetails;
        for (var i = 0; i < data.passengerDetails.length; i++) {
            var pData = data.passengerDetails[i];
            var scop = eval('this.addFormData.pd' + i + ' = {}');
            scop.checkbox = pData.checkbox;
            scop.empId = pData.empId;
            scop.empLevel = pData.empLevel;
            scop.name = pData.name;
            scop.sex = pData.sex;
            scop.age = pData.age;
            scop.coachNoSeat = pData.coachNoSeat;
            scop.planId = pData.planId;
            scop.ticketNo = pData.ticketNo;
        }

        this.addFormData.adult = data.passengerCount.adult;
        this.addFormData.child = data.passengerCount.child;
        this.addFormData.total = data.passengerCount.total;

        this.grandTotalInWords = this.Services.inwords((data.chargesDetails.grandTotal*1));
        this.creditAmountInWords = this.Services.inwords((data.chargesDetails.creditAmount*1));

        this.addFormData.chargesDetails = {};
        this.addFormData.chargesDetails.quota = data.chargesDetails.quota*1;
        this.addFormData.chargesDetails.eTicket = data.chargesDetails.eTicket*1;
        this.addFormData.chargesDetails.pg = data.chargesDetails.pg*1;
        this.addFormData.chargesDetails.catering = data.chargesDetails.catering*1;
        this.addFormData.chargesDetails.travelAgentCharge = data.chargesDetails.travelAgentCharge*1;
        this.addFormData.chargesDetails.ticketFare = data.chargesDetails.ticketFare*1;
        this.addFormData.chargesDetails.convenience = data.chargesDetails.convenience*1;
        this.addFormData.chargesDetails.service = data.chargesDetails.service*1;
        this.addFormData.chargesDetails.miscCharges = data.chargesDetails.miscCharges*1;
        this.addFormData.chargesDetails.taxes = data.chargesDetails.taxes*1;
        this.addFormData.chargesDetails.discountFrmAirlines = data.chargesDetails.discountFrmAirlines*1;
        this.addFormData.chargesDetails.total = data.chargesDetails.total*1;
        this.addFormData.chargesDetails.serviceTax = data.chargesDetails.serviceTax*1;
        this.addFormData.chargesDetails.serviceTaxVal = (data.chargesDetails.grandTotal*1) - (data.chargesDetails.total*1);
        this.addFormData.chargesDetails.cgstPer = data.chargesDetails.cgstPer*1;
        this.addFormData.chargesDetails.cgstVal = data.chargesDetails.cgstVal*1;
        this.addFormData.chargesDetails.sgstPer = data.chargesDetails.sgstPer*1;
        this.addFormData.chargesDetails.sgstVal = data.chargesDetails.sgstVal*1;
        this.addFormData.chargesDetails.igstPer = data.chargesDetails.igstPer*1;
        this.addFormData.chargesDetails.igstVal = data.chargesDetails.igstVal*1;
        this.addFormData.chargesDetails.serviceTaxFlag = true;
        this.addFormData.chargesDetails.gstFlag = true;
        this.addFormData.chargesDetails.igstFlag = true;
        if (this.addFormData.chargesDetails.serviceTax == 0) { this.addFormData.chargesDetails.serviceTaxFlag = false; }

        if (data.chargesDetails.cgstPer == undefined || (data.chargesDetails.cgstPer*1) == 0 || data.chargesDetails.cgstPer == '') {
            this.addFormData.chargesDetails.gstFlag = false;
        }
        if (data.chargesDetails.igstPer == undefined || (data.chargesDetails.igstPer*1) == 0 || data.chargesDetails.igstPer == '') {
            this.addFormData.chargesDetails.igstFlag = false;
        }
    
        this.addFormData.chargesDetails.grandTotal = data.chargesDetails.grandTotal*1;
        this.addFormData.chargesDetails.creditAmount = data.chargesDetails.creditAmount*1;
        this.addFormData.chargesDetails.cancellationRailway = data.chargesDetails.cancellationRailway*1;
        this.addFormData.chargesDetails.cancellationService = data.chargesDetails.cancellationService*1;
        this.addFormData.chargesDetails.cancellationRailwayTotal = data.chargesDetails.cancellationRailwayTotal*1;
        this.addFormData.chargesDetails.cancellationServiceTotal = data.chargesDetails.cancellationServiceTotal*1;

        if (data.bookingType == 'Air') {
            this.addFormData.chargesDetails.totalOtherChargesData = data.chargesDetails.totalOtherChargesData;
        }

        this.addFormData.paymentDetails = {};
        this.addFormData.paymentDetails.paymentStatus = data.paymentDetails.paymentStatus;
        this.addFormData.paymentDetails.paymentFrom = data.paymentDetails.paymentFrom;
        this.addFormData.paymentDetails.paymentBy = data.paymentDetails.paymentBy;
        this.addFormData.paymentDetails.paymentByChequeNo = data.paymentDetails.paymentByChequeNo;
        this.addFormData.paymentDetails.paymentByBankName = data.paymentDetails.paymentByBankName;
        this.addFormData.paymentDetails.paymentDate = data.paymentDetails.paymentDate;
        this.addFormData.paymentDetails.paymentDateTP = data.paymentDetails.paymentDateTP;
        this.addFormData.paymentDetails.creditNoteFlag = data.paymentDetails.creditNoteFlag;
        this.addFormData.paymentDetails.creditNotePaymentStatus = data.paymentDetails.creditNotePaymentStatus;
        this.addFormData.paymentDetails.creditNotePaymentDate = data.paymentDetails.creditNotePaymentDate;
        this.addFormData.paymentDetails.creditNotePaymentDateTP = data.paymentDetails.creditNotePaymentDateTP;
        this.addFormData.paymentDetails.creditNoteNo = data.paymentDetails.creditNoteNo;
        this.addFormData.paymentDetails.creditNoteDate = data.paymentDetails.creditNoteDate;
        this.addFormData.paymentDetails.discount = data.paymentDetails.discount;
        this.addFormData.paymentDetails.pendingAmount = data.paymentDetails.pendingAmount;
        this.addFormData.paymentDetails.paymentReceived = data.paymentDetails.paymentReceived;
        this.addFormData.paymentDetails.cancelled = data.paymentDetails.cancelled;
        this.addFormData.paymentDetails.remark = data.paymentDetails.remark;

        if (data.bookingType != 'Package') {
            this.addFormData.agentDetails = {};
            /*>>>>>>>>>>
            if(data.agentDetails.agentType == undefined || data.agentDetails.userId == undefined || data.agentDetails.payment == undefined){
                this.addFormData.agentDetails.agentType = '';
                this.addFormData.agentDetails.userId = '';
                this.addFormData.agentDetails.payment = '';
            }
            else{
                this.addFormData.agentDetails.agentType = data.agentDetails.agentType;
                this.addFormData.agentDetails.userId = data.agentDetails.userId;
                this.addFormData.agentDetails.payment = data.agentDetails.payment;
            }
            */
            this.addFormData.agentDetails.agentType = '';
            this.addFormData.agentDetails.userId = '';
            this.addFormData.agentDetails.payment = '';

        }

        this.addFormData.otherDetails = {};
        this.addFormData.otherDetails.otherDetailsId = data.otherDetails.otherDetailsId;
        this.addFormData.otherDetails.createdBy = data.otherDetails.createdBy;
        this.addFormData.otherDetails.createdDate = data.otherDetails.createdDate;
        this.addFormData.otherDetails.createdTime = data.otherDetails.createdTime;
        this.addFormData.otherDetails.modifiedBy = data.otherDetails.modifiedBy;
        this.addFormData.otherDetails.modifiedDate = data.otherDetails.modifiedDate;
        this.addFormData.otherDetails.modifiedTime = data.otherDetails.modifiedTime;
    }
      
    public expExcelClientFunc = function() {
        var objct = this.jsonListData;
        this.jsonListDataExcel = [];
        var excelCont = '[';
        for (var i = 0; i < objct.length; i++) {
            //console.log('I--'+i);
            var obj = objct[i];
            var pdLen = obj.passengerDetails.length;
            for (var j = 0; j < pdLen; j++) {
                var serviceTaxVal = parseInt(this.valChk(obj.chargesDetails.grandTotal)) - parseInt(this.valChk(obj.chargesDetails.total));

                excelCont += '{';
                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"planId": "' + this.valChk(obj.passengerDetails[j].planId) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"ticketNo": "' + this.valChk(obj.passengerDetails[j].ticketNo) + '",';
                }
                //excelCont += '"groupInvoiceNo": "' + this.valChk(obj.groupInvoiceNo) + '",';
                excelCont += '"invoiceNo": "' + this.valChk(obj.invoiceNo) + '",';
                if (obj.bookingType == 'Train') {
                    excelCont += '"quota": "' + this.valChk(obj.bookingDetails.quota) + '",';
                    excelCont += '"class": "' + this.valChk(obj.bookingDetails.class) + '",';
                }
                excelCont += '"invoiceFullDate": "' + this.valChk(obj.invoiceFullDate) + '",';
                excelCont += '"client": "' + this.valChk(obj.client) + '",';
                if (this.valChk(obj.passengerDetails[j].empId) == undefined) {
                    excelCont += '"empId": " - ",';
                } else {
                    excelCont += '"empId": "' + this.valChk(obj.passengerDetails[j].empId) + '",';
                }
                excelCont += '"name": "' + this.valChk(obj.passengerDetails[j].name) + '",';
                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"from": "' + this.valChk(obj.bookingDetails.from) + '-' + this.valChk(obj.bookingDetails.to) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"airSector": "' + this.valChk(obj.bookingDetails.airSector) + '",';
                }
                excelCont += '"journeyDate": "' + this.valChk(obj.bookingDetails.journeyDate) + '",';
                excelCont += '"pnrNo": "' + this.valChk(obj.bookingDetails.pnrNo) + '",';
                if (obj.bookingType == 'Train') {
                    excelCont += '"trainNo": "' + this.valChk(obj.bookingDetails.trainNo) + '",';
                }
                excelCont += '"creditNoteNo": "' + this.valChk(obj.paymentDetails.creditNoteNo) + '",';
                //excelCont += '"pdLen": "'+ this.valChk(obj.chargesDetails.ticketFare/pdLen) +'",';
                excelCont += '"pdLen": "' + (this.valChk(obj.chargesDetails.grandTotal / pdLen)) + '",';
                excelCont += '"service": "' + this.valChk(obj.chargesDetails.service) / pdLen + '",';
                //excelCont += '"serviceTax": "'+ this.valChk(obj.chargesDetails.serviceTax)/pdLen +'",';

                //excelCont += '"serviceTax": "'+ serviceTaxVal +'",';
                //excelCont += '"serviceTax": "' + Math.round(this.valChk(serviceTaxVal) / pdLen) + '",';
                excelCont += '"serviceTax": "' +this.valChk((obj.chargesDetails.serviceTax*1 / pdLen), 'num') + '",';

                excelCont += '"cgstPer": "' +this.valChk(((obj.chargesDetails.cgstVal*1) / pdLen), 'num') + '",';
                excelCont += '"sgstPer": "' +this.valChk(((obj.chargesDetails.sgstVal*1) / pdLen), 'num') + '",';
                excelCont += '"igstPer": "' +this.valChk(((obj.chargesDetails.igstVal*1) / pdLen), 'num') + '",';

                excelCont += '"paymentStatus": "' + this.valChk(obj.paymentDetails.paymentStatus) + '",';
                excelCont += '"creditAmount": "' + this.valChk(obj.chargesDetails.creditAmount, 'num') + '",';
                excelCont += '"pendingAmount": "' + this.valChk(obj.paymentDetails.pendingAmount, 'num') + '",';
                excelCont += '"cancelled": "' + this.valChk(obj.paymentDetails.cancelled, 'num') + '",';
                excelCont += '"approvedBy": "' + this.valChk(obj.approvedBy) + '",';
                excelCont += '"empLevel": "' + this.valChk(obj.passengerDetails[j].empLevel) + '"';
                excelCont += '},';
            }
        }
        excelCont += ']';
        //SULAMAN WIP//
        this.jsonListDataExcel = eval(excelCont);

        setTimeout(()=> {
            var blob = new Blob([document.getElementById('listGridExcel').innerHTML], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            });
            saveAs(blob, "exportToExcel.xls");
        }, 1000);
    }


      
    public expExcelClientCreditNoteFunc = function(clientObj, param) {
        var objct = this.jsonListData;
        this.jsonListDataExcelNew = [];
        var excelCont = '[';
        for (var i = 0; i < objct.length; i++) {
            var obj = objct[i];
            var pdLen = obj.passengerDetails.length;
            for (var j = 0; j < pdLen; j++) {
                var serviceTaxVal = parseInt(this.valChk(obj.chargesDetails.grandTotal)) - parseInt(this.valChk(obj.chargesDetails.total));

                if( obj.paymentDetails.creditNoteNo != "" ){

                excelCont += '{';

                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"planId": "' + this.valChk(obj.passengerDetails[j].planId) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"ticketNo": "' + this.valChk(obj.passengerDetails[j].ticketNo) + '",';
                }
                excelCont += '"invoiceNo": "' + this.valChk(obj.invoiceNo) + '",';
                if (obj.bookingType == 'Train') {
                    excelCont += '"quota": "' + (this.valChk(obj.bookingDetails.quota)).replace(' Quota','').match(/\b(\w)/g).join('') + '",';
                    excelCont += '"class": "' + this.valChk(obj.bookingDetails.class) + '",';
                }
                excelCont += '"invoiceFullDate": "' + this.valChk(obj.invoiceFullDate) + '",';
                excelCont += '"client": "' + this.valChk(obj.client) + '",';
                if (this.valChk(obj.passengerDetails[j].empId) == undefined) { excelCont += '"empId": " - ",';} 
                else { excelCont += '"empId": "' + this.valChk(obj.passengerDetails[j].empId) + '",';}

                if(clientObj.clientGSTIN.length > 10){ excelCont += '"clientGSTIN": "' + clientObj.clientGSTIN + '",'; }
                else{ excelCont += '"clientGSTIN": "-",'; }

                excelCont += '"name": "' + this.valChk(obj.passengerDetails[j].name) + '",';
                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"from": "' + this.valChk(obj.bookingDetails.from) + '",';
                    excelCont += '"to": "' + this.valChk(obj.bookingDetails.to) + '",';
                    excelCont += '"boarding": "' + this.valChk(obj.bookingDetails.boarding) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"airSector": "' + this.valChk(obj.bookingDetails.airSector) + '",';
                }
                excelCont += '"journeyDate": "' + this.valChk(obj.bookingDetails.journeyDate) + '",';
                if(this.specialClient.global_show_journeyDetails_pnrNo){
                    excelCont += '"pnrNo": "' + this.valChk(obj.bookingDetails.pnrNo) + '",';
                }
                
                if(clientObj.clientSAC != undefined){
                    if(clientObj.clientSAC.length > 0){ excelCont += '"clientSAC": "' + clientObj.clientSAC + '",'; }
                    else{ excelCont += '"clientSAC": "-",'; }
                }
                else{ excelCont += '"clientSAC": "-",'; }

                if (obj.bookingType == 'Train') {
                    excelCont += '"trainNo": "' + this.valChk(obj.bookingDetails.trainNo) + '",';
                }
                excelCont += '"trainCancelled": "' + this.valChk(obj.chargesDetails.cancellationRailway) + '",';
                excelCont += '"creditNoteNo": "' + this.valChk(obj.paymentDetails.creditNoteNo, 'num') + '",';
                excelCont += '"pdLen": "' + (this.valChk(obj.chargesDetails.grandTotal / pdLen)) + '",';
               
                excelCont += '"pg_tasc": "' + ((this.valChk(obj.chargesDetails.pg, 'num') / pdLen) + (this.valChk(obj.chargesDetails.travelAgentCharge, 'num') / pdLen)) + '",';
                excelCont += '"sp": "-",';

                let ccN = obj.chargesDetails.catering;
                if( isNaN(ccN) || ccN == undefined || ccN == ''){
                    ccN = 0;
                }
                else{
                    ccN = (this.valChk(ccN) / pdLen)*1;
                }
                excelCont += '"fare": "'+ ((this.valChk(obj.chargesDetails.ticketFare / pdLen))-(-ccN)) +'",';
                excelCont += '"R_C": "R",';
                if( obj.paymentDetails.creditNoteNo != "" ){
                    excelCont += '"R_C": "C",';
                }
                excelCont += '"serviceTax": "' +this.valChk((obj.chargesDetails.serviceTax*1 / pdLen), 'num') + '",';
                
                if(param == 'exportExcelJsonNew'){
                    excelCont += '"service": "' + this.valChk(obj.chargesDetails.service) / pdLen + '",';
                    if( obj.chargesDetails.misc == undefined ){
                        excelCont += '"misc": "' + 0 + '",';
                    }
                    else{
                        excelCont += '"misc": "' + this.valChk(obj.chargesDetails.misc) / pdLen + '",';
                    }
                    excelCont += '"grandTotal": "' +this.valChk((obj.chargesDetails.grandTotal*1 / pdLen), 'num') + '",';
                    excelCont += '"cgstPer": "' +this.valChk(((obj.chargesDetails.cgstVal*1) / pdLen), 'num') + '",';
                    excelCont += '"sgstPer": "' +this.valChk(((obj.chargesDetails.sgstVal*1) / pdLen), 'num') + '",';
                    excelCont += '"igstPer": "' +this.valChk(((obj.chargesDetails.igstVal*1) / pdLen), 'num') + '",';
                }
                else if(param == 'exportExcelJsonByPassBill'){
                    let service = this.valChk(obj.chargesDetails.service);
                    let misc = (this.valChk(obj.chargesDetails.miscCharges) / pdLen)*1;
                    let tt = ((this.valChk(obj.chargesDetails.total) / pdLen)*1) - misc;
                    
                    let cc = obj.chargesDetails.catering;
                    if( isNaN(cc) || cc == undefined || cc == ''){
                        cc = 0;
                    }
                    else{
                        cc = (this.valChk(cc) / pdLen)*1;
                    }

                    if(misc == service){
                        misc = 0;
                    }
                    let service_misc = misc+service;

                    let cgstPer = service_misc * 9 / 100;
                    let sgstPer = service_misc * 9 / 100;
                    
                    let igstPer = 0;
                    let grandTotal = tt + service_misc + cgstPer + sgstPer ;

                    excelCont += '"service": "' + service + '",';
                    excelCont += '"misc": "' + misc + '",';
                    excelCont += '"cgstPer": "' + cgstPer + '",';
                    excelCont += '"sgstPer": "' + sgstPer + '",';
                    excelCont += '"igstPer": "' + 0 + '",';
                    excelCont += '"grandTotal": "' + grandTotal + '",';
                }
                else if(param == 'exportExcelJsonByPassCreditNote'){
                    excelCont += '"service": "' + this.valChk(obj.chargesDetails.cancellationService, 'num') + '",';
                    excelCont += '"misc": "' + 0 + '",';
                    excelCont += '"grandTotal": "' +this.valChk(obj.chargesDetails.creditAmount, 'num') + '",';
                    excelCont += '"cgstPer": "' + 0 + '",';
                    excelCont += '"sgstPer": "' + 0 + '",';
                    excelCont += '"igstPer": "' + 0 + '",';
                }

                excelCont += '"paymentStatus": "' + this.valChk(obj.paymentDetails.paymentStatus) + '",';
                excelCont += '"creditAmount": "' + (this.valChk(obj.chargesDetails.creditAmount, 'num') + this.valChk(obj.chargesDetails.cancellationService, 'num') ) + '",';
                excelCont += '"pendingAmount": "' + this.valChk(obj.paymentDetails.pendingAmount, 'num') + '",';
                excelCont += '"cancelled": "' + this.valChk(obj.paymentDetails.cancelled, 'num') + '",';
                excelCont += '"clientContactPerson": "' + clientObj.clientContactPerson + '",';
                excelCont += '"empLevel": "' + this.valChk(obj.passengerDetails[j].empLevel) + '"';
                excelCont += '},';
                }
            }
        }
        excelCont += ']';

        this.jsonListDataExcelNew = eval(excelCont);
        setTimeout(()=> {
            var blob = new Blob([document.getElementById('listGridExcelCC').innerHTML], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            });
            let dt = new Date();
            let dtFull = dt.getFullYear()+''+dt.getMonth()+''+dt.getDate()+''+dt.getHours()+''+dt.getMinutes()+''+dt.getSeconds()+'';
            let fineName = "MIS_CC_byPass_"+dtFull+".xls";
            saveAs(blob, fineName);
        }, 1000);
    }


      
    public expExcelClientBillFunc = function(clientObj, param) {
        var objct = this.jsonListData;
        this.jsonListDataExcelNew = [];
        var excelCont = '[';
        for (var i = 0; i < objct.length; i++) {
            //console.log('I--'+i);
            var obj = objct[i];
            var pdLen = obj.passengerDetails.length;
            for (var j = 0; j < pdLen; j++) {
                var serviceTaxVal = parseInt(this.valChk(obj.chargesDetails.grandTotal)) - parseInt(this.valChk(obj.chargesDetails.total));

                excelCont += '{';
                excelCont += '"clientRequestDate": "' + this.valChk(obj.clientRequestDate) + '",';
                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"planId": "' + this.valChk(obj.passengerDetails[j].planId) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"ticketNo": "' + this.valChk(obj.passengerDetails[j].ticketNo) + '",';
                }
                excelCont += '"invoiceNo": "' + this.valChk(obj.invoiceNo) + '",';
                if (obj.bookingType == 'Train') {
                    excelCont += '"quota": "' + (this.valChk(obj.bookingDetails.quota)).replace(' Quota','').match(/\b(\w)/g).join('') + '",';
                    excelCont += '"class": "' + this.valChk(obj.bookingDetails.class) + '",';
                }
                excelCont += '"invoiceFullDate": "' + this.valChk(obj.invoiceFullDate) + '",';
                excelCont += '"client": "' + this.valChk(obj.client) + '",';
                if (this.valChk(obj.passengerDetails[j].empId) == undefined) { excelCont += '"empId": " - ",';} 
                else { excelCont += '"empId": "' + this.valChk(obj.passengerDetails[j].empId) + '",';}

                //console.log(clientObj);
                if(clientObj == undefined || clientObj.clientGSTIN == undefined || clientObj.clientGSTIN == ''){
                    excelCont += '"clientGSTIN": "-",';
                }
                else if(clientObj.clientGSTIN.length > 10){ excelCont += '"clientGSTIN": "' + clientObj.clientGSTIN + '",'; }
                else{ excelCont += '"clientGSTIN": "-",'; }

                excelCont += '"name": "' + this.valChk(obj.passengerDetails[j].name) + '",';
                if (obj.bookingType == 'Train' || obj.bookingType == 'Bus') {
                    excelCont += '"from": "' + this.valChk(obj.bookingDetails.from) + '",';
                    excelCont += '"to": "' + this.valChk(obj.bookingDetails.to) + '",';
                    excelCont += '"boarding": "' + this.valChk(obj.bookingDetails.boarding) + '",';
                }
                if (obj.bookingType == 'Air') {
                    excelCont += '"airSector": "' + this.valChk(obj.bookingDetails.airSector) + '",';
                }
                excelCont += '"journeyDate": "' + this.valChk(obj.bookingDetails.journeyDate) + '",';
                if(this.specialClient.global_show_journeyDetails_pnrNo){
                    excelCont += '"pnrNo": "' + this.valChk(obj.bookingDetails.pnrNo) + '",';
                }
                
                
                if(clientObj == undefined || clientObj.clientSAC == undefined || clientObj.clientSAC == ''){
                    excelCont += '"clientSAC": "-",';
                }
                else{ excelCont += '"clientSAC": '+clientObj.clientSAC+','; }

                if (obj.bookingType == 'Train') {
                    excelCont += '"trainNo": "' + this.valChk(obj.bookingDetails.trainNo) + '",';
                }
                excelCont += '"creditNoteNo": "' + this.valChk(obj.paymentDetails.creditNoteNo) + '",';
                //excelCont += '"pdLen": "'+ this.valChk(obj.chargesDetails.ticketFare/pdLen) +'",';
                excelCont += '"pdLen": "' + (this.valChk(obj.chargesDetails.grandTotal / pdLen)) + '",';
               
                excelCont += '"pg_tasc": "' + ((this.valChk(obj.chargesDetails.pg, 'num') / pdLen) + (this.valChk(obj.chargesDetails.travelAgentCharge, 'num') / pdLen)) + '",';
                //console.log( obj.chargesDetails.pg , obj.chargesDetails.travelAgentCharge );
                //console.log( (this.valChk(obj.chargesDetails.pg, 'num') / pdLen) , (this.valChk(obj.chargesDetails.travelAgentCharge, 'num')) );
                //console.log( (this.valChk(obj.chargesDetails.pg, 'num') / pdLen) , (this.valChk(obj.chargesDetails.travelAgentCharge, 'num') / pdLen) );

                excelCont += '"sp": "-",';

                //let ccN = (this.valChk(obj.chargesDetails.catering) / pdLen)*1;
                let ccN = obj.chargesDetails.catering;
                if( isNaN(ccN) || ccN == undefined || ccN == ''){
                    ccN = 0;
                }
                else{
                    ccN = (this.valChk(ccN) / pdLen)*1;
                }
                excelCont += '"fare": "'+ ((this.valChk(obj.chargesDetails.ticketFare / pdLen))-(-ccN)) +'",';
                excelCont += '"R_C": "R",';
                if( obj.paymentDetails.creditNoteNo != "" ){
                    excelCont += '"R_C": "C",';
                }
                //excelCont += '"serviceTax": "'+ this.valChk(obj.chargesDetails.serviceTax)/pdLen +'",';
                //excelCont += '"serviceTax": "'+ serviceTaxVal +'",';
                //excelCont += '"serviceTax": "' + Math.round(this.valChk(serviceTaxVal) / pdLen) + '",';
                excelCont += '"serviceTax": "' +this.valChk((obj.chargesDetails.serviceTax*1 / pdLen), 'num') + '",';
                
                if(param == 'exportExcelJsonNew'){
                    excelCont += '"service": "' + this.valChk(obj.chargesDetails.service) / pdLen + '",';
                    if( obj.chargesDetails.miscCharges == undefined ){
                        excelCont += '"misc": "' + 0 + '",';
                    }
                    else{
                        excelCont += '"misc": "' + this.valChk(obj.chargesDetails.miscCharges) / pdLen + '",';
                    }
                    excelCont += '"grandTotal": "' +this.valChk((obj.chargesDetails.grandTotal*1 / pdLen), 'num') + '",';
                    excelCont += '"cgstPer": "' +this.valChk(((obj.chargesDetails.cgstVal*1) / pdLen), 'num') + '",';
                    excelCont += '"sgstPer": "' +this.valChk(((obj.chargesDetails.sgstVal*1) / pdLen), 'num') + '",';
                    excelCont += '"igstPer": "' +this.valChk(((obj.chargesDetails.igstVal*1) / pdLen), 'num') + '",';
                }
                else if(param == 'exportExcelJsonByPassBill'){
                    let service = this.valChk(obj.chargesDetails.service);
                    let misc = (this.valChk(obj.chargesDetails.miscCharges) / pdLen)*1;
                    let tt = ((this.valChk(obj.chargesDetails.total) / pdLen)*1) - misc;
                    
                    let cc = obj.chargesDetails.catering;
                    if( isNaN(cc) || cc == undefined || cc == ''){
                        cc = 0;
                    }
                    else{
                        cc = (this.valChk(cc) / pdLen)*1;
                    }

                    if(misc == service){
                        misc = 0;
                    }
                    let service_misc = misc+service;

                    let cgstPer = service_misc * 9 / 100;
                    let sgstPer = service_misc * 9 / 100;
                    
                    let igstPer = 0;
                    let grandTotal = tt + service_misc + cgstPer + sgstPer ;

                    excelCont += '"service": "' + service + '",';
                    excelCont += '"misc": "' + misc + '",';
                    excelCont += '"cgstPer": "' + cgstPer + '",';
                    excelCont += '"sgstPer": "' + sgstPer + '",';
                    excelCont += '"igstPer": "' + 0 + '",';
                    excelCont += '"grandTotal": "' + grandTotal + '",';
                }

                excelCont += '"paymentStatus": "' + this.valChk(obj.paymentDetails.paymentStatus) + '",';
                excelCont += '"creditAmount": "' + this.valChk(obj.chargesDetails.creditAmount, 'num') + '",';
                excelCont += '"pendingAmount": "' + this.valChk(obj.paymentDetails.pendingAmount, 'num') + '",';
                excelCont += '"cancelled": "' + this.valChk(obj.paymentDetails.cancelled, 'num') + '",';

                
                if(clientObj == undefined || clientObj.clientContactPerson == undefined || clientObj.clientContactPerson == ''){
                    excelCont += '"clientContactPerson": "-",';
                }
                else{ 
                    excelCont += '"clientContactPerson": "' + clientObj.clientContactPerson + '",';
                }
                excelCont += '"empLevel": "' + this.valChk(obj.passengerDetails[j].empLevel) + '"';
                excelCont += '},';
            }
        }
        excelCont += ']';
        //SULAMAN WIP//
        this.jsonListDataExcelNew = eval(excelCont);
        console.log('this.jsonListDataExcelNew >>>>> ', this.jsonListDataExcelNew );
        setTimeout(()=> {
            var blob = new Blob([document.getElementById('listGridExcelNew').innerHTML], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            });
            let dt = new Date();
            let dtFull = dt.getFullYear()+''+dt.getMonth()+''+dt.getDate()+''+dt.getHours()+''+dt.getMinutes()+''+dt.getSeconds()+'';
            let fineName='';
            if(param == 'exportExcelJsonNew'){
                fineName = "MIS_"+dtFull+".xls"
            }
            else if(param == 'exportExcelJsonByPassBill'){
                fineName = "MIS_byPass_"+dtFull+".xls"
            }
            saveAs(blob, fineName);
        }, 1000);
    }

    
    public downloadAsExportClientFunc = function() {
        $('#listGridTemp').show().html( $('#listGrid').html() );
        $('#listGridTemp tbody tr').remove();
        for(let i=0; i<$('#listGrid tbody tr').length; i++){
            $('#listGridTemp tbody').prepend( "<tr>"+ $('#listGrid tbody tr').eq(i).html() +"</tr>" );
        }
        setTimeout(()=> {
            for(let i=0; i<$('#listGridTemp tbody tr').length; i++){
                $('#listGridTemp tbody tr').eq(i).find('td').eq(1).text((i+1));
            }
            setTimeout(()=> {
                $('#listGridTemp tr>th:eq(0), #listGridTemp tr>th:eq(2), #listGridTemp tr>th:eq(3), #listGridTemp tr>th:eq(4), #listGridTemp tr>th:eq(5), #listGridTemp tr>th:eq(6), #listGridTemp tr>th:eq(7)').addClass('deleteTH');
                for(let i=0; i<$('#listGridTemp>table>tbody>tr').length; i++){
                    $('#listGridTemp>table>tbody>tr').eq(i).find('td:eq(0),td:eq(2),td:eq(3),td:eq(4),td:eq(5),td:eq(6),td:eq(7)').addClass('deleteTD');
                }
                setTimeout(()=> {
                    $('#listGridTemp .deleteTH, #listGridTemp .deleteTD').remove();
                    var blob = new Blob([document.getElementById('listGridTemp').innerHTML], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                    });
                    var dd = new Date();
                    var fulldate = dd.getSeconds()+''+dd.getMinutes()+''+dd.getHours()+'.'+dd.getDate()+'-'+(dd.getMonth()+1)+'-'+dd.getFullYear();
                    var time = (Math.random()*100000).toFixed(0);
                    saveAs(blob, "download-as-export--"+fulldate+".xls");
                }, 1000);
            }, 300);
        }, 300);
    }

    
    public expExcelFunc1 = function(idArray) {
              var objct = this.viewCheckboxObjList;
              var cont = '';
              for (var i = 0; i < objct.length; i++) {
                  var obj = objct[i];
                  var pdLen = obj.passengerDetails.length;
                  for (var j = 0; j < pdLen; j++) {
                      cont += this.valChk(obj.passengerDetails[j].planId) + '||'; //travel plan id
                      cont += this.valChk(obj.invoiceNo) + '||'; //bill no
                      cont += this.valChk(obj.bookingDetails.quota) + '||'; //tatkal
                      cont += this.valChk(obj.bookingDetails.class) + '||'; //class
                      cont += this.valChk(obj.invoiceFullDate) + '||'; //booking date
                      cont += this.valChk(obj.client) + '||'; //Client name
                      cont += this.valChk(obj.passengerDetails[j].empId) + '||'; //employ code
                      cont += this.valChk(obj.passengerDetails[j].name) + '||'; //name of pax
                      cont += this.valChk(obj.bookingDetails.from) + '-' + this.valChk(obj.bookingDetails.to) + '||'; //sector
                      cont += this.valChk(obj.bookingDetails.journeyDate) + '||'; //travel date
                      cont += this.valChk(obj.bookingDetails.pnrNo) + '||'; //tkt no/pnr no
                      cont += this.valChk(obj.bookingDetails.trainNo) + '||'; //train no
                      cont += this.valChk(obj.paymentDetails.creditNoteNo) + '||'; //credit note no
                      cont += this.valChk(obj.chargesDetails.grandTotal / pdLen) + '||'; //total amt
                      cont += this.valChk(obj.chargesDetails.service) / pdLen + '||'; //sev chagres
                      cont += this.valChk(obj.chargesDetails.serviceTax) / pdLen + '||'; //ser tax
                      cont += this.valChk(obj.paymentDetails.paymentStatus) + '||'; //Payment received
                      cont += this.valChk(obj.chargesDetails.creditAmount) + '||'; //credit note am
                      cont += this.valChk(obj.paymentDetails.pendingAmount) + '||'; //pending amount
                      cont += this.valChk(obj.paymentDetails.cancelled) + '||'; //cancel charges
                      cont += this.valChk(obj.approvedBy) + '||'; //Approved by 
                      cont += this.valChk(obj.passengerDetails[j].empLevel) + '##'; //tkt type(Band)
                  }
              }
              return cont;
    }
      
    public valChk = function(param, typ='str') {
        if (param == '' && typ == 'num') { return 0 }
        else if (param == '' && typ == 'str' || param == undefined ) { return '-' }
        return param;
    }
    public actions = function(id, param) {
        //console.log('check 1', this.filterFormData.bookingType, param);
        if (param == 'showClientToggle') {
            this.showClientTo = !this.showClientTo;
            //if (this.showClientTo == true) { this.showClientTo = false } else if (this.showClientTo == false) { this.showClientTo = true }
        }
        
        if (id == 'creditNotePaymentStatus') {
            return param;
            /*
            if(param == true){return 'Given'}
            else if(param == false){return 'Pending'}
            else{return param}
            */
        }

        if (param == 'saveListObj') {
            //this.viewCheckboxObjList =[];
            if (this.viewCheckboxObjList.length >= $('#listGrid tbody tr').length) {
                this.viewCheckboxObjList = [];
            }
            //var obj1 = this.getObjectFromId();
            //var obj = obj1 || id.i || this.viewCheckboxObj;
            var obj = id.i;
            //var idObj = id.i.otherDetails.otherDetailsId;
            this.viewCheckboxObjList.push(obj);
        }

        if (param == 'generateGroupInvoiceNo') {
            this.invoiceNoArray = [];
            for(let gen=0; gen<this.jsonListDataAdvancedCheck.length; gen++){
                if( this.jsonListDataAdvancedCheck[gen] == true ){
                    this.invoiceNoArray.push( this.jsonListData[gen].invoiceNo );
                }
            }
        }

        if (param == 'generateGroupInvoiceNoBtn') {
            let type1 = this.filterFormData.bookingType;
            if(type1 == ""){
                alert('!! Kindly select Booking Type from Filter section');
                return false;
            }

            this.generateGroupInvoiceNoFT.fromDate = $('input[name="generateGroupInvoiceNo_fromDate"]').val();
            this.generateGroupInvoiceNoFT.toDate = $('input[name="generateGroupInvoiceNo_toDate"]').val();
            this.generateGroupInvoiceNoFT.date = $('input[name="generateGroupInvoiceDate"]').val();
                if( this.generateGroupInvoiceNoFT.fromDate == '' || this.generateGroupInvoiceNoFT.toDate == '' || this.generateGroupInvoiceNoFT.date == '' ){
                    alert('!! Kindly select all the dates of Generate group invoice');
                    return false;
                }
            let data = { array: this.invoiceNoArray, type: type1, dates:this.generateGroupInvoiceNoFT };
            this.Services.groupInvoiceNoForm(data).subscribe(res =>{
                $('#generateGroupInvoiceNoForm .close').click();
                this.actions(this, 'filterListData');
            },
            err => {
                console.log(err);
            });
        }

        if (param == 'inputCheckboxAll') {
            
            
            setTimeout(()=>{
                
                

                for(let c=0; c<this.jsonListDataAdvancedCheck.length; c++){
                    this.jsonListDataAdvancedCheck[c] = this.jsonListDataAdvancedCheckAll;
                    if(this.jsonListDataAdvancedCheckAll == true){
                        $('.DYNAMIC tbody tr').addClass('selectedTr');
                    }
                    else if(this.jsonListDataAdvancedCheckAll == false){
                        $('.DYNAMIC tbody tr').removeClass('selectedTr');
                    }
                }
                $('.multiEventButtons a').removeClass('disabled');
                
                setTimeout(()=> {
                    this.dynamicJsonListDataAdvancedFunc('onlyTotal');
                }, 510);
            },10);
        }

        if (param == 'inputCheckbox') {
            
            
            this.viewCheckboxObj = this.jsonListData[id];

            if (this.viewCheckboxObj.paymentDetails.creditNoteFlag == false) {
                $('#btnPrintCredit').animate({ 'border': 0 }, 100, function() { $(this).addClass('disabled'); });
            } else if (this.viewCheckboxObj.paymentDetails.creditNoteFlag == true) {
                $('#btnPrintCredit').removeClass('disabled');
            }

            setTimeout(()=> {
                this.dynamicJsonListDataAdvancedFunc('onlyTotal');
            }, 510);


            if (parseInt(this.viewCheckboxObj.paymentDetails.pendingAmount) == 0) {
                setTimeout(()=> {
                    if ($('.selectedTr.lastClicked').length) {
                        $('.selectedTr.lastClicked').addClass('paymentAlreadyDone');
                    }
                }, 10);
            } else {
                $('#btnMarkAsPaymentReceived').removeClass('disabled');
            }

        }

        if (param == 'edit' || param == 'triggerEdit') {
            var obj1 = this.getObjectFromId();
            var obj = obj1 || id.i || this.viewCheckboxObj;
            var obcj = { 'addUpdate': 'edit', 'id': obj.otherDetails.otherDetailsId, 'fullObject': obj }
            localStorage._booking = JSON.stringify(obcj);
            this.router.navigate(['/add']);
        }

        if (param == 'view' || param == 'triggerView' || param == 'printBill' || param == 'triggerPrintBill' || param == 'triggerPrintCredit') {
            var obj = this.getObjectFromId();
            if (param == 'printBill' || param == 'triggerPrintBill' || param == 'triggerPrintCredit') {
                if (obj.typeOfClient == 'Company' && obj.bookingType == 'Air') { this.typeOfClientForAirFlag = true } else if (obj.typeOfClient == 'Individual' && obj.bookingType == 'Air') { this.typeOfClientForAirFlag = false }
            }
            else{
                this.loadInputData(obj);
            }
        }

        if (param == 'triggerGroupPrintBill') {
            ////////ssssssssssssssssssssssssssssssssssss
            var obj = this.getObjectFromId();
            var data2 = { '_groupInvNo': obj.groupInvoiceNo, 'id': obj._id };
            this.Services.groupPrintBill(data2).subscribe(res =>{
                let responseData = res;
                 
                this.groupPrintObject = {
                    clientInfo:{
                        clientName:'',
                        clientAddress:'',
                        clientNumber:'',
                        clientGSTIN:'',
                    },
                    groupInvoiceNo:obj.groupInvoiceNo,
                    groupInvoiceDate:obj.groupInvoiceDate,
                    groupInvoiceNoFromDate:obj.groupInvoiceNoFromDate,
                    groupInvoiceNoToDate:obj.groupInvoiceNoToDate,
                    ticketInvoiceNo:[],
                    ticketBill:{
                        ticketFare:0,
                        serviceCharges:0,
                        cgst:0,
                        sgst:0,
                        igst:0,
                        grandTotal:0,
                    },
                    creditNote:{
                        ticketFare:0,
                        railwayCancellation:0,
                        serviceCancellation:0,
                        creditAmount:0,
                    },
                }
                let checkVal = function(param){
                    if(param == undefined || isNaN(param*1)){return 0}
                    return param*1;
                }
          
                for(let r=0; r<responseData.length; r++){
                    this.groupPrintObject.ticketInvoiceNo.push( responseData[r].invoiceNo );

                    let tf = checkVal(responseData[r].chargesDetails.quota) + checkVal(responseData[r].chargesDetails.eTicket) + checkVal(responseData[r].chargesDetails.pg) + checkVal(responseData[r].chargesDetails.ticketFare) + checkVal(responseData[r].chargesDetails.catering);
                    let tf1 = tf;
                    let sc = checkVal(responseData[r].chargesDetails.service);
                    let cgst = checkVal(responseData[r].chargesDetails.cgstVal);
                    let sgst = checkVal(responseData[r].chargesDetails.sgstVal);
                    let igst = checkVal(responseData[r].chargesDetails.igstVal);
                    let gt = checkVal(responseData[r].chargesDetails.grandTotal);

                    this.groupPrintObject.ticketBill.ticketFare += tf1;
                    this.groupPrintObject.ticketBill.serviceCharges += sc;
                    this.groupPrintObject.ticketBill.cgst += cgst;
                    this.groupPrintObject.ticketBill.sgst += sgst;
                    this.groupPrintObject.ticketBill.igst += igst;
                    this.groupPrintObject.ticketBill.grandTotal += gt;

                    ////////////////////////////////////////////////////////////////////////////

                    let tf2 = tf;
                    let rcan = checkVal(responseData[r].chargesDetails.cancellationRailwayTotal);
                    let scan = checkVal(responseData[r].chargesDetails.cancellationServiceTotal);
                    let ca = checkVal(responseData[r].chargesDetails.creditAmount);
                    if(ca == undefined){ca = 0}
                    
                    if(ca != 0){
                        this.groupPrintObject.creditNote.ticketFare += tf2;
                        this.groupPrintObject.creditNote.railwayCancellation += rcan;
                        this.groupPrintObject.creditNote.serviceCancellation += scan;
                        this.groupPrintObject.creditNote.creditAmount += ca;
                    }

                    ////////////////////////////////////////////////////////////////////////////

                }

                this.groupPrintObject.ticketBill.ticketFare = (this.groupPrintObject.ticketBill.ticketFare).toFixed(2);
                this.groupPrintObject.ticketBill.serviceCharges = (this.groupPrintObject.ticketBill.serviceCharges).toFixed(2);
                this.groupPrintObject.ticketBill.cgst = (this.groupPrintObject.ticketBill.cgst).toFixed(2);
                this.groupPrintObject.ticketBill.sgst = (this.groupPrintObject.ticketBill.sgst).toFixed(2);
                this.groupPrintObject.ticketBill.igst = (this.groupPrintObject.ticketBill.igst).toFixed(2);
                this.groupPrintObject.ticketBill.grandTotal = (this.groupPrintObject.ticketBill.grandTotal).toFixed(2);

                this.groupPrintObject.creditNote.ticketFare = (this.groupPrintObject.creditNote.ticketFare).toFixed(2);
                this.groupPrintObject.creditNote.railwayCancellation = (this.groupPrintObject.creditNote.railwayCancellation).toFixed(2);
                this.groupPrintObject.creditNote.serviceCancellation = (this.groupPrintObject.creditNote.serviceCancellation).toFixed(2);
                this.groupPrintObject.creditNote.creditAmount = (this.groupPrintObject.creditNote.creditAmount).toFixed(2);

                var data = { listFormJsonData2: { searchType: {} } }
                this.Services.listMastersData(data).subscribe(res =>{
                    let clientInfoNew = this.getDropdownData(res, 'clientList', 'clientName', true);
                    this.groupPrintObject.clientInfo = clientInfoNew.filter(dt => dt.clientName == responseData[0].client)[0];
                },
                err => {
                  console.log(err);
                });

            },
            err => {
                console.log(err);
            });
        }

        if (param == 'printList') {
            $('#printListGrid').html('');
            $('.clicked').removeClass('clicked');
            //$('.sectionToPrint').removeClass('sectionToPrint');

            var header = '<div class="pageBreak">';
            header += '<div class="col-xs printHeader">';
            header += '<img src="assets/uploads/' + this.otherDetails.logoPath + '" />';
            header += '<label class="title">Invoice <br> GSTIN: ' + this.otherDetails.GSTIN + ' </label>';
            header += '<label class="address">';
            header += this.fullAddress(this.otherDetails.fullAddress);
            header += this.otherDetails.contactDetails;
            header += '</label>';
            header += '</div>';
            //var cont = $('#listGrid').html();
            var tab1 = '<table class="table table-striped table-bordered listTable">';
            var tab2 = '</table>';
            var tabHead = $('#listGrid thead').html();
            var tabBody = $('#listGrid tbody tr');
            var tabCont = '';
            var tabPagination = 30;

            var pages = Math.ceil(tabBody.length / tabPagination); //   50/10 == 5
            //var totArr = ['.totalCA','.totalSC','.totalBA','.totalST','.totalD','.totalPA','.totalBA.Received','.totalCGST','.totalSGST'];
            var totArr = ['totalBA_final', 'totalCA_final', 'totalPA_final', 'totalCGST_final', 'totalSGST_final', 'totalIGST_final'];
            var tot = '';
            for (var i = 0; i < totArr.length; i++) {
                tot += '<div class="col-md-3">' + $('.totalWrapper .' + totArr[i]).html() + '</div>';
            }
            for (var i = 0; i < pages; i++) {
                tabCont = '';
                tabCont += header;
                tabCont += tab1;
                tabCont += tabHead;
                var c = i - (-1);
                var count = i * tabPagination;
                for (var j = count; j < (count + tabPagination); j++) {
                    var cnt = $('#listGrid tbody tr').eq(j).html();
                    if (cnt != undefined) {
                        tabCont += '<tr>' + $('#listGrid tbody tr').eq(j).html(); + '</tr>';
                    }
                }
                tabCont += tab2;
                if (i == pages - 1) {
                    tabCont += '<div class="totalWrapper contWrapper col-md-12">' + tot + '</div>';
                }
                tabCont += '<div class="pagination"> Page No.' + c + '  <a href="javascript:;" class="showHideTable"></a> </div></div>';
                $('#printListGrid').append(tabCont);
                $('#printListGrid tbody').eq(i).find('th').eq(0).hide();
                $('#printListGrid input').parent().hide();
                //$('#printListGrid').parent().addClass('sectionToPrint');
                //$('#printListGrid').html('hello');
            }

            //$('#printListGrid').html(header+cont);
        }

        if (param == 'confirmPrintBill' || param == 'confirmPrintList' || param == 'confirmPrintCredit') {
            window.print();
        }

        if (param == 'multipleDelete') {
            //db.users.remove({'_id':{'$in':inactive_users}});
            var delIdArr = [];
            for (var i = 0; i < $('.table input:checked').length; i++) {
                var id = $('.table input:checked').eq(i).attr('data-id');
                delIdArr.push(id);
            }

            var data = { 'idArray': delIdArr };
            this.Services.deleteAllData(data).subscribe(res =>{
                $('#multipleDeleteConfirmation .close').click();
                localStorage._booking = JSON.stringify({ 'addUpdate': '', 'id': '' });
                this.router.navigate(['/list']);
            },
            err => {
                console.log(err);
            });
        }

        if (param == 'markAsPaymentReceived') {
            //id.preventDefault();
            if ($('.table tbody input:checked').length) {
                var d = new Date();
                var d1 = d.getDate();
                var d2 = d.getMonth();
                var d3 = d.getFullYear();
                var curDate1 = d1 + ' ' + this.monthArr[d2] + ', ' + d3;

                //id.i.client
                var pFrom = $('.table tbody input:checked').eq(0).attr('data-client');

                //this.addFormData = { payment: {} }
                this.addFormData.paymentDetails.paymentStatus = 'Received';
                this.addFormData.paymentDetails.paymentFrom = pFrom;
                //this.addFormData.paymentDetails.paymentBy = 'Cash';
                this.addFormData.paymentDetails.paymentByChequeNo = '-';
                this.addFormData.paymentDetails.paymentByBankName = '-';
                this.addFormData.paymentDetails.paymentDate = curDate1;
                this.addFormData.paymentDetails.paymentDateTP = this.Services.fullDateTPFunc(d1,d2+1,d3);

                var updatePaymentIdArr = [];
                var checkClientName = [pFrom];
                var checkClientNameFlag = true;
                var paymentAlreadyDoneFlag = false;

                for (var i = 0; i < $('.table tbody input:checked').length; i++) {
                    var id = $('.table tbody input:checked').eq(i).attr('data-id');
                    var pFrom2 = $('.table tbody input:checked').eq(i).attr('data-client');
                    updatePaymentIdArr.push(id);
                    if (pFrom != pFrom2) {
                        checkClientName.push(pFrom2);
                        checkClientNameFlag = false;
                        //break;
                    }
                    //if ($('.table tbody input:checked').eq(i).parents('tr').hasClass('paymentAlreadyDone') ) {
                    if ($('.table tbody input:checked').eq(i).parents('td').attr('data-status') == "Received" ) {
                        paymentAlreadyDoneFlag = true;
                    }
                }
                var updatePaymentRecArr = [];
                this.updatePaymentRecNum = 0;
                for (var i = 0; i < $('#listGrid .table tbody input:checked').length; i++) {
                    let rec = parseInt($('#listGrid .table tbody input:checked').eq(i).attr('data-pending'));
                    updatePaymentRecArr.push(rec);
                    this.updatePaymentRecNum += rec;
                }

                if (checkClientNameFlag == false || paymentAlreadyDoneFlag == true) {
                    var msg = 'Sorry !! ';
                    if (checkClientNameFlag == false) {
                        msg += '\n\nYou cannot do payment of 2(or more) different clients. \n(' + checkClientName.join(', ') + ').';
                    }
                    if (paymentAlreadyDoneFlag == true) {
                        msg += '\n\nYou have selected a Ticket whose payment is already done.';
                    }
                    setTimeout(()=> {
                        alert(msg);
                        $('#paymentReceivedForm button[data-dismiss="modal"]').click();
                    }, 500);
                }

            }
        }

        if (param == 'submitPaymentReceived') {
            var updatePaymentIdArr = [];
            for (var i = 0; i < $('.table tbody input:checked').length; i++) {
                let id = $('.table tbody input:checked').eq(i).attr('data-id');
                updatePaymentIdArr.push(id);
            }

            var updatePaymentRecArr = [];
            for (var i = 0; i < $('.table tbody input:checked').length; i++) {
                let rec = parseInt($('.table tbody input:checked').eq(i).attr('data-pending'));
                updatePaymentRecArr.push(rec);
            }

            let d = new Date(this.addFormData.paymentDetails.paymentDate);
            let d1:any = this.Services.addZero(d.getDate());
            let d2:any = this.Services.addZero(d.getMonth() + 1);
            let d3:any = d.getFullYear();
            var payDate = d1 + '/' + d2 + '/' + d3;

            var paymentDetailsObj = {
                paymentStatus: this.addFormData.paymentDetails.paymentStatus,
                paymentFrom: this.addFormData.paymentDetails.paymentFrom,
                paymentBy: this.addFormData.paymentDetails.paymentBy,
                paymentByChequeNo: this.addFormData.paymentDetails.paymentByChequeNo,
                paymentByBankName: this.addFormData.paymentDetails.paymentByBankName,
                paymentDate: payDate,
                remark: this.addFormData.paymentDetails.remark,
                paymentDateTP: this.Services.fullDateTPFunc(d1,d2,d3),
                pendingAmount: 0
            }

            let data = { array: updatePaymentIdArr, obj: paymentDetailsObj, payRec: updatePaymentRecArr };
            this.Services.paymentReceivedForm(data).subscribe(res =>{
                $('#paymentReceivedForm .close').click();
                this.actions('', 'filterListData');
            },
            err => {
                console.log(err);
            });
        }

        if (param == 'submitPaymentReceivedCancel') {
            this.addFormData.paymentDetails.paymentStatus = '';
            this.addFormData.paymentDetails.paymentFrom = '';
            this.addFormData.paymentDetails.paymentBy = '';
            this.addFormData.paymentDetails.paymentByChequeNo = '';
            this.addFormData.paymentDetails.paymentByBankName = '';
            this.addFormData.paymentDetails.paymentDate = '';
            this.addFormData.paymentDetails.paymentDateTP = '';
            this.addFormData.paymentDetails.remark = '';
        }

        if (param == 'uploadFileWrap' || param == 'postponeBooking' || param == 'triggerPostponeBooking') {
            //id.preventDefault();
            var obj1 = this.getObjectFromId();
            var obj = obj1 || id.i || this.viewCheckboxObj;
            this.addFormData = obj;
            if ($('.table input:checked').length) {
                //console.log(obj);
                //console.log('>>>>>> ',obj.bookingDetails.journeyDate, obj.invoiceFullDate, obj.invoiceFullDateTP);
            }
        }

        if (param == 'openPdfFile') {
            let path = (this.Services.myUrl).replace('/user','');
            window.open(path+"/_uploads/_"+localStorage._bookingCID+"/"+id, "_blank");
        }

        if (param == 'deleteUploadedFile') {
            //alert('deleteUploadedFile');
            let array = this.uploadFileArray;
            for(let uf=0; uf<array.length; uf++){
                if( array[uf].showFlag == undefined ){
                    array[uf]['showFlag'] = 'true';
                }
                if( array[uf].fileName == id ){
                    array[uf]['showFlag'] = 'false';
                }
            }
            
            let finalArray = JSON.stringify(array);
            let data = { _id: this.addFormData._id, obj: finalArray }
            this.Services.deleteUploadedFile(data).subscribe(res =>{
                this.uploadFileArray = array;
            },
            err => {
                console.log(err);
            });

        }


        if (param == 'uploadFileWrap' || param == 'uploadFileWrapJump') {
            this.actions(this, 'filterListData');

            this.uploadFileArray = [];
            if(this.addFormData.uploadFiles != undefined || param == 'uploadFileWrapJump'){
                let fw1;
                if(param == 'uploadFileWrapJump'){
                    fw1 = JSON.parse(id.uploadFiles);
                }
                else{
                    fw1 = JSON.parse(this.addFormData.uploadFiles);
                }
                let fw2=[];
                if(fw1.length > 0){
                    for(let f=0; f<fw1.length; f++){
                        fw2.push(
                            {
                                "fileName": fw1[f].fileName,
                                "fileComment": fw1[f].fileComment,
                                "showFlag": fw1[f].showFlag,
                            }
                        );
                    }
                }
                this.uploadFileArray = fw2;
                $('body').removeClass('topLoading');

            }
        }
        

        if (param == 'submitPostponeBooking') {
            var updatePostponeIdArr = this.addFormData._id;
            
            let mixObj = {
                id: this.addFormData._id
            }

            if( ($('input[name="BookingDate2"]').val()).indexOf('/') == -1 ){
                let bd = $('input[name="BookingDate2"]').datepicker("getDate");
                let bdTP1 = this.Services.addZero(bd.getDate())+'/'+this.Services.addZero(bd.getMonth()+1)+'/'+bd.getFullYear();
                mixObj['invoiceFullDate'] = bdTP1;
                mixObj['invoiceFullDateTP'] = this.Services.fullDateTPFunc( bd.getDate(), (parseInt(bd.getMonth())+1), bd.getFullYear() );
            }

            if( ($('input[name="JourneyDate2"]').val()).indexOf('/') == -1 ){
                let jd = $('input[name="JourneyDate2"]').datepicker("getDate");
                let jdTP1 = this.Services.addZero(jd.getDate())+'/'+this.Services.addZero(jd.getMonth()+1)+'/'+jd.getFullYear(); 
                mixObj['journeyDate'] = jdTP1;
                mixObj['journeyDateTP'] = this.Services.fullDateTPFunc( jd.getDate(), (parseInt(jd.getMonth())+1), jd.getFullYear() );
            }
            
            let data = { array: updatePostponeIdArr, obj: mixObj }
            
            this.Services.postponeBookingForm(data).subscribe(res =>{
                $('#postponeBookingForm .close').click();
                //$('#filterWrapper input[type="submit"]').click();
                this.actions('', 'filterListData');
            },
            err => {
                console.log(err);
            });
        
        }

        if (param == 'creditNoteFormCheckbox') {
            var len = $('#creditNoteForm input:checked').length;
            if (len == 0) {
                $('#creditNoteForm button[type="submit"]').addClass('disabled');
                this.addFormData.chargesDetails.cancellationServiceTotal = '';
                this.addFormData.chargesDetails.cancellationRailwayTotal = '';
                this.addFormData.chargesDetails.creditAmount = '';
                alert('Kindly select above check box');
                return false;
            }
            $('#creditNoteForm button[type="submit"]').removeClass('disabled');
            var cst = parseInt(this.addFormData.chargesDetails.cancellationService) * len;
            var crt = parseInt(this.addFormData.chargesDetails.cancellationRailway) * len;
            var tf1 = parseInt(this.addFormData.chargesDetails.ticketFare);
            var tf2 = tf1 / parseInt($('#creditNoteForm input:checkbox').length);
            var tf3 = Math.round(tf2 * len);
            this.addFormData.chargesDetails.cancellationServiceTotal = cst;
            this.addFormData.chargesDetails.cancellationRailwayTotal = crt;
            this.addFormData.chargesDetails.creditAmount = tf3 - cst - crt;
        }

        if (param == 'creditNoteInputBlur') {
            var len = $('#creditNoteForm input:checked').length;
            if (len == 0) {
                alert('Kindly select above check box');
                return false;
            }
            var tf1 = parseInt(this.addFormData.chargesDetails.ticketFare);
            var tf2 = tf1 / parseInt($('#creditNoteForm input:checkbox').length);
            var tf3 = Math.round(tf2 * len);
            var cst = parseInt(this.addFormData.chargesDetails.cancellationServiceTotal);
            var crt = parseInt(this.addFormData.chargesDetails.cancellationRailwayTotal);
            this.addFormData.chargesDetails.creditAmount = tf3 - cst - crt;
        }

        if (param == 'creditNote' || param == 'triggerCreditNote') {
            var obj1 = this.getObjectFromId();
            var obj = obj1 || id.i || this.viewCheckboxObj;
            this.passengerList = obj.passengerDetails;
            //this.addFormData = { charges: {}, payment: {} }
            this.readonlyCreditNotePaymentDate = false;

            this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
                this.viewOtherDetailsData = res[0];
                var creDate = obj.paymentDetails.creditNoteDate;
                var creditPaymentDate = obj.paymentDetails.creditNotePaymentDate;
                var creditPaymentDateTP = obj.paymentDetails.creditNotePaymentDateTP;
                if (creDate == '') {
                    var d = new Date();
                    var d1 = d.getDate();
                    var d2 = d.getMonth();
                    var d3 = d.getFullYear();
                    creDate = d1 + ' ' + this.monthArr[d2] + ', ' + d3;
                } else {
                    var creDate1 = creDate.split('/')
                    var d = new Date(creDate1[1] + '/' + creDate1[0] + '/' + creDate1[2]);
                    var d1 = d.getDate();
                    var d2 = d.getMonth();
                    var d3 = d.getFullYear();
                    creDate = d1 + ' ' + this.monthArr[d2] + ', ' + d3;
                }
                if (obj.paymentDetails.creditNoteFlag == true) {
                    $('#creditNoteForm .disabled').removeClass('disabled');
                }
                if (obj.paymentDetails.creditNotePaymentStatus == 'Given') {
                    this.readonlyCreditNotePaymentDate = true;
                    var creditPaymentDate1 = creditPaymentDate.split('/')
                    var d = new Date(creditPaymentDate1[1] + '/' + creditPaymentDate1[0] + '/' + creditPaymentDate1[2]);
                    var d1 = d.getDate();
                    var d2 = d.getMonth();
                    var d3 = d.getFullYear();
                    creditPaymentDate = d1 + ' ' + this.monthArr[d2] + ', ' + d3;
                    creditPaymentDateTP = this.Services.fullDateTPFunc(d1,d2,d3);
                    this.addFormData.paymentDetails.creditNotePaymentStatus = obj.paymentDetails.creditNotePaymentStatus;
                } else {
                    this.addFormData.paymentDetails.creditNotePaymentStatus = 'Pending';
                }

                $('#creditNoteForm input[name="creditNoteNoCN"]').attr('data-id', obj.otherDetails.otherDetailsId);
                this.addFormData.chargesDetails.grandTotal = obj.chargesDetails.grandTotal*1;
                this.addFormData.paymentDetails.creditNoteFlag = true;
                this.addFormData.paymentDetails.creditNotePaymentDate = creditPaymentDate;
                this.addFormData.paymentDetails.creditNotePaymentDateTP = creditPaymentDateTP;
                this.addFormData.paymentDetails.creditNoteNo = obj.paymentDetails.creditNoteNo;
                this.addFormData.paymentDetails.creditNoteDate = creDate;
                this.addFormData.paymentDetails.paymentReceived = obj.paymentDetails.paymentReceived*1;
                this.addFormData.paymentDetails.pendingAmount = obj.paymentDetails.pendingAmount*1;
                this.addFormData.chargesDetails.ticketFare = obj.chargesDetails.ticketFare*1;
                this.addFormData.chargesDetails.convenience = obj.chargesDetails.convenience*1;
                this.addFormData.chargesDetails.cancellationService = obj.chargesDetails.cancellationService*1;
                this.addFormData.chargesDetails.cancellationRailway = obj.chargesDetails.cancellationRailway*1;
                this.addFormData.chargesDetails.cancellationServiceTotal = obj.chargesDetails.cancellationServiceTotal*1;
                this.addFormData.chargesDetails.cancellationRailwayTotal = obj.chargesDetails.cancellationRailwayTotal*1;
                this.addFormData.paymentDetails.remark = obj.paymentDetails.remark;
                this.addFormData.chargesDetails.creditAmount = obj.chargesDetails.creditAmount*1;

                if (this.addFormData.paymentDetails.creditNoteNo == '') {
                    this.addFormData.paymentDetails.creditNoteNo = parseInt(this.viewOtherDetailsData.CreditNotes) - (-1);
                    this.creditEntry = 'add';
                } else {
                    this.creditEntry = 'edit';
                }

                if (obj.bookingType == 'Air') {
                    this.addFormData.chargesDetails.cancellationService = 0;
                    this.addFormData.chargesDetails.cancellationRailway = 0;
                }
            },
            err => {
                console.log(err);
            });
            
        }

        if (param == 'submitCreditNote') {
            if ($('#creditNoteForm .modal-footer .disabled').length != 0) { return false }
            //var obj1 = this.getObjectFromId();
            //var obj = obj1 || id.i || this.viewCheckboxObj;
            //var obj = id.i || this.viewCheckboxObj;
            
            var updateCreditIdArr = $('#creditNoteForm input[name="creditNoteNoCN"]').attr('data-id');
            var l = $('#creditNoteForm input:checkbox').length; //1
            var passengerDetailsObj = [];

            let d = new Date( $('#creditNoteForm input[name="creditNoteDateCN"]').val() );
            //>>>>>>>let d = new Date(this.addFormData.paymentDetails.creditNoteDate);
            let d1:any = d.getDate();
            let d2:any = d.getMonth() + 1;
            let d3:any = d.getFullYear();
            if (d1 < 10) { d1 = '0' + d1 }
            if (d2 < 10) { d2 = '0' + d2 }
            var creditDate = d1 + '/' + d2 + '/' + d3;
            var creditPaymentDate = '';
            var creditPaymentDateTP = '';
            //if (this.addFormData.paymentDetails.creditNotePaymentStatus == 'Given') {
            //    let d = new Date(this.addFormData.paymentDetails.creditNotePaymentDate);
            if ($('#creditNoteForm input[name="creditNotePaymentStatusCN"]').val() == 'Given') {
                let d = new Date($('#creditNoteForm input[name="creditNotePaymentDateCN"]').val());
                let d1:any = d.getDate();
                let d2:any = d.getMonth() + 1;
                let d3:any = d.getFullYear();
                if (d1 < 10) { d1 = '0' + d1 }
                if (d2 < 10) { d2 = '0' + d2 }
                creditPaymentDate = d1 + '/' + d2 + '/' + d3;
                creditPaymentDateTP = this.Services.fullDateTPFunc(d1,d2,d3);
            }

            for (var i = 0; i < l; i++) {
                let c:any = $('#creditNoteForm input:checkbox').eq(i).prop('checked');
                if (c == true) { c = true } else if (c == false) { c = '' }
                //var pd1 = JSON.parse(angular.toJson(obj.passengerDetails[i]));
                var pd1 = this.addFormData.passengerDetails[i];
                pd1.checkbox = c;
                passengerDetailsObj.push(pd1);
            }
            //console.log('Ng-Modal not working here');
            //console.log(this.addFormData);

            var creditDetailsObj = {
                passengerDetails: passengerDetailsObj,
                chargesDetails: {
                    grandTotal: parseInt($('#creditNoteForm input[name="grandTotalCN"]').val()),
                    ticketFare: parseInt($('#creditNoteForm input[name="ticketFareCN"]').val()),
                    cancellationService: parseInt($('#creditNoteForm input[name="cancellationServiceCN"]').val()),
                    cancellationRailway: parseInt($('#creditNoteForm input[name="cancellationRailwayCN"]').val()),
                    cancellationServiceTotal: parseInt($('#creditNoteForm input[name="cancellationServiceTotalCN"]').val()),
                    cancellationRailwayTotal: parseInt($('#creditNoteForm input[name="cancellationRailwayTotalCN"]').val()),
                    creditAmount: parseInt($('#creditNoteForm input[name="creditAmountCN"]').val())
                },
                paymentDetails: {
                    creditNoteFlag: null,//($('#creditNoteForm input[name="creditNoteFlagCN"]').val()),
                    creditNotePaymentStatus: ($('#creditNoteForm select[name="creditNotePaymentStatusCN"]').val()),
                    creditNotePaymentDate: creditPaymentDate,
                    creditNotePaymentDateTP: creditPaymentDateTP,
                    creditNoteNo: parseInt($('#creditNoteForm input[name="creditNoteNoCN"]').val()),
                    creditNoteDate: creditDate,
                    remark: ($('#creditNoteForm input[name="remarkCN"]').val()),
                    pendingAmount: parseInt($('#creditNoteForm input[name="pendingAmountCN"]').val()),
                    paymentReceived: parseInt($('#creditNoteForm input[name="paymentReceivedCN"]').val())
                }
            }
            if( creditDetailsObj.paymentDetails.creditNotePaymentStatus == "" ){
                alert('Kindly select - Credit Note Status');
                return false;
            }

            let data = { array: updateCreditIdArr, obj: creditDetailsObj, creditEntry: this.creditEntry }
            //console.log(data);
            this.Services.creditNoteForm(data).subscribe(res =>{                  
                $('#creditNoteForm .close').click();
                //$('#filterWrapper input[type="submit"]').click();
                this.actions('', 'filterListData');
            },
            err => {
                console.log(err);
            });
        }

        if (param == 'exportExcelJson') {
            this.expExcelClientFunc();
        }
        if (param == 'exportExcelJsonNew' || param == 'exportExcelJsonByPassBill' || param == 'exportExcelJsonByPassCreditNote') {
            if( this.filterFormData.client == '' || (this.filterFormData.client).toLowerCase() == 'select' ){
                alert('!! Kindly select a client');
                return false;
            }
            console.log( 'res>>', this.filterFormData.client );
            //let data = { listFormJsonData2: { searchType: { "type": "clientList", "clientName": this.filterFormData.client } } }
            let data = { listFormJsonData2: { searchType: { "clientName": this.filterFormData.client } } }
            this.Services.listMastersData(data).subscribe(res =>{
                if (param == 'exportExcelJsonByPassCreditNote') {
                    this.expExcelClientCreditNoteFunc( res[0], param );
                }
                else{
                    console.log( 'res>>', res );
                    this.expExcelClientBillFunc( res[0], param );
                }
            },
            err => {
              console.log(err);
            });
        }

        if (param == 'downloadAsExport') {
            this.downloadAsExportClientFunc();
        }

        if (param == 'filterClearDates') {
            var _d = new Date();
            var _d1 = _d.getDate();
            var _d2 = _d.getMonth();
            var _d3 = _d.getFullYear();
            var curDate1 = _d1 + ' ' + this.monthArr[_d2] + ', ' + _d3;

            var d = new Date();
            d.setDate(d.getDate() - 15);
            var d1 = d.getDate();
            var d2 = d.getMonth();
            var d3 = d.getFullYear();
            var curDate2 = d1 + ' ' + this.monthArr[d2] + ', ' + d3;

            //this.filterFormData={};
            this.filterFormData.journeyDateFrom = curDate2;
            this.filterFormData.journeyDateTo = curDate1;
        }

        if (param == 'filterClear') {
            $('body').addClass('loading');
            $('.noDataFound').removeClass('noDataFound');
            //this.showListFlag = true;//SULAMAN
            this.showListFlag = false;

            this.filterFormData = {};
            this.filterFormData.bookingType = this.bookingTypeList[0].bookingName;
            this.filterFormData.typeOfDate = "billDate";
            this.actions('', 'filterClearDates');

            this.filterFormData.groupInvoiceNo = '';
            this.filterFormData.invoiceNo = '';
            this.filterFormData.transactionNo = '';
            this.filterFormData.pnrNo = '';
            this.filterFormData.creditNoteNo = '';
            this.filterFormData.client = '';
            this.filterFormData.agentType = 'Select';
            this.filterFormData.paymentStatus = 'Select';
            this.filterFormData.creditNotePaymentStatus = 'Select';
            this.filterFormData.busTravelPaymentStatus = 'Select';
            this.filterFormData.contactNo1 = '';
            this.filterFormData.advanceBooking = 'Select';
            this.filterFormData.trainClass = 'Select';
            this.showListFlag = false;
            $('body').removeClass('loading');
            //this.actions('', 'filterListData');

            //this.filterFormData.paymentDetails = {};
            //this.filterFormData.planId = '';
            //this.filterFormData.ticketNo = '';
            //this.filterFormData.paymentDetails.creditNoteNo = '';

            //this.filterFormData.paymentStatus = 'Select'; 
            //this.filterFormData.approvedBy = '';
            /*
            var f1 = new Date(curDate2);
            var t1 = new Date(curDate1);
            var f2 = f1.getTime();
            var t2 = t1.getTime();

            if (this.onClearClickSeverHit) {
                this.onClearClickSeverHit = false
                var bt = {
                    "bookingType": this.filterFormData.bookingType, 
                    "invoiceFullDateTP":{"$gte" : f2, "$lte" : t2}
                };
                //var bt = '"bookingType":"'+this.filterFormData.bookingType+'"';
                this.jsonListData = [];

                var filterDataObj = bt;
                var listFormJsonData1 = {
                    searchType: 'filter',
                    searchBy: filterDataObj
                };

                let data = { listFormJsonData2: listFormJsonData1 }
                this.Services.listData(data).subscribe(res =>{
                    this.jsonListData = (res).reverse();
                    //this.actions(this.jsonListData, 'getTotalAmountAdvanced');


                    /////////////////////////////////////////////////////////////
                    console.log('BELOW CODE IS ADDED JUST TO LOAD THE PAGE FATER THAN NOW');
                    this.jsonListDataAdvancedFunc('save');


                    $('body').removeClass('loading');
                },
                err => {
                    console.log(err);
                    $('body').removeClass('loading');
                });
                
            }
            */
        }

        if (param == 'filterListData') {
            
            
            //id.preventDefault();

            $('body').addClass('loading');
            $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
            this.showListFlag = true;

            let valB = this.filterFormData.bookingType;
            this.dyCustomizeListFinal = JSON.parse(localStorage._dynamicList)[valB];

            var _obj = '';

            //this.filterFormData.bookingType
            var b = this.filterFormData.bookingType;
            if (b == '' || b == undefined) {  }
            else{_obj += '"bookingType":"' + b + '"';}
        
            //this.filterFormData.invoiceNo
            var gv = this.filterFormData.groupInvoiceNo;
            if (gv == '' || gv == undefined) {  }
            else{_obj += ',"groupInvoiceNo":"' + gv + '"';}
            
            //this.filterFormData.invoiceNo
            var v = this.filterFormData.invoiceNo;
            if (v == '' || v == undefined) {  }
            else{_obj += ',"invoiceNo":"' + v + '"';}
            
            //this.filterFormData.planId
            //>>>>>if (this.vehicleFilterWrap == true) { return false; }
            var p = this.filterFormData.transactionNo;
            if (p == '' || p == undefined) { }
            else{_obj += ',"bookingDetails.transactionNo":"' + p + '"';}
        
            //this.filterFormData.ticketNo;
            var t = this.filterFormData.ticketNo;
            if (t == '' || t == undefined) { }
            else{_obj += ',"passengerDetails.ticketNo":"' + t + '"';}
            
            //this.filterFormData.pnrNo
            var p = this.filterFormData.pnrNo;
            if (p == '' || p == undefined) { }
            else{_obj += ',"bookingDetails.pnrNo":"' + p + '"';}

            //this.filterFormData.creditNoteNo;
            var cnn = this.filterFormData.creditNoteNo;
            if (cnn == '' || cnn == undefined || cnn == 'Select') { }
            else{
                _obj += ',"paymentDetails.creditNoteNo":' + cnn + '';
            }

            //this.filterFormData.client;
            var cln = this.filterFormData.client;
            if (cln == '' || cln == undefined || cln == 'Select') { }
            else{_obj += ',"client":"' + cln + '"';}

            //this.filterFormData.agentType;
            //>>>if (this.vehicleFilterWrap == true) { return false; }
            var a = this.filterFormData.agentType;
            if (a == '' || a == undefined || a == 'Select') { }
            else{_obj += ',"agentDetails.agentType":"' + a + '"';}
            
            //this.filterFormData.paymentStatus;
            var ps = this.filterFormData.paymentStatus;
            if (ps == '' || ps == undefined || ps == 'Select') { }
            else{_obj += ',"paymentDetails.paymentStatus":"' + ps + '"';}

            //this.filterFormData.creditNotePaymentStatus;
            var cnps = this.filterFormData.creditNotePaymentStatus;
            if (cnps == '' || cnps == undefined || cnps == 'Select') {  }
            else{_obj += ',"paymentDetails.creditNotePaymentStatus":"' + cnps + '"';}            

            //this.filterFormData.trainClass;
            if(this.filterFormData.bookingType == 'Train'){
                var tcl = this.filterFormData.trainClass;
                if (tcl == '' || tcl == undefined || tcl == 'Select') {  }
                else{_obj += ',"bookingDetails.class":"' + tcl + '"';}
            }
            //this.filterFormData.journeyDateFrom;
            //this.filterFormData.journeyDateTo;
            
            //var f0 = this.filterFormData.journeyDateFrom;//This line of code is not working :(
            //var t0 = this.filterFormData.journeyDateTo;//This line of code is not working :(
            let f0 = $('input[name="journeyDateFrom"]').val();
            let t0 = $('input[name="journeyDateTo"]').val();
            let f1:any = new Date('' + f0 + '');
            let t1:any = new Date('' + t0 + '');

            if( this.filterFormData.typeOfDate != 'none' ){
                if ( f1 == 'Invalid Date' || t1 == 'Invalid Date' ) {
                    alert('Kindly select the date range again.');
                    return false;
                }
                else{
                    var f22 = f1.getTime();
                    var t22 = t1.getTime();
                    if (f22 == '' || f22 == undefined || isNaN(f22) || t22 == '' || t22 == undefined || isNaN(t22)) { alert('Major Problem, kindly contact Software Engineer 2'); }
                    else{ 
                        if( this.filterFormData.typeOfDate == 'none' ){
                            _obj += '';
                        }
                        else if( this.filterFormData.typeOfDate == 'billDate' ){
                            _obj += ',"invoiceFullDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
                        }
                        else if( this.filterFormData.typeOfDate == 'journeyDate' ){
                            _obj += ',"bookingDetails.journeyDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
                            //_obj += ',"bookingDetails__journeyDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
                        }
                        else if( this.filterFormData.typeOfDate == 'paymentDate' ){
                            _obj += ',"paymentDetails.paymentDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
                            //_obj += ',"paymentDetails__paymentDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
                        }
                    }
                }
            }
            
            //this.filterFormData.contactNo1;
            var cn = this.filterFormData.contactNo1;
            if (cn == '' || cn == undefined || cn == 'Select') { }
            else{_obj += ',"contactNo1":"' + cn + '"';}

            //this.filterFormData.advanceBooking;
            var advbk = this.filterFormData.advanceBooking;
            if (advbk == '' || advbk == undefined || advbk == 'Select') {
                
            }
            else if (advbk == 'false' || advbk == false) {
                _obj += ',"advanceBooking":"' + advbk + '"';
            }
            else{
                _obj += ',"advanceBooking":"' + advbk + '"';
            }
            
            
            /*let obj = function() {
                var objArr1 = ['bt()', 'iv()', 'pi()', 'pn()', 'tn()', 'cd()', 'cl()', 'ag()', 'ps()', 'cp()', 'ft()', 'cn()'];
                var objArr2 = '';
                for (var i = 0; i < objArr1.length; i++) {
                    let v = eval(objArr1[i]);
                    if (objArr2 == '' && v != '') {
                        objArr2 = v;
                    } else if (v != '') {
                        objArr2 += ',' + v;
                    }
                }
                return '{' + objArr2 + '}';
            };
            var filterDataObj = JSON.parse(obj());
            */

            //let filterDataObj:any = JSON.parse('{' + _obj + '}');
            let myFilterDataObj1 = JSON.parse('{' + _obj + '}');
            let filterDataObj:any = myFilterDataObj1;

            let listFormJsonData1 = {
                searchType: 'filter',
                searchBy: filterDataObj
            };
            let data = { listFormJsonData2: listFormJsonData1 }

            this.Services.listData(data).subscribe(res =>{
                var data = res;
                if (data.length == 0) {
                    //alert('No Data Found');
                    this.jsonListData = [];
                    this.errorClass = 'noDataFound';
                    this.showListActionFlag = false;
                    this.jsonListDataAdvancedFunc('save');
                } else {
                    this.jsonListData = data.reverse();
                    console.log('this.jsonListData .............', this.jsonListData[0]);
                    this.errorClass = '';
                    this.jsonListDataAdvancedCheck = new Array( data.length );
                    this.jsonListDataAdvancedCheckAll = false;

                    this.showListActionFlag = true;
                    this.AirWrap = this.BusWrap = this.TrainWrap = this.PackageWrap = this.VehicleWrap = this.HotelWrap = this.VisaWrap = this.ExpenseWrap = false;
                    this.AirWrapRequired = this.BusWrapRequired = this.TrainWrapRequired = this.PackageWrapRequired = this.VehicleWrapRequired = this.HotelWrapRequired = this.VisaWrapRequired = this.ExpenseWrapRequired = '';
                    
                    var type = this.filterFormData.bookingType;
                    if(type == 'Air'){ this.AirWrap = true; this.AirWrapRequired = 'required'; }
                    else if(type == 'Bus'){ this.BusWrap = true; this.BusWrapRequired = 'required'; }
                    else if(type == 'Train'){ this.TrainWrap = true; this.TrainWrapRequired = 'required'; }
                    else if(type == 'Package'){ this.PackageWrap = true; this.PackageWrapRequired = 'required'; }
                    else if(type == 'Vehicle'){ this.VehicleWrap = true; this.VehicleWrapRequired = 'required'; }
                    else if(type == 'Hotel'){ this.HotelWrap = true; this.HotelWrapRequired = 'required'; }
                    else if(type == 'Visa'){ this.VisaWrap = true; this.VisaWrapRequired = 'required'; }
                    else if(type == 'Expense'){ this.ExpenseWrap = true; this.ExpenseWrapRequired = 'required'; }
                    else if(type == ''){
                        
                    }

                    var tnc = this.otherDetails.allTerms;
                    if (tnc != undefined) {
                        for (var i = 0; i < tnc.length; i++) {
                            if (tnc[i].bookingName == type) {
                                this.otherDetails.terms = tnc[i].TermsConditions;
                                break;
                            }
                        };
                    }

                    this.searchResultInfoBox = "Total result searched: "+this.jsonListData.length;
                    if( this.jsonListData.length > 500 ){
                        this.searchResultInfoBox = "Result: "+this.jsonListData.length+" | This may reduce the performance of browser!";
                    }
                    else if( this.jsonListData.length > 1000 ){
                        this.searchResultInfoBox = "Result: "+this.jsonListData.length+" | This may crash your browser!!";
                    }
                    else if(this.jsonListData.length < 20){
                        this.searchResultInfoBox = "";
                    }
                    
                    //this.actions('', 'getTotalAmount');
                    //this.actions(this.jsonListData, 'getTotalAmountAdvanced');

                    //this.jsonListDataAdvancedFunc('save');
                    this.dynamicJsonListDataAdvancedFunc('full');
                    console.log('this.addFormData.chargesDetails .............', this.addFormData.chargesDetails);
                }

                $('body').removeClass('loading');
            },
            err => {
                //error
                $('body').removeClass('loading');
                console.log(err);
            })
        }

        if (param == 'filterUpdateAgentSelectBox') {
            
            let selectionType = this.filterFormData.bookingType;
            this.agentListFinal = [];
            let agentListFin = []; 

            this.AirFilterWrap = this.BusFilterWrap = this.TrainFilterWrap = this.PackageFilterWrap = this.VehicleFilterWrap = this.HotelFilterWrap = this.VisaFilterWrap = this.ExpenseFilterWrap = false;
            //var type = this.filterFormData.bookingType;
            //eval('this.' + type + 'FilterWrap = true');
            
            if(selectionType == 'Air'){ this.AirFilterWrap = true; }
            else if(selectionType == 'Bus'){ this.BusFilterWrap = true; }
            else if(selectionType == 'Train'){ this.TrainFilterWrap = true; }
            else if(selectionType == 'Package'){ this.PackageFilterWrap = true; }
            else if(selectionType == 'Vehicle'){ this.VehicleFilterWrap = true; }
            else if(selectionType == 'Hotel'){ this.HotelFilterWrap = true; }
            else if(selectionType == 'Visa'){ this.VisaFilterWrap = true; }
            else if(selectionType == 'Expense'){ this.ExpenseFilterWrap = true; }
            
            this.filterFormData.agentType = 'Select';
            for(var alf = 0; alf<this.agentList.length; alf++){
                if( this.agentList[alf].bookingType == selectionType ){
                    agentListFin.push(this.agentList[alf]);
                }
            }
            this.agentListFinal = agentListFin;
        }

        if (param == 'getTotalAmountAdvanced') {
            
            this.totalListAmount.grandTotal = 0;
            this.totalListAmount.advancePayment = 0;
            this.totalListAmount.ticketFare = 0;
            this.totalListAmount.serviceCharges = 0;
            this.totalListAmount.serviceTaxAmount = 0;
            this.totalListAmount.cgstval = 0;
            this.totalListAmount.sgstval = 0;
            this.totalListAmount.igstval = 0;
            this.totalListAmount.discount = 0;
            this.totalListAmount.serviceCharges = 0;
            this.totalListAmount.creditAmount = 0;
            this.totalListAmount.paymentReceived = 0;
            this.totalListAmount.pendingAmount = 0;

            setTimeout(()=> {
                let listObj = id;
                for (var i = 0; i < listObj.length; i++) {
                    var myListObject = listObj[i];
                    this.totalListAmount.grandTotal += Number(myListObject.chargesDetails.grandTotal*1);
                    this.totalListAmount.ticketFare += Number(myListObject.chargesDetails.ticketFare*1);
                    this.totalListAmount.serviceCharges += Number(myListObject.chargesDetails.service*1);
                    if( this.specialClient.all_flagHiddenServiceCharges && this.addFormData.chargesDetails.hiddenServiceCharges>0 ){
                        this.totalListAmount.serviceCharges += Number(myListObject.chargesDetails.hiddenServiceCharges*1);
                    }
                    
                    if( myListObject.chargesDetails.serviceTax > 0 ){
                        this.totalListAmount.serviceTaxPercent += Number(myListObject.chargesDetails.serviceTax*1);
                        this.totalListAmount.serviceTaxAmount += Math.round((Number(myListObject.chargesDetails.total)/100)*Number(myListObject.chargesDetails.serviceTax));
                    }
                    
                    if( myListObject.chargesDetails.cgstval != undefined ){
                        this.totalListAmount.cgstval += Number(myListObject.chargesDetails.cgstval*1);
                    }
                    
                    if( myListObject.chargesDetails.sgstval != undefined ){
                        this.totalListAmount.sgstval += Number(myListObject.chargesDetails.sgstval*1);
                    }
                    
                    if( myListObject.chargesDetails.igstval != undefined ){
                        if( (myListObject.chargesDetails.igstval*1) > 0 ){
                            this.totalListAmount.igstval += Number(myListObject.chargesDetails.igstval*1);
                        }
                    }
                    
                    if( myListObject.paymentDetails.advancePayment != undefined ){
                        if( (myListObject.paymentDetails.advancePayment*1) > 0 ){
                            this.totalListAmount.advancePayment += Number(myListObject.paymentDetails.advancePayment*1);
                        }
                    }
                    
                    this.totalListAmount.paymentReceived += Number(myListObject.paymentDetails.paymentReceived*1);
                    this.totalListAmount.pendingAmount += Number(myListObject.paymentDetails.pendingAmount*1);
                    this.totalListAmount.discount += Number(myListObject.paymentDetails.discount*1);
                    this.totalListAmount.creditAmount += Number(myListObject.chargesDetails.creditAmount*1);
                }

            }, 500);
        }

        if (param == 'getTotalAmount') {
            /*
            setTimeout(()=> {
                var ths = $('#listGrid');
                var totalArray = ['.totalCA', '.totalSC', '.totalBA', '.totalST', '.totalD', '.totalPA', '.totalBA.Received', '.totalCGST', '.totalSGST'];
                for (var i = 0; i < totalArray.length; i++) {
                    var a = ths.find(totalArray[i]);
                    var b = 0;
                    for (var j = 0; j < a.length; j++) {
                        var amt = Number(a.eq(j).text());
                        if (!isNaN(amt)) {
                            b += (Math.round(amt));
                        }
                    }
                    $(totalArray[i] + '_final span').addClass('working').text(' ' + b.toLocaleString('en'));
                }
            }, 500);
            */
        }

        if (param == 'checkBox_onload') {
            
            
            var chk = (localStorage._ckeckBoxList);
            //console.log(chk);
            if (chk.indexOf('obj') == -1) {
                //if( chk == undefined || chk == 'undefined' || this.ls == undefined || this.ls == ''){
                //if(localStorage._ckeckBoxList localStorage._ckeckBoxList.length <= 20 || localStorage._ckeckBoxList == undefined || localStorage._ckeckBoxList == 'undefined' ){
                this.ls = {
                    obj: {}
                }
                var valI = this.ls.obj.cl1 = false;
                var valJ = this.ls.obj.cl2 = false;
                var valK = this.ls.obj.cl3 = false;
                for (var i = 1; i < 40; i++) {
                    eval('this.ls.obj.cl1_' + i + '=' + valI);
                    eval('this.ls.obj.cl2_' + i + '=' + valJ);
                    eval('this.ls.obj.cl3_' + i + '=' + valK);
                }
                localStorage._ckeckBoxList = JSON.stringify(this.ls);
            } else {
                this.ls = JSON.parse(localStorage._ckeckBoxList);
            }
        }

        if( id=='dynamicCustomizeList_checkbox' ){
            
            
            setTimeout(()=>{
                let valB = this.filterFormData.bookingType;
                let dynamicListVal = JSON.parse(localStorage._dynamicList);
                for(let x=0; x<dynamicListVal[valB].length; x++){
                    if(dynamicListVal[valB][x].ref == param[0]){
                        dynamicListVal[valB][x].client_checked = param[1];
                        break;
                    }
                }
                localStorage._dynamicList = JSON.stringify(dynamicListVal);
                this.dyCustomizeListFinal = JSON.parse(localStorage._dynamicList)[valB];
                
                this.dynamicJsonListDataAdvancedFunc('full');
            },10);
        }


        if( id=='dynamicCustomizeList_checkbox_clearAll' ){
            setTimeout(()=>{
                //   param = [tList.ref, tList.client_checked] //
                let valB = this.filterFormData.bookingType;
                let dynamicListVal = JSON.parse(localStorage._dynamicList);
                for(let x=0; x<dynamicListVal[valB].length; x++){
                    if(dynamicListVal[valB][x].ref == param[0] && dynamicListVal[valB][x].source == 'none' ){
                        dynamicListVal[valB][x].client_checked = param[1];
                        for(let y=(x+1); y<dynamicListVal[valB].length; y++){
                            if( dynamicListVal[valB][y].source == 'none' ){ break; }
                            else{ dynamicListVal[valB][y].client_checked = param[1]; }
                        }
                        break;
                    }
                }
                localStorage._dynamicList = JSON.stringify(dynamicListVal);            
                this.dyCustomizeListFinal = dynamicListVal[valB];
                this.dynamicJsonListDataAdvancedFunc('full');
            },20);
        }

        /*
        if( id=='customizeList_checkbox' ){
            let valI = !eval('this.'+param);
            eval('this.' + param + '=' + valI);
        
            localStorage._ckeckBoxList = JSON.stringify(this.ls);
            this.jsonListDataAdvancedFunc('save');
        }

        if (param == 'checkBox_cl1') {
            let valI = !this.ls.obj.cl1;
            for (var i = 1; i < 40; i++) {
                eval('this.ls.obj.cl1_' + i + '=' + valI);
            }
            localStorage._ckeckBoxList = JSON.stringify(this.ls);
            this.jsonListDataAdvancedFunc('save');
        }
        if (param == 'checkBox_cl2') {
            let valJ = !this.ls.obj.cl2;
            for (var j = 1; j < 40; j++) {
                eval('this.ls.obj.cl2_' + j + '=' + valJ);
            }
            localStorage._ckeckBoxList = JSON.stringify(this.ls);
            this.jsonListDataAdvancedFunc('save');
        }
        if (param == 'checkBox_cl3') {
            let valK = !this.ls.obj.cl3;
            for (var k = 1; k < 40; k++) {
                eval('this.ls.obj.cl3_' + k + '=' + valK);
            }
            localStorage._ckeckBoxList = JSON.stringify(this.ls);
            this.jsonListDataAdvancedFunc('save');
        }
        /***/

        if (param == 'paymentBy') {
            var pb = this.addFormData.paymentDetails.paymentBy;
            if (pb == 'Online') {
                this.readonlyPaymentByChequeNo = false;
                this.readonlyPaymentByBankName = true;
                this.addFormData.paymentDetails.paymentByChequeNo = '';
            } else if (pb == 'Cash' || pb == 'Select') {
                this.readonlyPaymentByChequeNo = false;
                this.readonlyPaymentByBankName = false;
                this.addFormData.paymentDetails.paymentByChequeNo = '';
                this.addFormData.paymentDetails.paymentByBankName = '';
            } else if (pb == 'Cheque') {
                this.readonlyPaymentByChequeNo = true;
                this.readonlyPaymentByBankName = true;
            }
        }

        if (param == 'creditNotePayment') {
            var cnps = this.addFormData.paymentDetails.creditNotePaymentStatus;
            if (cnps == 'Pending') {
                this.readonlyCreditNotePaymentDate = false;
                this.addFormData.paymentDetails.creditNotePaymentDate = '';
                this.addFormData.paymentDetails.creditNotePaymentDateTP = 0;
            } else if (cnps == 'Given') {
                var d = new Date();
                var d1 = d.getDate();
                var d2 = d.getMonth();
                var d3 = d.getFullYear();
                this.readonlyCreditNotePaymentDate = true;
                this.addFormData.paymentDetails.creditNotePaymentDate = d1 + ' ' + this.monthArr[d2] + ', ' + d3;
                this.addFormData.paymentDetails.creditNotePaymentDateTP = this.Services.fullDateTPFunc(d1,d2,d3);
                $('#creditNoteForm .disabled').removeClass('disabled');
            }
        }
    }
    
    public getObjectFromId = function() {
        var id = $('#listGrid input:checked').attr('data-id');

        var data1 = this.jsonListData;
        var data2;
        for (var i = 0; i < data1.length; i++) {
            if(data1[i].otherDetails.otherDetailsId == id) {
                data2 = data1[i];
                break;
            }
        }
        this.loadInputData(data2);
        return data2;
    }
    
    public getListId = function() {
        var tr = $('#listGrid tbody tr');
        var arr = [];
        for (var i = 0; i < tr.length; i++) {
            var id = tr.eq(i).find('td').eq(0).find('input').attr('data-id');
            arr.push(id);
        }
        return arr;
    }
    
    public multipleShow = function(p1, p2, p3) {
        if (p1 == true && p2 == true && p3 == undefined) {
            return true;
        } else if (p1 == true && p2 == true && p3 == true) {
            return true;
        }
        return false;
    }
    
    public getFirstPassName = function(p1){
        return p1[0].name;
    }
    public getServiceTaxAmt = function(p1){
        var calc = Math.round(p1.grandTotal-p1.total);
        if(p1.serviceTax == 0 ){return 0}
        return calc;
    }


    /*public customizeListFunc(){
        this.dyCustomizeListFinal=[];
        this.dyCustomizeListFinal = this.dyCustomizeList.slice(0);
        this.dyCustomizeListFinal.sort(function(a,b) {
            return a.srNo - b.srNo;
        });
        //console.log('this.dyCustomizeListFinal >> ', this.dyCustomizeListFinal);
    }*/

    public dynamicJsonListDataAdvancedFunc(param='full'){

        let jsonListDataAdv0 = this.dyCustomizeListFinal;
        let jsonListDataAdv1 = this.jsonListData;
        this.totalListAmountObjFlag = false;

        if(param == 'full'){
            this.dynamicJsonListDataAdvanced = [];
            this.dyCustomizeListFinalHeading = [];
            
            this.totalListAmountObj.advancePayment.total = 0;
            this.totalListAmountObj.creditAmount.total = 0;
            this.totalListAmountObj.serviceCharges.total = 0;
            this.totalListAmountObj.grandTotal.total = 0;
            this.totalListAmountObj.serviceTaxPercent.total = 0;
            this.totalListAmountObj.serviceTaxAmount.total = 0;
            this.totalListAmountObj.paymentReceived.total = 0;
            this.totalListAmountObj.pendingAmount.total = 0;
            this.totalListAmountObj.cgstval.total = 0;
            this.totalListAmountObj.sgstval.total = 0;
            this.totalListAmountObj.igstval.total = 0;
            this.totalListAmountObj.discount.total = 0;
            this.totalListAmountObj.ticketFare.total = 0;
            this.totalListAmountObj.hiddenAmount1.total = 0;
            this.totalListAmountObj.hiddenAmount2.total = 0;

        }
        if(param == 'onlyTotal'){    
            this.totalListAmountObj.advancePayment.checked = 0;
            this.totalListAmountObj.creditAmount.checked = 0;
            this.totalListAmountObj.serviceCharges.checked = 0;
            this.totalListAmountObj.grandTotal.checked = 0;
            this.totalListAmountObj.serviceTaxPercent.checked = 0;
            this.totalListAmountObj.serviceTaxAmount.checked = 0;
            this.totalListAmountObj.paymentReceived.checked = 0;
            this.totalListAmountObj.pendingAmount.checked = 0;
            this.totalListAmountObj.cgstval.checked = 0;
            this.totalListAmountObj.sgstval.checked = 0;
            this.totalListAmountObj.igstval.checked = 0;
            this.totalListAmountObj.discount.checked = 0;
            this.totalListAmountObj.ticketFare.checked = 0;
            this.totalListAmountObj.hiddenAmount1.checked = 0;
            this.totalListAmountObj.hiddenAmount2.checked = 0;
            
            this.totalListAmountObj.advancePayment.unchecked = 0;
            this.totalListAmountObj.creditAmount.unchecked = 0;
            this.totalListAmountObj.serviceCharges.unchecked = 0;
            this.totalListAmountObj.grandTotal.unchecked = 0;
            this.totalListAmountObj.serviceTaxPercent.unchecked = 0;
            this.totalListAmountObj.serviceTaxAmount.unchecked = 0;
            this.totalListAmountObj.paymentReceived.unchecked = 0;
            this.totalListAmountObj.pendingAmount.unchecked = 0;
            this.totalListAmountObj.cgstval.unchecked = 0;
            this.totalListAmountObj.sgstval.unchecked = 0;
            this.totalListAmountObj.igstval.unchecked = 0;
            this.totalListAmountObj.discount.unchecked = 0;
            this.totalListAmountObj.ticketFare.unchecked = 0;
            this.totalListAmountObj.hiddenAmount1.unchecked = 0;
            this.totalListAmountObj.hiddenAmount2.unchecked = 0;
        }

        //-------------------------------------------------------------------
        let dontRender=[];
        let jsonListDataAdv1Revised=[];
        /**/
        for(let dr0=0; dr0<jsonListDataAdv1.length; dr0++){
            if(jsonListDataAdv1[dr0].roundTripReferenceInvoiceNo != undefined){
                dontRender.push({'roundTripInv':jsonListDataAdv1[dr0].roundTripReferenceInvoiceNo, 'inv':jsonListDataAdv1[dr0].invoiceNo});
            }
        }
        /**/
        for(let dr1=0; dr1<dontRender.length; dr1++){
            for(let dr2=0; dr2<jsonListDataAdv1.length; dr2++){
                if( jsonListDataAdv1[dr2]['invoiceNo'] == dontRender[dr1]['roundTripInv']){
                    let inv = '('+dontRender[dr1]['roundTripInv']+' - '+dontRender[dr1]['inv']+')';
                    jsonListDataAdv1[dr2]['roundTripReferenceInvoiceNo'] = 'To be deleted';
                    let revised = JSON.parse(JSON.stringify(jsonListDataAdv1[dr2]));
                    revised['roundTripNew'] = 'roundTrip1';
                    revised['roundTripReferenceInvoiceNo'] = 'Round Trip'+inv;
                    jsonListDataAdv1Revised.push(revised);
                    // // //jsonListDataAdv1.push(revised);
                }
            }
        }
        /**/
        //-------------------------------------------------------------------



        jsonListDataAdv1 = jsonListDataAdv1.concat(jsonListDataAdv1Revised);
        let table = [];
        for(let i=0; i<jsonListDataAdv1.length; i++){

            let a = jsonListDataAdv1[i];
            if(param == 'full'){
                let row = [];
                row.push( a.paymentDetails.paymentStatus );
                row.push( a.otherDetails.otherDetailsId );
                row.push( a.client );
                row.push( a.paymentDetails.pendingAmount );
                row.push( a._id );
                row.push( a.roundTripNew );
                if(a.roundTripReferenceInvoiceNo == undefined){ row.push( '-' ); }
                else if(a.roundTripReferenceInvoiceNo != undefined){ 
                    row.push( a.roundTripReferenceInvoiceNo );
                }
                if( a.roundTripNew == 'roundTrip1' ){
                    //console.log('--------------> roundTrip1 > ', a.invoiceNo);
                }
                
                //console.log('------------------------------------------------>>>>>>>>>');
                if(i==0){
                    this.dyCustomizeListFinalHeading.push( 'PaymentStatus' );
                    this.dyCustomizeListFinalHeading.push( 'OtherDetailsId' );
                    this.dyCustomizeListFinalHeading.push( 'Client' );
                    this.dyCustomizeListFinalHeading.push( 'PendingAmount' );
                    this.dyCustomizeListFinalHeading.push( '_id' );
                    this.dyCustomizeListFinalHeading.push( 'roundTrip' );
                    this.dyCustomizeListFinalHeading.push( 'Round Trip Information' );
                }
                for(let j=0; j<jsonListDataAdv0.length; j++){
                    let col = jsonListDataAdv0[j].source;
                    let title = jsonListDataAdv0[j].title;
    
                    if(col != 'none'){
                        if(jsonListDataAdv0[j].client_checked == true){
                            if(col != undefined){
                                if( col == 'passengerDetails'){
                                    let names = [];
                                    let passengers = (eval('a.'+col));
                                    for(let ps=0; ps<passengers.length; ps++){
                                        names.push( passengers[ps].name );
                                    }
                                    row.push( names );
                                }
                                else{
                                    
                                    if( a.roundTripReferenceInvoiceNo != undefined && (a.roundTripReferenceInvoiceNo).indexOf('Round Trip') != -1 ){
                                        let v = (eval('a.'+col)*1);
                                        if( isNaN(v) ){ row.push( eval('a.'+col) ); }
                                        else{ row.push( (v*2) ); }
                                    }
                                    else{
                                        row.push( eval('a.'+col) );
                                    }
                                }
                            }
                            else if(col == undefined){
                                row.push( '-' );
                            }
                            if(i==0){
                                this.dyCustomizeListFinalHeading.push( title );
                            }
                        }
                    }
                }
                table.push( row );
            }

            this.totalListAmountObj = _totalListAmountObj;//resetting all the TOTAL values
            if(a.roundTripNew == 'roundTrip1'){
                //console.log('dont add this AMT in total ...');
            }
            else{
                this.totalListAmountObjFunc(a, i, param);
            }
        }

        if(param == 'full'){
            setTimeout(()=>{
                this.dynamicJsonListDataAdvanced = table;
            },50);
        }
    }


    public hideRow(djlda){
        if(djlda[5] == 'roundTrip1'){ return 'hidden' }
        return '';
    }


    public totalListAmountObjFunc(a, ind, param){
        let arr = Object.keys(this.totalListAmountObj);
        for(let k=0; k<arr.length; k++){
            let ths = this.totalListAmountObj[arr[k]];
            let refO = eval('a.'+ths.refObj);
            
            if( (refO == undefined || refO == '' || refO == null ) ){
            } else{
                if(param == 'full'){
                    ths.total += (refO*1);
                }
                if( this.jsonListDataAdvancedCheck[ind] == true ){
                    this.totalListAmountObjFlag = true;
                    ths.checked += (refO*1);
                }
                ths.unchecked = ths.total - ths.checked;
            }
        }

        this.totalListAmountObj.creditAmount.checked = Math.round(this.totalListAmountObj.creditAmount.checked);
        this.totalListAmountObj.serviceCharges.checked = Math.round(this.totalListAmountObj.serviceCharges.checked);
        this.totalListAmountObj.grandTotal.checked = Math.round(this.totalListAmountObj.grandTotal.checked);
        this.totalListAmountObj.serviceTaxPercent.checked = Math.round(this.totalListAmountObj.serviceTaxPercent.checked);
        this.totalListAmountObj.serviceTaxAmount.checked = Math.round(this.totalListAmountObj.serviceTaxAmount.checked);
        this.totalListAmountObj.paymentReceived.checked = Math.round(this.totalListAmountObj.paymentReceived.checked);
        this.totalListAmountObj.pendingAmount.checked = Math.round(this.totalListAmountObj.pendingAmount.checked);
        this.totalListAmountObj.cgstval.checked = Math.round(this.totalListAmountObj.cgstval.checked);
        this.totalListAmountObj.sgstval.checked = Math.round(this.totalListAmountObj.sgstval.checked);
        this.totalListAmountObj.igstval.checked = Math.round(this.totalListAmountObj.igstval.checked);
        this.totalListAmountObj.discount.checked = Math.round(this.totalListAmountObj.discount.checked);
        this.totalListAmountObj.ticketFare.checked = Math.round(this.totalListAmountObj.ticketFare.checked);
        this.totalListAmountObj.hiddenAmount1.checked = Math.round(this.totalListAmountObj.hiddenAmount1.checked);
        this.totalListAmountObj.hiddenAmount2.checked = Math.round(this.totalListAmountObj.hiddenAmount2.checked);

        this.totalListAmountObj.creditAmount.unchecked = Math.round(this.totalListAmountObj.creditAmount.unchecked);
        this.totalListAmountObj.serviceCharges.unchecked = Math.round(this.totalListAmountObj.serviceCharges.unchecked);
        this.totalListAmountObj.grandTotal.unchecked = Math.round(this.totalListAmountObj.grandTotal.unchecked);
        this.totalListAmountObj.serviceTaxPercent.unchecked = Math.round(this.totalListAmountObj.serviceTaxPercent.unchecked);
        this.totalListAmountObj.serviceTaxAmount.unchecked = Math.round(this.totalListAmountObj.serviceTaxAmount.unchecked);
        this.totalListAmountObj.paymentReceived.unchecked = Math.round(this.totalListAmountObj.paymentReceived.unchecked);
        this.totalListAmountObj.pendingAmount.unchecked = Math.round(this.totalListAmountObj.pendingAmount.unchecked);
        this.totalListAmountObj.cgstval.unchecked = Math.round(this.totalListAmountObj.cgstval.unchecked);
        this.totalListAmountObj.sgstval.unchecked = Math.round(this.totalListAmountObj.sgstval.unchecked);
        this.totalListAmountObj.igstval.unchecked = Math.round(this.totalListAmountObj.igstval.unchecked);
        this.totalListAmountObj.discount.unchecked = Math.round(this.totalListAmountObj.discount.unchecked);
        this.totalListAmountObj.ticketFare.unchecked = Math.round(this.totalListAmountObj.ticketFare.unchecked);
        this.totalListAmountObj.hiddenAmount1.unchecked = Math.round(this.totalListAmountObj.hiddenAmount1.unchecked);
        this.totalListAmountObj.hiddenAmount2.unchecked = Math.round(this.totalListAmountObj.hiddenAmount2.unchecked);

        this.totalListAmountObj.advancePayment.total = Math.round(this.totalListAmountObj.advancePayment.total);
        this.totalListAmountObj.ticketFare.total = Math.round(this.totalListAmountObj.ticketFare.total);
        this.totalListAmountObj.serviceCharges.total = Math.round(this.totalListAmountObj.serviceCharges.total);
        this.totalListAmountObj.cgstval.total = Math.round(this.totalListAmountObj.cgstval.total);
        this.totalListAmountObj.sgstval.total = Math.round(this.totalListAmountObj.sgstval.total);
        this.totalListAmountObj.igstval.total = Math.round(this.totalListAmountObj.igstval.total);
        this.totalListAmountObj.serviceTaxAmount.total = Math.round(this.totalListAmountObj.serviceTaxAmount.total);
        this.totalListAmountObj.grandTotal.total = Math.round(this.totalListAmountObj.grandTotal.total);
        this.totalListAmountObj.paymentReceived.total = Math.round(this.totalListAmountObj.paymentReceived.total);
        this.totalListAmountObj.pendingAmount.total = Math.round(this.totalListAmountObj.pendingAmount.total);
        this.totalListAmountObj.creditAmount.total = Math.round(this.totalListAmountObj.creditAmount.total);
        this.totalListAmountObj.discount.total = Math.round(this.totalListAmountObj.discount.total);
        this.totalListAmountObj.hiddenAmount1.total = Math.round(this.totalListAmountObj.hiddenAmount1.total);
        this.totalListAmountObj.hiddenAmount2.total = Math.round(this.totalListAmountObj.hiddenAmount2.total);
    }

    public setIntervalVal;
    public jsonListDataAdvancedTemp = [];

    public jsonListDataAdvancedFunc(action=''){
        setTimeout(()=>{
            this.dynamicJsonListDataAdvancedFunc('full');
        },500);
    }

}






