import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';
import { parse } from 'querystring';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  status;
  dt = new Date();
  dd = 0;
  mm = 0;
  yy = 0;
  dashboardGraphData;
  //dashboardGraph = {};
  dynamicYear;
  allpagetitle='';
  bookingTypeList;
  headerSection;
  graph1Total=0;
  graph4Total=0;
  graph5Total=0;
  graph6Total=0;
  graph7Total=0;
  graph8Total=0;
  graph3RecTotal=0;
  graph3PenTotal=0;
  specialClientFunc:any;
  specialClient:any;
 
  dashboardGraph = {
    "bookingType":"",
    "dateList":[],
    "dateFrm" : 0,
    "dateTo" : 0,
    "monthList" : [],
    "monthFrm" : 0,
    "monthTo" : 0,
    "yearList" : [],
    "yearFrm" : 0,
    "yearTo" : 0
  }

  constructor(private router:Router, private http:HttpClient, private Services:CommonService) { }

  ngOnInit() {
    this.Services.checkValidURL('dashboard');
    this.headerSection = true;

    this.dd = this.dt.getDate();
    this.mm = this.dt.getMonth();
    this.yy = this.dt.getFullYear();
    
    this.dynamicYear = function(){
      var yr = [];
      for(var y1=2016; y1<=(this.yy+1); y1++){
        yr.push(y1);
      }
      return yr;
    } 

    this.dashboardGraph = {
      bookingType:"",
      dateList : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      dateFrm : 1,
      dateTo : this.dd,
      monthList : [1,2,3,4,5,6,7,8,9,10,11,12],
      monthFrm : this.mm,
      monthTo : (this.mm+1),
      yearList : this.dynamicYear(),
      yearFrm : this.yy,
      yearTo : this.yy,
    }
    this.specialClient = this.Services.specialClientObj();

    //>>>this.Services.viewOtherDetails();
    
    //let obj = {companyId : localStorage._bookingCID, companyUNM : localStorage._bookingUNM }
    //this.http.post('/user/viewOtherDetails',obj).subscribe(res =>{
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      //success
      let data = res;
      this.specialClient = this.Services.specialClientFunc(data[0].specialClient, this.Services.specialClientObj());//Special Settings for the client
      this.allpagetitle = localStorage._AllPageTitle = data[0].AllPageTitle;
      //this.bookingTypeList = data[0].bookingType;
      //this.bookingTypeList = data[0].bookingType;
      this.bookingTypeList = JSON.parse(localStorage._dynamicBTypeList);
      
      let tempDashboardGraph;
      tempDashboardGraph = this.dashboardGraph;
      tempDashboardGraph.bookingType = this.bookingTypeList[0].bookingName;
      this.dashboardGraph = tempDashboardGraph; 
      
      setTimeout(()=>{
        this.updateDashboard('graphs');
      },100);
    },
    err => {
      //error
      console.log(err);
      //return err;
    });

  }

  public getUniqueData = function(collection, keyname) {
    var output = [], keys = [];
    /*angular.forEach(collection, function(item) {
        var key = eval('item.'+keyname);
        if(keys.indexOf(key) === -1) {
            keys.push(key);
            output.push(item);
        }
    });*/
    for(var i=0; i<collection.length; i++){
      var key = eval('collection['+i+'].'+keyname);
      if(keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(collection[i]);
      }
    }
    return keys;
  };

  public ngGraph1 = true;
  public ngGraph2 = true;
  public ngGraph3 = true;
  public ngGraph4 = true;
  public ngGraph5 = true;
  public ngGraph6 = true;
  public ngGraph7 = true;
  public ngGraph8 = true;
  
  public getDashboardData = function(myObj, graphName, serviceType='BK'){
    $('.panel-body .graph').addClass('loader1');
    this.ngGraph1 = this.ngGraph2 = this.ngGraph3 = this.ngGraph4 = this.ngGraph5 = this.ngGraph6 = this.ngGraph7 = this.ngGraph8 = true;

    let filterDataObj=JSON.parse(myObj);

    let dashboardJsonData1 = {
      searchType:'filter',
      searchBy:filterDataObj,
      searchFor:graphName
    };

    let data = {dashboardJsonData2:dashboardJsonData1, companyId:localStorage._bookingCID, companyUNM:localStorage._bookingUNM}
    if(serviceType == 'oneTime'){
      this.Services.dashboardBK(data).subscribe(res =>{
      //this.http.post('/user/dashboardBK',this.Services.convertToJson(data)).subscribe(res => {

          let data = res;
          if(data.length == 0 ){
            this.ngGraph1 = this.ngGraph2 = this.ngGraph3 = this.ngGraph4 = this.ngGraph5 = this.ngGraph6 = this.ngGraph7 = this.ngGraph8 = false;
            alert('No Data Found');
          }
          else{
            this.dashboardGraphData = data.graphData;
            setTimeout(()=>{
              
              if( this.dashboardGraph.bookingType == 'Expense' ){
                this.graph8Func1();

                this.ngGraph1 = false;
                this.ngGraph2 = false;
                this.ngGraph3 = false;
                this.ngGraph4 = false;
                this.ngGraph5 = false;
                this.ngGraph6 = false;
                this.ngGraph7 = false;
                this.ngGraph8 = true;
              }
              else{
                this.graph1Func1();
                this.graph3Func1();
                this.graph4Func1();
                this.graph5Func1();//ERROR-Fixed
                this.graph6Func1();//ERROR
                this.graph7Func1();

                this.ngGraph1 = true;
                this.ngGraph2 = true;
                this.ngGraph3 = true;
                this.ngGraph4 = true;
                this.ngGraph5 = true;
                this.ngGraph6 = true;
                this.ngGraph7 = true;
                this.ngGraph8 = false;
              }
              
            },100);
  
            $('.panel-body .graph').animate({'border':0},500,function(){
              $(this).removeClass('loader1');
            });
          }
      },
      // Errors will call this callback instead:
      err => {
        this.ngGraph1 = this.ngGraph2 = this.ngGraph3 = this.ngGraph4 = this.ngGraph5 = this.ngGraph6 = this.ngGraph7 = this.ngGraph8 = false;
        alert('No Data Found');
      });
    
    }
    
  }

  public getGraphDate = function(){
    var frmY = this.dashboardGraph.yearFrm;
    var frmM = this.dashboardGraph.monthFrm;
    var frmD = this.dashboardGraph.dateFrm;
    var toY = this.dashboardGraph.yearTo;
    var toM = this.dashboardGraph.monthTo;
    var toD = this.dashboardGraph.dateTo;
    var f = (new Date(frmM+'/'+frmD+'/'+frmY)).getTime();
    var t = (new Date(toM+'/'+toD+'/'+toY)).getTime();
    var bt = this.dashboardGraph.bookingType;

    if(bt == 'All'){bt = ''}
    else{bt = '"bookingType":"'+bt+'" ,'}
    var returnData = bt+'"invoiceFullDateTP":{"$gte" : '+f+', "$lte" : '+t+'}';

    return returnData;
  }

  //Graph 0 = All In One
  public graph0Func1 = function(){
    var v1 = this.getGraphDate();
    var vFinal = '{'+v1+'}';
    this.getDashboardData( vFinal, 'graph0', 'oneTime');
  }
  
  //Graph 1 = Outstanding amount of all clients in 1 graph, with a Dropdown of select year
  public graph1Func1 = function(){
    var v1 = '"paymentDetails.paymentStatus":"Pending"';
    var data1 = [];
    for(var i=0; i<this.dashboardGraphData.length; i++){
      if( this.dashboardGraphData[i].paymentDetails.paymentStatus == 'Pending' ){
        data1.push(this.dashboardGraphData[i]);
      }
    }

    if(data1.length == 0){
      setTimeout(()=>{
        //this.ngGraph1 = false;
        this.graph1Func2([]);
      },100);
    }
    else{
      this.ngGraph1 = true;
      this.graph1Func2(data1);
    }
  }
  
  public graph1Func2 = function(data){
    let clientArray = [];
    let clientAmtArray = [];
    let clientFinalArray = [];
    
    for(var i=0; i<data.length; i++){
      var c = data[i].client;
      if( clientArray.indexOf(c) == -1 ){
        clientArray.push(c);
        clientAmtArray.push(0);
      }
    }
    for(var j=0; j<data.length; j++){
      var a = parseInt(data[j].paymentDetails.pendingAmount);
      var c = data[j].client;
      var i = clientArray.indexOf(c);
      var o = parseInt(clientAmtArray[i]);
      if(isNaN(a)){a = 0}
      if(isNaN(o)){o = 0}
      clientAmtArray[i] = o-(-a);
    }
    //console.log(clientAmtArray);
    for(var k=0; k<clientArray.length; k++){
      var k1 = {name:clientArray[k], y:clientAmtArray[k]};
      clientFinalArray.push(k1);
    }
    var str1 = clientAmtArray.join('+');
    //console.log( 'eval(str1) >>>>>>', eval(str1) );
    if(eval(str1) != undefined){
      this.graph1Total = (eval(str1)).toLocaleString('en-IN');
    }
    this.graph('#dbGraph1', clientFinalArray, {name:'Client',y:'Rs.'});
  }
  //this.graph1Func1();
  
  //Graph 3 = Total service charges - from all the client in selected Month (only payment received and not outstanding amt)
  public graph3Func1 = function(){
    var v1 = '"paymentDetails.paymentStatus":"Received"';
    var data3_R = [];
    var data3_P = [];
    for(var i=0; i<this.dashboardGraphData.length; i++){
      if( this.dashboardGraphData[i].paymentDetails.paymentStatus == 'Received' ){
        data3_R.push(this.dashboardGraphData[i]);
      }
      else if( this.dashboardGraphData[i].paymentDetails.paymentStatus == 'Pending' ){
        data3_P.push(this.dashboardGraphData[i]);
      }
    }
    if(data3_R.length == 0 && data3_P.length == 0){
      setTimeout(()=>{
        //this.ngGraph3 = false;
        this.graph3Func2([], []);
      },100);
    }
    else{
      this.ngGraph3 = true;
      this.graph3Func2(data3_R, data3_P);
    }
  }
  public graph3Func2 = function(data, data2){
    let clientArray = [];
    let clientRecAmtArray = [];
    let clientPenAmtArray = [];
    let clientFinalArray = [];
    for(var i=0; i<data.length; i++){
      var c = data[i].client;
      if( clientArray.indexOf(c) == -1 ){
        clientArray.push(c);
        clientRecAmtArray.push(0);
        clientPenAmtArray.push(0);
      }
    }
    for(var i=0; i<data2.length; i++){
      var c = data2[i].client;
      if( clientArray.indexOf(c) == -1 ){
        clientArray.push(c);
        clientRecAmtArray.push(0);
        clientPenAmtArray.push(0);
      }
    }
    for(var j=0; j<data.length; j++){
      var a = parseInt(data[j].chargesDetails.service);
      if( data[j].chargesDetails.hiddenServiceCharges != undefined ){ a += parseInt(data[j].chargesDetails.hiddenServiceCharges) }
      var c = data[j].client;
      var i = clientArray.indexOf(c);
      var o = parseInt(clientRecAmtArray[i]);
      if(isNaN(a)){a = 0}
      if(isNaN(o)){o = 0}
      clientRecAmtArray[i] = o-(-a);
    }
    //console.log(data2);
    for(var j=0; j<data2.length; j++){
      var a = parseInt(data2[j].chargesDetails.service);
      if( data2[j].chargesDetails.hiddenServiceCharges != undefined ){ a += parseInt(data2[j].chargesDetails.hiddenServiceCharges) }
      var c = data2[j].client;
      var i = clientArray.indexOf(c);
      var o = parseInt(clientPenAmtArray[i]);
      if(isNaN(a)){a = 0}
      if(isNaN(o)){o = 0}
      clientPenAmtArray[i] = o-(-a);
    }
    for(var k=0; k<clientArray.length; k++){
      var k1 = {name:clientArray[k], y:clientRecAmtArray[k]};
      clientFinalArray.push(k1);
    }
    var clientRecAmtArrayJoin = clientRecAmtArray.join('+')
    var clientPenAmtArrayJoin = clientPenAmtArray.join('+')

    if( eval(clientRecAmtArrayJoin) != undefined){
      this.graph3RecTotal = (eval(clientRecAmtArrayJoin)).toLocaleString('en-IN');
    }
    if( eval(clientPenAmtArrayJoin) != undefined){
      this.graph3PenTotal = (eval(clientPenAmtArrayJoin)).toLocaleString('en-IN');
    }

    this.graph('#dbGraph3', [clientArray,clientPenAmtArray,clientRecAmtArray], {name:'Client',y:'Rs.'});
  }
  
  //Graph 4 = Total Service-tax graph
  public graph4Func1 = function(){
    this.graph4Func2(this.dashboardGraphData);
  }
  public graph4Func2 = function(data){
    var taxArray = ["Service Tax","SGST","CGST","IGST"]
    var taxRecAmtArray = [0,0,0,0];
    var taxPenAmtArray = [0,0,0,0];

    var taxArrayFinal = []
    var taxRecAmtArrayFinal = [];
    var taxPenAmtArrayFinal = [];
    
    for(var i=0; i<data.length; i++){
      var c = data[i].paymentDetails.paymentStatus;
      var sc = parseInt(data[i].chargesDetails.service);
      //cannnot add "hiddenServiceCharges" as it not a part of Grand Total
      
      var st = parseInt(data[i].chargesDetails.serviceTax);
      var stVal = sc*st/100;
      if(st == 0 || isNaN(st)){ stVal = 0 }
      
      var sgst = parseInt(data[i].chargesDetails.sgstPer);
      var sgstVal = sc*sgst/100;
      if(sgst == 0 || isNaN(sgst)){ sgstVal = 0 }

      var cgst = parseInt(data[i].chargesDetails.cgstPer);
      var cgstVal = sc*cgst/100;
      if(cgst == 0 || isNaN(cgst)){ cgstVal = 0 }
      
      var igst = parseInt(data[i].chargesDetails.igstPer);
      var igstVal = sc*igst/100;
      if(igst == 0 || isNaN(igst)){ igstVal = 0 }

      if( c == 'Pending' ){
        taxPenAmtArray[0] += stVal;
        taxPenAmtArray[1] += sgstVal;
        taxPenAmtArray[2] += cgstVal;
        taxPenAmtArray[3] += igstVal;
      }
      else if( c == 'Received' ){
        taxRecAmtArray[0] += stVal;
        taxRecAmtArray[1] += sgstVal;
        taxRecAmtArray[2] += cgstVal;
        taxRecAmtArray[3] += igstVal;
      }
    }

    var taxArrayFinal = []
    var taxRecAmtArrayFinal = [];
    var taxPenAmtArrayFinal = [];

    for(let arr=0; arr<4; arr++){
      if( !(taxRecAmtArray[arr] == 0 && taxPenAmtArray[arr] == 0) ){
        taxArrayFinal.push( taxArray[arr] );
        taxRecAmtArrayFinal.push( taxRecAmtArray[arr] );
        taxPenAmtArrayFinal.push( taxPenAmtArray[arr] );
      }
    }
    
    if(taxArrayFinal.length == 0){
      setTimeout(()=>{
        //this.ngGraph4 = false;
        this.graph4RecTotal = 0;
        this.graph4PenTotal = 0;
        this.graph('#dbGraph4', [[],[],[]], {name:'Client',y:'Rs.'});
      },100);
    }
    else{
      this.ngGraph4 = true;
      var taxRecAmtArrayJoin = taxRecAmtArray.join('+');
      var taxPenAmtArrayJoin = taxPenAmtArray.join('+');
      if( eval(taxRecAmtArrayJoin) != undefined){
        this.graph4RecTotal = (eval(taxRecAmtArrayJoin)).toLocaleString('en-IN');
      }
      if( eval(taxPenAmtArrayJoin) != undefined){
        this.graph4PenTotal = (eval(taxPenAmtArrayJoin)).toLocaleString('en-IN');
      }
      this.graph('#dbGraph4', [taxArrayFinal,taxPenAmtArrayFinal,taxRecAmtArrayFinal], {name:'Client',y:'Rs.'});
    }

  }
  //this.graph4Func1();

  //Graph 5 = Credit note graph with pending & given status
  public graph5Func1 = function(){
    var v1 = '"paymentDetails.creditNoteFlag":true';
    var data5 = [];
    for(var i=0; i<this.dashboardGraphData.length; i++){
      if( this.dashboardGraphData[i].paymentDetails.creditNoteFlag == true ){
        data5.push(this.dashboardGraphData[i]);
      }
    }
    if(data5.length == 0){
      setTimeout(()=>{
        //this.ngGraph5 = false;
        this.graph5Func2([]);
      },100);
    }
    else{
      this.ngGraph5 = true;
      this.graph5Func2(data5);
    }
  }
  public graph5Func2 = function(data){
    var clientArrayPending = [];
    var clientArrayGiven = [];
    var clientAmtPending = 0;
    var clientAmtGiven = 0;
    var clientFinalArray = [];
    for(var i=0; i<data.length; i++){
      var c = data[i].paymentDetails.creditNotePaymentStatus;
      var val = parseInt(data[i].chargesDetails.creditAmount)*1;
      if( c == 'Pending' ){
        clientArrayPending.push(val);
        clientAmtPending += val;
      }
      else if( c == 'Given' ){
        clientArrayGiven.push(val);
        clientAmtGiven += val;
      }
    }

    clientFinalArray.push( {name:'Pending', y:clientAmtPending, len:clientArrayPending.length},{name:'Given', y:clientAmtGiven, len:clientArrayGiven.length} );
    this.graph5Total = 'Amt:'+(clientAmtPending+clientAmtGiven).toLocaleString('en-IN') +' / '+ 'Tickets:'+(clientArrayPending.length+clientArrayGiven.length);
    this.graph('#dbGraph5', clientFinalArray, {name:'Credit Note',y:'Rs.',len:'Total Tickets'});

  }
  //this.graph5Func1();
  
  //Graph 6 = Credit note graph with pending & given status
  public graph6Func1 = function(){
    var v1 = [this.dashboardGraph.bookingType];
    if(v1[0] == 'All'){ v1 = ['Air','Bus','Train','Package']; }
    var vType = '';
    var data6_1 = this.getUniqueData(this.dashboardGraphData, 'agentDetails.agentType');
    var data6_2 = [];
    var data6_3 = 0;
    var data6_4 = 0;
    var data6_5 = [];

    for(var g=0; g<v1.length; g++){
      for(var h=0; h<data6_1.length; h++){
        data6_2.push([]);
        data6_3=0;
        data6_4=0;
        for(var i=0; i<this.dashboardGraphData.length; i++){
          if( this.dashboardGraphData[i].bookingType == v1[g] && this.dashboardGraphData[i].agentDetails.agentType == data6_1[h] ){
            var t = parseInt(this.dashboardGraphData[i].chargesDetails.ticketFare);
            var c = parseInt(this.dashboardGraphData[i].chargesDetails.catering);
            var p = parseInt(this.dashboardGraphData[i].chargesDetails.pg);
            if( isNaN(t) ){t=0}
            if( isNaN(c) ){c=0}
            if( isNaN(p) ){p=0}
            data6_2[h].push(this.dashboardGraphData[i]);
            data6_3 += parseInt(this.dashboardGraphData[i].chargesDetails.total);
            data6_4 += t+c+p;
            //console.log(i,' - ',t,c,p);
          }
        }
        if(v1.length > 1){ vType = ' ('+v1[g]+')'; }
        data6_5.push({"agentType":data6_1[h]+vType,"agentTypeCount":data6_2[h].length, "total":data6_3, "pg":data6_4 });
      }
    }

    if(data6_5.length == 0){
      setTimeout(()=>{
        //this.ngGraph6 = false;
      this.graph6Func2([]);
      },100);
    }
    else{
      this.ngGraph6 = true;
      //console.log('data6_5',data6_5);
      this.graph6Func2(data6_5);
    }
  }
  public graph6Func2 = function(data){
    var agentFinalArray = [];
    var agentFinalCnt = 0;
    for(var i=0; i<data.length; i++){
      var aTyp = data[i].agentType;
      var aCnt = parseInt(data[i].agentTypeCount);
      var aTot = parseInt(data[i].total);
      agentFinalArray.push( {name:aTyp, y:aCnt, total:aTot, pg:data[i].pg} );
      agentFinalCnt += aCnt; 
    }
    //console.log('agentFinalCnt',agentFinalCnt, agentFinalArray, data);

    this.graph6Total = agentFinalCnt;
    this.graph('#dbGraph6', agentFinalArray, {name:'Agent',y:'Tickets',total:'Total Amount',pg:'Agent Charges'});
  }
  //this.graph6Func1();

  //Graph 7 = Credit note graph with pending & given status
  public graph7Func1 = function(){
    var v1 = this.dashboardGraph.bookingType;
    var data7_1 = this.getUniqueData(this.dashboardGraphData, 'otherDetails.createdBy');
    var data7_2 = [];
    var data7_3 = [];

    for(var h=0; h<data7_1.length; h++){
      data7_2.push([]);
      for(var i=0; i<this.dashboardGraphData.length; i++){
        if( this.dashboardGraphData[i].otherDetails.createdBy == data7_1[h] ){
          data7_2[h].push(this.dashboardGraphData[i]);
        }
      }
      data7_3.push({"userN":data7_1[h],"userNCount":data7_2[h].length});
    }

    if(data7_3.length == 0){
      setTimeout(()=>{
        //this.ngGraph7 = false;
      this.graph7Func2([]);
      },100);
    }
    else{
      this.ngGraph7 = true;
      this.graph7Func2(data7_3);
    }
  }
  public graph7Func2 = function(data){
    var usersFinalArray = [];
    var usersFinalCnt = 0;
    for(var i=0; i<data.length; i++){
      var uTyp = data[i].userN;
      var uCnt = parseInt(data[i].userNCount);
      usersFinalArray.push( {name:uTyp, y:uCnt} );
      usersFinalCnt += uCnt; 
    }
    this.graph7Total = usersFinalCnt;
    this.graph('#dbGraph7', usersFinalArray, {name:'User',y:'Tickets'});
  }

  
  
  public graph8Func1 = function(){
    //var v1 = '"paymentDetails.paymentStatus":"Pending"';
    var data1 = [];
    for(var i=0; i<this.dashboardGraphData.length; i++){
      //if( this.dashboardGraphData[i].paymentDetails.paymentStatus == 'Pending' ){
        data1.push(this.dashboardGraphData[i]);
      //}
    }

    if(data1.length == 0){
      setTimeout(()=>{
        //this.ngGraph8 = false;
        this.graph8Func2([]);
      },100);
    }
    else{
      this.ngGraph8 = true;
      this.graph8Func2(data1);
    }
  }
  
  public graph8Func2 = function(data){
    let clientArray = [];
    let clientAmtArray = [];
    let clientFinalArray = [];
    
    for(var i=0; i<data.length; i++){
      var c = data[i].client;
      if( clientArray.indexOf(c) == -1 ){
        clientArray.push(c);
        clientAmtArray.push(0);
      }
    }
    for(var j=0; j<data.length; j++){
      var a = parseInt(data[j].paymentDetails.pendingAmount);
      var c = data[j].client;
      var i = clientArray.indexOf(c);
      var o = parseInt(clientAmtArray[i]);
      if(isNaN(a)){a = 0}
      if(isNaN(o)){o = 0}
      clientAmtArray[i] = o-(-a);
    }
    for(var k=0; k<clientArray.length; k++){
      var k1 = {name:clientArray[k], y:clientAmtArray[k]};
      clientFinalArray.push(k1);
    }
    var str1 = clientAmtArray.join('+');
    if(eval(str1) != undefined){
      this.graph8Total = (eval(str1)).toLocaleString('en-IN');
    }
    this.graph('#dbGraph8', clientFinalArray, {name:'Client', y:'Rs.'});
  }






  public updateDashboard = function(param){
    if(param == 'graphs'){
      var frmY = this.dashboardGraph.yearFrm;
      var frmM = this.dashboardGraph.monthFrm;
      var frmD = this.dashboardGraph.dateFrm;
      var toY = this.dashboardGraph.yearTo;
      var toM = this.dashboardGraph.monthTo;
      var toD = this.dashboardGraph.dateTo;
      var f = (new Date(frmM+'/'+frmD+'/'+frmY)).getTime();
      var t = (new Date(toM+'/'+toD+'/'+toY)).getTime();
      if(f>t){ alert('Invalid selection of range'); return false }
      this.graph0Func1();
    
    }
  }

  //////////////////////////////////Graph Coding
  
  public graph = function(gId,gData,gTitle){

    var typ = 'column';
    if(gId == '#dbGraph5' || gId == '#dbGraph6' || gId == '#dbGraph7'){typ = 'pie'}

    var param1 ='';
    if(gId == '#dbGraph5'){
      param1 = '<br> '+gTitle.len+': {point.len}';
    }
    else if(gId == '#dbGraph6'){
      //param1 = '<br> '+gTitle.total+': {point.total} <br> '+gTitle.pg+': {point.pg}';
      param1 = '<br> '+gTitle.pg+': {point.pg}';
    }

    if( gId == '#dbGraph3' || gId == '#dbGraph4' ){
      $(gId).highcharts({
          chart: {
            type: typ,
            spacingBottom: 0,
            spacingTop: 10,
            spacingLeft: 0,
            spacingRight: 0,
            backgroundColor:'rgba(255, 255, 255, 0.001)'
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          exporting: { enabled: false },
          credits: {enabled: false },
          xAxis: {
            categories:gData[0]
          },
          yAxis: {
            gridLineWidth: 1,
            title: {
              text: ''
            },
            labels: {
              enabled: true
            },
          },
          legend: {
            enabled: true
          },
          plotOptions: {
            series: {
              borderWidth: 1,
              dataLabels: {
                enabled: true
              }
            }
          },
          tooltip: {
            //headerFormat: '<span style="font-size:11px">'+gTitle.name+' : </span>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span><br> '+gTitle.y+' {point.y}'+param1
          },
          series: [{
              name: 'Pending',
              data: gData[1]
              //,color: '#50B432'
          }, {
              name: 'Received',
              data: gData[2]
          }]
      });
      //Highcharts.setOptions({ colors: ['#50B432', '#ED561B', '#DDDF00'] });
    }
    else{
      $(gId).highcharts({
          chart: {
            type: typ,
            spacingBottom: 0,
            spacingTop: 10,
            spacingLeft: 0,
            spacingRight: 0,
            backgroundColor:'rgba(255, 255, 255, 0.001)'
          },
          title: {
            text: ''
          },
          subtitle: {
            text: ''
          },
          exporting: { enabled: false },
          credits: {enabled: false },
          xAxis: {
            gridLineWidth: 0,
            type: 'category',
            labels: {
              enabled: true
            },
          },
          yAxis: {
            gridLineWidth: 1,
            title: {
              text: ''
            },
            labels: {
              enabled: true
            },
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              borderWidth: 1,
              dataLabels: {
                enabled: true
              }
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size:12px">'+gTitle.name+' : </span>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span><br> '+gTitle.y+' : {point.y}'+param1
          },
          series: [
            {
              showInLegend: false, 
              name: ' ',
              colorByPoint: true,
              data: gData
            }
          ]
      });
    }
  }
  

}
