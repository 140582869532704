import { Injectable } from '@angular/core';

@Injectable()
export class BusService {

  constructor() { }

  private monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public autoFillContentFlag = false;
  public busTravels = ''; 
  public busName = '';
  public busTicketNo:any = '';
  public allTravelNameArray = ['Anand Travels', 'Vishal Travels', 'Sugama Tourists', 'Canara Pinto', 'Sheethal Travels', 'Seat Seller', 'Reshma bus', 'NEETA', 'VRL'];
  //public allTravelName = (this.allTravelNameArray).join(', ');

  public getTravelsName = function(src) {
    if (src.indexOf('ANAND TRAVELS') > 0 && src.indexOf('anandbus') > 0) {
        this.busTravels = 'anandbus';
        this.busName = this.allTravelNameArray[0];
    } else if (src.indexOf('(Vishal') > 0) {
        this.busTravels = 'vishalbus';
        this.busName = this.allTravelNameArray[1];
    } else if (src.indexOf('sugamatourists') > 0) {
        this.busTravels = 'sugamabus';
        this.busName = this.allTravelNameArray[2];
    } else if (src.indexOf('canarapinto') > 0) {
        this.busTravels = 'canarapintobus';
        this.busName = this.allTravelNameArray[3];
    } else if (src.indexOf('sheethaltravel') > 0) {
        this.busTravels = 'sheethaltravelbus';
        this.busName = this.allTravelNameArray[4];
    } else if (src.indexOf('SeatSeller') > 0) {
        this.busTravels = 'seatsellerbus';
        this.busName = this.getBusSrcData(src, 'Bus Operator', '(', 'str'); //'Seat Seller';
    } else if (src.indexOf('reshmabus') > 0) {
        this.busTravels = 'reshmabus';
        this.busName = this.allTravelNameArray[6];
    } else if (src.indexOf('NEETA TOURS') > 0) {
        this.busTravels = 'neetabus';
        this.busName = this.allTravelNameArray[7];
    } else if (src.indexOf('vrlbus.in') > 0) {
        this.busTravels = 'vrlbus';
        this.busName = this.allTravelNameArray[7];
    }
  }
  public getBusSrcData = function(src, frm, to, typ) {
        if (src == undefined) { this.autoFillContentFlag = true; return ''; }
        var a = src.split(frm)[1];
        if (a == undefined) { this.autoFillContentFlag = true; return ''; }
        if (to != '') {
            a = a.split(to)[0];
            if (a == undefined) { this.autoFillContentFlag = true; return ''; }
        }
        if (typ == 'num') { return parseInt(a); } else if (typ == 'str') {
            return a.trim();
        } else if (typ == 'numDecimal') { return a.replace(/ /g, '') * 1 }
  }

  public autoFilldate_1 = function(src, param) {
      var dt = (this.getBusSrcData(src, 'Date', 'Dep', 'str')).split('/');
      if (param == 'dd') { return parseInt(dt[0]) + '' } else if (param == 'mm') { return parseInt(dt[1]) + '' } else if (param == 'yy') { return parseInt(dt[2]) + '' }
  }
  public getSrcTotalData_1 = function(src) {
      var tt1 = ((this.getBusSrcData(src, 'Fare', '(', 'str')).replace(/,/gi, ''));
      var tt2 = '';
      for (var i = 0; i < tt1.length; i++) {
          if (tt1.charAt(i) >= 0 || tt1.charAt(i) == '.') {
              tt2 += tt1.charAt(i);
          }
      }
      return parseInt(tt2);
  }


  public autoFilldate_2 = function(src, param) {
      var dt = ((this.getBusSrcData(src, 'Journey', 'Received', 'str')).split(':')[1]).split('/');
      if (param == 'dd') { return parseInt(dt[0]) + '' } else if (param == 'mm') { return parseInt(dt[1]) + '' } else if (param == 'yy') { return parseInt(dt[2]) + '' }
  }
  public getSrcTotalData_2 = function(src) {
      var tt1 = (this.getBusSrcData(src, 'Amount', 'Boarding', 'str')).replace(/,/gi, '').replace(/:/gi, '').replace('\x0A', '');
      if (isNaN(tt1 * 1)) {
          var tt2 = '';
          for (var i = 1; i < tt1.length; i++) {
              tt2 += tt1.charAt(i);
          }
          tt1 = tt2;
      }
      var tt3 = (tt1.replace('?', '')) * 1;

      return tt3;
  }


  public autoFilldate_3 = function(src, param) {
      var dt = this.getBusSrcData(src, ' on ', 'https:', 'str');
      var d1 = parseInt(dt.charAt(0) + dt.charAt(1)) + '';
      var m1 = dt.charAt(3) + dt.charAt(4) + dt.charAt(5) + '';
      let m2 = (parseInt(this.monthArr.indexOf(m1)) + 1) + '';
      var y1 = dt.charAt(7) + dt.charAt(8) + dt.charAt(9) + dt.charAt(10) + '';

      if (param == 'dd') { return d1 } else if (param == 'mm') { return m2 } else if (param == 'yy') { return y1 }
  }
  public getSrcTotalData_3 = function(src) {
      var tt1 = this.getBusSrcData(src, 'Dropping Point Address', 'Location', 'str');
      return tt1.replace(/,/gi, '');
  }


  public autoFilldate_4 = function(src, param) {
      var dt = this.getBusSrcData(src, 'Booking Date', 'Journey', 'str').replace(/ /, '');
      var d1 = dt.split('-');
      if (param == 'dd') { return parseInt(d1[0]) + '' } else if (param == 'mm') { return parseInt(d1[1]) + '' } else if (param == 'yy') { return parseInt(d1[2]) + '' }
  }
  public getSrcTotalData_4 = function(src) {
      var tt1 = (this.getBusSrcData(src, 'Total Fare (Rs.)', 'Total Seats', 'str'));
      return tt1.replace(/,/, '');
  }


  public getSrcTotalData_5 = function(src) {
      var tt1 = (this.getBusSrcData(src, 'BOOKING AMOUNT', 'TERMS AND', 'str')).split('=')[1];
      return tt1.replace(/,/, '');
  }


  public autoFillHtml = function(src, myObj){
        this.getTravelsName(src);

        if (this.busTravels == 'anandbus' || this.busTravels == 'canarapintobus' || this.busTravels == 'sheethaltravelbus' || this.busTravels == 'sugamabus' || this.busTravels == 'reshmabus') {
            //console.log('anandbus + '+'canarapintobus + '+'sheethaltravelbus + '+'sugamabus + '+'reshmabus');
            
            this.busTicketNo = this.getBusSrcData(src, 'Ticket No:', 'Ticket Details', 'str');

            myObj.busCoachType = this.getBusSrcData(src, 'on service ', 'Seat', 'str');
            myObj.busDepTime = this.getBusSrcData(src, 'Time', 'Report', 'str').replace('-', '');
            myObj.busBoardingAddress = this.getBusSrcData(src, '@', 'Booked', 'str');

            myObj.journeyDateDD = this.autoFilldate_1(src, 'dd');
            myObj.journeyDateMM = this.autoFilldate_1(src, 'mm');
            myObj.journeyDateYY = this.autoFilldate_1(src, 'yy');
            
            var ft1 = (this.getBusSrcData(src, 'Ticket Details for', 'on service', 'str'));
            var ft2 = ft1.split('from');
            var ft3 = ft2[0].replace(/ms /ig, "").replace(/mr /ig, "");
            var ft4 = ft2[1].split('to');
            var cnt1 = parseInt(this.getBusSrcData(src, 'Numbers', 'Seats)', 'str').split('(')[1]);
            var cnt2 = (this.getBusSrcData(src, 'Seats)', 'Journey', 'str')).split("\x0A");

            myObj.from = ft4[0];
            myObj.to = ft4[1];
            myObj.adult = cnt1;
            myObj.total = cnt1;

            for (var i = 0; i < cnt1; i++) {
                var cnt3 = cnt2[i].split(' ')[0];
                eval('myObj.pd' + i + '={}');
                eval('myObj.pd' + i + '.name = ft3');
                eval('myObj.pd' + i + '.coachNoSeat = cnt3');
            }

            myObj.chargesDetails.ticketFare = this.getSrcTotalData_1(src);

        } else if (this.busTravels == 'vishalbus') {
          
            this.busTicketNo = this.getBusSrcData(src, 'Ticket No', 'Journey', 'str').split(':')[1].replace(/ /, '');

            myObj.busCoachType = this.getBusSrcData(src, 'Service No', 'Ticket No', 'str').split('(')[1].split(')')[0];
            myObj.busDepTime = this.getBusSrcData(src, 'Departure', 'Amount', 'str').replace(':', '');
            myObj.busBoardingAddress = this.getBusSrcData(src, 'Boarding at', 'For Vishal Travels', 'str').replace(':', '');

            myObj.journeyDateDD = this.autoFilldate_2(src, 'dd');
            myObj.journeyDateMM = this.autoFilldate_2(src, 'mm');
            myObj.journeyDateYY = this.autoFilldate_2(src, 'yy');
            
            
            var ft1 = (this.getBusSrcData(src, 'Received From', 'as per', 'str')).replace(':', '').replace(/ms /ig, '').replace(/mr /ig, '');
            var ft2 = this.getBusSrcData(src, 'Seats', 'Report At', 'str').replace(' : ', '').replace(':', '').split('-');
            var ft3 = ft2[1].split(',');
            var cnt1 = parseInt(ft2[0]);
            var frmTo = (this.getBusSrcData(src, 'Received From', 'Seats', 'str')).split('From')[1].replace(/:/, '').replace(/\t/gi, ' ').replace(/  /gi, ' ').replace(':', '').split(' To ');
            myObj.from = frmTo[0];
            myObj.to = frmTo[1];
            myObj.adult = cnt1;
            myObj.total = cnt1;
            for (var i = 0; i < cnt1; i++) {
                eval('myObj.pd' + i + '={}');
                eval('myObj.pd' + i + '.name = ft1');
                eval('myObj.pd' + i + '.coachNoSeat = (ft3[' + i + ']).replace(" ","")');
            }

            myObj.chargesDetails.ticketFare = this.getSrcTotalData_2(src);

        } else if (this.busTravels == 'seatsellerbus') {
            this.busTicketNo = this.getBusSrcData(src, 'Ticket #', 'Seat', 'str').replace(/ /gi, '');

            //var depTm1 = this.getBusSrcData(src, 'Point Address', 'Location', 'str').replace(/ /gi,'#').replace(/\t/gi,'___');//Non A/C Sleeper (2+1)  05:15 PM  05:30 PM  
            var depTm1 = this.getBusSrcData(src, 'Point Address', 'Location', 'str').split('\t'); //Detect TAB //Non A/C Sleeper (2+1)  05:15 PM  05:30 PM  
            myObj.busDepTime = depTm1[2];
            myObj.busCoachType = depTm1[0];
            myObj.busBoardingAddress = (this.getBusSrcData(src, 'Location', 'Operator Fare', 'str').split('Landmark')[0]).replace(':', '');

            var ft1 = (src).replace(/\x0A/gi, '').split('Bus Operator')[0]; ///detect enter
            var ft2 = ft1.replace(/\t/g, ' ').replace(/  /g, ' ').split(' ');
            if (ft2[0] == '') { ft2.splice(0, 1); }
            var ft3 = ft2[ft2.length - 1];
            var ft4 = ft3.split('-');

            let cnt1 = this.getBusSrcData(src, 'Contact Number', 'Bus Type', 'str');
            let cnt2 = cnt1.split('\x0A');
            let cnt3 = cnt2.length;
            let cnt4 = cnt2[0].replace(/\t/g, ' ').replace(/  /g, ' ').split(' ');
            myObj.contactNo1 = cnt4[cnt4.length - 1];

            myObj.from = ft2[0];
            myObj.to = ft2[ft2.length - 2];
            myObj.adult = cnt3;
            myObj.total = cnt3;

            for (var i = 0; i < cnt3; i++) {
                var cnt5 = cnt2[i].replace(/\t/g, ' ').replace(/  /g, ' ').split(' ');
                eval('myObj.pd' + i + '={}');
                eval('myObj.pd' + i + '.name = cnt5[0]');
                eval('myObj.pd' + i + '.coachNoSeat = cnt5[cnt5.length-2]');
            }
            myObj.journeyDateDD = parseInt(ft4[0]) + '';
            myObj.journeyDateMM = (parseInt(this.monthArr.indexOf(ft4[1])) + 1) + '';
            myObj.journeyDateYY = parseInt(ft4[2]) + '';

            myObj.chargesDetails.ticketFare = this.getSrcTotalData_3(src);

        } else if (this.busTravels == 'neetabus') {
            
            this.busTicketNo = this.getBusSrcData(src, 'PNR No.#', '.', 'str').replace(/ /, '');

            myObj.busDepTime = this.getBusSrcData(src, 'Reporting Time', 'Journey', 'str');
            myObj.busBoardingAddress = this.getBusSrcData(src, 'PickUp Point', 'Landmark', 'str');

            myObj.journeyDateDD = this.autoFilldate_4(src, 'dd');
            myObj.journeyDateMM = this.autoFilldate_4(src, 'mm');
            myObj.journeyDateYY = this.autoFilldate_4(src, 'yy');
            
            
            var nm1 = this.getBusSrcData(src, 'Passenger Name', 'PNR', 'str');
            var nm2 = nm1.replace(/\t/gi, ' ').replace(/  /gi, ' ').replace(/-/gi, ' ').split(' ');
            var cnt = (this.getBusSrcData(src, 'Seats', 'Kindly', 'str'));
            var seatNo = (this.getBusSrcData(src, 'No(s)', 'Sleeper', 'str')).replace(/ /gi, '').split(',');
            myObj.adult = cnt;
            myObj.total = cnt;
            myObj.contactNo1 = nm2[nm2.length - 1];

            myObj.busCoachType = (this.getBusSrcData(src, 'Route Name', 'Booking', 'str')).split('-')[1];
            myObj.from = (this.getBusSrcData(src, 'Route Name', 'TO', 'str'));
            myObj.to = (this.getBusSrcData(src, 'Route Name', '-', 'str')).split('TO')[1];

            for (var i = 0; i < cnt; i++) {
                eval('myObj.pd' + i + '={}');
                eval('myObj.pd' + i + '.name = nm2[0]');
                eval('myObj.pd' + i + '.coachNoSeat = seatNo[' + i + ']');
            }

            myObj.chargesDetails.ticketFare = this.getSrcTotalData_4(src);

        } else if (this.busTravels == 'vrlbus') {

            var d1 = ((this.getBusSrcData(src, 'COACH', 'REPORTING', 'str')).split("\x0A")[0]);
            var d2 = d1.split('\t');
            var d3 = d2[2].split(' ');
            var d4 = d3[0].split('-');

            myObj.from = d2[0];
            myObj.to = d2[1];
            myObj.busDepTime = d3[1] + ' ' + d3[2];
            myObj.busCoachType = d2[3];

            myObj.journeyDateDD = parseInt(d4[0]) + '';
            myObj.journeyDateMM = parseInt(d4[1]) + '';
            myObj.journeyDateYY = d4[2];

            let cnt1 = (this.getBusSrcData(src, 'FARE', 'TOTAL', 'str')).split("\x0A");
            let cnt2 = cnt1.length;

            myObj.adult = cnt2;
            myObj.total = cnt2;
            myObj.contactNo1 = cnt1[0].split('\t')[3];

            for (var i = 0; i < cnt2; i++) {
                var cnt3 = cnt1[i].replace(/\t/gi, ' ').replace(/ /gi, ' ');
                cnt3 = cnt3.replace(/\t/gi, ' ').replace(/  /gi, ' ').replace(/ /gi, ' ');
                var cnt4 = cnt3.split(' ');
                eval('myObj.pd' + i + '={}');
                eval('myObj.pd' + i + '.name = cnt4[2]');
                eval('myObj.pd' + i + '.coachNoSeat = cnt4[1]');
            }

            this.busTicketNo = this.getBusSrcData(src, 'PAN NO', 'ST REG', 'str').replace(':', '').replace(/ /g, '');

            myObj.busBoardingAddress = this.getBusSrcData(src, 'BOARDING ADDRESS', 'DROPING POINT', 'str');
            myObj.chargesDetails.ticketFare = this.getSrcTotalData_5(src);
        }

        console.log('this.busName---->>'+this.busName);

        //myObj.this.busTicketNo = this.busTicketNo;
        myObj.pnrNo = this.busTicketNo;
        myObj.busType = myObj.busName = this.busName;
        myObj.chargesDetails.service = 0;
        myObj.chargesDetails.total = myObj.chargesDetails.ticketFare;
        myObj.chargesDetails.serviceTax = 0;
        myObj.chargesDetails.cgstPer = 0;
        myObj.chargesDetails.sgstPer = 0;
        myObj.chargesDetails.cgstVal = 0;
        myObj.chargesDetails.sgstVal = 0;
        myObj.chargesDetails.grandTotal = myObj.chargesDetails.ticketFare;
        myObj.paymentDetails.pendingAmount = myObj.chargesDetails.ticketFare;
        myObj.paymentDetails.paymentReceived = 0;

        /*if (this.busTravels == 'vrlbus' || this.busTravels == 'neetabus' || this.busTravels == 'seatsellerbus') {} //it is commented as it was showing error in VRL
        else {
            this.addAction('updateCharger');
        }*/

        return myObj;
  }

}
