import { Component, Input } from '@angular/core';
import { CommonService } from './common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tour of Heroes';
  public myGlobalObject={
    compId:'',
    usern:'',
    usert:'',
    adminLogin:false,
    AllPageTitle:''
  }

  constructor( private Services:CommonService){
    this.Services._setGlobalObjectFunc.subscribe(res=>{
      //This service is not required can be removed....
        /*
          if(res.dataType == 'loginData'){
            this.myGlobalObject.compId = localStorage._bookingCID = res.data.bookingCID2;
            this.myGlobalObject.usern = localStorage._bookingUNM = res.data.bookingUNM2;
            this.myGlobalObject.usert = localStorage._bookingUT = res.data.bookingUT2;
            this.myGlobalObject.adminLogin = localStorage._bookingAL = false;
            if(res.data.usert == 'administrator'){
              this.myGlobalObject.adminLogin = localStorage._bookingAL = true;
            }
          }
          if(res.dataType == 'viewOtherDetails'){
            this.myGlobalObject.AllPageTitle = res.data.AllPageTitle;
          }
        */
    });
  }
}

export class Hero {
  id: number;
  name: string;
}


