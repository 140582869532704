import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {
  filterFormData:any = {};
  requestViewData:any = {};
  viewOtherDetails = '';
  showClientTo = true;
  showListFlag = false;
  bookmarkData = 'javascript:;';
  monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  viewOtherDetailsData;
  bookingTypeList;
  specialClientFunc;
  typeOfClient = 'Individual';
  errorClass = false;
  viewFlag = false;
  
  viewCheckboxObjList = [];
  jsonListDataExcel = [];
  jsonListData:any;

  bookingRequestStatusList = [];
  passengerDetailsList = [];
  passengerChildDetailsList = [];



  valChk = function(param) {
      if (param == '') { return '-' }
      return param;
  }

  constructor(private http:HttpClient, private Services:CommonService) {
    //var status = AuthService.header({ 'event': 'show', 'activeLink': 'none', 'pageCheck': 'requestPage' });
  }

  ngOnInit() {
    this.Services.checkValidURL('request');
    
    this.requestViewData = {
        "number":1122334455,
        "invoiceFullDate" : "date",
        "invoiceFullDateTP" : 0,
        "address" : "address",
        "contactNo1" : "numb",
        "emailId" : "email",
        "remark" : "remrk",
        "bookingRequestStatus" : "true",
        "clientName" : "CLNam",
        "bookingType" : "Traim",
        "bookingDetails" : {
            "journeyDate" : "",
            "from" : "",
            "to" : "",
            "boarding" : "",
            "class" : "",
            "quota" : ""
        },
        "passengerDetailsList" : [
            {
                "name" : "",
                "sex" : "",
                "age" : "",
                "birth" :"",
                "food" : null
            }
        ],
        "passengerChildDetailsList" : [
            {
                "name" : "",
                "sex" : "",
                "age" : ""
            }
        ]
    };

    this.filterFormData={
      bookingType:"Train",
      bookingRequestStatus:"Select",
      journeyDateFrom:"",
      journeyDateTo:"",
      contactNo1:"",
      name:"",
      emailId:""
    }

    var onClearClickSeverHit = false;
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      var d = res[0];
      var contactDetails = '';
      this.viewOtherDetailsData = d;
      this.bookingTypeList = this.viewOtherDetailsData.bookingType;
      
      //this.filterFormData.bookingType = "Train";

      //>>>>>>>this.specialClientFunc(d.specialClient);///Important Feature

      setTimeout(()=> {
          onClearClickSeverHit = true;
          this.requestActions('', 'filterClearDates','');
      }, 100);
    },
    err => {
      console.log(err);
      //return err;
    });

    //this.filterFormData.bookingType = "Train";//Temp

    var obj = { listFormJsonData2: { searchType: {} } }
    this.Services.listMasters(obj).subscribe(res =>{
    //this.http.post('/user/listMasters',this.Services.convertToJson(obj)).subscribe(res =>{
        //>>>>>pageLoader('hide');
    },
    err => {
      //>>>>>pageLoader('hide');
      console.log(err);
      //return err;
    });
    
    if (localStorage._jsonDropdown == undefined) {
      this.Services.jsonDropdown().subscribe(res =>{
        localStorage._jsonDropdown = JSON.stringify(res);
        this.updateDropdownList(localStorage._jsonDropdown);
      },err =>{
        console.log(err);
      });
    }
    else {
      this.updateDropdownList(localStorage._jsonDropdown);
    }
    
    $(".dropdown").on("show.bs.dropdown", function(event) {
        var x = $(event.relatedTarget).text(); // Get the button text
    });
  
    $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
    $('#listGrid tbody').on('change', 'input', function() {
        $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
        var l = $('#listGrid .table input:checked').length;
        if (l >= 1) {
            $('.multiEventButtons a').removeClass('disabled');
        }
        if (l == 1) {
            $('.singleEventButtons a').removeClass('disabled');
        }
    });
  
    $(".datepicker").datepicker({
        dateFormat: 'dd M, yy',
        changeMonth: true,
        changeYear: true
    });
  
    /*$(document).on('click', '.showHideTable', function() {
        var ths = $(this).parent().parent();
        if (ths.hasClass('notPrintable')) {
            ths.removeClass('hidePage').removeClass('notPrintable');
        } else {
            ths.addClass('hidePage').addClass('notPrintable');
        }
    })
    
    $('.listWrapper').on('click', 'th', function() {
      if ($(this).hasClass('clicked')) {
          $('.listWrapper .clicked').removeClass('clicked');
          return false;
      }
      $('.listWrapper .clicked').removeClass('clicked');
      var ind = $(this).index();
      var len = $('.listWrapper tr').length;
      $('.listWrapper tr').eq(0).find('th').eq(ind).addClass('clicked');
      for (var i = 0; i < len; i++) {
          $('.listWrapper tr').eq(i).find('td').eq(ind).addClass('clicked');
      }
    });
    */
    
  }

  getDropdownData = function(data, type, typeName, selectFlag) {
      var dataFinal = [];
      for (var i = 0; i < data.length; i++) {
          if (data[i].type == type) {
              dataFinal.push(data[i]);
          }
      }
      return dataFinal;
  }  
  
  updateDropdownList = function(data) {
      var data = JSON.parse(data);
      ///this.clientList = data.clientList;
      //this.agentList = data.agentList;
      //this.paymentStatusList = data.paymentStatusList;
      //this.busTravelPaymentStatusList = data.busTravelPaymentStatusList;
      this.bookingRequestStatusList = data.bookingRequestStatusList;
      //this.paymentByList = data.paymentByList;
      //this.bookingTypeList = data.bookingTypeList;
  
      //this.filterFormData.bookingType = data.bookingTypeList[0].bookingName;
      //this.filterFormData.client = data.clientList[0].clientName;
      //this.filterFormData.agentType = data.agentList[0].agentName;
      //this.filterFormData.paymentStatus = data.paymentStatusList[0].statusName;
      //this.filterFormData.busTravelPaymentStatus = data.busTravelPaymentStatusList[0].statusName;
      this.filterFormData.bookingRequestStatus = data.bookingRequestStatusList[0].statusName;
      //this.requestViewData = {}
      //this.requestViewData.payment = {}
      //this.requestViewData.payment.paymentBy = data.paymentByList[0].paymentByName;
  
      localStorage._booking = JSON.stringify({ 'addUpdate': '', 'id': '' });
  }
    
  loadInputData = function(data) {
    console.log(data);

    this.requestViewData = {};
    this.requestViewData.invoiceNo = data.invoiceNo;
    this.requestViewData.client = data.client;
    this.requestViewData.clientGSTINFlag = false;
    this.requestViewData.typeOfClient = true;
    this.requestViewData.clientAddress = '';

    this.requestViewData.invoiceFullDate = data.invoiceFullDate;
    this.requestViewData.address = data.address;
    this.requestViewData.remark = data.remark;
    this.requestViewData.contactNo1 = data.contactNo1;
    this.requestViewData.emailId = data.emailId;

    //this.requestViewData.journeyDate = data.bookingDetails.journeyDateDD+'/'+  this.monthArr[parseInt(data.bookingDetails.journeyDateMM)-1] +'/'+data.bookingDetails.journeyDateYY;
    this.requestViewData.journeyDate = data.bookingDetails.journeyDate;
    this.requestViewData.from = data.bookingDetails.from;
    this.requestViewData.to = data.bookingDetails.to;

    this.requestViewData.class = data.bookingDetails.class;
    this.requestViewData.quota = data.bookingDetails.quota;

    this.passengerDetailsList = data.passengerDetails;
    for (var i = 0; i < data.passengerDetails.length; i++) {
        var pData = data.passengerDetails[i];
        var scop = eval('this.requestViewData.pd' + i + ' = {}');
        scop.checkbox = pData.checkbox;
        scop.empId = pData.empId;
        scop.empLevel = pData.empLevel;
        scop.name = pData.name;
        scop.sex = pData.sex;
        scop.age = pData.age;
        scop.birth = pData.birth;
        scop.food = pData.food;
    }

    this.passengerChildDetailsList = data.passengerChildDetails;
    for (var i = 0; i < data.passengerChildDetailsList.length; i++) {
        var pData = data.passengerChildDetailsList[i];
        var scop = eval('this.requestViewData.pd' + i + ' = {}');
        scop.name = pData.childName;
        scop.sex = pData.childSex;
        scop.age = pData.childAge;
    }
  }

  requestActions = function(id, param, evnt) {
      if (param == 'showClientToggle') {
          if (this.showClientTo == true) { this.showClientTo = false } else if (this.showClientTo == false) { this.showClientTo = true }
      }

      if (param == 'saveListObj') {
          if (this.viewCheckboxObjList.length >= $('#listGrid tbody tr').length) {
              this.viewCheckboxObjList = [];
          }
          this.viewCheckboxObjList.push(id.i);
      }

      if (param == 'inputCheckbox') {
          this.viewCheckboxObj = id;
          this.loadInputData(this.viewCheckboxObj);
      }

      if (param == 'view' || param == 'triggerView') {
          var obj1 = this.getObjectFromId();
          var obj = obj1 || this.viewCheckboxObj;
          this.loadInputData(obj);
      }
      if (param == 'addToBookmarkFunc') {
          var ths = this.jsonListData;
          for (var i = 0; i < ths.length; i++) {
              var obj = ths[i];
              var hrefData = this.requestActions(obj, 'addToBookmark','');
              $('#listGrid tbody tr').eq(i).find('.trBookmark').attr('href', hrefData);
          };
      }
      if (param == 'addToBookmark') {

         var originalOBJ = id;
          var obj2 = {
              "cn": originalOBJ.contactNo1,
              "ad": originalOBJ.address,
              "bd": {
                  "jd": originalOBJ.bookingDetails.journeyDateDD+'-'+originalOBJ.bookingDetails.journeyDateMM+'-'+originalOBJ.bookingDetails.journeyDateYY,
                  "f": originalOBJ.bookingDetails.from,
                  "t": originalOBJ.bookingDetails.to
              },
              "pd": originalOBJ.passengerDetails,
              "pcd": originalOBJ.passengerChildDetails
          };
          /*if ($('#addPassengerForm').length) {
              var ths = $('tbody[id="addPassengerForm:psdetail:tb"] tr');
              for (var i = 0; i < obj2.pd.length; i++) {
                  ths.eq(i).find('td').eq(1).find('input').val(obj2.pd[i].name);
                  ths.eq(i).find('td').eq(2).find('input').val(obj2.pd[i].age);
                  ths.eq(i).find('td').eq(3).find('select').val(obj2.pd[i].sex);
                  ths.eq(i).find('td').eq(4).find('select').val(obj2.pd[i].birth);
                  ths.eq(i).find('td').eq(5).find('input').val(obj2.pd[i].food);
              }
              $('input[id="addPassengerForm:mobileNo').val(obj.cn);
          }*/

          var bookMarkHtml2 = '';
          bookMarkHtml2 += "javascript:function S(){";
          bookMarkHtml2 += "var obj2 = " + JSON.stringify(obj2) + ";";
          bookMarkHtml2 += "if ($('#jpform').length) {";
          bookMarkHtml2 += "$('input[id=\"jpform:fromStation\"]').val(obj2.bd.f);";
          bookMarkHtml2 += "$('input[id=\"jpform:toStation\"]').val(obj2.bd.t);";
          bookMarkHtml2 += "$('input[id=\"jpform:journeyDateInputDate\"]').val(obj2.bd.jd);"; ///14-11-2017
          bookMarkHtml2 += "}";
          bookMarkHtml2 += "if( $('#addPassengerForm').length ){";
          bookMarkHtml2 += "var ths1 = $('tbody[id=\"addPassengerForm:psdetail:tb\"] tr');";
          bookMarkHtml2 += "for (var i=0; i<obj2.pd.length; i++) {";
          bookMarkHtml2 += "ths1.eq(i).find('td').eq(1).find('input').val(obj2.pd[i].name);";
          bookMarkHtml2 += "ths1.eq(i).find('td').eq(2).find('input').val(obj2.pd[i].age);";
          bookMarkHtml2 += "ths1.eq(i).find('td').eq(3).find('select').val(obj2.pd[i].sex);";
          bookMarkHtml2 += "ths1.eq(i).find('td').eq(4).find('select').val(obj2.pd[i].birth);";
          bookMarkHtml2 += "ths1.eq(i).find('td').eq(5).find('select').val(obj2.pd[i].food);";
          bookMarkHtml2 += "}";
          
          bookMarkHtml2 += "var ths2 = $('tbody[id=\"addPassengerForm:childInfoTable:tb\"] tr');";
          bookMarkHtml2 += "for(var jj=0; jj<2; jj++){";
          bookMarkHtml2 += "ths2.eq(jj).find('td').eq(1).find('input').val(obj2.pcd[jj].childName);";
          bookMarkHtml2 += "ths2.eq(jj).find('td').eq(2).find('select').val(obj2.pcd[jj].childAge);";
          bookMarkHtml2 += "ths2.eq(jj).find('td').eq(3).find('select').val(obj2.pcd[jj].childSex);";
          bookMarkHtml2 += "}";
          
          bookMarkHtml2 += "$('input[id=\"addPassengerForm:mobileNo\"]').val(obj2.cn);";
          bookMarkHtml2 += "}";
          bookMarkHtml2 += "if( $('input[ng-model=\"addFormData.address\"').length ){";
          bookMarkHtml2 += "$('input[ng-model=\"requestViewData.address\"').val(obj2.ad);";
          bookMarkHtml2 += "}";
          bookMarkHtml2 += "}";
          bookMarkHtml2 += "S();";
          return bookMarkHtml2;
      }

      if (param == 'markAsBookingDone') {
          if ($('.table input:checked').length) {
              var d = new Date();
              var d1 = d.getDate();
              var d2 = d.getMonth();
              var d3 = d.getFullYear();
              var curDate1 = d1 + ' ' + this.monthArr[d2] + ', ' + d3;

              //var pFrom = $('.table input:checked').eq(0).attr('data-client');
              //this.addFormData.bookingRequestStatus = 'Done';

              var updateBookingIdArr = [];
              //var checkClientName = [pFrom];
              //var checkClientNameFlag = true;
              var paymentAlreadyDoneFlag = false;

              for (var i = 0; i < $('.table input:checked').length; i++) {
                  var id = $('.table input:checked').eq(i).attr('data-id');
                  updateBookingIdArr.push(id);
                  console.log(id);
              }
              console.log({array:updateBookingIdArr});

              //>>>>>>>>pageLoader('show');
              this.Services.bookingStatus({array:updateBookingIdArr}).subscribe(res =>{
              //this.http.post('/user/bookingStatus',this.Services.convertToJson( {array:updateBookingIdArr} )).subscribe(res =>{
                $('#paymentReceivedForm .close').click();
                this.requestActions('', 'filterListData','');
                //>>>>pageLoader('hide');
              },
              err => {
                //>>>>pageLoader('hide');
                console.log(err);
                //return err;
              })

 
          }
      }

      /*
        if (param == 'multipleDelete') {
            //db.users.remove({'_id':{'$in':inactive_users}});
            var delIdArr = [];
            for (var i = 0; i < $('.table input:checked').length; i++) {
                var id = $('.table input:checked').eq(i).attr('data-id');
                delIdArr.push(id);
            }
  
            var data = { 'idArray': delIdArr }
            AuthService.deleteAllData(data)
                .then(function(data) {
                    $('#multipleDeleteConfirmation .close').click();
                    localStorage._booking = JSON.stringify({ 'addUpdate': '', 'id': '' });
                    $location.path('/list');
                })
                .catch(function() {
                    alert('error');
                });
        }
        if (param == 'submitPaymentReceived') {
            var updateBookingIdArr = [];
            for (var i = 0; i < $('.table input:checked').length; i++) {
                var id = $('.table input:checked').eq(i).attr('data-id');
                updateBookingIdArr.push(id);
            }
  
            var updatePaymentRecArr = [];
            for (var i = 0; i < $('.table input:checked').length; i++) {
                var rec = $('.table input:checked').eq(i).parent().parent().find('.totalPA').text();
                updatePaymentRecArr.push(rec);
            }
  
            var d = new Date(this.addFormData.payment.paymentDate);
            var d1 = d.getDate();
            var d2 = d.getMonth() + 1;
            var d3 = d.getFullYear();
            if (d1 < 10) { d1 = '0' + d1 }
            if (d2 < 10) { d2 = '0' + d2 }
            var payDate = d1 + '/' + d2 + '/' + d3;
  
            var paymentDetailsObj = {
                paymentStatus: this.addFormData.payment.paymentStatus,
                paymentFrom: this.addFormData.payment.paymentFrom,
                paymentBy: this.addFormData.payment.paymentBy,
                paymentByChequeNo: this.addFormData.payment.paymentByChequeNo,
                paymentByBankName: this.addFormData.payment.paymentByBankName,
                paymentDate: payDate,
                pendingAmount: 0
            }
  
            var data = { array: updateBookingIdArr, obj: paymentDetailsObj, payRec: updatePaymentRecArr }
            AuthService.paymentReceivedForm(data)
                // handle then
                .then(function() {
                    $('#paymentReceivedForm .close').click();
                    this.requestActions(this, 'filterListData');
                })
                // handle error
                .catch(function() {
                    alert('error');
                });
        }
          if (param == 'submitPaymentReceivedCancel') {
            //this.paymentReceivedForm = true;
            this.addFormData = { payment: {} }
            this.addFormData.payment.paymentStatus = '';
            this.addFormData.payment.paymentFrom = '';
            this.addFormData.payment.paymentBy = '';
            this.addFormData.payment.paymentByChequeNo = '';
            this.addFormData.payment.paymentByBankName = '';
            this.addFormData.payment.paymentDate = '';
        }
          if (param == 'submitCreditNote') {
            if ($('#creditNoteForm .modal-footer .disabled').length != 0) { return false }
            var obj1 = this.getObjectFromId();
            var obj = obj1 || id.i || this.viewCheckboxObj;
            //var obj = id.i || this.viewCheckboxObj;
            var updateCreditIdArr = $('#creditNoteForm input[name="creditNoteNo"]').attr('data-id');
            var l = $('#creditNoteForm input:checkbox').length; //1
            var passengerDetailsObj = [];
  
            var d = new Date(this.addFormData.payment.creditNoteDate);
            var d1 = d.getDate();
            var d2 = d.getMonth() + 1;
            var d3 = d.getFullYear();
            if (d1 < 10) { d1 = '0' + d1 }
            if (d2 < 10) { d2 = '0' + d2 }
            var creditDate = d1 + '/' + d2 + '/' + d3;
            var creditPaymentDate = '';
            if (this.addFormData.payment.bookingRequestStatus == 'Given') {
                var d = new Date(this.addFormData.payment.creditNotePaymentDate);
                var d1 = d.getDate();
                var d2 = d.getMonth() + 1;
                var d3 = d.getFullYear();
                if (d1 < 10) { d1 = '0' + d1 }
                if (d2 < 10) { d2 = '0' + d2 }
                creditPaymentDate = d1 + '/' + d2 + '/' + d3;
            }
  
            for (var i = 0; i < l; i++) {
                var c = $('#creditNoteForm input:checkbox').eq(i).prop('checked');
                if (c == true) { c = true } else if (c == false) { c = '' }
                var pd1 = JSON.parse(angular.toJson(obj.passengerDetails[i]));
                pd1.checkbox = c;
                passengerDetailsObj.push(pd1);
            }
  
            var creditDetailsObj = {
                passengerDetails: passengerDetailsObj,
                chargesDetails: {
                    grandTotal: this.addFormData.charges.grandTotal,
                    ticketFare: this.addFormData.charges.ticketFare,
                    cancellationService: this.addFormData.charges.cancellationService,
                    cancellationRailway: this.addFormData.charges.cancellationRailway,
                    cancellationServiceTotal: this.addFormData.charges.cancellationServiceTotal,
                    cancellationRailwayTotal: this.addFormData.charges.cancellationRailwayTotal,
                    creditAmount: this.addFormData.charges.creditAmount
                },
                paymentDetails: {
                    creditNoteFlag: this.addFormData.payment.creditNoteFlag,
                    bookingRequestStatus: this.addFormData.payment.bookingRequestStatus,
                    creditNotePaymentDate: creditPaymentDate,
                    creditNoteNo: this.addFormData.payment.creditNoteNo,
                    creditNoteDate: creditDate,
                    remark: this.addFormData.payment.remark,
                    pendingAmount: this.addFormData.payment.pendingAmount,
                    paymentReceived: this.addFormData.payment.paymentReceived
                }
            }
  
            var data = { array: updateCreditIdArr, obj: creditDetailsObj, creditEntry: this.creditEntry }
            AuthService.creditNoteForm(data)
                // handle then
                .then(function() {
                    $('#creditNoteForm .close').click();
                    this.requestActions(this, 'filterListData');
                })
                // handle error
                .catch(function() {
                    alert('error');
                });
        }
  
        if (param == 'exportExcelJson') {
            this.expExcelClientFunc();
            return false;
        }
        
        if (param == 'filterClear') {
            $('body').addClass('loading');
            $('.noDataFound').removeClass('noDataFound');
            this.showListFlag = true;
  
            this.filterFormData = {};
            this.filterFormData.bookingType = "Train";
            this.filterFormData.paymentDetails = {};
            this.requestActions('', 'filterClearDates');
  
            this.filterFormData.contactNo1 = '';
            this.filterFormData.emailId = '';
            this.filterFormData.name = '';
            this.filterFormData.bookingRequestStatus = 'Select';
  
            var f1 = new Date(curDate2);
            var t1 = new Date(curDate1);
            var f2 = f1.getTime();
            var t2 = t1.getTime();
  
            this.errorClass = '';
            this.showListFlag = false;
        }
      */

      if (param == 'filterClearDates') {
          let d = new Date();
          let d1 = d.getDate();
          let d2 = d.getMonth();
          let d3 = d.getFullYear();
          let curDate1 = d1 + ' ' + this.monthArr[d2] + ', ' + d3;

          let dd = new Date();
          dd.setDate(dd.getDate() - 15);
          let dd1 = dd.getDate();
          let dd2 = dd.getMonth();
          let dd3 = dd.getFullYear();
          let curDate2 = dd1 + ' ' + this.monthArr[dd2] + ', ' + dd3;

          //this.filterFormData={};
          this.filterFormData.journeyDateFrom = curDate2;
          this.filterFormData.journeyDateTo = curDate1;
      }
      if (param == 'filterListData') {
          if( id != ''){
              id.preventDefault();
          }
          
          $('body').addClass('loading');
          $('.multiEventButtons a, .singleEventButtons a').addClass('disabled');
          this.showListFlag = true;
          //let this.filterFormData = this.filterFormData;

          let bt = () => {
              var b = this.filterFormData.bookingType;
              if (b == '' || b == undefined) { return '' };
              return '"bookingType":"' + b + '"';
          };
          let brs = () => {
              var p = this.filterFormData.bookingRequestStatus;
              if (p == '' || p == undefined || p == 'Select') { return '' };
              return '"bookingRequestStatus":"' + p + '"';
          };
          let ft = () => {
              //let f0 = this.filterFormData.journeyDateFrom;
              //let t0 = this.filterFormData.journeyDateTo;
              let f0 = $('input[name="journeyDateFrom"]').val();
              let t0 = $('input[name="journeyDateTo"]').val();
              let f1:any = new Date('' + f0 + '');
              let t1:any = new Date('' + t0 + '');

              if (f1 == 'Invalid Date' || t1 == 'Invalid Date') {
                  alert('Kindly select the date range again.');
                  return '';
              } else {
                  let f22 = f1.getTime();
                  let t22 = t1.getTime();
                  if (f22 == '' || f22 == undefined || isNaN(f22) || t22 == '' || t22 == undefined || isNaN(t22)) {
                      alert('Major Problem, kindly contact Software Engineer');
                      return ''
                  };
                  return '"invoiceFullDateTP":{"$gte" : ' + f22 + ', "$lte" : ' + t22 + '}';
              }
          };
          let cn = () => {
              var c = this.filterFormData.contactNo1;
              if (c == '' || c == undefined || c == 'Select') { return '' };
              return '"contactNo1":"' + c + '"';
          };
          let ei = () => {
              var e = this.filterFormData.emailId;
              if (e == '' || e == undefined || e == 'Select') { return '' };
              return '"emailId":"' + e + '"';
          };
          let nm = () => {
              var c = this.filterFormData.name;
              if (c == '' || c == undefined || c == 'Select') { return '' };
              return '"passengerDetails.name":"' + c + '"';
          };

          let obj = () => {
              var objArr1 = ['bt()', 'brs()', 'ft()', 'cn()', 'ei()', 'nm()'];
              var objArr2 = '';
              for (var i = 0; i < objArr1.length; i++) {
                  var v = eval(objArr1[i]);
                  if (objArr2 == '' && v != '') {
                      objArr2 = v;
                  } else if (v != '') {
                      objArr2 += ',' + v;
                  }
              }
              return '{' + objArr2 + '}';
          };

          var filterDataObj = JSON.parse(obj());
          var listFormJsonData1 = {
              searchType: 'filter',
              searchBy: filterDataObj
          };
          this.Services.requestList({listFormJsonData2:listFormJsonData1}).subscribe(res =>{
          //this.http.post('/user/requestList',this.Services.convertToJson({listFormJsonData2:listFormJsonData1})).subscribe(res =>{
            let data = res;
            if (data.length == 0) {
                //alert('No Data Found');
                this.jsonListData = '';
                this.errorClass = 'noDataFound';
            } else {
                this.jsonListData = data.reverse();
                this.errorClass = '';
            }
      
            setTimeout(()=> {
                this.requestActions('', 'addToBookmarkFunc','');
                setTimeout(()=> {
                    $('body').removeClass('loading');
                }, 100);
            }, 10);
          },
          err => {
            //>>>>pageLoader('hide');
            $('body').removeClass('loading');
            console.log(err);
            //return err;
          });

      }
  }

  getObjectFromId = function() {
      return false;
      /*
      var id = $('#listGrid input:checked').attr('data-id');
      var data1 = this.jsonListData;
      var data2;
      for (var i = 0; i < data1.length; i++) {
          if (data1[i].otherDetails.otherDetailsId == id) {
              data2 = data1[i];
              break;
          }
      }
      return JSON.parse(angular.toJson(data2));
      */
  }

  getListId = function() {
      var tr = $('#listGrid tbody tr');
      var arr = [];
      for (var i = 0; i < tr.length; i++) {
          var id = tr.eq(i).find('td').eq(0).find('input').attr('data-id');
          arr.push(id);
      }
      return arr;
  }

  multipleShow = function(p1, p2, p3) {
      if (p1 == true && p2 == true && p3 == undefined) {
          return true;
      } else if (p1 == true && p2 == true && p3 == true) {
          return true;
      }
      return false;
  }
 
}
