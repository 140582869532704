import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var MD5:any;//this way u can include other MD5 file/JQuery library
declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  errorMessage;

  mainClient = 0;
  mainTheme = 0;
  demoPerson = false;
  bookingCID2 = '';
  bookingUNM2 = '';
  bookingUT2 = '';
  globalVar={};

  userLogin = false;
  loginForm={
    "username":"",
    "password":"",
    "companyId":""
  };
  error = false;
  disabled = true;
  viewOtherDetailsObj;

  constructor(private router:Router, private route:ActivatedRoute, private http:HttpClient, private Services:CommonService){ }
  
  ngOnInit() {
    this.loginForm = {
      "username":"",
      "password":"",
      "companyId":""
    }
    //localStorage._client = ( this.route.snapshot.params.client );
    /*
      console.log('this.http.get');
      //this.http.get('http://api.github.com/users/seeschweiler').subscribe(res =>{
        console.log(res);
      });
      
      //this.http.post('https://reqres.in/api/users',{"name":"morpheus","job":"leader"}).subscribe(
        // Successful responses call the first callback.
        data => {
              console.log(res);
        },
        // Errors will call this callback instead:
        err => {
          console.log('Something went wrong!');
        }
      );
    
      this.router.navigate(['thankyou']);
   
      var hrf = $location.search();
      if(hrf.login == 'demo'){
        localStorage._ckeckBoxList = '{"obj":{"cl1":false,"cl1_1":false,"cl1_2":false,"cl1_3":false,"cl1_4":false,"cl1_5":false,"cl1_6":false,"cl1_7":false,"cl1_8":false,"cl1_9":false,"cl1_10":false,"cl1_11":false,"cl1_12":false,"cl1_13":false,"cl1_14":false,"cl1_15":false,"cl1_16":false,"cl1_17":false,"cl1_18":false,"cl1_19":false,"cl2":true,"cl2_1":true,"cl2_2":true,"cl2_3":true,"cl2_4":true,"cl2_5":true,"cl2_6":true,"cl2_7":true,"cl2_8":true,"cl2_9":true,"cl2_10":true,"cl2_11":true,"cl2_12":true,"cl2_13":true,"cl2_14":true,"cl2_15":true,"cl2_16":true,"cl2_17":true,"cl2_18":true,"cl2_19":true,"cl3":false,"cl3_1":false,"cl3_2":false,"cl3_3":false,"cl3_4":false,"cl3_5":false,"cl3_6":false,"cl3_7":false,"cl3_8":false,"cl3_9":false,"cl3_10":false,"cl3_11":false,"cl3_12":false,"cl3_13":false,"cl3_14":false,"cl3_15":false,"cl3_16":false,"cl3_17":false,"cl3_18":false,"cl3_19":false}}';
          $rootScope.demoPerson = true;
    
        $scope.loginForm.username = $scope.loginForm.password = 'user1';
        $scope.loginForm.companyId = 'guest';
        $scope.login();
      }
    */
    //let menuLinks = [{"title":"Login","usr1":true,"usr2":true,"evnt":"login" },{"title":"Register","usr1":true,"usr2":true,"evnt":"register"}];
    this.Services.updateMainHeader('','forcefully');//Main Navigation func to connect with other component

  }

  loginAction(e, param, funcData ){
    
    if(param == 'login'){
      let tempLoginForm;
      tempLoginForm = this.loginForm;
      if( tempLoginForm.username.length > 15 || tempLoginForm.password.length > 15 || tempLoginForm.companyId.length > 15 ){
        alert('Login details cannot be more than 15 letters');
        tempLoginForm.username = tempLoginForm.password = tempLoginForm.companyId = '';
        this.loginForm = tempLoginForm;
        return false;
      }
      else if( tempLoginForm.username.length < 5 || tempLoginForm.password.length < 5 || tempLoginForm.companyId.length < 2 ){
        alert('Invalid Details');
        tempLoginForm.username = tempLoginForm.password = tempLoginForm.companyId = '';
        this.loginForm = tempLoginForm;
        return false;
      }
      var dayArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];  
      var date = new Date();
      var dtFinal = dayArr[date.getDay()] + ', ' + date.getDate() + '/' + monthArr[date.getMonth()] + '/' + date.getFullYear() + ' || ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

      
      $('body').addClass('loading');
      let sendData = {"username": tempLoginForm.username, "password": MD5(tempLoginForm.password), "companyId2": tempLoginForm.companyId,"dt":dtFinal};
      this.Services.login(sendData).subscribe(res =>{
        
        let data = res;
        this.Services._updateGlobalObjectFunc(data,'loginData');
        if(data.status == "Login successful!"){
          //this.Services.setUserLoggedIn();
          //this.router.navigate(['/dashboard']);
          /*
          this.bookingCID2 = localStorage._bookingCID = data.compId;
          this.bookingUNM2 = localStorage._bookingUNM = data.usern;
          this.bookingUT2 = localStorage._bookingUT = data.usert;
          */

          localStorage._bookingCID = data.compId;
          localStorage._bookingUNM = data.usern;
          localStorage._bookingUT = data.usert;
          localStorage._bookingAL = false;
          if(data.usert == 'administrator'){
            localStorage._bookingAL = true;
          }
          //localStorage._bookingNav = JSON.stringify(data.navBar);
          //////////////////////////////SULAMAN///////////////////
          
          if(localStorage._dynamicList == undefined || localStorage._dynamicListVersion == undefined || localStorage._dynamicListVersion != data.custConfig.Version){
            let customizeSettingsTemp = {};
            let bookingTypeList = [];
            for(let r=0; r<data.custConfig.CustomizeSettings.length; r++){
              let cObj = data.custConfig.CustomizeSettings[r];
              customizeSettingsTemp[cObj.bookingName+''] = cObj.custValue;

              if( cObj.booking_checked == true ){
                bookingTypeList.push({ "bookingName": cObj.bookingName });
              }
            }
            localStorage._dynamicList = JSON.stringify(customizeSettingsTemp);
            localStorage._dynamicListVersion = data.custConfig.Version;
            localStorage._dynamicBTypeList = JSON.stringify(bookingTypeList);
          }
          localStorage._bookingNav = JSON.stringify(data.custConfig.navigationLinks);

          this.Services.viewOtherDetails({"Settings":"All"}).subscribe(res =>{
            
            for(let rsp=0; rsp<res.length; rsp++){
              if( res[rsp].Settings == 'Settings' ){
                this.viewOtherDetailsObj = res[rsp];
              }
              else if( res[rsp].Settings == 'TrainCities' ){
                localStorage.TrainCities = JSON.stringify( res[rsp].data );
              }
              else if( res[rsp].Settings == 'AirCities' ){
                localStorage.AirCities = JSON.stringify( res[rsp].data );
              }
            }


            var HeaderObj = {
              "_allpagetitle":this.viewOtherDetailsObj.AllPageTitle,
              "_LogoPath":this.viewOtherDetailsObj.LogoPath,
              "_myusername":this.viewOtherDetailsObj.myusername,
              "_menuLinks":data.custConfig.navigationLinks
              //"_menuLinks":data.navBar
              //"_menuLinks":this.viewOtherDetailsObj.navigationLinks
            }
            
            this.Services.updateMainHeader(HeaderObj,'');//Main Navigation func to connect with other component
            this.Services._updateGlobalObjectFunc(this.viewOtherDetailsObj,'viewOtherDetails');
            
            localStorage.JWT_Token = data.token;
            localStorage.JWT_Username = tempLoginForm.username;
            this.userLogin = true;
            
            localStorage.mainDetails = JSON.stringify({'cl':(data.clientName).replace(/ /g,''), 'thm':data.bootStrapTheme, 'hrd':data.headerPosition, 'sd':data.startDate, 'dd':data.diffenDays, 'sl':data.slkLayout, 'pth':'css/bootstrap-theme-'+data.bootStrapTheme+'.css'});
            localStorage._booking = JSON.stringify({'addUpdate':'', 'id':''});      
            
            if (data.slkLayout != undefined) {
              $('#sleekLayoutID').attr('href', '/assets/css/sleek-' + data.slkLayout + '.css');
            }
          
            var ls = JSON.parse(localStorage.mainDetails);
            this.mainClient = data.clientName;
            this.mainTheme = data.bootStrapTheme;
            $('body').addClass(data.headerPosition);
            $('#mainThemePath').attr('href','/assets/'+ls.pth);
            this.disabled = false;
            this.loginForm = {
              "username":"",
              "password":"",
              "companyId":""
            };
            
            if(localStorage._ckeckBoxList == undefined){
              localStorage._ckeckBoxList = '';
            }

            this.Services.setUserLoggedIn();
            /*
            var status = this.Services.header({'event':'show', 'activeLink':'none', 'pageCheck':'loginPage'});
            if(status == 'dashboardPage'){ this.router.navigate(['dashboard']); }
            else if(status == 'listPage'){ this.router.navigate(['list']); }
            */
            //console.log( 'Userloged In ==>> ' + this.Services.getUserLoggedIn() );
            $('body').removeClass('loading');
            this.router.navigate([ '/'+JSON.parse(localStorage._bookingNav)[0].evnt ]);
          },
          err => {
            $('body').removeClass('loading');
            return err;
          });

        } else {
          $('body').removeClass('loading');
          this.loginAction('','loginFailed',data);
        }
      },err => {
        console.log(err);
        this.loginAction('','loginFailed',err);
      });
      
    }  
    
    if(param == 'loginFailed'){      
      if(funcData.auth == 'pending'){
        this.errorMessage = funcData.err;
      }
      else if(funcData.auth == 'Expired'){
        this.errorMessage = funcData.err;
      }
      else{
        this.errorMessage = funcData.err;
      }
      this.error = true;
      this.disabled = false;
      this.loginForm = {
        "username":"",
        "password":"",
        "companyId":""
      };
    }

  }

}
