import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';

declare var MD5:any;//this way u can include other MD5 file/JQuery library
declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  errorMessage;
  registerForm = {
    username:'',
    password:'',
    password2:'',
    companyId:''
  };

  error = false;
  disabled = true;

  constructor(private router:Router, private route:ActivatedRoute, private http:HttpClient, private Services:CommonService) { 
    this.registerForm = {
      username:'',
      password:'',
      password2:'',
      companyId:''
    }
  }

  ngOnInit() {
  }

  registerAction = function(e,param,funcData){
    if(param == 'register'){
      let tempRegisterForm;
      tempRegisterForm = this.registerForm;

      if( tempRegisterForm.username.length > 15 || tempRegisterForm.password.length > 15 || tempRegisterForm.companyId.length > 15 ){
        alert('Login details cannot be more than 15 letters');
        return false;
      }
      else if( tempRegisterForm.username.length < 5 || tempRegisterForm.password.length < 5 || tempRegisterForm.companyId.length < 2 ){
        alert('Kindly enter the minimum required length');
        return false;
      }
      else if( tempRegisterForm.password == tempRegisterForm.password2 ){
        var postData = {"username": tempRegisterForm.username, "password": MD5(tempRegisterForm.password), "companyId2": tempRegisterForm.companyId};
        this.Services.register(postData).subscribe(res =>{
        //this.http.post('/user/register',postData).subscribe(res =>{
          this.disabled = false;
          tempRegisterForm = {};
          tempRegisterForm.username = tempRegisterForm.password = tempRegisterForm.password2 = tempRegisterForm.companyId = '';
          this.registerForm = tempRegisterForm;
          this.router.navigate(['login']);
        },err => {
          this.registerAction('','registerFailed',err);
        });
      }
	    else{
	    	alert('Password is incorrect');
	    }
    }
    if(param == 'registerFailed'){
      this.error = true;
      this.errorMessage = funcData.err;
      this.disabled = false;
      this.registerForm = {};
      this.router.navigate(['register']);
    }
  }

}
