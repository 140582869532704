import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

//import {  FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
//https://appdividend.com/2018/05/25/angular-6-file-upload-tutorial/

 
import { CommonService } from '../common.service';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  errorMessage='';
  error=false;
  settingsFormData = {
      "Settings":"Settings",
      "AllPageTitle":'',
      "Address":'',
      "LogoPath":'',
      "GSTIN":'',
      "sac_shn":'',
      "hsn_shn":'',
      "bootStrapTheme":'',
      "headerPosition":'',
      "sleekLayout":'',
      "ContactDetails":[],
      "AllTermsConditions":[],
      "airInvoiceNo":'',
      "busInvoiceNo":'',
      "railInvoiceNo":'',
      "packageInvoiceNo":''
  };
  tncRepeater;
  contactList;
  TermsConditionsList;
  contactObject = {"contactType":"", "contactNumber":""};
  //uploader: FileUploader = new FileUploader({url: 'http://localhost:4007/user/upload', itemAlias: 'photo'});

  constructor(private router:Router, private http:HttpClient, private Services:CommonService) {  }

  ngOnInit() {
    /*
    this.uploader.onAfterAddingFile = (file) => { 
      file.withCredentials = false; 
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
         console.log('ImageUpload:uploaded:', item, status, response);
         alert('File uploaded successfully');
    };
    */

    this.Services.checkValidURL('settings');

    //>>>>$var status = AuthService.header({'event':'show', 'activeLink':'none', 'pageCheck':'settingsPage'});
    //>>>>$if(status == false){ $location.path('/list') };

    //let obj = {companyId : localStorage._bookingCID,companyUNM : localStorage._bookingUNM }
    //this.http.post('/user/viewOtherDetails',obj).subscribe(res =>{
    this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
      this.settingsFormData = res[0];
      //console.log(this.settingsFormData);
      this.tncRepeater = this.settingsFormData.AllTermsConditions;
      this.convertObjToTxt();
      this.contactList = this.settingsFormData.ContactDetails;

    },
    err => {
      console.log(err);
    })
  }

  convertObjToTxt = function(){
    for(var i=0; i<this.tncRepeater.length; i++){
      var d = this.tncRepeater[i].TermsConditions;
      var a = '';
      for(var j=0; j<d.length; j++){
        a += d[j].line;
        if(j<d.length-1){ a += ' ## ' }
      }
      this.tncRepeater[i].TermsConditions = a;
    }
  };

  generateHtml = function(type, object){
    if(type == 'addContacts'){
      //var obj = {"contactType":"", "contactNumber":""}
      this.settingsFormData.ContactDetails.push(this.contactObject);
    }
    if(type == 'removeContacts'){
      //this.contactList.splice(this.contactList.length-1, 1);
      for(var i=0; i<this.contactList.length; i++){
        if(  this.contactList[i].contactType == object.contactType && this.contactList[i].contactNumber == object.contactNumber  ){
          this.contactList.splice(i, 1);
          break;
        }
      }
    }
    this.contactList = this.settingsFormData.ContactDetails;
  }

  convertTxtToObj = function(txt){
    var t1 = txt;
    var t2 = t1.split('##');
    var t3 = [];
    for (var i = 0; i < t2.length; i++) {
      t3.push({"line":t2[i]});
    };
    return t3;
  }
  
  settingsForm = function(){

    for(var i=0; i<this.tncRepeater.length; i++){
      this.tncRepeater[i].TermsConditions = this.convertTxtToObj(this.tncRepeater[i].TermsConditions);
    }
    
    let settingsFormDataUpdated = {
        "Settings":"Settings",
        "AllPageTitle":this.settingsFormData.AllPageTitle,
        "Address":this.settingsFormData.Address,
        "LogoPath":this.settingsFormData.LogoPath,
        "GSTIN":this.settingsFormData.GSTIN,
        "sac_shn":this.settingsFormData.sac_shn,
        "hsn_shn":this.settingsFormData.hsn_shn,
        "bootStrapTheme":this.settingsFormData.bootStrapTheme,
        "headerPosition":this.settingsFormData.headerPosition,
        "sleekLayout":this.settingsFormData.sleekLayout,
        "ContactDetails":this.contactList,
        "AllTermsConditions":this.tncRepeater,
        "airInvoiceNo":this.settingsFormData.airInvoiceNo,
        "busInvoiceNo":this.settingsFormData.busInvoiceNo,
        "railInvoiceNo":this.settingsFormData.railInvoiceNo,
        "packageInvoiceNo":this.settingsFormData.packageInvoiceNo
    }

    var settingsFormDataFinal = {"obj":settingsFormDataUpdated, "queryOld":"Settings"};
    this.Services.saveOtherDetails(settingsFormDataFinal).subscribe(res =>{
    //this.http.post('/user/saveOtherDetails',this.Services.convertToJson(settingsFormDataFinal)).subscribe(res =>{
    //AuthService.saveOtherDetails(settingsFormDataFinal).then(function (data) {
      //>>>>$rootScope.allpagetitle = localStorage._AllPageTitle = this.settingsFormData.AllPageTitle;
      this.convertObjToTxt();
      alert('Data Saved Successfully');

      var lsMD = localStorage.mainDetails;
      var ls = JSON.parse(localStorage.mainDetails);
      ls.hrd = this.settingsFormData.headerPosition;
      ls.sl = this.settingsFormData.sleekLayout;
      ls.pth = 'css/bootstrap-theme-'+this.settingsFormData.bootStrapTheme+'.css';
      ls.thm = this.settingsFormData.bootStrapTheme;
      
      //>>>>$rootScope.mainClient = ls.cl;
      //>>>>$rootScope.mainTheme = ls.thm;
      $('body').addClass(ls.hrd);
      $('#mainThemePath').attr('href','/assets/'+ls.pth);

      $('#sleekLayoutID').attr('href','/assets/css/sleek-'+ls.sl+'.css');
      /*
      if(ls.sl == "level_1"){
        $('#sleekLayoutID').attr('href','/assets/css/sleek-level-1.css');
      }
      else if(ls.sl == "level_2"){
        $('#sleekLayoutID').attr('href','/assets/css/sleek-level-2.css');
      }
      else if(ls.sl == "level_3"){
        $('#sleekLayoutID').attr('href','/assets/css/sleek-level-3.css');
      }
      else{
        $('#sleekLayoutID').attr('href','/assets/css/blank.css');
      }
      */
      localStorage.mainDetails = JSON.stringify(ls);

    },
    err => {
      console.log(err);
    })

  }
}
