import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { InfoComponent } from './info/info.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { LogComponent } from './log/log.component';
import { MastersComponent } from './masters/masters.component';
import { RegisterComponent } from './register/register.component';
import { ListComponent } from './list/list.component';
import { ReportsComponent } from './reports/reports.component';
import { RequestComponent } from './request/request.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { AddComponent } from './add/add.component';


import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'authentication',
    canActivate:[AuthGuard],
    component:AuthenticationComponent
  },
  {
    path:'dashboard',
    canActivate:[AuthGuard],
    component:DashboardComponent
  },
  {
    path:'info',
    canActivate:[AuthGuard],
    component:InfoComponent
  },
  {
    path:'settings',
    canActivate:[AuthGuard],
    component:SettingsComponent
  },
  {
    path:'notification',
    canActivate:[AuthGuard],
    component:NotificationComponent
  },
  {
    path:'log',
    canActivate:[AuthGuard],
    component:LogComponent
  },
  {
    path:'masters',
    canActivate:[AuthGuard],
    component:MastersComponent
  },
  {
    path:'register',
    component:RegisterComponent
  },
  {
    path:'list',
    canActivate:[AuthGuard],
    component:ListComponent
  },
  {
    path:'reports',
    canActivate:[AuthGuard],
    component:ReportsComponent
  },
  {
    path:'request',
    canActivate:[AuthGuard],
    component:RequestComponent
  },
  {
    path:'thankyou',
    component:ThankyouComponent
  },
  {
    path:'uploads',
    component:ThankyouComponent
  },
  {
    path:'uploads/:pdfFile',
    component:ThankyouComponent
  },
  {
    path:'add',
    canActivate:[AuthGuard],
    component:AddComponent
  },
  {path:'logout', redirectTo:'login',pathMatch:'full'},
  {path:'', redirectTo:'login',pathMatch:'full'},
  {path:'**', redirectTo:'login',pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
