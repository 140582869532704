import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private Services:CommonService, private router:Router){
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('canActivate-------');
    //console.log('------>> '+ this.Services.getUserLoggedIn() );
    if(this.Services.getUserLoggedIn()){
      //this.router.navigate(["/dashboard"]);
      return true;
    }
    else{
      setTimeout(()=> {
        this.router.navigate(["/login"]);
      }, 150);
      return false;
    }
    ///return true;
  }
  
}

