import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../common.service';
import { AirService } from './air.service';
import { BusService } from './bus.service';
import { TrainService } from './train.service';
//import { availableTrainCities, availableAirCities } from './citylist.json';
import { concat } from 'rxjs/internal/operators/concat';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  error = false;
  errorMessage = '';
  //var status = AuthService.header({ 'event': 'show', 'activeLink': 'none', 'pageCheck': 'addPage' });
  monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  monthArrSmall = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  patchFor_updateChargerEvents = true;
  addFormJsonDataTypeVal = 'add';
  addFormJsonDataTypeID = null;
  ifNotPackageHideElementFlag = true;

  dateDD = ['DD', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  dateMM = ['MM', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  dateYY = ['YY',2016];
  days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  bookingTypeCount = true;
  otherChargesFlag = false;
  showSubmitFlag=true;
  
  readonlyPaymentByChequeNo = false;
  readonlyPaymentByBankName = false;
  paymentStatusFlag = false;
  AirWrap = false;
  BusWrap = false;
  PackageWrap = false;
  TrainWrap = false;
  HotelWrap = false;
  VisaWrap = false;
  VehicleWrap = false;
  ExpenseWrap = false;
  clientType = true;
  clientTypeWidth = 'col-md-2';
  packageTicketFlag = false;
  submitButton = 'Submit';
  paymentStatusList=[];
  roundTripNewReferenceInvoiceNo:any;

  viewOtherDetailsData:any;
  specialClientFunc:any;
  updateDropdownAdd:any;

  autoFillAirArea:any;
  autoFillBusArea:any;
  autoFillTrainArea:any;
  allTravelName:any;
  airPreferenceList:any;
  busTypeList:any;
  all_flagHiddenServiceCharges:any;
  paymentByList:any;
  busTravelPaymentStatusList:any = [];

  agentList:any;
  airClassList:any;
  airFlightList:any;
  clientList:any;
  trainClassList:any;
  trainQuotaList:any;
  trainNumberList:any;
  tourPackageList:any;
  roomTypeList:any;
  visaTypeList:any;
  expenseList:any;
  noOfNightsList:any;
  noOfRoomsList:any;
  addFormData:any={};
  ls:any;
  tourPackageViewObj:any;

  specialClient:any={};

  serverData1:any;
  bookingTypeList:any = [];

  addMastersFormData={
    trainNumber:0,
    trainName:''
  }
  availableTrainCitiesArr=[];
  availableAirCitiesArr=[];
  

  constructor(private http:HttpClient, private router:Router, private Services:CommonService, private AirSer:AirService, private BusSer:BusService, private TrainSer:TrainService) { }

  ngOnInit() {

      let nd = ((new Date()).getFullYear())+1;
      for(let y=2017; y<=nd; y++){ this.dateYY.push(y); }

      this.Services.checkValidURL('add');
      this.loadDataOnload();
      this.specialClient = this.Services.specialClientObj();
      this.availableTrainCitiesArr = JSON.parse(localStorage.TrainCities);
      this.availableAirCitiesArr = JSON.parse(localStorage.AirCities);

      this.addFormData['chargesDetails']['quota'] = 0;
      this.addFormData['chargesDetails']['eTicket'] = 0;
      this.addFormData['chargesDetails']['pg'] = 0;
      this.addFormData['chargesDetails']['catering'] = 0;
      this.addFormData['chargesDetails']['convenience'] = 0;
      this.addFormData['chargesDetails']['insurance'] = 0;
      this.addFormData['chargesDetails']['ticketFare'] = 0;
      this.addFormData['chargesDetails']['travelAgentCharge'] = 0;
      this.addFormData['chargesDetails']['totalOtherChargesData'] = [];
      this.addFormData['chargesDetails']['taxes'] = 0;
      this.addFormData['chargesDetails']['discountFrmAirlines'] = 0;
      this.addFormData['chargesDetails']['service'] = 0;
      this.addFormData['chargesDetails']['miscCharges'] = 0;
      this.addFormData['chargesDetails']['total'] = 0;
      this.addFormData['chargesDetails']['serviceTax'] = 0;
      this.addFormData['chargesDetails']['sgstPer'] = 0;
      this.addFormData['chargesDetails']['sgstPer'] = 0;
      this.addFormData['chargesDetails']['igstPer'] = 0;
      this.addFormData['chargesDetails']['grandTotal'] = 0;
      
      this.Services.viewOtherDetails({"Settings":"Settings"}).subscribe(res =>{
          let data = res[0];
          this.viewOtherDetailsData = data;
          this.specialClient = this.Services.specialClientFunc(data.specialClient, this.Services.specialClientObj());//Special Settings for the client

          this.updateDropdownAdd = function(data) {
              var data = JSON.parse(data);
              this.paymentByList = data.paymentByList;
              this.paymentStatusList = data.paymentStatusList;

              this.addFormData.paymentDetails.paymentBy = data.paymentByList[0].paymentByName;
              this.addFormData.agentDetails.agentType = 'Select';
              this.addFormData.paymentDetails.paymentStatus = data.paymentStatusList[1].statusName;

              this.busTravelPaymentStatusList = data.busTravelPaymentStatusList;
              this.addFormData.paymentDetails.busTravelPaymentStatus = data.busTravelPaymentStatusList[1].statusName;

              this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.airInvoiceNo);
              this.airPreferenceList = data.airPreferenceList;
              this.addFormData.airPreference = data.airPreferenceList[0].airPreferenceName;

              //this.bookingTypeList = this.viewOtherDetailsData.bookingType;
              this.bookingTypeList = JSON.parse(localStorage._dynamicBTypeList);
              this.addFormData.bookingType = this.bookingTypeList[0].bookingName;

              if ((JSON.stringify(this.bookingTypeList)).indexOf('Package') == -1) {
                  this.ifNotPackageHideElementFlag = false;
              }

              setTimeout(()=> {
                  this.addAction('updateBookingType');
                  this.addAction('getAllTravelName', '');
              }, 150);
          }
          if (localStorage._jsonDropdown == undefined) {
              this.Services.jsonDropdown().subscribe(res =>{
                  localStorage._jsonDropdown = JSON.stringify(res);
                  this.updateDropdownAdd(localStorage._jsonDropdown);
              },
              err => {
                console.log(err);
              });
          } else {
              this.updateDropdownAdd(localStorage._jsonDropdown);
          }
      },
      err => {
        console.log(err);
      });
      
      var data = { listFormJsonData2: { searchType: {} } }
      this.Services.listMastersData(data).subscribe(res =>{
          let data1 = res;
          this.serverData1 = data1;
        ////////SULAMAN addFormData.bookingType
        this.agentList = (this.getDropdownData(data1, 'agentList', 'agentType', true)).filter((al) => { 
            if(al.bookingType != undefined){
                if(al.bookingType == this.addFormData.bookingType ){
                    return al;
                }
            }
        });
        //this.agentList = this.getDropdownData(data1, 'agentList', 'agentType', true);

        this.airClassList = this.getDropdownData(data1, 'airClassList', 'airClassName', true);
        this.airFlightList = this.getDropdownData(data1, 'airFlightList', 'airFlightName', true);
        this.clientList = this.getDropdownData(data1, 'clientList', 'clientName', true);
        //var dummyEntry = {"cancelRail":"0","cancelService":"0","clientAddress":"-","clientContactPerson":"","clientName":"Select","clientNumber":"0","clientType":"Individual","pg":"0","service":"0","serviceTax":"0","type":"clientList"};
        //this.clientList = (((this.Services._ObjOrdering( false, 'clientName', this.clientList)).push(dummyEntry))).reverse();
        //this.clientList = this.Services._ObjOrdering( true, 'clientName', this.clientList);
        this.clientList = this.Services._ObjOrdering( true, 'clientName', this.clientList);
        this.clientList = this.Services._getActiveClients(this.clientList);
        
        this.trainClassList = this.getDropdownData(data1, 'trainClassList', 'className', true);
        this.trainQuotaList = this.getDropdownData(data1, 'trainQuotaList', 'quotaName', true);
        this.tourPackageList = this.getDropdownData(data1, 'tourPackageList', 'packageName', true);
        this.roomTypeList = this.getDropdownData(data1, 'hotelRoomTypeList', 'roomTypeName', true);
        this.visaTypeList = this.getDropdownData(data1, 'visaTypeList', 'visaTypeName', true);
        this.expenseList = this.getDropdownData(data1, 'expenseList', 'expenseName', true);
        
        //this.addFormData.agentDetails = {};
        
        if( this.agentList.length ){
            this.addFormData.agentDetails.agentType = this.agentList[0].agentType;
        }
        this.addFormData.airClass = this.airClassList[0].airClassName;
        if( this.airFlightList.length != 0 ){
            this.addFormData.airFlight = this.airFlightList[0].airFlightName;
        }
        this.addFormData.client = this.clientList[0].clientName;
        //this.addFormData.client = this.Services._ObjOrdering( true, 'clientName', this.clientList[0].clientName);
        this.addFormData.class = this.trainClassList[0].className;
        this.addFormData.quota = this.trainQuotaList[0].quotaName;

        this.addFormData.advanceBooking = 'false';
        this.addFormData.roundTrip = 'false';
        this.addFormData.roundTripNew = 'false';
        this.addFormData.roundTripNewReferenceInvoiceNo = '';

        this.addFormData.tourPackage = '';
        //console.log('check1',this.tourPackageList.length);
        if( this.tourPackageList.length > 0 ){
            this.addFormData.tourPackage = this.tourPackageList[0].packageName;
        }
        var data = { listFormJsonData2: { searchType: 'tn' } }
        this.Services.globalListMastersData(data).subscribe(res =>{
          this.trainNumberList = this.getDropdownData(res, 'trainNumberList', 'trainNumber', false);
          this.addAction('checkAgentCount','');
        },
        err => {
          console.log(err);
        });
      
      $(".datepicker").datepicker({
        dateFormat: 'dd M, yy',
        changeMonth: true,
        changeYear: true
      });
      
      this.ls = JSON.parse(localStorage._booking);
  
      if (this.ls.addUpdate == 'edit') {
        //console.log('Edit mode start');
        this.patchFor_updateChargerEvents = false;
        this.addFormJsonDataTypeVal = 'edit';
        
        $('body').addClass('loading');
        //setTimeout(()=> {
            var obj = { 'otherDetails.otherDetailsId': this.ls.id };
            //this.getJsonData(obj);
            //console.log(obj, this.ls.fullObject);
            this.loadInputData(this.ls.fullObject);
            this.submitButton = 'Update';
            setTimeout(()=> {            
                this.addAction('updateCharger','');
                setTimeout(()=> {
                    $('body').removeClass('loading');
                    this.patchFor_updateChargerEvents = true;
                    setTimeout(()=> {
                        if( isNaN(this.addFormData.boarding) || this.addFormData.boarding == '' || this.addFormData.boarding == undefined ){
                            this.addFormData.boarding = '-';
                        }
                        if( isNaN(this.addFormData.chargesDetails.igstPer) ){
                            this.addFormData.chargesDetails.igstPer = 0;
                            this.addFormData.chargesDetails.igstVal = 0;
                        }
                    }, 200);
                }, 500);
            }, 1000);
        //}, 500);
      } else {
          this.submitButton = 'Submit';
      }
    
      $(document).ready(function() {
        $(window).keydown(function(event) {
            if (event.keyCode == 13) {
                event.preventDefault();
                return false;
            }
        });
      });
    
    },
    err => {
      console.log(err);
    });

  }

  getInvoiceVal = function(param) {
    var p1 = (param).split('-');
    var p2 = p1[0] + '-' + (parseInt(p1[1]) + 1);
    return p2;
  }

  getDropdownData = function(data, type, typeName, selectFlag) {
    if(data == undefined){return [{}];}
    
    var dataFinal = [];
    //console.log('check2',data.length);
    for (var i = 0; i < data.length; i++) {
        if (data[i].type == type) {
            dataFinal.push(data[i]);
        }
    }
    if (typeName == 'trainNumber') {
        var dataFinal2 = '';
        dataFinal2 += '{';
        for (var i = 0; i < dataFinal.length; i++) {
            var tn1 = dataFinal[i].trainNumber;
            var tn2 = dataFinal[i].trainName;
            dataFinal2 += '"train_' + tn1 + '":"' + tn2 + '"';
            if (i != dataFinal.length - 1) { dataFinal2 += ','; }
        }
        dataFinal2 += '}';

        return JSON.parse(dataFinal2);
    }
    return dataFinal;
  }

  multipleShow = function(p1, t1, p2) {
      if (t1 == 'and') {
          if (p1 == true && p2 == true) { return true; }
      } else if (t1 == 'or') {
          if (p1 == true || p2 == true) { return true; }
      }
      return false;
  }

  addAction = function(param, e='') { //actionFunction
    if (param == 'updatePackageTicket') {
        var t = this.packageTicketFlag = eval(t);
    }

    if (param == 'getTrainName') {
        var tn1 = this.addFormData.trainNo;
        if (eval('this.trainNumberList.train_' + tn1) != undefined) {
            this.addFormData.trainName = eval('this.trainNumberList.train_' + tn1);
        } else {
            this.addMastersFormData.trainNumber = tn1;
            this.addMastersFormData.trainName = '';
            //setTimeout(()=>{
            //alert('Kindly add the Train name!!');
            //},500);
            if(tn1!=''){
                $('a[data-target="#addTrainName"]').click();
            }
        }
    }
    if (param == 'updateCharger' || param == 'updateChargerOnBlur' || param == 'focusToAdult') {
        
        if( this.clientList == undefined ){ return false }

        var bt = this.addFormData.bookingType;

        var dd1={
            "type":"clientList","clientName":"","clientAddress":"-","clientNumber":"-",
            "clientContactPerson":"-","pg":"0","service":"0","serviceTax":"0","cancelRail":"0",
            "cancelService":"0","clientType":"Individual","clientOfType":"Individual","cgstPer":"0","sgstPer":"0",
            "clientGSTIN":"0","clientStatus":"true","igstPer":"0","clientSAC":"0","typeOfClient":"-"
        };

        for(let c1=0; c1<this.clientList.length; c1++){
            var client1 = (this.clientList[c1].clientName).trim().toLowerCase();
            var client2 = (this.addFormData.client).trim().toLowerCase();
            if( client1 == client2 ){ dd1 = this.clientList[c1]; break; }
        }
        
        var dd2;
        for(let c2=0; c2<this.trainClassList.length; c2++){
            if( this.trainClassList[c2].className == this.addFormData.class ){ dd2 = this.trainClassList[c2]; break; }
        }
        var dd3;
        for(let c3=0; c3<this.trainQuotaList.length; c3++){
            if( this.trainQuotaList[c3].quotaName == this.addFormData.quota ){ dd3 = this.trainQuotaList[c3]; break; }
        }

        var dd4 = $('.selectTourPackage option:selected');

        var tf = parseInt(this.addFormData.chargesDetails.ticketFare);
        var tx = parseInt(this.addFormData.chargesDetails.taxes);
        var da = parseInt(this.addFormData.chargesDetails.discountFrmAirlines);

        var pc = function() {
            //return false;
            var len = $('.pdRowWrapper .inpName').length;
            var inputLen = 0;
            for (var i = 0; i < len; i++) {
                var val = $('.pdRowWrapper .inpName').eq(i).val();
                if (val.length) { inputLen++ }
            }
            //console.log('inputLen -- '+inputLen);
            /*>>>>>>>>
            if (this.PackageWrap == false) {
                this.addFormData.adult = inputLen;
            }
            */
            return inputLen;
        }
        if (e == 'clientSelectChange') {
            //console.log('dd1---', dd1);
            if(dd1 == undefined){
                this.addFormData.address = ''
                this.addFormData.clientGSTIN = '';
                this.addFormData.clientType = '';
            }
            else{
                this.addFormData.address = dd1.clientAddress;
                this.addFormData.clientGSTIN = dd1.clientGSTIN;
                this.addFormData.clientType = dd1.clientType;
            }
            //console.log('dd1---', dd1);
            //this.addFormData.address = dd1.address;
            //this.addFormData.clientGSTIN = dd1.clientGSTIN;
            //this.addFormData.clientType = dd1.clientType;
        }

        if (this.addFormData.paymentDetails.discount == '' || this.addFormData.paymentDetails.discount == 0) {
            this.addFormData.paymentDetails.discount = 0;
        }

        var qt = 0,
            et, catc, conven, insur, pg, tac, sr, st, cgstPer, sgstPer, igstPer, cr, cs, ct, cn, apk = 0,
            tk = 0,
            misC = 0,
            th = 0,
            pk_cstA, pk_cstC, pk_cstOriA, pk_cstOriC, pk_cntA, pk_cntC, pk_dis;
        pk_cstA = parseInt(dd4.attr('data-costAdult'));
        pk_cstC = parseInt(dd4.attr('data-costChild'));
        pk_cstOriA = parseInt(dd4.attr('data-costOriginalAdult'));
        pk_cstOriC = parseInt(dd4.attr('data-costOriginalChild'));
        pk_cntA = parseInt(this.addFormData.adult);
        pk_cntC = parseInt(this.addFormData.child);
        pk_dis = parseInt(this.addFormData.paymentDetails.discount);


        if (param == 'updateCharger' || param == 'focusToAdult') {
            et = dd2.eTicket;
            if (et == undefined || et == '' || isNaN(et)) { et = 0 }
            else{ et = parseInt(et) }

            if(dd1 == undefined){
                pg = 0;
            }
            else{
                pg = parseInt(dd1.pg);
                if (pg == '' && this.addFormData.chargesDetails.pg == 0 || isNaN(pg) && this.addFormData.chargesDetails.pg == 0) {
                    pg = 0;
                } else if (pg == 0 && Math.ceil(this.addFormData.chargesDetails.pg) > 0) {
                    pg = this.addFormData.chargesDetails.pg;
                } else if (this.addFormData.chargesDetails.pg != pg) {
                    pg = this.addFormData.chargesDetails.pg;
                }
                pg = pg * 1;
            }

            if (catc == '' || this.addFormData.chargesDetails.catering == 0) { catc = 0 } 
            else { catc = this.addFormData.chargesDetails.catering }
            if (conven == '' || this.addFormData.chargesDetails.convenience == 0) { conven = 0 } 
            else { conven = this.addFormData.chargesDetails.convenience }
            if (insur == '' || this.addFormData.chargesDetails.insurance == 0) { insur = 0 } 
            else { insur = this.addFormData.chargesDetails.insurance }
            
            sr = parseInt(dd1.service) * pc();
            st = parseInt(dd1.serviceTax);

            cgstPer = parseInt(dd1.cgstPer);
            sgstPer = parseInt(dd1.sgstPer);
            igstPer = parseInt(dd1.igstPer);
            
            if (st == undefined || st == '' || isNaN(st) ) { st = 0; }
            if (cgstPer == undefined || cgstPer == '' || isNaN(cgstPer) ) { cgstPer = sgstPer = 0; }
            if (igstPer == undefined || igstPer == '' || isNaN(igstPer) ) { igstPer = 0; }
            //console.log('heed to check cgstPer value...');
            cr = parseInt(dd1.cancelRail);
            cs = parseInt(dd1.cancelService);
            ct = dd1.clientType;
            cn = dd1.clientNumber;

            this.addFormData.typeOfClient = dd1.typeOfClient;
            this.addFormData.paymentDetails.paymentFrom = this.addFormData.client;

            this.clientTypeWidth = 'col-md-8';
            if (ct == 'Individual') {
                this.clientType = false;
                this.clientTypeWidth = 'col-md-8';
                this.addFormData.contactNo1 = cn;
                if (this.addFormData.bookingType == 'Air') {
                    this.clientTypeWidth = 'col-md-9';
                } else if (this.addFormData.bookingType == 'Package') {
                    this.clientTypeWidth = 'col-md-10';
                }
                else{
                    this.clientTypeWidth = 'col-md-8';
                }
            } else if (ct == 'Company') {
                this.clientType = true;
                this.clientTypeWidth = 'col-md-2';
                if (this.addFormData.bookingType == 'Air') {
                    this.clientTypeWidth = 'col-md-3';
                } else if (this.addFormData.bookingType == 'Package') {
                    this.clientTypeWidth = 'col-md-4';
                }
                else{
                    this.clientTypeWidth = 'col-md-4';
                }
            } else {
                alert('Selected client is not having "Client Type", hence Grand Total will not Work');
                return false;
            }
        } else if (param == 'updateChargerOnBlur') {
            et = parseInt(this.addFormData.chargesDetails.eTicket);
            if (et == '' || isNaN(et)) { et = 0 }
            pg = (this.addFormData.chargesDetails.pg) * 1;
            catc = (this.addFormData.chargesDetails.catering) * 1;
            conven = (this.addFormData.chargesDetails.convenience) * 1;
            insur = (this.addFormData.chargesDetails.insurance) * 1;
            tac = parseInt(this.addFormData.chargesDetails.travelAgentCharge);
            sr = parseInt(this.addFormData.chargesDetails.service);
            misC = parseInt(this.addFormData.chargesDetails.miscCharges);
            st = parseInt(this.addFormData.chargesDetails.serviceTax);
            cgstPer = parseInt(this.addFormData.chargesDetails.cgstPer);
            sgstPer = parseInt(this.addFormData.chargesDetails.sgstPer);
            igstPer = parseInt(this.addFormData.chargesDetails.igstPer);
            cr = parseInt(this.addFormData.chargesDetails.cancellationRailway);
            cs = parseInt(this.addFormData.chargesDetails.cancellationService);
        }

        //console.log('cgstPer-'+cgstPer+'-');
        if (cgstPer == '' || isNaN(cgstPer)) {
            cgstPer = 0;
            sgstPer = 0;
        }

        var tt = 0;
        var gt = 0;
        
        if (bt == 'Air') {
            et = catc = conven = insur = pg = 0;
            tt = tf + tx + sr + misC;
            
            /*if (st == 0 || st == '') {
                gt = Math.round(tt + (sr * cgstPer / 100) + (sr * sgstPer / 100));
            } else if (cgstPer == 0 && sgstPer == 0 || cgstPer == '' && sgstPer == '') {
                gt = Math.round(tt + (sr * st / 100));
            } else if (cgstPer > 0 && sgstPer > 0 && igstPer > 0 && st > 0) {
                alert('Cannot proceed further as you cannot have "Service Tax + CGST + SGST" all togather');
                return false;
            }*/
            //alert('GST Pending');
        } else if (bt == 'Bus') {
            //console.log('Need to work from here');
            et = catc = conven = insur = pg = sr = tx = 0;
            sr = parseInt(this.addFormData.chargesDetails.service);
            st = parseInt(this.addFormData.chargesDetails.serviceTax);
            //cgstPer = parseInt(this.addFormData.chargesDetails.cgstPer);
            //sgstPer = parseInt(this.addFormData.chargesDetails.sgstPer);
            //igstPer = parseInt(this.addFormData.chargesDetails.igstPer);
            if (sr == '') { sr = 0 }
            if (st == '') { st = 0 }
            tt = (tf + sr + misC);

            /*if (st == 0 || st == '') {
                gt = Math.ceil(tt + (sr * cgstPer / 100) + (sr * sgstPer / 100));
            } else if (cgstPer == 0 && sgstPer == 0 || cgstPer == '' && sgstPer == '') {
                gt = Math.ceil(tt + (sr * st / 100));
            } else {
                console.log('Note: GST & ServiceTax both are empty!!');
                gt = Math.ceil(tt);
            }*/
        } else if (bt == 'Train') {
            qt = parseInt(this.addFormData.chargesDetails.quota);
            tac = parseInt(this.addFormData.chargesDetails.travelAgentCharge);
            if( isNaN(tac) ){ tac = 0 }

            tx = 0; 
            tt = (qt + et + catc + conven + insur + pg + tac + tf + sr + tx + misC); 

            /*if (st == 0 || st == '') {
                gt = Math.ceil(tt + (sr * cgstPer / 100) + (sr * sgstPer / 100));
            } else if (cgstPer == 0 && sgstPer == 0 || cgstPer == '' && sgstPer == '') {
                gt = Math.ceil(tt + (sr * st / 100));
            } else if (cgstPer > 0 && sgstPer > 0 && st > 0) {
                setTimeout(function(){
                    alert('Cannot proceed further as you cannot have "Service Tax + CGST + SGST" all togather');
                },1000);
                return false;
            } else {
                console.log('Note: GST & ServiceTax both are empty!!');
                gt = Math.ceil(tt);
            }*/

            /*
            this.addFormData.chargesDetails.cgstVal = sr * cgstPer / 100;
            this.addFormData.chargesDetails.sgstVal = sr * sgstPer / 100;
            this.addFormData.chargesDetails.igstVal = sr * igstPer / 100;
            */
        } else if (bt == 'Hotel') {
            qt = parseInt(this.addFormData.chargesDetails.quota);
            tac = parseInt(this.addFormData.chargesDetails.travelAgentCharge);
            if( isNaN(tac) ){ tac = 0 }

            tx = 0; 
            tt = (qt + et + catc + conven + insur + pg + tac + tf + sr + tx + misC); 
        } else if (bt == 'Visa') {
            qt = parseInt(this.addFormData.chargesDetails.quota);
            tac = parseInt(this.addFormData.chargesDetails.travelAgentCharge);
            if( isNaN(tac) ){ tac = 0 }

            tx = 0; 
            tt = (qt + et + catc + conven + insur + pg + tac + tf + sr + tx + misC); 
        } else if (bt == 'Package') {
            et = pg = tx = 0;
            tt = ((pk_cstA * pk_cntA) + (pk_cstC * pk_cntC));
            tf = tt;
            gt = Math.ceil(tt + (tt * st / 100)) - pk_dis;
            sr = Math.round(tt - ((pk_cstOriA * pk_cntA) + (pk_cstOriC * pk_cntC) - pk_dis));
        } else if (bt == 'Expense') {
            qt = 0;
            tac = 0;
            if( isNaN(tac) ){ tac = 0 }

            tx = 0; 
            tt = (qt + et + catc + conven + insur + pg + tac + tf + sr + tx + misC); 
        }
        else if (bt == 'Vehicle') {
            this.addFormData.chargesDetails.totalKms = parseInt(this.addFormData.vehicleEndingKms) - parseInt(this.addFormData.vehicleStartingKms);
            apk = parseInt(this.addFormData.chargesDetails.amountPerKm);
            tk = parseInt(this.addFormData.chargesDetails.totalKms);
            th = parseInt(this.addFormData.chargesDetails.totalHours);

            et = catc = conven = insur = pg = tx = 0;
            tt = apk * tk;
            tf = tt;
            gt = Math.ceil(tt + (tt * st / 100)) - pk_dis;
            sr = this.addFormData.chargesDetails.service;
            misC = parseInt(this.addFormData.chargesDetails.miscCharges);
            //alert('GST Pending');
        }

        this.addFormData.chargesDetails.cgstVal = (sr+misC) * cgstPer / 100;
        this.addFormData.chargesDetails.sgstVal = (sr+misC) * sgstPer / 100;
        this.addFormData.chargesDetails.igstVal = (sr+misC) * igstPer / 100;

        gt = Math.round( tt+ st+ this.addFormData.chargesDetails.cgstVal+ this.addFormData.chargesDetails.sgstVal+ this.addFormData.chargesDetails.igstVal );

        /*
        //console.log(st,cgstPer,sgstPer,igstPer);
        if (st >= 0 && cgstPer >= 0 && sgstPer >= 0 && igstPer >= 0 ) {
            if (st > 0 && cgstPer == 0 && sgstPer == 0 && igstPer == 0 ) { gt = Math.round(tt + (sr * st / 100)); }
            else if (st == 0 && cgstPer > 0 && sgstPer > 0 && igstPer == 0 ) { gt = Math.round(tt + (sr * cgstPer / 100) + (sr * sgstPer / 100)); }
            else if (st == 0 && cgstPer == 0 && sgstPer == 0 && igstPer > 0 ) { gt = Math.round(tt + (sr * igstPer / 100) ); }
            //else{ alert('Please check GST fields'); return false; }
            else{ gt = Math.round(tt); }
        }
        else{ gt = Math.round(tt); }*/

        setTimeout(()=>{
            //console.log( cgstPer , igstPer , sgstPer );
            if( cgstPer > 0 && igstPer > 0 || sgstPer > 0 && igstPer > 0 || cgstPer > 0 && sgstPer == 0 || cgstPer == 0 && sgstPer > 0 ){
                alert('Please check GST %');
            }
            //console.log('blur Value 2',  this.addFormData.chargesDetails );
        },4000);
        //else{ alert('Please check GST fields'); return false; }


        /*else if (bt == 'Package') {
            et = pg = tx = 0;
            tt = ((pk_cstA * pk_cntA) + (pk_cstC * pk_cntC));
            tf = tt;
            gt = Math.ceil(tt + (tt * st / 100)) - pk_dis;
            sr = Math.round(tt - ((pk_cstOriA * pk_cntA) + (pk_cstOriC * pk_cntC) - pk_dis));
            //alert('GST Pending');
        } else if (bt == 'Vehicle') {
            console.log('Working pending');
            this.addFormData.chargesDetails.totalKms = parseInt(this.addFormData.vehicleEndingKms) - parseInt(this.addFormData.vehicleStartingKms);
            apk = parseInt(this.addFormData.chargesDetails.amountPerKm);
            tk = parseInt(this.addFormData.chargesDetails.totalKms);
            th = parseInt(this.addFormData.chargesDetails.totalHours);

            et = catc = conven = insur = pg = tx = 0;
            tt = apk * tk;
            tf = tt;
            gt = Math.ceil(tt + (tt * st / 100)) - pk_dis;
            sr = this.addFormData.chargesDetails.service;
            misC = parseInt(this.addFormData.chargesDetails.miscCharges);
            //alert('GST Pending');
        }*/

        var ad = parseInt(this.addFormData.adult);
        var ch = parseInt(this.addFormData.child);
        var toc = parseInt(this.addFormData.chargesDetails.totalOtherCharges);////this is used in Airlines
        
        if (isNaN(toc)) { toc = 0 }
        tt += toc;
        
        if ((tt+'').indexOf('NaN') >= 0) { tt=0 }
        //if( ls.addUpdate == 'edit' ){return false;}
        if (this.patchFor_updateChargerEvents == false) { return false }

        //this.addFormData.chargesDetails.totalOtherChargesData = [];
        this.addFormData.total = ad + ch;
        this.addFormData.chargesDetails.quota = qt;
        this.addFormData.chargesDetails.eTicket = et;
        this.addFormData.chargesDetails.pg = pg;
        this.addFormData.chargesDetails.catering = catc;
        this.addFormData.chargesDetails.convenience = conven;
        this.addFormData.chargesDetails.insurance = insur;
        this.addFormData.chargesDetails.travelAgentCharge = tac;
        this.addFormData.chargesDetails.ticketFare = tf;
        this.addFormData.chargesDetails.service = sr;
        this.addFormData.chargesDetails.miscCharges = misC;
        this.addFormData.chargesDetails.taxes = tx;
        this.addFormData.chargesDetails.discountFrmAirlines = da;
        this.addFormData.chargesDetails.total = tt;
        this.addFormData.chargesDetails.serviceTax = st;
        this.addFormData.chargesDetails.cgstPer = cgstPer;
        this.addFormData.chargesDetails.sgstPer = sgstPer;
        this.addFormData.chargesDetails.igstPer = igstPer;
        
        this.addFormData.chargesDetails.grandTotal = gt + toc;
        this.addFormData.paymentDetails.pendingAmount = (gt + toc)- (this.addFormData.paymentDetails.paymentReceived*1);

        this.addFormData.chargesDetails.cancellationRailway = cr;
        this.addFormData.chargesDetails.cancellationService = cs;
    }
    if (param == 'focusToAdult') {
        //>>>>>>>>>>>if (eval('this.' + e) == undefined) {
            //console.log('e.length-------',eval('this.'+e),'------',eval('this.'+e+'.length'),'------',e.length);
            if( eval('this.'+e+'.length') <= 0 ){
                $('#focusToAdult').focus();
                console.log('check >>> ', e, e.replace(/[a-z A-Z]/g,''));
                //this.addFormData.adult = e.replace(/[a-z A-Z]/g,'');
            }
        //>>>>>>>>>>>}
    }
    if (param == 'advancePaymentBlur') {
        this.addFormData.paymentDetails.pendingAmount -= this.addFormData.paymentDetails.advancePayment;
    }
    if (param == 'editMode_agentType') {
    }
    if (param == 'agentType') {
        this.addFormData.agentDetails.userId = '';
        this.addFormData.agentDetails.payment = '';
        this.addFormData.agentDetails.agentGSTIN = '';

        var val = this.addFormData.agentDetails.agentType;
        //console.log('check8',this.agentList.length);
        for (var i = 0; i < this.agentList.length; i++) {
            if (this.agentList[i].agentType == val) {
                this.addFormData.agentDetails.payment = this.agentList[i].agentPaymentMode;
                break;
            }
        }
    }

    if (param == 'paymentStatusSorting') {
        var val1 = this.addFormData.paymentDetails.paymentStatus;
        var val2 = this.addFormData.chargesDetails.grandTotal;
        this.addFormData.paymentDetails.paymentReceived = 0;
        this.addFormData.paymentDetails.pendingAmount = 0;
        if (val1 == 'Received') {
            this.addFormData.paymentDetails.paymentReceived = val2;
            this.paymentStatusFlag = true;
            var d = new Date();
            this.addFormData.paymentDetails.paymentDateDD = d.getDate() + '';
            this.addFormData.paymentDetails.paymentDateMM = (d.getMonth() + 1) + '';
            this.addFormData.paymentDetails.paymentDateYY = d.getFullYear() + '';
            this.addFormData.paymentDetails.paymentDateTP = this.Services.fullDateTPFunc(this.addFormData.paymentDetails.paymentDateDD,this.addFormData.paymentDetails.paymentDateMM,this.addFormData.paymentDetails.paymentDateYY)
        } else if (val1 == 'Pending') {
            this.addFormData.paymentDetails.pendingAmount = val2;
            this.paymentStatusFlag = false;
        }
    }
    if (param == 'checkPNR') {
        let listFormJsonData1 = {
            searchType: 'filter',
            searchBy: {
                "bookingDetails.pnrNo": this.addFormData.pnrNo,
                "bookingType": this.addFormData.bookingType,
            }
        };
        this.Services.listData({ listFormJsonData2: listFormJsonData1 }).subscribe(res =>{
            var data = res;
            if(data.length >0){
                var str = 'Attention !!! \n\n';
                str += 'This PNR is repeated in below bill no.s \n';
                str += '----------------------------------------\n';
                for(let p=0; p<data.length; p++){
                    var p1= p+1;
                    str += (p1)+') '+data[p].invoiceNo+'\n';
                }
                str += '----------------------------------------\n';
                alert(str);
            }
        });
    }
    if (param == 'updateBookingType') {
        this.AirWrap = this.BusWrap = this.TrainWrap = this.HotelWrap = this.VisaWrap = this.PackageWrap = this.VehicleWrap = this.ExpenseWrap = false;
        this.AirWrapRequired = this.BusWrapRequired = this.TrainWrapRequired = this.HotelWrapRequired = this.VisaWrapRequired = this.PackageWrapRequired = this.VehicleWrapRequired = this.ExpenseWrapRequired = '';

        var type = this.addFormData.bookingType;
        //console.log('type >>> ', type);
        if (type == 'Air') {
            this.AirWrap = true;
            this.AirWrapRequired = 'required'; 
            setTimeout(()=>{
                $(".availableAirCities").autocomplete({
                    source: this.availableAirCitiesArr,
                    minLength: 3
                });
            },1500);
        } else if (type == 'Bus') {
            this.BusWrap = true;
            this.BusWrapRequired = 'required';
        } else if (type == 'Train') {
            this.TrainWrap = true;
            this.TrainWrapRequired = 'required';
            setTimeout(()=>{
                $(".availableTrainCities").autocomplete({
                    source: this.availableTrainCitiesArr,
                    minLength: 3
                });
            },1500);
        } else if (type == 'Hotel') {
            this.HotelWrap = true;
            this.HotelWrapRequired = 'required';
        } else if (type == 'Visa') {
            this.VisaWrap = true;
            this.VisaWrapRequired = 'required';
        } else if (type == 'Package') {
            this.PackageWrap = true;
            this.PackageWrapRequired = 'required';
        } else if (type == 'Vehicle') {
            this.VehicleWrap = true;
            this.VehicleWrapRequired = 'required';
        } else if (type == 'Expense') {
            this.ExpenseWrap = true;
            this.ExpenseWrapRequired = 'required';
        }

        this.agentList = (this.getDropdownData(this.serverData1, 'agentList', 'agentType', true)).filter((al) => { 
            if(al.bookingType != undefined){
                if(al.bookingType == this.addFormData.bookingType ){
                    return al;
                }
            }
        });

        if (this.ls.addUpdate != 'edit') {
            this.addAction('updateCharger');
            this.addFormData.agentDetails.agentType = 'Select';
            this.addFormData.agentDetails.payment = '';
            this.addFormData.packageInfo1.ticketFlag = 'false';
            this.addAction('updateCharger', 'clientSelectChange');

            if (type == 'Air') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.airInvoiceNo); } 
            else if (type == 'Bus') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.busInvoiceNo); } 
            else if (type == 'Train') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.railInvoiceNo); } 
            else if (type == 'Hotel') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.hotelInvoiceNo); } 
            else if (type == 'Visa') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.visaInvoiceNo); } 
            else if (type == 'Package') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.packageInvoiceNo); } 
            else if (type == 'Vehicle') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.vehicleInvoiceNo); }
            else if (type == 'Expense') { this.addFormData.invoiceNo = this.getInvoiceVal(this.viewOtherDetailsData.expenseInvoiceNo); }
            
        }

        if (param == 'checkAgentCount') {
            this.bookingTypeCount = false;
            setTimeout(function() {
                //console.log('check9',this.agentList.length);
                if (this.agentList.length) {
                    for (var a = 0; a < this.agentList.length; a++) {
                        if (this.agentList[a].bookingType == this.addFormData.bookingType) {
                            this.bookingTypeCount = true;
                            break;
                        }
                    }
                }
            }, 100);
        }

    }
    if (param == 'paymentBy') {
        var pb = this.addFormData.paymentDetails.paymentBy;
        if (pb == 'Online') {
            this.readonlyPaymentByChequeNo = false;
            this.readonlyPaymentByBankName = true;
            this.addFormData.paymentDetails.paymentByChequeNo = '';
        } else if (pb == 'Cash') {
            this.readonlyPaymentByChequeNo = false;
            this.readonlyPaymentByBankName = false;
            this.addFormData.paymentDetails.paymentByChequeNo = '';
            this.addFormData.paymentDetails.paymentByBankName = '';
        } else if (pb == 'Cheque') {
            this.readonlyPaymentByChequeNo = true;
            this.readonlyPaymentByBankName = true;
        }
    }

    if (param == 'getAllTravelName') {
        this.allTravelNameArray = ['Anand Travels', 'Vishal Travels', 'Sugama Tourists', 'Canara Pinto', 'Sheethal Travels', 'Seat Seller', 'Reshma bus', 'NEETA', 'VRL'];
        this.allTravelName = (this.allTravelNameArray).join(', ');
        return false;
    }

    if (param == 'autoFillTrainAreaSmartHTML') {
        let monthArrSmallTrain = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        let regx={
            number : /\d+/g,
            numberDecimal_ : /^\d+(\.\d{1,2})?$/g,
            numberDecimal : /^\d+\.\d{0,2}$/,
            numberPattern : function(val){
                return parseInt(  (val).match(this.number))
            },
            stringPattern : function(val){
                let sp1 = (val).match(/[a-zA-Z|/| |0-9]/g);
                while(sp1[0] == ' '){ sp1.shift(); }
                let sp2 = sp1.join('');
                return sp2;
            },
            numberDecimalPattern : function(val){
                return parseInt(  (val).match(this.numberDecimal))
            },
            datePattern: function(val){
                return this.numberPattern(val.replace(/\/+/g,':').split(':')[1].split('-')[0]);
            },
            monthPattern: function(val){
                let m1 = val.replace(/\/+/g,':').split(':')[1].split('-')[1];
                if(m1.length == 2){//number
                    return m1.match(this.numberPattern);
                }
                else if(m1.length == 3){//string
                    return monthArrSmallTrain.indexOf( (m1.toLowerCase()) )+1;
                }
            },
            yearPattern: function(val){
                return this.numberPattern(val.replace(/\/+/g,':').split(':')[1].split('-')[2]);
            }
        }

        $('#_autoFillTrainAreaSmartHTML').html( $('#autoFillTrainArea').val() );
        let ths = $('#_autoFillTrainAreaSmartHTML');
        let myObj = this.addFormData;
        for(let t=0; t<$('#_autoFillTrainAreaSmartHTML').find('table').length; t++ ){
            $('#_autoFillTrainAreaSmartHTML').find('table').eq(t).addClass('table_'+t);
        }
        myObj.trainNo = regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(0).find('td').eq(1).text());
        myObj.journeyDateDD = regx.datePattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(3).find('td').eq(1).text());
        myObj.journeyDateMM = regx.monthPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(3).find('td').eq(1).text());
        myObj.journeyDateYY = regx.yearPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(3).find('td').eq(1).text());
        myObj.transactionNo = regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(1).find('td').eq(0).text());
        myObj.from = ($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(2).find('td').eq(0).text()).split(':')[1];
        myObj.to = ($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(2).find('td').eq(2).text()).split(':')[1];
        myObj.boarding = ($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(3).find('td').eq(0).text()).split(':')[1];        
        myObj.class = ($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(1).find('td').eq(2).text()).replace(')','').split('(')[1];

        myObj.pnrNo = regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(0).find('td').eq(0).text());
        myObj.contactNo1 = regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(5).find('td').eq(0).text());
        myObj.quota = ($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(0).find('td').eq(2).text()).replace(')','').split('(')[1];
        myObj.chargesDetails.pg = regx.numberDecimalPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').eq( ($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').length-2) ).find('td').eq(1).text());
        myObj.chargesDetails.catering = 0;
        myObj.chargesDetails.convenience = 0;
        myObj.chargesDetails.insurance = 0;
        myObj.chargesDetails.ticketFare = regx.numberDecimalPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').eq( ($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').length-1) ).find('td').eq(1).text());
        myObj.chargesDetails.eTicket = 0;
        myObj.chargesDetails.travelAgentCharge = regx.numberDecimalPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').eq( ($('#_autoFillTrainAreaSmartHTML').find('table').eq(11).find('tr').length-3) ).find('td').eq(1).text());

        myObj.chargesDetails.quota = 0;
        myObj.adult = parseInt((regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(9).find('tr').eq(4).find('td').eq(2).text())+'').charAt(0));
        myObj.child = 0;
        
        for(let tr=0; tr<myObj.adult; tr++){
            let tRow = {
                "checkbox" : false, 
                "empId" : "", 
                "empLevel" : "", 
                "name" : regx.stringPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(12).find('tr').eq(tr+1).find('td').eq(1).text()), 
                "sex" : regx.stringPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(12).find('tr').eq(tr+1).find('td').eq(3).text()).charAt(0), 
                "age" : regx.numberPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(12).find('tr').eq(tr+1).find('td').eq(2).text()), 
                "coachNoSeat" : regx.stringPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(12).find('tr').eq(tr+1).find('td').eq(4).text())
            }
            eval('myObj.pd'+tr+' =tRow');
        }

        var at1 = (regx.stringPattern($('#_autoFillTrainAreaSmartHTML').find('table').eq(14).find('tr').eq(0).find('td').eq(1).text()).split(' ')[0]).toLowerCase();
        var at5 = this.agentList;
        for (var i = 0; i < at5.length; i++) {
            if ( (at5[i].agentType).toLowerCase().indexOf(at1) >= 0 ) {
                myObj.agentDetails.agentType = at5[i].agentType;
                myObj.agentDetails.payment = at5[i].payment;
                myObj.agentDetails.userId = at5[i].userId;
                myObj.agentDetails.agentGSTIN = at5[i].agentGSTIN;
                break;
            }
        };

        //console.log(myObj);
        this.addAction('updateCharger');
        this.addAction('updateChargerOnBlur');
    }

    if (param == 'autoFillTrainAreaHTML' || param == 'autoFillTrainAreaPDF' || param == 'autoFillBusAreaHTML' || param == 'autoFillAirAreaPDF' || param == 'autoFillAirAreaHTML') {
     
        var src = undefined;
        if (this.addFormData.bookingType == 'Air') {
            src = this.autoFillAirArea;
            if (src == undefined) { src = $('#autoFillAirArea').val() }
        } else if (this.addFormData.bookingType == 'Bus') {
            src = this.autoFillBusArea;
            if (src == undefined) { src = $('#autoFillBusArea').val() }
        } else if (this.addFormData.bookingType == 'Train') {
            src = this.autoFillTrainArea;
            if (src == undefined) { src = $('#autoFillTrainArea').val() }
        }

        /**/ 
        var autoFillContentFlag = false;
        var getSrcData = function(frm, to, typ) {
            if (src == undefined) { autoFillContentFlag = true; return ''; }
            var a = src.split(frm)[1];
            if (a == undefined) { autoFillContentFlag = true; return ''; }
            if (to != '') {
                a = a.split(to)[0];
                if (a == undefined) { autoFillContentFlag = true; return ''; }
            }
            if (typ == 'num') { return parseInt(a); } else if (typ == 'str') {
                return a.trim();
            } else if (typ == 'numDecimal') { return a.replace(/ /g, '') * 1 }
        }
        
        if (src == '' || src == undefined) {
            alert('Kindly copy paste ticket content');
            return false;
        }

        if (param == 'autoFillBusAreaHTML') {
            this.BusSer.autoFillHtml(src, this.addFormData);
            this.autoFillBusArea = '';
            
            if (this.addFormData.busTravels == 'vrlbus' || this.addFormData.busTravels == 'neetabus' || this.addFormData.busTravels == 'seatsellerbus') {
                //it is commented as it was showing error in VRL
            }
            else {
                this.addAction('updateCharger');
            }
            setTimeout(()=> {
                this.addAction('updateCharger');
                this.addAction('updateChargerOnBlur');
            }, 500);
        }
        
        if (param == 'autoFillTrainAreaPDF') {
            this.addAction('updateCharger', 'clientSelectChange');
            this.TrainSer.autoFillPdf(src, this.addFormData, this.trainClassList, this.agentList, this.trainQuotaList, this.clientList, this.specialClient.all_flagShowTravelAgentCharge);
            this.autoFillTrainArea = '';
            this.addAction('updateChargerOnBlur');
            this.addAction('paymentStatusSorting');

            setTimeout(()=> {
                this.addAction('updateChargerOnBlur');
                this.addAction('updateCharger');
                this.addAction('paymentStatusSorting');
            }, 500);
        }

        if (param == 'autoFillAirAreaHTML') {
            //console.log('autoFillHtml-pending');
            this.AirSer.autoFillHtml(src, e, this.addFormData, this.agentList);
            this.autoFillAirArea = '';
        
            this.otherChargesFlag = true;
            this.addAction('otherChargesBlur');
            this.addAction('updateChargerOnBlur');
            this.addAction('paymentStatusSorting');
        }
        if (param == 'autoFillAirAreaPDF') {
            this.AirSer.autoFillPdf(src, e, this.addFormData, this.agentList);
            this.autoFillAirArea = '';
            
            this.addAction('otherChargesShow');
            this.addAction('otherChargesBlur')  
            this.addAction('updateChargerOnBlur');
            setTimeout(function() {
                this.addAction('updateCharger');
                this.addAction('paymentStatusSorting');
            }, 500);
        }
        
        if (this.autoFillContentFlag == true) {
            alert('Due to incorrect copy/paste/data, some content is missing. Kindly check all the data!');
        }
    }

    if (param == 'updatePackageCost') {
        this.addFormData.packageCostAdult = $('.selectTourPackage option:selected').attr('data-costAdult');
        this.addFormData.packageCostChild = $('.selectTourPackage option:selected').attr('data-costChild');
        this.addFormData.packageOriginalCostAdult = $('.selectTourPackage option:selected').attr('data-costOriginalAdult');
        this.addFormData.packageOriginalCostChild = $('.selectTourPackage option:selected').attr('data-costOriginalChild');
        this.addFormData.packageId = $('.selectTourPackage option:selected').attr('data-id');

        var selectedPacId = $('.selectTourPackage option:selected').attr('data-id');
        //console.log('check10',this.tourPackageList.length);
        for (var p = 0; p < this.tourPackageList.length; p++) {
            var pId = this.tourPackageList[p].packageInfo.id;
            if (selectedPacId == pId) {
                this.addFormData.packageName = this.tourPackageList[p].packageName;
                this.addFormData.packageLinkInformation = this.tourPackageList[p];
                break;
            }
        }
    }

    if (param == 'viewPackageInfo') {
        var packageData = this.addFormData.packageLinkInformation;

        var data = { listFormJsonData2: { searchType: { "type": "tourPackageList", "packageInfo.id": this.addFormData.packageId } } }
        this.Services.listMastersData(data).subscribe(res =>{
            var d1 = res[0];
            for (var i = 0; i < d1.groupDetail.length; i++) {
                var rteCont1 = d1.groupDetail[i].rte;
                rteCont1 = rteCont1.replace(/#B#/g, '<b>').replace(/#BB#/g, '</b>');
                rteCont1 = rteCont1.replace(/#OL#/g, '<ol>').replace(/#OLOL#/g, '</ol>');
                rteCont1 = rteCont1.replace(/#UL#/g, '<ul>').replace(/#ULUL#/g, '</ul>');
                rteCont1 = rteCont1.replace(/#DL#/g, '<dl>').replace(/#DLDL#/g, '</dl>');
                rteCont1 = rteCont1.replace(/#LI#/g, '<li>').replace(/#LILI#/g, '</li>');
                rteCont1 = rteCont1.replace(/#I#/g, '<i>').replace(/#II#/g, '</i>');
                rteCont1 = rteCont1.replace(/#UU#/g, '<u>').replace(/#UU#/g, '</u>');
                rteCont1 = rteCont1.replace(/#BR#/g, '<br>');
                d1.groupDetail[i].rte = rteCont1;
            };
            this.tourPackageViewObj = d1;
            setTimeout(()=> {
                $('.rteViewWrapper').each(function() {
                    $(this).html($(this).text());
                })
            }, 10);
        },
        err => {
          console.log(err);
        });
    }

    if (param == 'otherChargesAdd') {
        let data = this.addFormData.chargesDetails.totalOtherChargesData;
        let dataObj = { "title": "", "amount": "" };
        data.push(dataObj);
        //for (var i = 0; i < e; i++) {
            this.addFormData.chargesDetails.totalOtherChargesData = data;
        //}
        this.addAction('otherChargesBlur');
    }

    if (param == 'otherChargesDel') {
        let data = this.addFormData.chargesDetails.totalOtherChargesData;
        data = data.splice(0, data.length - 1);
        this.addFormData.chargesDetails.totalOtherChargesData = data;
        this.addAction('otherChargesBlur');
    }

    if (param == 'otherChargesBlur') {
        let data = this.addFormData.chargesDetails.totalOtherChargesData;
        var finalVal = 0;
        for (var amt = 0; amt < data.length; amt++) {
            finalVal += parseInt(data[amt].amount);
        }
        if (isNaN(finalVal)) { finalVal = 0 }
        this.addFormData.chargesDetails.totalOtherCharges = finalVal;
    }

    if (param == 'otherChargesShow') {
        this.otherChargesFlag = this.otherChargesFlag == true ? false : true;
    }

    if( param == 'masterAddTrainName'){
        let t = "train_"+this.addMastersFormData.trainNumber; 
        let bookingDropdownObj='';
        bookingDropdownObj += '{';
        bookingDropdownObj += '"type":"trainNumberList",'; 
        bookingDropdownObj += '"'+t+'":"'+this.addMastersFormData.trainName+'",';    
        bookingDropdownObj += '"trainNumber":"'+this.addMastersFormData.trainNumber+'",';
        bookingDropdownObj += '"trainName":"'+this.addMastersFormData.trainName+'"'; 
        bookingDropdownObj += '}';
        
        var bookingDetailsObj= JSON.parse(bookingDropdownObj);
        var addMastersFormJsonData1 = bookingDetailsObj;
        var addMastersFormJsonDataTypeVal = 'add'; 
    
        let data = {addMastersFormJsonDataType:addMastersFormJsonDataTypeVal, addMastersFormJsonData2:addMastersFormJsonData1}
        
        this.Services.addMasters(data).subscribe(res =>{
            $('#addTrainName button[data-dismiss="modal"]').click();
            this.addFormData.trainName = this.addMastersFormData.trainName;
        },
        err => {
            console.log(err);
        });
    }

  }
  

  loadDataOnload = function() {
    var d = new Date();
    var d1 = d.getDate();
    var d2 = d.getMonth();
    var d3 = d.getFullYear();
    var curDate = d1 + ' ' + this.monthArr[d2] + ', ' + d3;

    this.addFormData.invoiceNo = 'RL-';
    this.addFormData.client = '';
    this.addFormData.clientGSTIN = '';
    this.addFormData.typeOfClient = '';
    this.addFormData.invoiceFullDate = curDate;
    this.addFormData.invoiceFullDateDD = d1 + '';
    this.addFormData.invoiceFullDateMM = (d2 + 1) + '';
    this.addFormData.invoiceFullDateYY = d3 + '';

    this.addFormData.clientRequestDateDDDate = curDate;
    this.addFormData.clientRequestDateDDDateDD = d1 + '';
    this.addFormData.clientRequestDateDDDateMM = (d2 + 1) + '';
    this.addFormData.clientRequestDateDDDateYY = d3 + '';

    this.addFormData.address = '';
    this.addFormData.contactNo1 = '';
    this.addFormData.contactNo2 = '-';
    this.addFormData.approvedBy = '';
    this.addFormData.bookingType = 'Train';
    this.addFormData.travelPlanId = '-';

    this.addFormData.journeyDate = curDate;
    this.addFormData.journeyDateDD = d1 + '';
    this.addFormData.journeyDateMM = (d2 + 1) + '';
    this.addFormData.journeyDateYY = d3 + '';

    if( this.addFormData.roundTrip == 'true' ){
        this.addFormData.returnJourneyDate = curDate;
        this.addFormData.returnJourneyDateDD = d1 + '';
        this.addFormData.returnJourneyDateMM = (d2 + 1) + '';
        this.addFormData.returnJourneyDateYY = d3 + '';
    }

    this.addFormData.trainNo = '';
    this.addFormData.returnTrainNo = '';
    this.addFormData.trainName = '';
    this.addFormData.busName = '';
    this.addFormData.returnBusName = '';
    this.addFormData.busType = '';
    this.addFormData.busCoachType = '';
    this.addFormData.busDepTime = '';
    this.addFormData.busBoardingAddress = '';
    this.addFormData.from = '';
    this.addFormData.to = '';
    this.addFormData.boarding = '';
    this.addFormData.class = '';
    this.addFormData.pnrNo = '';
    this.addFormData.airSector = '';
    this.addFormData.transactionNo = '';
    this.addFormData.referenceNo = '';
    this.addFormData.quota = '';
    
    this.addFormData.hotelName = '';
    this.addFormData.hotelNumber = '';
    this.addFormData.hotelAddress = '';
    this.addFormData.journeyDate = '';
    this.addFormData.checkOutDate = '';
    this.addFormData.roomType = '';
    this.addFormData.noOfNights = '';
    this.addFormData.noOfRooms = '';

    this.addFormData.visaType = '';
    this.addFormData.journeyDate = '';
    this.addFormData.journeyDateTP = '';
    this.addFormData.validTill = '';
    this.addFormData.validTillTP = '';
    this.addFormData.travelingTo = '';
    this.addFormData.visaName = '';
    this.addFormData.visaIssuesFrom = '';
    this.addFormData.contactNo1 = '';
    this.addFormData.passportNo = '';

    this.addFormData.packageInfo1 = {};
    this.addFormData.packageInfo1.ticketFlag = 'false';
    this.addFormData.packageInfo1.invoiceNo = '';
    this.addFormData.packageInfo1.tourId = '';

    this.addFormData.packageName = '';
    this.addFormData.packageCostAdult = '';
    this.addFormData.packageCostChild = '';
    this.addFormData.packageOriginalCostAdult = '';
    this.addFormData.packageOriginalCostChild = '';
    this.addFormData.packageId = '';

    for(var i=0; i<11; i++){
        eval("this.addFormData.pd"+i+" = {}");
        eval("this.addFormData.pd"+i+".checkbox = false");
        eval("this.addFormData.pd"+i+".empId = ''");
        eval("this.addFormData.pd"+i+".empLevel = ''");
        eval("this.addFormData.pd"+i+".name = ''");
        eval("this.addFormData.pd"+i+".sex = ''");
        eval("this.addFormData.pd"+i+".age = ''");
        eval("this.addFormData.pd"+i+".coachNoSeat = ''");
        eval("this.addFormData.pd"+i+".planId = ''");
        eval("this.addFormData.pd"+i+".ticketNo = ''");
    }
    this.addFormData.adult = '';
    this.addFormData.child = '0';
    this.addFormData.total = '';

    this.addFormData.chargesDetails = {};
    this.addFormData.chargesDetails.quota = '';
    this.addFormData.chargesDetails.eTicket = '';
    this.addFormData.chargesDetails.pg = '';
    this.addFormData.chargesDetails.catering = 0;
    this.addFormData.chargesDetails.convenience = 0;
    this.addFormData.chargesDetails.insurance = 0;
    this.addFormData.chargesDetails.travelAgentCharge = '';
    this.addFormData.chargesDetails.ticketFare = '';
    this.addFormData.chargesDetails.service = '';
    this.addFormData.chargesDetails.miscCharges = '';
    this.addFormData.chargesDetails.taxes = '';
    this.addFormData.chargesDetails.discountFrmAirlines = '';
    this.addFormData.chargesDetails.total = '';
    this.addFormData.chargesDetails.serviceTax = '';
    this.addFormData.chargesDetails.cgstPer = '';
    this.addFormData.chargesDetails.sgstPer = '';
    this.addFormData.chargesDetails.igstPer = '';
    this.addFormData.chargesDetails.cgstVal = '';
    this.addFormData.chargesDetails.sgstVal = '';
    this.addFormData.chargesDetails.igstVal = '';
    this.addFormData.chargesDetails.grandTotal = '';
    this.addFormData.chargesDetails.hiddenServiceCharges = 0;
    this.addFormData.chargesDetails.totalOtherChargesData = [];
    this.addFormData.chargesDetails.totalOtherCharges = 0;
    this.addFormData.chargesDetails.creditAmount = '';
    this.addFormData.chargesDetails.cancellationRailway = '';
    this.addFormData.chargesDetails.cancellationService = '';
    this.addFormData.chargesDetails.cancellationRailwayTotal = '';
    this.addFormData.chargesDetails.cancellationServiceTotal = '';

    this.addFormData.paymentDetails = {};
    this.addFormData.paymentDetails.paymentStatus = '';
    this.addFormData.paymentDetails.paymentFrom = '';
    this.addFormData.paymentDetails.paymentBy = '';
    this.addFormData.paymentDetails.paymentByChequeNo = '';
    this.addFormData.paymentDetails.paymentByBankName = '';
    this.addFormData.paymentDetails.paymentDate = '';
    this.addFormData.paymentDetails.paymentDateDD = 'DD';
    this.addFormData.paymentDetails.paymentDateMM = 'MM';
    this.addFormData.paymentDetails.paymentDateYY = 'YY';
    this.addFormData.paymentDetails.paymentDateTP = 0;
    this.addFormData.paymentDetails.busTravelPaymentStatus = '';
    this.addFormData.paymentDetails.creditNoteFlag = false;
    this.addFormData.paymentDetails.creditNotePaymentStatus = '';
    this.addFormData.paymentDetails.creditNotePaymentDate = '';
    this.addFormData.paymentDetails.creditNotePaymentDateTP = 0;
    this.addFormData.paymentDetails.creditNoteNo = '';
    this.addFormData.paymentDetails.creditNoteDate = '';
    this.addFormData.paymentDetails.creditNoteDateTP = 0;
    this.addFormData.paymentDetails.discount = '';
    this.addFormData.paymentDetails.pendingAmount = '';
    this.addFormData.paymentDetails.paymentReceived = '';
    this.addFormData.paymentDetails.advancePayment = 0;
    this.addFormData.paymentDetails.cancelled = '';
    this.addFormData.paymentDetails.remark = '';
    this.addFormData.paymentDetails.hiddenAmount1 = '';
    this.addFormData.paymentDetails.hiddenAmount2 = '';

    this.addFormData.agentDetails = {};
    this.addFormData.agentDetails.agentType = null;
    this.addFormData.agentDetails.userId = '';
    this.addFormData.agentDetails.payment = '';
    this.addFormData.agentDetails.agentGSTIN = '';

    this.addFormData.otherDetails = {};
  }

  /*
  loadDataOnload_ready = function() {
    var m = Math.round(Math.random() * 100);
    var d = new Date();
    var dd = d.getDate();
    var mm = d.getMonth() + 1;
    var yy = d.getFullYear();

    if (m < 15) { m = 15 }
    this.addFormData.invoiceNo = 'RL-1' + 2346 * m;
    this.addFormData.client = 'FRL-' + m;
    this.addFormData.clientGSTIN = '';
    this.addFormData.typeOfClient = '';
    this.addFormData.invoiceFullDate = dd + '/' + mm + '/' + yy;
    this.addFormData.address = 'RNA Broadway Avenue, B-A' + Math.round(m / 15) + ', Flat-' + Math.round(m / 5) + ', Near jangid complex, miraroad(east), Thane';
    this.addFormData.contactNo1 = '9833' + m * 88561;
    this.addFormData.contactNo2 = '-';
    this.addFormData.approvedBy = '-';
    this.addFormData.bookingType = 'Train';
    this.addFormData.travelPlanId = '-';

    this.addFormData.journeyDate = Math.min(m + 10, 31) + '/' + Math.ceil(Math.min(m / 5 + 2, 12)) + '/2016';
    this.addFormData.trainNo = '10002';
    this.addFormData.trainName = 'Dorendo Exp';
    this.addFormData.from = 'MUM';
    this.addFormData.to = 'GOA';
    this.addFormData.class = '';
    this.addFormData.pnrNo = 'PNR-2' + m + '685' + m;
    this.addFormData.airSector = 'airSector-' + m;
    this.addFormData.transactionNo = 'transactionNo 2' + m;
    this.addFormData.referenceNo = 'referenceNo 2' + m;
    this.addFormData.quota = '';

    this.addFormData.packageInfo1 = {};
    this.addFormData.packageInfo1.ticketFlag = 'false';
    this.addFormData.packageInfo1.invoiceNo = '';
    this.addFormData.packageInfo1.tourId = '';

    this.addFormData.packageName = '';
    this.addFormData.packageCostAdult = '';
    this.addFormData.packageCostChild = '';
    this.addFormData.packageOriginalCostAdult = '';
    this.addFormData.packageOriginalCostChild = '';
    this.addFormData.packageId = '';

    this.addFormData.pd0 = {};
    this.addFormData.pd0.checkbox = false;
    this.addFormData.pd0.empId = '02376';
    this.addFormData.pd0.empLevel = 'Manager';
    this.addFormData.pd0.name = 'Sulaman';
    this.addFormData.pd0.sex = 'M';
    this.addFormData.pd0.age = '30';
    this.addFormData.pd0.coachNoSeat = 'CNF/B2/' + m;
    this.addFormData.pd0.planId = '7766' + m;
    this.addFormData.pd0.ticketNo = '7766' + m;

    this.addFormData.adult = '';
    this.addFormData.child = '0';
    this.addFormData.total = '';

    this.addFormData.chargesDetails = {};
    this.addFormData.chargesDetails.quota = '0';
    this.addFormData.chargesDetails.eTicket = '';
    this.addFormData.chargesDetails.pg = '';
    this.addFormData.chargesDetails.catering = '0';
    this.addFormData.chargesDetails.convenience = '0';
    this.addFormData.chargesDetails.insurance = '0';
    this.addFormData.chargesDetails.travelAgentCharge = '0';
    this.addFormData.chargesDetails.ticketFare = '';
    this.addFormData.chargesDetails.service = '';
    this.addFormData.chargesDetails.miscCharges = '';
    this.addFormData.chargesDetails.taxes = '';
    this.addFormData.chargesDetails.discountFrmAirlines = '';
    this.addFormData.chargesDetails.total = '';
    this.addFormData.chargesDetails.serviceTax = '';
    this.addFormData.chargesDetails.cgstPer = '';
    this.addFormData.chargesDetails.sgstPer = '';
    this.addFormData.chargesDetails.cgstVal = '';
    this.addFormData.chargesDetails.sgstVal = '';
    this.addFormData.chargesDetails.grandTotal = '';
    this.addFormData.chargesDetails.hiddenServiceCharges = 0;
    this.addFormData.chargesDetails.totalOtherChargesData = [];
    this.addFormData.chargesDetails.totalOtherCharges = 0;

    this.addFormData.chargesDetails.amountPerKm = '';
    this.addFormData.chargesDetails.totalKms = '';
    this.addFormData.chargesDetails.totalHours = '';

    this.addFormData.chargesDetails.creditAmount = '';
    this.addFormData.chargesDetails.cancellationRailway = '';
    this.addFormData.chargesDetails.cancellationService = '';
    this.addFormData.chargesDetails.cancellationRailwayTotal = '';
    this.addFormData.chargesDetails.cancellationServiceTotal = '';

    this.addFormData.paymentDetails = {};
    this.addFormData.paymentDetails.paymentStatus = '';
    this.addFormData.paymentDetails.paymentFrom = '';
    this.addFormData.paymentDetails.paymentBy = '';
    this.addFormData.paymentDetails.paymentByChequeNo = '';
    this.addFormData.paymentDetails.paymentByBankName = '';
    this.addFormData.paymentDetails.paymentDate = '';
    this.addFormData.paymentDetails.busTravelPaymentStatus = '';
    this.addFormData.paymentDetails.creditNoteFlag = false;
    this.addFormData.paymentDetails.creditNotePaymentStatus = '';
    this.addFormData.paymentDetails.creditNotePaymentDate = '';
    this.addFormData.paymentDetails.creditNoteNo = '';
    this.addFormData.paymentDetails.creditNoteDate = '';
    this.addFormData.paymentDetails.discount = '';
    this.addFormData.paymentDetails.pendingAmount = '';
    this.addFormData.paymentDetails.paymentReceived = '';
    this.addFormData.paymentDetails.cancelled = '';
    this.addFormData.paymentDetails.remark = '';

    this.addFormData.agentDetails = {};
    this.addFormData.agentDetails.agentType = '';
    this.addFormData.agentDetails.userId = '';
    this.addFormData.agentDetails.payment = '';
    this.addFormData.agentDetails.agentGSTIN = '';

    this.addFormData.otherDetails = {};
  }*/

  alt = function() {
      console.log('alt--------');
  }

  loadInputData_getCustomDateFunc = function(dt) {
    if (dt == '' || dt == undefined) { return '' }
    
    var dt1 = dt.split('/');
    var d = new Date(dt1[1] + '/' + dt1[0] + '/' + dt1[2]);
    var d1 = d.getDate();
    var d2 = d.getMonth();
    var d3 = d.getFullYear();
    return d1 + ' ' + this.monthArr[d2] + ', ' + d3;
  }
  loadInputData = function(data) {
        
    //setTimeout((d=>ata) {
    //console.log('loadInputData Edit mode ?');

    var d = new Date(data.invoiceFullDate);
    let _d1:any = d.getDate();
    let _d2:any = d.getMonth();
    let _d3:any = d.getFullYear();
    var invDate = _d1 + ' ' + this.monthArr[_d2] + ', ' + _d3;
    //console.log('invDate--->>'+invDate);

    //this.addFormData = JSON.parse( JSON.stringify(this.addFormData) );
    this.addFormData.client = data.client;
    this.addAction('updateCharger', 'clientSelectChange');//EDIT MODE

    //console.log(this.addFormData.address ,' =============== ', data.address);

    setTimeout(()=>{
        if (data.paymentDetails.paymentStatus == 'Received') {
            this.paymentStatusFlag = true;
            if (data.paymentDetails.paymentBy == 'Online') {
                this.readonlyPaymentByBankName = true;
            } else if (data.paymentDetails.paymentBy == 'Cheque') {
                this.readonlyPaymentByBankName = true;
                this.readonlyPaymentByChequeNo = true;
            }
        }

        let d1 = data.invoiceFullDate.split('/');
        let d2 = data.bookingDetails.journeyDate.split('/');
        let d3 = data.paymentDetails.paymentDate.split('/');
        
        //this.addFormData = {};
        this.addFormJsonDataTypeID = data._id;
        this.addFormData.invoiceNo = data.invoiceNo;
        //this.addFormData.client = data.client;
        this.addFormData.clientGSTIN = data.clientGSTIN;
        this.addFormData.typeOfClient = data.typeOfClient;
        
        this.addFormData.invoiceFullDate = this.loadInputData_getCustomDateFunc(data.invoiceFullDate);
        this.addFormData.invoiceFullDateDD = parseInt(d1[0]) + '';
        this.addFormData.invoiceFullDateMM = parseInt(d1[1]) + '';
        this.addFormData.invoiceFullDateYY = d1[2] + '';
        
        this.addFormData.clientRequestDate = this.loadInputData_getCustomDateFunc(data.clientRequestDate);
        this.addFormData.clientRequestDateDD = parseInt(d1[0]) + '';
        this.addFormData.clientRequestDateMM = parseInt(d1[1]) + '';
        this.addFormData.clientRequestDateYY = d1[2] + '';

        this.addFormData.address = data.address;
        this.addFormData.contactNo1 = data.contactNo1;
        this.addFormData.contactNo2 = data.contactNo2;
        this.addFormData.approvedBy = data.approvedBy;
        this.addFormData.bookingType = data.bookingType;
        this.addFormData.travelPlanId = data.travelPlanId;

        this.addFormData.journeyDate = this.loadInputData_getCustomDateFunc(data.bookingDetails.journeyDate);
        this.addFormData.journeyDateDD = parseInt(d2[0]) + '';
        this.addFormData.journeyDateMM = parseInt(d2[1]) + '';
        this.addFormData.journeyDateYY = d2[2] + '';

        if( data.roundTrip == "true" ){
            var rdat = (data.bookingDetails.returnJourneyDate).split('/');
            this.addFormData.roundTrip = "true";
            this.addFormData.returnJourneyDate = data.bookingDetails.returnJourneyDate;
            this.addFormData.returnJourneyDateDD = parseInt(rdat[0]) + '';
            this.addFormData.returnJourneyDateMM = parseInt(rdat[1]) + '';
            this.addFormData.returnJourneyDateYY = rdat[2] + '';
        }
        else{
            this.addFormData.roundTrip = "false";
            this.addFormData.returnJourneyDate = "";
            this.addFormData.returnJourneyDateDD = "";
            this.addFormData.returnJourneyDateMM = "";
            this.addFormData.returnJourneyDateYY = "";
        }

        this.addFormData.from = data.bookingDetails.from;
        this.addFormData.to = data.bookingDetails.to;
        this.addFormData.boarding = data.bookingDetails.boarding;
        this.addFormData.pnrNo = data.bookingDetails.pnrNo;
        this.addFormData.airSector = data.bookingDetails.airSector;
        this.addFormData.transactionNo = data.bookingDetails.transactionNo;
        this.addFormData.referenceNo = data.bookingDetails.referenceNo;

        this.AirWrap = this.BusWrap = this.TrainWrap = this.PackageWrap = this.VehicleWrap = this.ExpenseWrap = false;
        this.AirWrapRequired = this.BusWrapRequired = this.TrainWrapRequired = this.PackageWrapRequired = this.VehicleWrapRequired = this.ExpenseWrapRequired = '';

        if (data.bookingType == 'Train') {
            this.addFormData.trainNo = data.bookingDetails.trainNo;
            this.addFormData.returnTrainNo = data.bookingDetails.returnTrainNo;
            this.addFormData.trainName = data.bookingDetails.trainName;
            this.addFormData.class = data.bookingDetails.class;
            this.addFormData.quota = data.bookingDetails.quota;

            this.TrainWrap = true;
            this.TrainWrapRequired = 'required';
        } else if (data.bookingType == 'Bus') {
            this.addFormData.busName = data.bookingDetails.busName;
            this.addFormData.returnBusName = data.bookingDetails.returnBusName;
            this.addFormData.busType = data.bookingDetails.busType;
            this.addFormData.busCoachType = data.bookingDetails.busCoachType;
            this.addFormData.busDepTime = data.bookingDetails.busDepTime;
            //this.addFormData.busTicketNo = data.bookingDetails.busTicketNo;
            this.addFormData.busBoardingAddress = data.bookingDetails.busBoardingAddress;

            this.BusWrap = true;
            this.BusWrapRequired = 'required';
        } else if (data.bookingType == 'Air') {
            this.addFormData.airName = data.bookingDetails.airName;
            this.addFormData.airPreference = data.bookingDetails.airPreference;
            this.addFormData.airClass = data.bookingDetails.airClass;
            this.addFormData.airFlight = data.bookingDetails.airFlight;
            this.addFormData.returnAirFlight = data.bookingDetails.returnAirFlight;
            this.addFormData.ticketÌd = data.bookingDetails.ticketÌd;
            this.addFormData.airDetails = data.bookingDetails.airDetails;

            this.addFormData.airBaggage_purchased = data.bookingDetails.airBaggage_purchased;
            this.addFormData.airBaggage_cabin = data.bookingDetails.airBaggage_cabin;
            this.addFormData.airBaggage_free = data.bookingDetails.airBaggage_free;
            this.addFormData.airReference_AO = data.bookingDetails.airReference_AO;
            this.addFormData.airReference_Airline = data.bookingDetails.airReference_Airline;
            this.addFormData.airReference_CRS = data.bookingDetails.airReference_CRS;
            this.addFormData.airDepTerminal = data.bookingDetails.airDepTerminal;
            this.addFormData.airArrTerminal = data.bookingDetails.airArrTerminal;
            this.addFormData.airDepTime = data.bookingDetails.airDepTime;
            this.addFormData.airArrTime = data.bookingDetails.airArrTime;

            this.AirWrap = true;
            this.AirWrapRequired = 'required';
        } else if (data.bookingType == 'Package') {
            this.addFormData.tourPackage = data.bookingDetails.packageName;
            this.addFormData.packageName = data.bookingDetails.packageName;
            this.addFormData.packageCostAdult = data.bookingDetails.packageCostAdult;
            this.addFormData.packageCostChild = data.bookingDetails.packageCostChild;
            this.addFormData.packageOriginalCostAdult = data.bookingDetails.packageOriginalCostAdult;
            this.addFormData.packageOriginalCostChild = data.bookingDetails.packageOriginalCostChild;
            this.addFormData.packageId = data.bookingDetails.packageId;
            this.PackageWrap = true;
            this.PackageWrapRequired = 'required';
        } else if (data.bookingType == 'Hotel') {    
            this.addFormData.hotelName = data.bookingDetails.hotelName;
            this.addFormData.hotelNumber = data.bookingDetails.hotelNumber;
            this.addFormData.hotelAddress = data.bookingDetails.hotelAddress;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            console.log('>>>>>>>>>>.', data.bookingDetails.checkOutDate, data);
            //this.addFormData.checkOutDateDD = data.checkOutDateDD;
            //this.addFormData.checkOutDateMM = data.checkOutDateMM;
            //this.addFormData.checkOutDateYY = data.checkOutDateYY;
            //this.addFormData.checkOutDate = data.bookingDetails.checkOutDate;
            
            let d1 = data.bookingDetails.checkOutDate.split('/');
            this.addFormData.checkOutDate = (data.bookingDetails.checkOutDate);
            this.addFormData.checkOutDateDD = parseInt(d1[0]) + '';
            this.addFormData.checkOutDateMM = parseInt(d1[1]) + '';
            this.addFormData.checkOutDateYY = d1[2] + '';

            this.addFormData.roomType = data.bookingDetails.roomType;
            this.addFormData.noOfNights = data.bookingDetails.noOfNights;
            this.addFormData.noOfRooms = data.bookingDetails.noOfRooms;
        } else if (data.bookingType == 'Visa') {
            this.addFormData.visaType = data.bookingDetails.visaType;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.journeyDateTP = data.bookingDetails.journeyDateTP;
            
            //this.addFormData.validTillDD = data.validTillDD;
            //this.addFormData.validTillMM = data.validTillMM;
            //this.addFormData.validTillYY = data.validTillYY;
            //this.addFormData.validTill = data.bookingDetails.validTill;

            this.addFormData.validTillTP = data.bookingDetails.validTillTP;
            this.addFormData.validTill = (data.bookingDetails.validTill);
            let d1 = data.bookingDetails.validTill.split('/');
            this.addFormData.validTillDD = parseInt(d1[0]) + '';
            this.addFormData.validTillMM = parseInt(d1[1]) + '';
            this.addFormData.validTillYY = d1[2] + '';
        
            this.addFormData.travelingTo = data.bookingDetails.travelingTo;
            this.addFormData.visaName = data.bookingDetails.visaName;
            this.addFormData.visaIssuesFrom = data.bookingDetails.visaIssuesFrom;
            this.addFormData.contactNo1 = data.bookingDetails.contactNo1;
            this.addFormData.passportNo = data.bookingDetails.passportNo;
            
        } else if (data.bookingType == 'Vehicle') {
            //console.log('Work pending');
            this.addFormData.tourPackage = data.bookingDetails.packageName;
            this.addFormData.packageName = data.bookingDetails.packageName;
            this.addFormData.packageCostAdult = data.bookingDetails.packageCostAdult;
            this.addFormData.packageCostChild = data.bookingDetails.packageCostChild;
            this.addFormData.packageOriginalCostAdult = data.bookingDetails.packageOriginalCostAdult;
            this.addFormData.packageOriginalCostChild = data.bookingDetails.packageOriginalCostChild;
            this.addFormData.packageId = data.bookingDetails.packageId;

            this.VehicleWrap = true;
            this.VehicleWrapRequired = 'required';
        } else if (data.bookingType == 'Expense') {
            //alert('Work pending');
            this.addFormData.expenseType = data.bookingDetails.expenseType;
            this.addFormData.journeyDate = data.bookingDetails.journeyDate;
            this.addFormData.journeyDateTP = data.bookingDetails.journeyDateTP;
            this.addFormData.contactNo1 = data.bookingDetails.contactNo1;
            this.addFormData.vendorInvoiceNo = data.bookingDetails.vendorInvoiceNo;
            this.addFormData.description = data.bookingDetails.description;

            this.ExpenseWrap = true;
            this.ExpenseWrapRequired = 'required';
        }

        this.addFormData.advanceBooking = 'false';
        if(data.advanceBooking != undefined){this.addFormData.advanceBooking = data.advanceBooking;}
        
        ///////changes - 2017-07-26 -- DONE
        this.addFormData.packageInfo1 = {};
        if (data.packageInfo1 == undefined) {
            this.addFormData.packageInfo1.tourId = '';
            this.addFormData.packageInfo1.ticketFlag = 'false';
            this.addFormData.packageInfo1.invoiceNo = '';
        } else {
            this.addFormData.packageInfo1.tourId = data.packageInfo1.tourId;
            this.addFormData.packageInfo1.ticketFlag = data.packageInfo1.ticketFlag;
            this.addFormData.packageInfo1.invoiceNo = data.packageInfo1.invoiceNo;
        }

        this.passengerDetailsList = data.passengerDetails;
        /*
        for (var i = 0; i < data.passengerDetails.length; i++) {
            var pData = data.passengerDetails[i];
            console.log(pData);
            var scop = eval('this.addFormData.pd' + i + ' = {}');
            scop.checkbox = pData.checkbox;
            scop.empId = pData.empId;
            scop.empLevel = pData.empLevel;
            scop.name = pData.name;
            scop.sex = pData.sex;
            scop.age = pData.age;
            scop.coachNoSeat = pData.coachNoSeat;
            scop.planId = pData.planId;
            scop.ticketNo = pData.ticketNo;
            
            /*var scop = {
                "checkbox" : pData.checkbox,
                "empId" : pData.empId,
                "empLevel" : pData.empLevel,
                "name" : pData.name,
                "sex" : pData.sex,
                "age" : pData.age,
                "coachNoSeat" : pData.coachNoSeat,
                "planId" : pData.planId,
                "ticketNo" : pData.ticketNo
            };
            eval('this.addFormData.pd' + i + ' = scop');
        }
        */
        //console.log('data.passengerDetails.length >>>> ', data.passengerDetails.length, data.passengerDetails);
        
        if(data.passengerDetails.length > 0){ this.addFormData.pd0 = data.passengerDetails[0]; }
        if(data.passengerDetails.length > 1){ this.addFormData.pd1 = data.passengerDetails[1]; }
        if(data.passengerDetails.length > 2){ this.addFormData.pd2 = data.passengerDetails[2]; }
        if(data.passengerDetails.length > 3){ this.addFormData.pd3 = data.passengerDetails[3]; }
        if(data.passengerDetails.length > 4){ this.addFormData.pd4 = data.passengerDetails[4]; }
        if(data.passengerDetails.length > 5){ this.addFormData.pd5 = data.passengerDetails[5]; }
        if(data.passengerDetails.length > 6){ this.addFormData.pd6 = data.passengerDetails[6]; }
        if(data.passengerDetails.length > 7){ this.addFormData.pd7 = data.passengerDetails[7]; }
        if(data.passengerDetails.length > 8){ this.addFormData.pd8 = data.passengerDetails[8]; }
        if(data.passengerDetails.length > 9){ this.addFormData.pd9 = data.passengerDetails[9]; }
        if(data.passengerDetails.length > 10){ this.addFormData.pd10 = data.passengerDetails[10]; }



        this.addFormData.adult = data.passengerCount.adult;
        this.addFormData.child = data.passengerCount.child;
        this.addFormData.total = data.passengerCount.total;

        this.addFormData.chargesDetails = {};
        this.addFormData.chargesDetails.quota = data.chargesDetails.quota*1;
        this.addFormData.chargesDetails.eTicket = data.chargesDetails.eTicket*1;
        this.addFormData.chargesDetails.pg = data.chargesDetails.pg*1;
        this.addFormData.chargesDetails.catering = data.chargesDetails.catering*1;
        this.addFormData.chargesDetails.convenience = data.chargesDetails.convenience*1;
        this.addFormData.chargesDetails.insurance = data.chargesDetails.insurance*1;
        this.addFormData.chargesDetails.travelAgentCharge = data.chargesDetails.travelAgentCharge*1;
        this.addFormData.chargesDetails.ticketFare = data.chargesDetails.ticketFare*1;
        this.addFormData.chargesDetails.service = data.chargesDetails.service*1;

        if (data.chargesDetails.miscCharges == undefined) {
            this.addFormData.chargesDetails.miscCharges = 0;
        }
        else{
            this.addFormData.chargesDetails.miscCharges = data.chargesDetails.miscCharges*1;
        }

        this.addFormData.chargesDetails.taxes = data.chargesDetails.taxes*1;
        this.addFormData.chargesDetails.discountFrmAirlines = data.chargesDetails.discountFrmAirlines*1;
        this.addFormData.chargesDetails.total = data.chargesDetails.total*1;
        this.addFormData.chargesDetails.serviceTax = data.chargesDetails.serviceTax*1;
        
        setTimeout(()=>{
            if (data.chargesDetails.cgstPer == undefined || data.chargesDetails.cgstPer == null || data.chargesDetails.cgstPer == '') {
                this.addFormData.chargesDetails.cgstPer = this.addFormData.chargesDetails.cgstVal = 0;
            } else {
                this.addFormData.chargesDetails.cgstPer = data.chargesDetails.cgstPer*1;
                this.addFormData.chargesDetails.cgstVal = data.chargesDetails.cgstVal*1;
            }

            if (data.chargesDetails.sgstPer == undefined || data.chargesDetails.sgstPer == null || data.chargesDetails.sgstPer == '') {
                this.addFormData.chargesDetails.sgstPer = this.addFormData.chargesDetails.sgstVal = 0;
            }
            else{
                this.addFormData.chargesDetails.sgstPer = data.chargesDetails.sgstPer*1;
                this.addFormData.chargesDetails.sgstVal = data.chargesDetails.sgstVal*1;
            }

            if (data.chargesDetails.igstPer == undefined || data.chargesDetails.igstPer == null || data.chargesDetails.igstPer == '') {
                this.addFormData.chargesDetails.igstPer = this.addFormData.chargesDetails.igstVal = 0
            }
            else{
                this.addFormData.chargesDetails.igstPer = data.chargesDetails.igstPer*1;
                this.addFormData.chargesDetails.igstVal = data.chargesDetails.igstVal*1;
            }
            //console.log( this.addFormData.chargesDetails, data.chargesDetails );
        },1500);
        
        this.addFormData.chargesDetails.grandTotal = data.chargesDetails.grandTotal*1;
        if (data.chargesDetails.hiddenServiceCharges == undefined) { this.addFormData.chargesDetails.hiddenServiceCharges = 0; } else { this.addFormData.chargesDetails.hiddenServiceCharges = data.chargesDetails.hiddenServiceCharges; }

        this.addFormData.chargesDetails.totalOtherChargesData = data.chargesDetails.totalOtherChargesData;
        this.addFormData.chargesDetails.totalOtherCharges = data.chargesDetails.totalOtherCharges*1;
        this.addFormData.chargesDetails.creditAmount = data.chargesDetails.creditAmount*1;
        this.addFormData.chargesDetails.cancellationRailway = data.chargesDetails.cancellationRailway*1;
        this.addFormData.chargesDetails.cancellationService = data.chargesDetails.cancellationService*1;
        this.addFormData.chargesDetails.cancellationRailwayTotal = data.chargesDetails.cancellationRailwayTotal*1;
        this.addFormData.chargesDetails.cancellationServiceTotal = data.chargesDetails.cancellationServiceTotal*1;
        
        if (data.bookingType == 'Vehicle') {
            this.addFormData.chargesDetails.amountPerKm = data.chargesDetails.amountPerKm;
            this.addFormData.chargesDetails.totalKms = data.chargesDetails.totalKms;
            this.addFormData.chargesDetails.totalHours = data.chargesDetails.totalHours;
        }

        this.addFormData.paymentDetails = {};
        this.addFormData.paymentDetails.paymentStatus = data.paymentDetails.paymentStatus;
        this.addFormData.paymentDetails.paymentFrom = data.paymentDetails.paymentFrom;
        this.addFormData.paymentDetails.paymentBy = data.paymentDetails.paymentBy;
        this.addFormData.paymentDetails.paymentByChequeNo = data.paymentDetails.paymentByChequeNo;
        this.addFormData.paymentDetails.paymentByBankName = data.paymentDetails.paymentByBankName;
        this.addFormData.paymentDetails.paymentDate = this.loadInputData_getCustomDateFunc(data.paymentDetails.paymentDate);
        this.addFormData.paymentDetails.paymentDateDD = parseInt(d3[0]) + '';
        this.addFormData.paymentDetails.paymentDateMM = parseInt(d3[1]) + '';
        this.addFormData.paymentDetails.paymentDateYY = d3[2] + '';
        this.addFormData.paymentDetails.paymentDateTP = data.paymentDetails.paymentDateTP;

        this.addFormData.paymentDetails.busTravelPaymentStatus = data.paymentDetails.busTravelPaymentStatus;

        this.addFormData.paymentDetails.creditNoteFlag = data.paymentDetails.creditNoteFlag;
        this.addFormData.paymentDetails.creditNotePaymentStatus = data.paymentDetails.creditNotePaymentStatus;
        this.addFormData.paymentDetails.creditNotePaymentDate = data.paymentDetails.creditNotePaymentDate;
        this.addFormData.paymentDetails.creditNotePaymentDateTP = data.paymentDetails.creditNotePaymentDateTP;
        this.addFormData.paymentDetails.creditNoteNo = data.paymentDetails.creditNoteNo;
        this.addFormData.paymentDetails.creditNoteDate = this.loadInputData_getCustomDateFunc(data.paymentDetails.creditNoteDate);
        this.addFormData.paymentDetails.creditNoteDateTP = data.paymentDetails.creditNoteDateTP;
        this.addFormData.paymentDetails.discount = data.paymentDetails.discount;
        this.addFormData.paymentDetails.pendingAmount = data.paymentDetails.pendingAmount;
        this.addFormData.paymentDetails.paymentReceived = data.paymentDetails.paymentReceived;
        this.addFormData.paymentDetails.advancePayment = data.paymentDetails.advancePayment;
        this.addFormData.paymentDetails.cancelled = data.paymentDetails.cancelled;
        this.addFormData.paymentDetails.remark = data.paymentDetails.remark;
        this.addFormData.paymentDetails.hiddenAmount1 = data.paymentDetails.hiddenAmount1;
        this.addFormData.paymentDetails.hiddenAmount2 = data.paymentDetails.hiddenAmount2;

        this.addFormData.agentDetails = {};
        this.addFormData.agentDetails.agentType = data.agentDetails.agentType;
        this.addFormData.agentDetails.userId = data.agentDetails.userId;
        this.addFormData.agentDetails.payment = data.agentDetails.payment;
        this.addFormData.agentDetails.agentGSTIN = data.agentDetails.agentGSTIN;
        //this.addAction('agentType',this);

        this.addFormData.otherDetails = {};
        this.addFormData.otherDetails.otherDetailsId = data.otherDetails.otherDetailsId;
        this.addFormData.otherDetails.createdBy = data.otherDetails.createdBy;
        this.addFormData.otherDetails.createdDate = data.otherDetails.createdDate;
        this.addFormData.otherDetails.createdTime = data.otherDetails.createdTime;
        this.addFormData.otherDetails.modifiedBy = data.otherDetails.modifiedBy;
        this.addFormData.otherDetails.modifiedDate = data.otherDetails.modifiedDate;
        this.addFormData.otherDetails.modifiedTime = data.otherDetails.modifiedTime;

        this.addAction('updateBookingType');
        if (data.bookingType == 'Package') {
            //this.addAction('updatePackageCost');
        }
        //this.addAction('updateCharger', 'clientSelectChange');
        this.addAction('updatePackageTicket', '');
        this.addAction('paymentStatusSorting');

        //setTimeout(()=>{
        //this.addAction('updateChargerOnBlur');
        //    console.log( this.addFormData.chargesDetails, data.chargesDetails );
        //},500);

        //this.addAction('editMode_agentType');
        //}, 5000);
        //console.log('Data Loaded');
    },500);
  }

  getJsonData = function(obj) {
    //this.jsonListData={};
    var listFormJsonData1 = {
        searchType: 'edit',
        searchBy: obj
    };
    var data = { listFormJsonData2: listFormJsonData1 }
    this.Services.listData(data).subscribe(res =>{
        setTimeout(()=>{
            this.loadInputData(res[0]);
        },4000);
    },
    err => {
       //error
       console.log(err);
       //return err;
    })
  }

  addForm = function() {
    //roundTrip
    var ls = JSON.parse(localStorage.mainDetails);
    var diffenDays = 365 - parseInt(ls.dd);

    if (diffenDays <= 0) {
        alert('Subscription Expired!, Kindly subscribe to continue');
        var warningBox = '<div class="alert subscriptionExpWrap alert-danger"><strong>Danger!</strong> Subscription Expired!, Kindly subscribe to continue.</div>';
        $('body').append(warningBox);
        return false;
    } else if (diffenDays <= 20) {
        //alert('Subscription will expire within '+diffenDays+' days, Kindly renew it as soon as possible.');
        if ($('.subscriptionExpWrap').length == 0) {
            var warningBox = '<div class="alert subscriptionExpWrap alert-warning"><strong>Warning!</strong> Subscription will expire within ' + diffenDays + ' days, Kindly renew it as soon as possible.</div>';
            $('body').append(warningBox);
        }
    }

    // initial values
    this.error = false;
    this.disabled = true;
    var bookingDetailsObj = {};
    var passengerDetailsObj = [];
    var passengerCountObj = {}
    var packageInfo1 = {}
    var chargesDetailsObj = {}
    var agentDetailsObj = {}
    var otherDetailsObj = {}

    var getCustomDateFunc = function(dt) {
        if (dt == '') { return '' }
        var d = new Date(dt);
        let d1:any = d.getDate();
        let d2:any = d.getMonth() + 1;
        let d3:any = d.getFullYear();
        if (d1 < 10) { d1 = '0' + d1 }
        if (d2 < 10) { d2 = '0' + d2 }

        return d1 + '/' + d2 + '/' + d3;
    }
    var getFullDate = function(dd, mm, yy) {
        var jDateD = dd;
        if (jDateD < 10) { jDateD = '0' + jDateD }
        var jDateM = mm;
        if (jDateM < 10) { jDateM = '0' + jDateM }
        return jDateD + '/' + jDateM + '/' + yy;
    }



    var jDate = getFullDate(this.addFormData.journeyDateDD, this.addFormData.journeyDateMM, this.addFormData.journeyDateYY);
    var jDateTP = this.Services.fullDateTPFunc(this.addFormData.journeyDateDD, this.addFormData.journeyDateMM, this.addFormData.journeyDateYY);

    var rjDate = '';
    var rjDateTP = '';
    if( this.addFormData.roundTrip == 'true'){
        rjDate = getFullDate(this.addFormData.returnJourneyDateDD, this.addFormData.returnJourneyDateMM, this.addFormData.returnJourneyDateYY);
        rjDateTP = this.Services.fullDateTPFunc(this.addFormData.returnJourneyDateDD, this.addFormData.returnJourneyDateMM, this.addFormData.returnJourneyDateYY);
    }
        
    var payD = '';
    if (this.addFormData.paymentDetails.paymentDateDD == 'DD') {
        payD = this.addFormData.paymentDetails.paymentDateDD + '/' + this.addFormData.paymentDetails.paymentDateMM + '/' + this.addFormData.paymentDetails.paymentDateYY
    }

    if (this.addFormData.bookingType == 'Train') {
        bookingDetailsObj = {
            //journeyDate:getCustomDateFunc(this.addFormData.journeyDate),
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            returnJourneyDate: rjDate,
            returnJourneyDateTP: rjDateTP,
            trainNo: this.addFormData.trainNo,
            returnTrainNo: this.addFormData.returnTrainNo,
            trainName: this.addFormData.trainName,
            //from: this.addFormData.from,
            //to: this.addFormData.to,
            //boarding: this.addFormData.boarding,
            from: $('#trainFrom').val(),
            to: $('#trainTo').val(),
            boarding: $('#trainBoarding').val(),
            class: this.addFormData.class,
            pnrNo: this.addFormData.pnrNo+'',
            transactionNo: this.addFormData.transactionNo+'',
            quota: this.addFormData.quota
        }
    } else if (this.addFormData.bookingType == 'Bus') {
        bookingDetailsObj = {
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            returnJourneyDate: rjDate,
            returnJourneyDateTP: rjDateTP,
            busName: this.addFormData.busName,
            returnBusName: this.addFormData.returnBusName,
            busType: this.addFormData.busName,
            busCoachType: this.addFormData.busCoachType,
            busDepTime: this.addFormData.busDepTime,
            //busTicketNo:this.addFormData.busTicketNo,
            busBoardingAddress: this.addFormData.busBoardingAddress,
            from: this.addFormData.from,
            to: this.addFormData.to,
            boarding: this.addFormData.boarding,
            pnrNo: this.addFormData.pnrNo+'',
            transactionNo: this.addFormData.transactionNo+''
        }
    } else if (this.addFormData.bookingType == 'Air') {
        bookingDetailsObj = {
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            returnJourneyDate: rjDate,
            returnJourneyDateTP: rjDateTP,
            airName: this.addFormData.airName,
            airPreference: this.addFormData.airPreference,
            airClass: this.addFormData.airClass,
            airFlight: this.addFormData.airFlight,
            returnAirFlight: this.addFormData.returnAirFlight,
            airDetails: this.addFormData.airDetails,

            airBaggage_purchased: this.addFormData.airBaggage_purchased,
            airBaggage_cabin: this.addFormData.airBaggage_cabin,
            airBaggage_free: this.addFormData.airBaggage_free,
            airReference_AO: this.addFormData.airReference_AO,
            airReference_Airline: this.addFormData.airReference_Airline,
            airReference_CRS: this.addFormData.airReference_CRS,
            airDepTerminal: this.addFormData.airDepTerminal,
            airArrTerminal: this.addFormData.airArrTerminal,
            airDepTime: this.addFormData.airDepTime,
            airArrTime: this.addFormData.airArrTime,

            //from: this.addFormData.from,
            //to: this.addFormData.to,
            //boarding: this.addFormData.boarding,
            from: $('#airFrom').val(),
            to: $('#airTo').val(),
            boarding: $('#airBoarding').val(),
            airSector: this.addFormData.airSector,
            pnrNo: this.addFormData.pnrNo+'',
            referenceNo: this.addFormData.referenceNo+''
        }
        //console.log('bookingDetailsObj>>>>', bookingDetailsObj);
    } else if (this.addFormData.bookingType == 'Package') {
        bookingDetailsObj = {
            journeyDate: jDate,
            returnJourneyDate: rjDate,
            packageName: this.addFormData.packageName,
            packageCostAdult: this.addFormData.packageCostAdult,
            packageCostChild: this.addFormData.packageCostChild,
            packageOriginalCostAdult: this.addFormData.packageOriginalCostAdult,
            packageOriginalCostChild: this.addFormData.packageOriginalCostChild,
            packageId: this.addFormData.packageId
        }
    } else if (this.addFormData.bookingType == 'Hotel') {
        bookingDetailsObj = {
            hotelName: this.addFormData.hotelName,
            hotelNumber: this.addFormData.hotelNumber,
            hotelAddress: this.addFormData.hotelAddress,
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            checkOutDate: getFullDate(this.addFormData.checkOutDateDD, this.addFormData.checkOutDateMM, this.addFormData.checkOutDateYY),
            checkOutDateTP: this.Services.fullDateTPFunc(this.addFormData.checkOutDateDD, this.addFormData.checkOutDateMM, this.addFormData.checkOutDateYY),
            roomType: this.addFormData.roomType,
            noOfNights: this.addFormData.noOfNights,
            noOfRooms: this.addFormData.noOfRooms,
        }
    } else if (this.addFormData.bookingType == 'Visa') {
        bookingDetailsObj = {
            visaType: this.addFormData.visaType,
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            validTill: getFullDate(this.addFormData.validTillDD, this.addFormData.validTillMM, this.addFormData.validTillYY),
            validTillTP: this.Services.fullDateTPFunc(this.addFormData.validTillDD, this.addFormData.validTillMM, this.addFormData.validTillYY),
            travelingTo: this.addFormData.travelingTo,
            visaName: this.addFormData.visaName,
            visaIssuesFrom: this.addFormData.visaIssuesFrom,
            contactNo1: this.addFormData.contactNo1,
            passportNo: this.addFormData.passportNo
        }
    } else if (this.addFormData.bookingType == 'Vehicle') {
        var jEndDate = getFullDate(this.addFormData.vehicleJourneyEndDateDD, this.addFormData.vehicleJourneyEndDateMM, this.addFormData.vehicleJourneyEndDateYY);

        bookingDetailsObj = {
            journeyDate: jDate,
            returnJourneyDate: rjDate,
            vehicleJourneyEndDate: getFullDate(this.addFormData.vehicleJourneyEndDateDD, this.addFormData.vehicleJourneyEndDateMM, this.addFormData.vehicleJourneyEndDateYY),
            vehicleSeater: this.addFormData.vehicleSeater,
            vehicleName: this.addFormData.vehicleName,
            vehicleNo: this.addFormData.vehicleNo,
            vehicleStartingKms: this.addFormData.vehicleStartingKms,
            vehicleEndingKms: this.addFormData.vehicleEndingKms,
            vehicleGarageStartingTime: this.addFormData.vehicleGarageStartingTime,
            vehicleGarageEndingTime: this.addFormData.vehicleGarageEndingTime,
            vehicleDriverName: this.addFormData.vehicleDriverName,
            vehicleDriverMob: this.addFormData.vehicleDriverMob,
            from: this.addFormData.from,
            to: this.addFormData.to,
            boarding: this.addFormData.boarding,
        }
    } else if (this.addFormData.bookingType == 'Expense') {
        bookingDetailsObj = {
            expenseType: this.addFormData.expenseType,
            journeyDate: jDate,
            journeyDateTP: jDateTP,
            contactNo1: this.addFormData.contactNo1,
            vendorInvoiceNo: this.addFormData.vendorInvoiceNo,
            description: this.addFormData.description
        }
    } 

    packageInfo1 = {
        ticketFlag: this.addFormData.packageInfo1.ticketFlag,
        invoiceNo: this.addFormData.packageInfo1.invoiceNo,
        tourId: this.addFormData.packageInfo1.tourId
    }

    passengerCountObj = {
        adult: this.addFormData.adult*1,
        child: this.addFormData.child*1,
        total: this.addFormData.total*1
    }

    chargesDetailsObj = {
        quota: this.addFormData.chargesDetails.quota,
        eTicket: this.addFormData.chargesDetails.eTicket,
        pg: this.addFormData.chargesDetails.pg,
        catering: this.addFormData.chargesDetails.catering,
        convenience: this.addFormData.chargesDetails.convenience,
        insurance: this.addFormData.chargesDetails.insurance,
        travelAgentCharge: this.addFormData.chargesDetails.travelAgentCharge,
        ticketFare: this.addFormData.chargesDetails.ticketFare,
        service: this.addFormData.chargesDetails.service,
        miscCharges: this.addFormData.chargesDetails.miscCharges,
        taxes: this.addFormData.chargesDetails.taxes,
        discountFrmAirlines: this.addFormData.chargesDetails.discountFrmAirlines,
        total: this.addFormData.chargesDetails.total,
        serviceTax: this.addFormData.chargesDetails.serviceTax,
        cgstPer: this.addFormData.chargesDetails.cgstPer,
        sgstPer: this.addFormData.chargesDetails.sgstPer,
        igstPer: this.addFormData.chargesDetails.igstPer,
        cgstVal: this.addFormData.chargesDetails.cgstVal,
        sgstVal: this.addFormData.chargesDetails.sgstVal,
        igstVal: this.addFormData.chargesDetails.igstVal,
        grandTotal: this.addFormData.chargesDetails.grandTotal,
        hiddenServiceCharges: this.addFormData.chargesDetails.hiddenServiceCharges,
        totalOtherChargesData: this.addFormData.chargesDetails.totalOtherChargesData,
        totalOtherCharges: this.addFormData.chargesDetails.totalOtherCharges,
        creditAmount: this.addFormData.chargesDetails.creditAmount,
        cancellationRailway: this.addFormData.chargesDetails.cancellationRailway,
        cancellationService: this.addFormData.chargesDetails.cancellationService,
        cancellationRailwayTotal: this.addFormData.chargesDetails.cancellationRailwayTotal,
        cancellationServiceTotal: this.addFormData.chargesDetails.cancellationServiceTotal
    }

    if (this.addFormData.bookingType == 'Vehicle') {
        chargesDetailsObj['amountPerKm'] = this.addFormData.chargesDetails.amountPerKm;
        chargesDetailsObj['totalKms'] = this.addFormData.chargesDetails.totalKms;
        chargesDetailsObj['totalHours'] = this.addFormData.chargesDetails.totalHours;
    }

    let paymentDetailsObj:any = {
        paymentStatus: this.addFormData.paymentDetails.paymentStatus,
        paymentFrom: this.addFormData.paymentDetails.paymentFrom,
        paymentBy: this.addFormData.paymentDetails.paymentBy,
        paymentByChequeNo: this.addFormData.paymentDetails.paymentByChequeNo,
        paymentByBankName: this.addFormData.paymentDetails.paymentByBankName,
        paymentDate: payD,
        paymentDateTP: this.Services.fullDateTPFunc(this.addFormData.paymentDetails.paymentDateDD , this.addFormData.paymentDetails.paymentDateMM , this.addFormData.paymentDetails.paymentDateYY),
        creditNoteFlag: this.addFormData.paymentDetails.creditNoteFlag,
        creditNotePaymentStatus: this.addFormData.paymentDetails.creditNotePaymentStatus,
        creditNotePaymentDate: this.addFormData.paymentDetails.creditNotePaymentDate,
        creditNotePaymentDateTP: 0,
        creditNoteNo: this.addFormData.paymentDetails.creditNoteNo,
        creditNoteDate: getCustomDateFunc(this.addFormData.paymentDetails.creditNoteDate),
        creditNoteDateTP: 0,
        discount: this.addFormData.paymentDetails.discount,
        pendingAmount: this.addFormData.paymentDetails.pendingAmount,
        paymentReceived: this.addFormData.paymentDetails.paymentReceived,
        advancePayment: this.addFormData.paymentDetails.advancePayment,
        cancelled: this.addFormData.paymentDetails.cancelled,
        remark: this.addFormData.paymentDetails.remark,
        hiddenAmount1: this.addFormData.paymentDetails.hiddenAmount1,
        hiddenAmount2: this.addFormData.paymentDetails.hiddenAmount2
    }

    if (this.addFormData.bookingType == 'Bus') {
        paymentDetailsObj.busTravelPaymentStatus = this.addFormData.paymentDetails.busTravelPaymentStatus;
    }

    agentDetailsObj = {
        agentType: this.addFormData.agentDetails.agentType,
        userId: this.addFormData.agentDetails.userId,
        payment: this.addFormData.agentDetails.payment,
        agentGSTIN: this.addFormData.agentDetails.agentGSTIN
    }

    otherDetailsObj = {
        otherDetailsId: this.addFormData.otherDetails.otherDetailsId,
        createdBy: this.addFormData.otherDetails.createdBy,
        createdDate: this.addFormData.otherDetails.createdDate,
        createdTime: this.addFormData.otherDetails.createdTime,
        modifiedBy: this.addFormData.otherDetails.modifiedBy,
        modifiedDate: this.addFormData.otherDetails.modifiedDate,
        modifiedTime: this.addFormData.otherDetails.modifiedTime
    }

    if (this.addFormData.bookingType == 'Vehicle') {
        var rowObj = {
            checkbox: false,
            name: this.addFormData.pd0.name
        }
        passengerDetailsObj.push(rowObj);
    } else {
        for (var i = 0; i < $('.pdRow').length; i++) {
            var scop = eval('this.addFormData.pd' + i);
            if (scop == undefined) { break; }
            let rowObj = {
                checkbox: false,
                empId: scop.empId,
                empLevel: scop.empLevel,
                name: scop.name,
                sex: scop.sex,
                age: scop.age,
                coachNoSeat: scop.coachNoSeat,
                planId: scop.planId,
                ticketNo: scop.ticketNo
            }
            if( (scop.name).length > 2 ){
                passengerDetailsObj.push(rowObj);
            }
        }
    }


    var fulDateD = this.addFormData.invoiceFullDateDD;
    if (fulDateD < 10) { fulDateD = '0' + fulDateD }
    var fulDateM = this.addFormData.invoiceFullDateMM;
    if (fulDateM < 10) { fulDateM = '0' + fulDateM }

    var clrDateD = this.addFormData.clientRequestDateDD;
    if (clrDateD < 10) { clrDateD = '0' + clrDateD }
    var clrDateM = this.addFormData.clientRequestDateMM;
    if (clrDateM < 10) { clrDateM = '0' + clrDateM }

    /*this.fullDateTPFunc = function(dd,mm,yy) {
        var d = new Date('' + mm + '/' + dd + '/' + yy + '');
        var n = d.getTime();
        return n;
    }*/
    var addFormJsonData1 = {
        invoiceNo: this.addFormData.invoiceNo,
        client: this.addFormData.client,
        clientGSTIN: this.addFormData.clientGSTIN,
        typeOfClient: this.addFormData.typeOfClient,
        //invoiceFullDate:getCustomDateFunc(this.addFormData.invoiceFullDate),
        invoiceFullDate: fulDateD + '/' + fulDateM + '/' + this.addFormData.invoiceFullDateYY,
        invoiceFullDateTP: this.Services.fullDateTPFunc(this.addFormData.invoiceFullDateDD,this.addFormData.invoiceFullDateMM,this.addFormData.invoiceFullDateYY),

        clientRequestDate: clrDateD + '/' + clrDateM + '/' + this.addFormData.clientRequestDateYY,
        clientRequestDateTP: this.Services.fullDateTPFunc(this.addFormData.clientRequestDateDD,this.addFormData.clientRequestDateMM,this.addFormData.clientRequestDateYY),
        address: this.addFormData.address,
        contactNo1: this.addFormData.contactNo1,
        contactNo2: this.addFormData.contactNo2,
        approvedBy: this.addFormData.approvedBy,
        bookingType: this.addFormData.bookingType,
        travelPlanId: this.addFormData.travelPlanId,
        advanceBooking: this.addFormData.advanceBooking,
        //roundTrip: this.addFormData.roundTrip,
        roundTripNew: this.addFormData.roundTripNew,
        roundTripReferenceInvoiceNo: this.roundTripNewReferenceInvoiceNo,
        bookingDetails: bookingDetailsObj,
        passengerDetails: passengerDetailsObj,
        passengerCount: passengerCountObj,
        packageInfo1Details: packageInfo1,
        chargesDetails: chargesDetailsObj,
        paymentDetails: paymentDetailsObj,
        agentDetails: agentDetailsObj,
        otherDetails: otherDetailsObj
    };
    //console.log('addFormJsonData1 >>>>>> ', addFormJsonData1);
    
    this.showSubmitFlag=false;
    //>>>>>>>var data = { addFormJsonDataType: this.addFormJsonDataTypeVal, addFormJsonData2: addFormJsonData1, userID: $rootScope.bookingUNM2 };
    
    var data = { addFormJsonDataType: this.addFormJsonDataTypeVal, addFormJsonDataTypeID: this.addFormJsonDataTypeID, addFormJsonData2: addFormJsonData1 };
    //console.log('data>>>>', data);
    
    
    this.Services.addData(data).subscribe(res =>{
      this.disabled = false;
      //this.addFormData = {};
      localStorage._booking = JSON.stringify({ 'addUpdate': '', 'id': '' });
      if( res.roundTripNewInfo.roundTripNew == "true" ){
        //this.addFormData.roundTripNewReferenceInvoiceNo = res.roundTripNewInfo.roundTripNewInvoiceNo;
        this.roundTripNewReferenceInvoiceNo = res.roundTripNewInfo.roundTripNewInvoiceNo;
        this.showSubmitFlag = true;
        this.submitButton = "Submit Round Trip";
        alert('Your Round Trip 1st ticket added successful.');
        setTimeout(()=>{
            this.addFormData.roundTripNew = 'false';
            let f = this.addFormData.from;
            let t = this.addFormData.to;
            this.addFormData.from = t;
            this.addFormData.to = f;
            this.addFormData.boarding = f;
            this.addFormData.roundTripNewReferenceInvoiceNo = this.roundTripNewReferenceInvoiceNo;
        },1500);
      }
      else{
        this.router.navigate(['/list']);
      }
    },
    err => {
      alert('The ticket couldnt be submit, kindly resubmit.');
      this.showSubmitFlag=true;
      //error
      console.log(err);
      //return err;
    });

  }

  getDate = function(element) {
    var date;
    try {
        let dateFormat= 'dd M, yy';
        date = $.datepicker.parseDate(dateFormat, element.value);
    } catch (error) {
        date = null;
    }

    return date;
  }
  

}







