import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { HttpModule, Http, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../common.service';
import { parse } from 'querystring';

declare var jquery:any;//this way u can include other MD5 file/JQuery library
declare var $:any;//this way u can include other MD5 file/JQuery library

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  status;
  dt = new Date();
  dd = 0;
  mm = 0;
  yy = 0;
  reportsPageData:any={};
  dynamicYear;
  allpagetitle='';
  bookingTypeList;
  //headerSection;
  specialClientFunc:any;
  specialClient:any;

  salesFlag = false;
  costFlag = false;
  receivableFlag = false;
  undepositedFlag = false;
  payableFlag = false;

  reportsPage = {
    "bookingType":"",
    "dateList":[],
    "dateFrm" : 0,
    "dateTo" : 0,
    "monthList" : [],
    "monthFrm" : 0,
    "monthTo" : 0,
    "yearList" : [],
    "yearFrm" : 0,
    "yearTo" : 0
  }

  constructor(private router:Router, private http:HttpClient, private Services:CommonService) { }

  ngOnInit() {
    this.Services.checkValidURL('reports');
    //this.headerSection = true;

    this.dd = this.dt.getDate();
    this.mm = this.dt.getMonth();
    this.yy = this.dt.getFullYear();
    
    this.dynamicYear = function(){
      var yr = [];
      for(var y1=2016; y1<=(this.yy+1); y1++){
        yr.push(y1);
      }
      return yr;
    } 

    this.reportsPage = {
      bookingType:"",
      dateList : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      dateFrm : 1,
      dateTo : this.dd,
      monthList : [1,2,3,4,5,6,7,8,9,10,11,12],
      monthFrm : this.mm,
      monthTo : (this.mm+1),
      yearList : this.dynamicYear(),
      yearFrm : this.yy,
      yearTo : this.yy,
    }
    this.specialClient = this.Services.specialClientObj();
    
    this.reportsPage.bookingType = 'BalanceSheet';
    this.updateReports('graphs');
    
  }

  
  //BFIU
  public getReportsData = function(myObj, graphName, serviceType='BK'){
    $('.panel-body .graph').addClass('loader1');
    let filterDataObj=myObj;

    let reportsJsonData1 = {
      searchType:'filter',
      searchBy:filterDataObj,
      searchFor:graphName
    };

    console.log(' reportsPageData.Train.grandTotal >>> ', this.reportsPageData );

    let data = {reportsJsonData2:reportsJsonData1, companyId:localStorage._bookingCID, companyUNM:localStorage._bookingUNM}
    if(serviceType == 'oneTime'){
      this.Services.reportsData(data).subscribe(res =>{
          let data = res;
          if(data.length == 0 ){
            alert('No Data Found');
          }
          else{
            this.reportsPageData = data.resObjData;
            console.log(' reportsPageData.Train.grandTotal >>> ', data.resObjData);

            $('.panel-body .graph').animate({'border':0},500,function(){
              $(this).removeClass('loader1');
            });
          }
      },
      // Errors will call this callback instead:
      err => {
        alert('No Data Found');
      });
    
    }
    
  }

  //BFIU
  public updateReports = function(param){
    if(param == 'graphs'){
      var frmY = this.reportsPage.yearFrm;
      var frmM = this.reportsPage.monthFrm;
      var frmD = this.reportsPage.dateFrm;
      var toY = this.reportsPage.yearTo;
      var toM = this.reportsPage.monthTo;
      var toD = this.reportsPage.dateTo;
      var f = (new Date(frmM+'/'+frmD+'/'+frmY)).getTime();
      var t = (new Date(toM+'/'+toD+'/'+toY)).getTime();
      if(f>t){ alert('Invalid selection of range'); return false }
      
      var vFinal = { "invoiceFullDateTP":{"$gte" : f, "$lte" : t} };
      this.getReportsData( vFinal, this.reportsPage.bookingType, 'oneTime');
      
    }
  }

  generateReport(){

  }

}
