import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  thankData;
  constructor(private router:Router) {
    this.thankData = JSON.parse(localStorage._submitedData);
    console.log(this.thankData)
  }

  ngOnInit() {
  }

}
